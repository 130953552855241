import moment from "moment";

export const getNewDatesTimesObj = (
  hideDateFields,
  day = [],
  setFieldValue,
  rule
) => {
  const date = new Date();

  setFieldValue(
    "startDate",
    hideDateFields
      ? ""
      : rule !== "IN_STORE_PICKUP_ONLY"
      ? moment(date).format("YYYY-MM-DD")
      : ""
  );

  setFieldValue(
    "endDate",
    hideDateFields
      ? ""
      : rule !== "IN_STORE_PICKUP_ONLY"
      ? moment(date).format("YYYY-MM-DD")
      : ""
  );

  setFieldValue(
    "day",
    day?.length
      ? []
      : [
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
        ]
  );
};

export const newException = [
  {
    label: "",
    criteria: "",
    cityZipInfo: {},
    addressInfo: { country: "US" },
    status: true,
    additionalFee: "",
    day: [],
    productType: "",
    startDate: "",
    endDate: "",
    rule: "",
  },
];
