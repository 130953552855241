import React, { useEffect, useState } from "react";
import { View, Text, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import orderBy from "lodash/orderBy";
import toUpper from "lodash/toUpper";
import capitalize from "lodash/capitalize";
import { request } from "library/utils/request";

import {
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import { RichTextEditor, ToasterHandler } from "components/elements";
import { ConfirmModal, SecondaryButton } from "components/wrappers";
import { PreviewUrl, BulkActionsPicker } from "../helper";
import { ProductIDError } from "./helper";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  selectAllCollections,
  selectSharedCatalogIds,
  selectSharedCatalogTitles,
  selectScreenTitles,
  selectSharedCatalogs,
  selectAllowSyndicate,
  selectIsCatalogManager,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { navigateScreen } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  deleteProduct,
  restoreProduct,
  setBulkAction,
} from "library/sagas/views/home/drawer/product-catalog/product/slice";

import { selectBulkActionType } from "library/sagas/views/home/drawer/product-catalog/product/selector";
import { colors, fonts, theme } from "styles/theme";
import tw from "tailwind-rn";

const Details = ({ isCreateProduct, updateDisableStatus }) => {
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);

  const [collectionQuery, setCollectionQuery] = useState("");
  const [catalogQuery, setCatalogQuery] = useState("");

  const {
    values: {
      productId,
      group,
      productType,
      collections: floristCollections = [],
      productGroup = [],
      description,
      seoDisplayUrl,
    },
    setFieldValue,
    errors,
    touched,
  } = useFormikContext();

  const collectionsData = useSelector(selectAllCollections);
  const sharedCatalogIds = useSelector(selectSharedCatalogIds);
  const screenTitles = useSelector(selectScreenTitles);
  const sharedCatalogTitles = useSelector(selectSharedCatalogTitles);
  const sharedCatalogs = useSelector(selectSharedCatalogs);
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const catalogManager = useSelector(selectIsCatalogManager);
  const bulkActionType = useSelector(selectBulkActionType);
  const isMOLProduct = group === "mol";
  const isReadOnly = !isCreateProduct && isMOLProduct;
  const canDelete = !isCreateProduct && !isMOLProduct && catalogManager;
  const isProduct = productType !== "addon";
  const isAddon = productType === "addon";
  const collections = floristCollections.filter((val) => val !== "addons");
  const productTypeData = !isAddon
    ? [
        { label: "Florist Delivered", value: "product" },
        { label: "Dropship", value: "dropship" },
      ]
    : [{ label: "Add-on", value: "addon" }];
  const [pageError, setPageError] = useState("");
  const [modalVisible, setModalVisibile] = useState("");
  const dispatch = useDispatch();
  const setBulkActionType = (action) => dispatch(setBulkAction(action));
  const showAddonsCollectionMapping = false; //clear the flag when addon-collection mapping changes are done
  const showCollectionsDropDown =
    showAddonsCollectionMapping &&
    (!isCreateProduct ? isAddon && bulkActionType?.value : isAddon);

  useEffect(() => {
    if (isCreateProduct) {
      setPageError("");
      if (productId) {
        request("get-product", {
          productId,
          includeDeletedProducts: true,
        })
          .then((response) => {
            const isExist = !!response.productId;
            const isRestore = response.availability.isDeleted;

            const createProductErrors = {
              restoreProduct:
                "The Product ID you are trying to create was previously deleted, would you like to restore the product?",
              productExists:
                "The Product ID you are trying to create already exists, please try another one.",
            };

            if (isExist) {
              setPageError(
                isRestore
                  ? createProductErrors.restoreProduct
                  : createProductErrors.productExists
              );
              updateDisableStatus(true);
            } else {
              setPageError("");
              updateDisableStatus(false);
            }
          })
          .catch(() => {
            setPageError("");
            updateDisableStatus(false);
          });
      }
    }
  }, [productId]);

  const onRestore = () => {
    dispatch(
      restoreProduct({
        params: {
          productId,
        },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `Product ${productId} has been restored`)
          );
          setTimeout(() => {
            dispatch(
              navigateScreen({
                name: "product",
                params: {
                  handle: productId,
                },
              })
            );
          }, 1000);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "Product restore action failed, please try again."
            )
          );
        },
      })
    );
  };

  const onDelete = () => {
    dispatch(
      deleteProduct({
        params: {
          productId,
        },
        resolve: () => {
          ToasterHandler(
            "nice",
            Localise(messages, `Product ${productId} has been deleted`)
          );
          setTimeout(() => {
            dispatch(
              navigateScreen({
                name: "dashboard",
                params: {
                  handle: "",
                },
              })
            );
          }, 1000);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, "Deletion failed, please try again")
          );
        },
      })
    );
  };

  const handleProductAction = () => {
    setModalVisibile("");
    modalVisible === "delete" ? onDelete() : onRestore();
  };

  const handleNevermind = () => {
    setModalVisibile("");
  };
  const dropDownData = orderBy(
    collectionsData
      .filter(
        (e) =>
          toUpper(e.name).includes(toUpper(collectionQuery)) &&
          toUpper(e.name) !== "ADDONS" &&
          toUpper(e.name) !== "MOLTAX"
      )
      .map((e) => ({
        label: Localise(messages, e.name.split(" ").map(capitalize).join(" ")),
        value: e.handle,
      })),
    "label",
    "asc"
  );

  const collectionsDropDown = isAddon
    ? [{ label: "All Products", value: "*" }].concat(dropDownData)
    : dropDownData;

  return (
    <View style={tw("flex flex-row flex-wrap")}>
      <FormField
        name="name"
        label="Name"
        labelStyle={fonts.heading4}
        containerStyle={{
          width: isMobile ? "100%" : "66%",
        }}
        editable={!isReadOnly}
        disabled={isReadOnly}
        maxLength={255}
      />

      {isProduct && sharedCatalogIds.length > 0 && (
        <FormFieldAutoComplete
          name="productGroup"
          label="Catalogs"
          outerContainerStyle={{
            zIndex: 11,
            width: isMobile ? "100%" : "34%",
          }}
          labelStyle={fonts.heading4}
          data={orderBy(
            sharedCatalogs
              .filter(
                (e) =>
                  toUpper(e.groupId).includes(toUpper(catalogQuery)) &&
                  toUpper(e.groupId) !== "ADDONS" &&
                  toUpper(e.groupId) !== "ALL_CATALOGS"
              )
              .map((e) => ({
                label: Localise(
                  messages,
                  screenTitles[e.groupId] || sharedCatalogTitles[e.groupId]
                ),
                value: e.groupId,
              })),
            "label",
            "asc"
          )}
          placeholder={
            productGroup?.length > 0
              ? productGroup?.length == 1
                ? screenTitles[productGroup[0]] ||
                  sharedCatalogTitles[productGroup[0]]
                : `${productGroup?.length} ${Localise(messages, "Selected")}`
              : Localise(messages, "Add to Catalog(s)")
          }
          initialDataLength={sharedCatalogIds.length}
          isMultiSelect={true}
          onSelect={() => {
            setFieldValue("productGroup", productGroup);
          }}
          onBlur={() => setCatalogQuery("")}
          showOnFocus={true}
          setFocusBack={true}
          onChangeText={(val) => setCatalogQuery(val)}
          listDisplayValues={["label"]}
        />
      )}

      <FormField
        name="productId"
        label="ID"
        containerStyle={{ width: isMobile ? "100%" : "33%" }}
        labelStyle={fonts.heading4}
        isUpdateOnChange={!isDesktop}
        transformText={(val) => toUpper(val).replace(/[^a-zA-Z0-9-]/g, "")}
        editable={isCreateProduct}
        disabled={!isCreateProduct}
        useFormikValue={Platform.OS === "android"}
      />
      {isMobile && isCreateProduct && (
        <ProductIDError
          error={pageError}
          onRestore={() => setModalVisibile("restore")}
        />
      )}

      <FormFieldPicker
        name="productType"
        label="Type"
        labelStyle={fonts.heading4}
        containerStyle={{ width: isMobile ? "100%" : "33%", marginBottom: 10 }}
        placeholder={{
          label: "Select Product Type",
        }}
        data={productTypeData}
        disabled={isAddon}
        disabledFieldTouch={true}
      />
      {showAddonsCollectionMapping && isAddon ? (
        <View style={tw("flex flex-col mt-5")}>
          {!isCreateProduct ? (
            <BulkActionsPicker
              type={"addons"}
              bulkActionType={bulkActionType}
              setBulkActionType={setBulkActionType}
              isProductPage={true}
            />
          ) : null}
        </View>
      ) : null}
      {(isProduct || showCollectionsDropDown) && collectionsData.length > 0 ? (
        <FormFieldAutoComplete
          name="collections"
          label={"Collections"}
          outerContainerStyle={{
            zIndex: 10,
            width: isMobile ? "100%" : "34%",
          }}
          labelStyle={fonts.heading4}
          data={collectionsDropDown}
          placeholder={
            collections?.length > 0
              ? collections?.length == 1
                ? collectionsDropDown[
                    collectionsDropDown?.findIndex(
                      (i) => i.value == collections[0]
                    )
                  ]?.label
                : `${collections?.length} ${Localise(messages, "Selected")}`
              : bulkActionType?.value?.includes("remove")
              ? Localise(messages, "Remove from Collection(s)")
              : Localise(messages, "Add to Collection(s)")
          }
          initialDataLength={collectionsData.length}
          isMultiSelect={true}
          onSelect={(selectedValue) => {
            const newCollections = [...collections];
            const index = newCollections.indexOf(selectedValue.value);
            const unSelected = index >= 0;
            unSelected
              ? newCollections.splice(index, 1)
              : newCollections.push(selectedValue.value);

            setFieldValue("collections", newCollections);
          }}
          onBlur={() => setCollectionQuery("")}
          showOnFocus={true}
          setFocusBack={true}
          onChangeText={(val) => setCollectionQuery(val)}
          listDisplayValues={["label"]}
        />
      ) : null}
      {!isMobile && isCreateProduct && (
        <ProductIDError
          error={pageError}
          onRestore={() => setModalVisibile("restore")}
        />
      )}
      {!isCreateProduct && (
        <View
          style={tw("flex flex-row justify-between items-center mb-3 w-full")}
        >
          <View style={tw("flex flex-1")}>
            <PreviewUrl url={seoDisplayUrl} />
          </View>
          {canDelete ? (
            <>
              <SecondaryButton
                action={() => setModalVisibile("delete")}
                title={isProduct ? "Delete Product" : "Delete Add-on"}
              />
            </>
          ) : null}
        </View>
      )}
      <View
        style={{
          width: "100%",
          marginHorizontal: 5,
          ...fonts.default,
          color: isReadOnly ? colors.medium : colors.dark,
        }}
      >
        <Text style={fonts.heading4}>{Localise(messages, "Description")}</Text>
        <View
          style={{
            borderColor: "#dddddd",
            borderWidth: 1,
            borderRadius: 2,
            marginTop: 4,
            minHeight: 150,
          }}
        >
          <RichTextEditor
            name={"description"}
            value={description}
            testID={"product_description"}
            setValue={(val) => {
              /*
                Img tag is appending when we hover on link and after click on it, link will open in new tab and in product description
                also img tag is appending so we are replacing it with empty string.
              */
              setFieldValue("description", val.replace(/<img[^>]*>/g, ""));
            }}
            style={{
              minHeight: 150,
            }}
            scrollEnabled={true}
            readOnly={isReadOnly}
            placeholder={Localise(messages, "Enter Description")}
            textAlignment={"left"}
            toolbarOptions={
              Platform.OS === "web"
                ? {
                    options: ["link", "inline"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                  }
                : ["link", "bold", "italic", "underline"]
            }
          />
        </View>
      </View>
      {touched.description && errors.description && (
        <Text style={[theme.Picker.errorStyle, { marginHorizontal: 5 }]}>
          {Localise(messages, errors.description)}
        </Text>
      )}
      <ConfirmModal
        modalVisible={!!modalVisible}
        handlePrimary={handleProductAction}
        handleSecondary={handleNevermind}
        data={{
          modal: {
            primary: modalVisible === "delete" ? "Delete" : "Restore",
            secondary: "Nevermind",
            content: `${
              allowSyndicate
                ? "This change will apply to All shops and will override any local shop changes." +
                  "\n\n"
                : ""
            }Are you sure you want to ${modalVisible} this product?`,
          },
        }}
      />
    </View>
  );
};

export default Details;
