import React from "react";
import { Text, View } from "react-native";
import { useFormikContext } from "formik";
import { SubmitButton, ResetButton } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

const SaveCancelButtons = ({
  buttonTitle,
  onCancel,
  onSubmit,
  disableOnDirty = true,
  showCancelAlways = false,
  showCancel = true,
  disableOnErrors = false,
  buttonRef,
  cancelTestId = "cancel",
  saveTestId,
  customContainerStyles = {},
  isLoading = false,
  showWarningOnSave,
  warningText,
  disableSubmit = false,
  disableCancel = false,
  submitContainerStyle = {},
  saveCancelContainerStyles = {},
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { error } = useFormikContext();
  const { isDesktop } = React.useContext(DeviceContext);
  return (
    <View
      style={{
        ...tw("flex flex-col justify-end items-end"),
        ...saveCancelContainerStyles,
      }}
    >
      <Text style={{ ...fonts.heading4, color: colors.error }}>
        {Localise(messages, error) || ""}
      </Text>
      <View style={[tw("flex flex-row items-end")]}>
        <ResetButton
          disableOnDirty={disableOnDirty}
          containerStyle={{
            width: isDesktop ? 72 : 80,
            height: 40,
          }}
          title={Localise(messages, "Cancel")}
          titleStyle={{ fontSize: 12 }}
          refFromProp={buttonRef}
          disableOnErrors={disableOnErrors}
          testID={cancelTestId}
          onPressCallback={onCancel}
          customDisable={disableCancel}
        />

        <SubmitButton
          disableOnDirty={disableOnDirty}
          containerStyle={{
            minWidth: isDesktop ? 72 : 80,
            height: 40,
            marginLeft: -1,
            ...submitContainerStyle,
          }}
          saveCancel={true}
          title={buttonTitle}
          refFromProp={buttonRef}
          disableOnErrors={disableOnErrors}
          testID={saveTestId}
          onPressCallback={onSubmit}
          isLoading={isLoading}
          showWarningOnSave={showWarningOnSave}
          warningText={warningText}
          customDisable={disableSubmit}
        />
      </View>
    </View>
  );
};

export default SaveCancelButtons;
