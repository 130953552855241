import { put, call, select, fork } from "redux-saga/effects";

import { request } from "library/utils/request";

import {
  setApiResponse,
  setApiError,
  fetchZipCodesForExceptions,
} from "../slice";
import { selectShopCode } from "../../common/selector";
import { selectExceptionZips } from "../selector";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";

import moment from "moment";

export function* triggerGetZipcodesCall(content) {
  const existingZips = yield select(selectExceptionZips);
  const existingCities = Object.keys(existingZips);

  for (let i = 0; i < content.length; i++) {
    const city = get(content, `${i}.cityZipInfo.city`, "");
    const zipCodeDataExists = existingCities.includes(city);
    if (city && !zipCodeDataExists) {
      yield put(fetchZipCodesForExceptions(city));
    }
  }
}

export function* handleFetchDeliveryExceptions() {
  const serviceRequest = (params) => request("get-delivery-exceptions", params);
  const shopCode = yield select(selectShopCode);

  try {
    const response = yield call(serviceRequest, { shopCode });

    const content = processDeliveryExceptionsResponse(response);

    yield fork(triggerGetZipcodesCall, content);

    yield put(
      setApiResponse({
        path: "feesCoverage.deliveryExceptions",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.deliveryExceptions",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleSaveDeliveryExceptions(action = {}) {
  const { resolve, reject, params } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (payload) =>
    request("save-delivery-exceptions", payload);
  const requestPayload = preparePatchPayload(params);

  try {
    yield call(serviceRequest, {
      shopCode,
      requestPayload,
    });
    yield call(handleFetchDeliveryExceptions);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.deliveryExceptions",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleDeleteDeliveryExceptions(action = {}) {
  const {
    resolve,
    reject,
    params: { exceptionId, exceptionType },
  } = get(action, "payload", {});
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (payload) =>
    request("delete-delivery-exceptions", payload);

  try {
    yield call(serviceRequest, {
      shopCode,
      exceptionType,
      exceptionId,
    });
    yield call(handleFetchDeliveryExceptions);
    resolve && resolve();
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.deliveryExceptions",
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

export function* handleFetchCitiesForExceptions(action = {}) {
  const { payload: city } = action;

  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("get-exception-cities", params);

  try {
    const response = yield call(serviceRequest, {
      shopCode,
      city,
    });
    const content = processCitiesOrZipsResponse(response, "cities");
    yield put(
      setApiResponse({
        path: "feesCoverage.deliveryExceptionCities",
        content,
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.deliveryExceptionCities",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export function* handleFetchZipCodesForExceptions(action = {}) {
  const { payload: city = "" } = action;
  const serviceRequest = (params) => request("get-exception-city-zips", params);
  const shopCode = yield select(selectShopCode);
  try {
    const response = yield call(serviceRequest, { shopCode, city });
    const newZips = { [city]: processCitiesOrZipsResponse(response, "zips") };

    yield put(
      setApiResponse({
        path: "feesCoverage.deliveryExceptionZips",
        content: newZips,
        isPatch: true,
        contentType: "object",
      })
    );
  } catch (error) {
    yield put(
      setApiError({
        path: "feesCoverage.deliveryExceptionZips",
        error: "Something went wrong, please try again",
      })
    );
  }
}

export const isValidFeeValue = (fee) => {
  const isValid = (fee || fee === 0 || fee === "0") && !isNaN(fee);
  return isValid;
};

const processExceptionItem = (exception, rule) => {
  const {
    startDate,
    endDate,
    additionalFee,
    cityZipInfo,
    isDropShip = "",
    overwriteDeliveryFee,
  } = exception;

  return {
    ...exception,
    startDate: startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : "",
    endDate: endDate ? moment(new Date(endDate)).format("YYYY-MM-DD") : "",
    additionalFee: additionalFee ? additionalFee.toString() : "",
    cityZipInfo: cityZipInfo ? cityZipInfo : {},
    productType: isDropShip === "true" ? "dropship" : "floral",
    rule,
    overwriteDeliveryFee: overwriteDeliveryFee
      ? overwriteDeliveryFee.toString()
      : "",
  };
};

export const removeExpiredExceptions = (allExceptions) =>
  allExceptions.filter(
    ({ endDate }) =>
      !endDate ||
      !moment(moment().format("YYYY-MM-DD")).isAfter(moment(endDate))
  );

const processDeliveryExceptionsResponse = (response = {}) => {
  const additionalFeeInfo =
    get(response, "deliveryExceptions.additionalFeeInfo", []) || [];
  const doNotDeliverInfo =
    get(response, "deliveryExceptions.doNotDeliverInfo", []) || [];
  const inStorePickUpInfo =
    get(response, "deliveryExceptions.inStorePickUpInfo", []) || [];
  const overwriteDeliveryFeeInfo =
    get(response, "deliveryExceptions.overwriteDeliveryFeeInfo", []) || [];

  const additionalFeeExceptions = additionalFeeInfo.map((exception) =>
    processExceptionItem(exception, "ADDITIONAL_FEE")
  );
  const doNotDeliverExceptions = doNotDeliverInfo?.map((exception) =>
    processExceptionItem(exception, "DO_NOT_DELIVER")
  );
  const inStorePickupOnlyException = inStorePickUpInfo?.map((exception) =>
    processExceptionItem(exception, "IN_STORE_PICKUP_ONLY")
  );

  const overwriteDeliveryFeeExceptions = overwriteDeliveryFeeInfo?.map(
    (exception) => processExceptionItem(exception, "OVERWRITE_DELIVERY_FEE")
  );

  const allExceptions = [
    ...additionalFeeExceptions,
    ...doNotDeliverExceptions,
    ...inStorePickupOnlyException,
    ...overwriteDeliveryFeeExceptions,
  ];

  const updatedExpetions = removeExpiredExceptions(allExceptions);
  const sorted = updatedExpetions.sort(
    (a, b) => new Date(b.updatedOn) - new Date(a.updatedOn)
  );
  return sorted;
};

const preparePatchPayload = (params) => {
  const {
    cityZipInfo,
    additionalFee,
    startDate,
    endDate,
    productType = "",
    rule = "",
    overwriteDeliveryFee,
  } = params;
  const isAddressException = get(params, "criteria", "") === "ADDRESS";
  const excludeDays =
    productType !== "dropship" && rule !== "IN_STORE_PICKUP_ONLY";

  const payload = {
    ...params,
    rule: productType === "dropship" ? "DO_NOT_DELIVER" : params.rule,
    cityZipInfo: isEmpty(cityZipInfo) ? null : cityZipInfo,
    additionalFee:
      additionalFee === "" || additionalFee === null ? null : additionalFee,
    overwriteDeliveryFee:
      overwriteDeliveryFee === "" || overwriteDeliveryFee === null
        ? null
        : overwriteDeliveryFee,
    startDate: !startDate ? null : moment(startDate).utc(),
    endDate: !endDate ? null : moment(endDate).utc(),
    ...(productType === "dropship" ? { isDropShip: true } : {}),
  };
  !isAddressException && delete payload.addressInfo;
  excludeDays && delete payload.day;
  delete payload.productType;

  return payload;
};

const processCitiesOrZipsResponse = (response = {}, key) => {
  const values = get(response, `cityZipInfo.${key}`, []);

  return values.map((city) => ({
    label: startCase(toLower(city)),
    value: city,
  }));
};
