import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from "react-redux";
import ListingScreen from "components/containers/listing";
import OrdersList from "../orders-list";
import OrderDetails from "components/views/drawer/order-details";
import FloristSelection from "components/views/drawer/create-order/florist-selection";
import {
  UIConfig,
  InitOrdersData,
  InitViewMoreCounts,
  OrderFetchInterval,
  ListOfColumns,
  ListOfMobileColumns,
  ListOfSubColumns,
  ListOfActions,
  ListOfSubActions,
  ListOfFilters,
  ListOfSortOptions,
  ListOfOrderStatus,
} from "./config";
import {
  setRecordId,
  setDeliveryMethod,
  setSourceMemberCode,
  setActions,
  setRecordAction,
  fetchData,
  setLoading,
  resetBasicInfo,
  setDesignersAreaEnabled,
} from "library/sagas/ongoing/current-orders/slice";
import {
  fetchAddons,
  setCurrentPage,
} from "library/sagas/ongoing/global-data/slice";
import { DeviceContext } from "library/contexts/appSettings";
import fetchOrders from "library/utils/fetchOrders";
import UserProfileStorage from "library/storage/userProfile";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import cloneDeep from "lodash/cloneDeep";
import { PrintIframeRenderer } from "components/elements";
import {
  selectIsDesignersAreaEnabled,
  selectedLoader,
} from "library/sagas/ongoing/current-orders/selector";
import PageStorage from "library/storage/pageStorage";
import common from "library/utils/common";

const CurrentOrdersScreen = ({
  navigation: { setParams },
  route,
  currentOrdersInfo,
  global: { addons = [], shopCode: dashboardShopCode },
  fetchAddons,
  setCurrentPage,
  resetBasicInfo,
  ...userActions
}) => {
  // Filters applicable when clicked on dashboard widgets
  const { params = {} } = route;
  const { shops: paramShops = [], shopFilter = true } = params;
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const val = React.useRef();
  const {
    appliedFilters = [],
    shops = [],
    selectedShops = [],
    search = {},
    sort = {},
    orderIds = "",
  } = currentOrdersInfo?.actions || {};
  const shopNames = UserProfileStorage.getAllShopNames();
  const { setActions } = userActions;
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);
  const isLoading = useSelector(selectedLoader);

  //Checking whether the Design Center is active in the Redux state.
  const isDCActiveinRS = useSelector(selectIsDesignersAreaEnabled);

  React.useEffect(() => {
    if (dashboardShopCode !== "all" && !paramShops.length && shopFilter) {
      const selectedShops = [
        {
          ["Shop Codes"]: {
            title: `${dashboardShopCode} ${shopNames[dashboardShopCode]}`,
            value: dashboardShopCode,
          },
        },
      ];
      setActions({
        shops: selectedShops,
        selectedShops,
      });
    }
    // Discarding orders page data if we are redirected to the orders page with params
    if (!common.isObjectEmpty(params)) {
      PageStorage.clearPageData("Orders");
    }

    setParams({
      filters: undefined,
      shops: undefined,
      search: undefined,
      orderIds: undefined,
      shopFilter: undefined,
    }); // To eliminate the unwanted data in the URL when the user is from dashboard
    !addons.length && fetchAddons();

    //Checking whether the Design Center is active in session storage.
    const isDCActiveinSS = PageStorage.getOrdersPageActions(
      "isDesignCenterEnabled"
    );
    if (!isDCActiveinRS && isDCActiveinSS) {
      dispatch(setDesignersAreaEnabled(true));
      const actions = PageStorage.getOrdersPageActions("actions");
      dispatch(setActions(actions));
    } else {
      setActions({});
    }

    setCurrentPage("orders");

    isMounted.current = true;

    return () => {
      if (val.current) {
        fetchOrders(
          "current",
          { ...currentOrdersInfo, timeStamp: false },
          ListOfOrderStatus,
          InitOrdersData,
          userActions.fetchData
        );
      }
      resetBasicInfo();
    };
  }, []);

  React.useEffect(() => {
    val.current = appliedFilters.find(
      (obj) => obj["Date Range"] && obj["Date Range"].title === "Custom Range"
    );
  }, [appliedFilters]);

  //To retain the filters when the page is refreshed if the Design Center is active.
  React.useEffect(() => {
    if (isDCActiveinRS) {
      PageStorage.setOrdersPageActions("actions", currentOrdersInfo?.actions);
    }
  }, [currentOrdersInfo?.actions]);

  // Clearing the Design Center selections when there are any action changes during the updating phase of the Orders page component.
  React.useEffect(() => {
    if (isMounted.current && isDCActiveinRS && !isLoading) {
      const selections = PageStorage.getOrdersPageActions(
        "designCenterSelections"
      );
      PageStorage.setOrdersPageActions("designCenterSelections", {
        ...selections,
        bulkSelect: false,
        selectedOrders: [],
      });
    }
  }, [appliedFilters, selectedShops, search, sort, orderIds]);

  const settingsRoute = {
    page: "shop-settings",
    screen: "order-settings",
  };
  const createRoute = "create-order";

  let filterOptions = cloneDeep(ListOfFilters);
  let sortOptions = cloneDeep(ListOfSortOptions);

  let isMHQNonCoreUser = false;
  if (selectedShops.length > 0) {
    selectedShops.every((shop) => {
      isMHQNonCoreUser = isMHQNonCoreMember(shop["Shop Codes"].value);

      return !isMHQNonCoreUser;
    });
  } else {
    isMHQNonCoreUser = isMHQNonCoreMember(dashboardShopCode);
  }

  if (!isMHQNonCoreUser) {
    let categories = filterOptions.Categories;
    categories = categories.filter(
      (option) =>
        option.title !== "Rush Orders" && option.title !== "Timed Orders"
    );
    filterOptions.Categories = categories;

    sortOptions = sortOptions.filter(
      (option) => option.key !== "Default: Delivery Date + Rush/Timed"
    );
  }

  let sortDefaultValue = sortOptions[sortOptions.length - 1].key;

  return (
    <>
      <ListingScreen
        listingType="current"
        initConfig={UIConfig}
        initData={InitOrdersData}
        initViewMoreCounts={InitViewMoreCounts}
        pollInterval={OrderFetchInterval}
        columnsList={isMobile ? ListOfMobileColumns : ListOfColumns}
        subColumnsList={ListOfSubColumns}
        actionsList={ListOfActions}
        subActionsList={ListOfSubActions}
        sortOptions={sortOptions}
        sortDefaultValue={sortDefaultValue}
        filterOptions={filterOptions}
        statusList={ListOfOrderStatus}
        params={params}
        title={"Orders"}
        placeholder={"Search Orders"}
        showaction={false}
        showPriceRange={true}
        List={OrdersList}
        Details={OrderDetails}
        FloristSelection={FloristSelection}
        listingInfo={currentOrdersInfo}
        userActions={userActions}
        showDivider={false}
        showExistingSubHeader={false}
        showScreenHeader={true}
        showTitle={isDesktop ? false : true}
        settingsRoute={settingsRoute}
        createRoute={createRoute}
        shops={shops}
      />
      <PrintIframeRenderer testID={"order-listing-frame"} />
    </>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentOrdersInfo: state.mhq.ongoing.currentOrders,
    global: state.mhq.ongoing.global,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setDeliveryMethod,
      setSourceMemberCode,
      setRecordAction,
      setActions,
      fetchData,
      setLoading,
      fetchAddons,
      setCurrentPage,
      resetBasicInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentOrdersScreen);
