import React, { useCallback, useEffect } from "react";
import { ActivityIndicator, View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import { connect } from "react-redux";

import tw from "tailwind-rn";
import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

import { fonts, colors, shapes } from "styles/theme";

import {
  FormFieldAutoComplete,
  FormFieldCheckBox,
} from "components/elements/forms";

import I18NContext from "library/contexts/i18N";

import { getMembersInfo } from "library/utils/createOrder";
import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";
import Environment from "library/utils/environment";

//eslint-disable-next-line
export const FloristPartner = React.memo(
  ({
    index,
    title,
    orderInfo,
    setSideCarOpen,
    setSelectedFlorist,
    sendingMemberCode,
    memberCodes,
    addressVerificationInfo = {},
    values,
    setValues,
    canModifyOrder = true,
    isMultiOrderEligible,
  }) => {
    const [floristQuery, setFloristQuery] = useStateIfMounted("");
    const [florists, setFlorists] = useStateIfMounted([]);
    // eslint-disable-next-line no-unused-vars
    const [latLong, setLatLong] = useStateIfMounted({});
    // eslint-disable-next-line no-unused-vars
    const [senderLatLong, setSenderLatLong] = useStateIfMounted({});
    const [loading, setLoading] = useStateIfMounted(false);
    const [showError, setShowError] = useStateIfMounted(false);
    const [errorFlorists, setErrorFlorists] = useStateIfMounted(0);
    const finalValues = cloneDeep(values);

    const { messages, Localise } = React.useContext(I18NContext);

    const {
      deliveryInfo: {
        deliveryDate = "",
        deliveryMethod = "",
        floristInfo = {},
      },
      recipientInfo: {
        addressLine1,
        suite = "",
        city,
        state,
        zip = "",
        country,
      },
      lineItems,
      price,
    } = orderInfo;

    const avsInfo = addressVerificationInfo[index] || {};
    const { timeStamp = "" } = avsInfo;

    const { productFirstChoiceCode = "", actualPrice } = lineItems.length
      ? lineItems[0]
      : {};
    const { value: updatedPrice } = price.length ? price[0] : {};

    const path = `orderItems.${index}.deliveryInfo`;

    const isWireOut = deliveryMethod === "FLORIST_PARTNER";
    const deliveryFieldLabel = isWireOut
      ? Localise(messages, "Florist Partner Selection")
      : Localise(messages, "Transfer to Shop");
    const deliveryFieldLabelPlaceholder = isWireOut
      ? "Florist Partner will appear here once product, price, address, and date fields are completed."
      : "Search for a Shop to transfer";
    const showFloristNetworkLink = !!(
      zip &&
      deliveryDate &&
      productFirstChoiceCode
    );

    useEffect(() => {
      if (
        zip &&
        zip.length >= 3 &&
        deliveryDate &&
        state &&
        addressLine1 &&
        city &&
        country &&
        productFirstChoiceCode &&
        updatedPrice
      ) {
        const { latitude, longitude } = latLong;
        const requestParams = {
          memberCode: memberCodes[0],
          startDeliveryDate: deliveryDate,
          endDeliveryDate: deliveryDate,
          latitude: latitude,
          longitude: longitude,
          productCode: productFirstChoiceCode,
          zipCode: zip,
          addressLine1: encodeURIComponent(addressLine1),
          suite: encodeURIComponent(suite),
          city: encodeURIComponent(city),
          state: state,
          country: country,
          sendingMemberCode: sendingMemberCode,
          actualPrice: actualPrice || updatedPrice,
          updatedPrice,
        };
        setLoading(true);
        setShowError(false);
        request("get-florists-new", requestParams)
          .then((res) => {
            const {
              membersInfo,
              senderLatLong,
              errorFloristsCount = 0,
            } = getMembersInfo(res, false, false, true);
            if (membersInfo.length > 0) {
              const validMember = membersInfo.find(
                (memberInfo) =>
                  memberInfo.basicInfo.memberCode === floristInfo.memberCode
              );

              !validMember &&
                setSelectedFlorist({
                  florist: membersInfo[0].basicInfo,
                  formIKPath: path,
                });
              setShowError(false);
              setFlorists(membersInfo);
              setSenderLatLong(senderLatLong);
              setLoading(false);
            } else if (errorFloristsCount > 0) {
              setSelectedFlorist({ florist: {}, formIKPath: path });
              setFlorists([]);
              setErrorFlorists(errorFloristsCount);
              setShowError(true);
              setLoading(false);
            } else {
              setSelectedFlorist({ florist: {}, formIKPath: path });
              setFlorists([]);
              setErrorFlorists(errorFloristsCount);
              setShowError(true);
              setLoading(false);
            }
          })
          .catch(() => {
            setSelectedFlorist({ florist: {}, formIKPath: path });
            setFlorists([]);
            setShowError(true);
            setLoading(false);
          });
      } else {
        setSelectedFlorist({ florist: {}, formIKPath: path });
        setFlorists([]);
      }
    }, [deliveryDate, timeStamp, productFirstChoiceCode, updatedPrice]);

    const filterFlorists = useCallback(() => {
      const key = floristQuery?.toLocaleLowerCase().trim() || "";
      if (key === "" && florists.length > 0)
        return florists.map((florist) => {
          // As we need to show only florist name, membercode, amount, distance, phone number in the florist options, we are filtering other data
          let availableFlorist = {};

          Object.keys(florist?.basicInfo).filter((value) => {
            if (!["addressLine1", "city", "state", "zip"].includes(value)) {
              availableFlorist[value] = florist?.basicInfo?.[value];
            }
          });

          return availableFlorist;
        });

      const results = florists.filter(
        ({ basicInfo: florist }) =>
          florist.name.toLocaleLowerCase().includes(key) ||
          florist.memberCode.toLocaleLowerCase().includes(key)
      );

      return results.length > 0
        ? results.map((florist) => {
            // As we need to show only florist name, membercode, amount, distance, phone number in the florist options, we are filtering other data
            let availableFlorist = {};

            Object.keys(florist?.basicInfo).filter((value) => {
              if (!["addressLine1", "city", "state", "zip"].includes(value)) {
                availableFlorist[value] = florist?.basicInfo?.[value];
              }
            });

            return availableFlorist;
          })
        : [];
    });

    const anyFloristsReturned = florists.length > 0 || !!errorFlorists;

    const showFloristSelection = ["FLORIST_PARTNER", "transfer"].includes(
      deliveryMethod
    );

    const demoAccounts = Environment.get(
      "DEMO_ACCOUNTS",
      "90-0134AP:90-0134AT:90-0795AY:90-0795AZ:90-0795AA:90-0795AB:90-0795AC:90-0795AD:90-0795AE:90-0795AF:90-0795AG:90-0795AH"
    );

    const isDemoAccount = demoAccounts.split(":").includes(sendingMemberCode);

    const AutoForwardComponent = ({ containerStyle }) => (
      <View
        pointerEvents={isDemoAccount ? "none" : "auto"}
        style={{ opacity: isDemoAccount ? 0.6 : 1, zIndex: -1 }}
      >
        <FormFieldCheckBox
          containerStyle={containerStyle}
          iconRight={false}
          name="noAutoForward"
          size={20}
          title={
            isDemoAccount
              ? "No Auto Forward – Auto Forward disabled for this account"
              : "No Auto Forward"
          }
          path={`orderItems.${index}`}
        />
      </View>
    );

    return showFloristSelection ? (
      <View
        style={[
          {
            marginTop: 15,
            opacity: canModifyOrder ? 1 : 0.7,
          },
          !isMultiOrderEligible && { ...shapes.sectionBorder },
        ]}
        pointerEvents={canModifyOrder ? "auto" : "none"}
      >
        <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
          <Text style={[fonts.sectionHeading]}>
            {Localise(messages, title)}
          </Text>
        </View>

        <View style={tw("flex flex-row justify-between items-center")}>
          <View style={tw("flex flex-row")}>
            <Text
              style={{
                ...fonts.heading5,
                fontWeight: "normal",
                paddingBottom: 6,
                marginLeft: 5,
              }}
            >
              {deliveryFieldLabel}
            </Text>
            {loading && (
              <View style={{ marginLeft: 5, marginTop: -5 }}>
                <ActivityIndicator color={colors.activityIndicator} />
              </View>
            )}
          </View>

          {showFloristNetworkLink && anyFloristsReturned && (
            <TouchableOpacity
              style={{ marginRight: 5 }}
              onPress={() => {
                setSideCarOpen(`florist_selection-${index}`, {
                  memberCodes: memberCodes,
                });
              }}
              testID="florist_partner_selection"
              accessibilityLabel="florist_partner_selection"
            >
              <Text
                style={[tw("mb-1"), { ...fonts.link1, fontWeight: "normal" }]}
              >
                {Localise(messages, "Choose Another Florist")}
              </Text>
            </TouchableOpacity>
          )}
        </View>

        <FormFieldAutoComplete
          data={filterFlorists()}
          onChangeText={(text) => {
            setFloristQuery(text);
          }}
          showOnFocus={true}
          name="floristInfo"
          placeholder={deliveryFieldLabelPlaceholder}
          listDisplayValues={[
            "name",
            "memberCode",
            "minPrice",
            "distance",
            "phone",
          ]}
          delimiter="space"
          containerStyle={{ width: "100%" }}
          outerContainerStyle={{}}
          path={path}
          clearTextOnBackTab={true}
          showErrorMessage={
            showFloristNetworkLink && showError
              ? isWireOut
                ? anyFloristsReturned
                  ? Localise(
                      messages,
                      "No florists available. Please adjust date, price, or product."
                    )
                  : Localise(
                      messages,
                      "No florists available. Please adjust date, price, or product."
                    )
                : anyFloristsReturned
                ? Localise(
                    messages,
                    "No florists available. Please adjust date, price, or product."
                  )
                : Localise(
                    messages,
                    "No florists available. Please adjust date or product."
                  )
              : ""
          }
          onSelect={(selectedValue) => {
            set(finalValues, path, {
              ...get(finalValues, path),
              floristInfo: {
                ...selectedValue,
              },
            });
            setValues(finalValues);
          }}
          isFormatData={true}
          popperPlacement={"bottom"}
          onClear={() => {
            set(finalValues, path, {
              ...get(finalValues, path),
              floristInfo: "",
            });
            setValues(finalValues);
          }}
          showErrorOnTouched={true}
        />

        <AutoForwardComponent
          containerStyle={{
            marginTop: -10,
            width: 130,
            zIndex: -1,
          }}
        />
      </View>
    ) : null;
  }
);

const mapStateToProps = (state = {}) => {
  return {
    addressVerificationInfo: state.mhq.ongoing.global.addressVerificationInfo,
  };
};

export default connect(mapStateToProps, null)(React.memo(FloristPartner));
