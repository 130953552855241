import get from "lodash/get";
import isString from "lodash/isString";
import EnvironmentStorage from "library/storage/environment";
import Config from "react-native-config";
import { Platform } from "react-native";

const common = {
  ORDER_ACCEPTANCE_INTERVAL: 110,
  ORDER_ACCEPTANCE_EXPIRING_INTERVAL: 10,
  PUBLIC_API_TIMEOUT: 30000,
  ORDERS_LISTING_API_TIMEOUT: 60000,
  PUBLIC_API_BULK_UPLOAD_TIMEOUT: 300000,
  CREATE_ORDER_API_TIMEOUT: 65000,
  SHOW_AVS_INDICATOR: true,
  FLORIST_SUSPENDS_TIMEOUT: 6,
  GOTO_SUSPENDS_TIMEOUT: 10,
  MHQ_GOOGLE_CAPTCHA_SITE_KEY: "6LeAwyMbAAAAAHDuJuFPb6Uo4bcAUrXwLUYQpufy",
  PASSWORD_EXPIRATION_ALERT_MIN_DAYS: 7,
  SUPPORTED_LANGUAGES: "en,es,pt,fr",
  DELIVERY_MODULE_ACCESS: "ALL",
  SHOW_REPORTS_MENU: true,
  ENABLE_FORESEE: true,
  MOS_VERSION: "mercury",
  MNS_VERSION: "member-notification",
  MOS_REPORTING_VERSION: "mercury-reporting",
  EP_UP_VERSION: "ep-user-profile",
  MAX_ORDERS_LIMIT: 5000,
  REFUND_REASONS: "Non-Delivery,Quality,Other",
  DATA_MIGRATION_FILE_SIZE_LIMIT: 10485760,
  MAXIMUM_SRP_DEPRECIATION_ALLOWED: 10,
  SHOW_ROUTE_HINTS: true,
  SHOW_ZONES_IN_INTERACTIVE_MAP: true,
  DIGITAL_PAYMENTS_ACCESS: "ALL",
  ENABLE_SUBSCRIPTIONS: "ALL",
  SHOW_BULK_DS_REQUEST: true,
  SHOW_RELAY_FEE: true,
  PRINT_MANAGEMENT_ENABLED: true,
  ENABLE_SSO_LOGIN: false,
  ELIGIBLE_DELIVERY_PROVIDERS: "roadie,doordash,burq",
  PRINT_VERSION: 1,
  ENABLE_DESIGNER_WORKSHEET: true,
  IS_SMS_ENABLED: true,
  SHOW_BLACKOUT_DATES_AND_BRAND_SELECTOR: false,
  POLL_INTERVAL_FOR_PULLING_CONFIG_MAP: 1800000,
  MAX_ORDER_ITEMS_ALLOWED: 5,
  AUTO_PRINT_START_END_INTERVAL: 2592000000,
  SHOW_EVENTS_PLANNER: false,
  ENABLE_EVENTS_PLANNER: "ALL",
  SHOW_FUNERAL_LOG: true,
  ENABLE_SPLIT_PAYMENT: true,
  ROUTES_DEFAULT_START_RANGE: 7,
  ROUTES_DEFAULT_END_RANGE: 3,
  ENABLE_ROUTE_DND: true,
  OIR_VIEW_MORE_LIMIT: 30,
  ONIR_VIEW_MORE_LIMIT: 30,
  SHOW_CUSTOMER_SUGGESTIONS: true,
  SHOW_CUSTOMER_MERGE_AND_PURGE: true,
  SHOW_ADDONS_SETTINGS: true,
  SHOW_RECALCULATE_TAX_FEES_POPUP: true,
  ENABLE_OCCASION_REMINDER: "ALL",
  SHOW_PROMO_CODE_FEATURE: true,
};

const dev = {
  ...common,
  API_DOMAIN: "nonprod-gke-primary-1-web-api-gateway-dev1.gcp.ftd.com",
  PCI_EP_API_DOMAIN: "pci-ep-api-gateway-dev1.gcp.ftdi.com",
  EPAPI_DOMAIN: "ep-api-gateway-dev1.gcp.ftdi.com",
  API_PROXY_DOMAIN: "nonprod-gke-primary-1-proxy-dev1.gcp.ftdi.com",
  PUBLIC_API_KEY: "32B81368-2040-4641-BC7B-2656799C7C73",
  PUBLIC_API_DOMAIN: "ep-api-gateway-dev1.gcp.ftdi.com", //Actually Public EP-Gateway domain
  PUBLIC_UI_DOMAIN: "mercury-os-dev1.gcp.ftdi.com",
  GOOGLE_MAPS_API_KEY: "AIzaSyCnKKSftJdGWDvFFFBJ2T28EyBtMqElgBQ",
  MOL_GOOGLE_TRANSLATIONS_API_KEY: "AIzaSyArTtgcvg6jUQUHhDPAf_zdnzi2OJy_Wiw",
  DEMO_ACCOUNTS: "07-0016AA:12-3737AA",
  GOOGLE_MAPS_APP_ID: "ae81b3064704ddac",
  GIFT_CARDS_ENABLED: true,
  SHOW_DELIVERY_QUICK_FILTERS: true,
  ENABLE_PAYMENT_TERMINAL: "ALL",
};

const qa = {
  ...common,
  API_DOMAIN: "nonprod-gke-primary-1-web-api-gateway-qa1.gcp.ftd.com",
  PCI_EP_API_DOMAIN: "pci-ep-api-gateway-qa1.gcp.ftdi.com",
  EPAPI_DOMAIN: "ep-api-gateway-qa1.gcp.ftdi.com",
  API_PROXY_DOMAIN: "nonprod-gke-primary-1-proxy-qa1.gcp.ftdi.com",
  PUBLIC_API_KEY: "32B81368-2040-4641-BC7B-2656799C7C73",
  PUBLIC_API_DOMAIN: "pt-qa1.ftdi.com", //Actually Public EP-Gateway domain
  PUBLIC_UI_DOMAIN: "launchpadtest.ftd.com",
  GOOGLE_MAPS_API_KEY: "AIzaSyCnKKSftJdGWDvFFFBJ2T28EyBtMqElgBQ",
  MOL_GOOGLE_TRANSLATIONS_API_KEY: "AIzaSyArTtgcvg6jUQUHhDPAf_zdnzi2OJy_Wiw",
  DEMO_ACCOUNTS: "07-0016AA:12-3737AA",
  GOOGLE_MAPS_APP_ID: "ae81b3064704ddac",
  GIFT_CARDS_ENABLED: true,
  SHOW_DELIVERY_QUICK_FILTERS: true,
  ENABLE_PAYMENT_TERMINAL: "ALL",
};

const EnvironmentConfig = {
  dev,
  dev1: {
    ...dev,
  },
  dev2: {
    ...dev,
    PUBLIC_UI_DOMAIN: "mercury-os-dev2.gcp.ftdi.com",
    MOS_VERSION: "v2/mercury",
  },
  dev3: {
    ...dev,
    PUBLIC_UI_DOMAIN: "mercury-os-dev3.gcp.ftdi.com",
    MOS_VERSION: "v3/mercury",
  },
  dev4: {
    ...dev,
    PUBLIC_UI_DOMAIN: "mercury-os-dev4.gcp.ftdi.com",
    MOS_VERSION: "v4/mercury",
    MNS_VERSION: "v2/member-notification",
    MOS_REPORTING_VERSION: "v2/mercury-reporting",
    EP_UP_VERSION: "v2/ep-user-profile",
  },
  qa,
  qa1: {
    ...qa,
  },
  qa2: {
    ...qa,
    PUBLIC_UI_DOMAIN: "launchpadtest2.ftd.com",
    MOS_VERSION: "v2/mercury",
  },
  qa3: {
    ...qa,
    PUBLIC_UI_DOMAIN: "launchpadtest3.ftd.com",
    MOS_VERSION: "v3/mercury",
  },
  qa4: {
    ...qa,
    PUBLIC_UI_DOMAIN: "launchpadtest4.ftd.com",
    MOS_VERSION: "v4/mercury",
    MNS_VERSION: "v2/member-notification",
    MOS_REPORTING_VERSION: "v2/mercury-reporting",
    EP_UP_VERSION: "v2/ep-user-profile",
  },
  stag: {
    ...common,
    API_DOMAIN: "stag-web-api-gateway.gcp.ftd.com",
    PCI_EP_API_DOMAIN: "pci-ep-api-gateway-stag2.gcp.ftdi.com",
    EPAPI_DOMAIN: "ep-gateway-stag2.gcp.ftdi.com",
    API_PROXY_DOMAIN: "prod-gke-primary-1-proxy-stag.gcp.ftdi.com",
    PUBLIC_API_KEY: "6037A2A0-4901-44A8-845E-CCAA7E7B3C42",
    PUBLIC_API_DOMAIN: "ep-gateway-stag2.gcp.ftdi.com", //Actually Public EP-Gateway domain
    PUBLIC_UI_DOMAIN: "mercury-os-stag2.gcp.ftdi.com",
    GOOGLE_MAPS_API_KEY: "AIzaSyAPMdYXaLpwQM1nZIjr_GJyNKLe5ZLKzFU",
    MOL_GOOGLE_TRANSLATIONS_API_KEY: "AIzaSyB20Q4YoOt4iSdQgCGLwm8SrlxqaCA-6LU",
    DEMO_ACCOUNTS:
      "90-0134AP:90-0134AT:90-0795AY:90-0795AZ:90-0795AA:90-0795AB:90-0795AC:90-0795AD:90-0795AE:90-0795AF:90-0795AG:90-0795AH",
    GOOGLE_MAPS_APP_ID: "c797496f52b0285a",
    GIFT_CARDS_ENABLED: true,
    SHOW_DELIVERY_QUICK_FILTERS: false,
    ENABLE_PAYMENT_TERMINAL: "OFF",
  },
  prod: {
    ...common,
    API_DOMAIN: "api-gateway.ftd.com",
    PCI_EP_API_DOMAIN: "pci-ep-api-gateway-prod.gcp.ftdi.com",
    EPAPI_DOMAIN: "ep-gateway-prod.gcp.ftdi.com",
    API_PROXY_DOMAIN: "prod1-2-ep-gateway.gcp.ftdi.com",
    PUBLIC_API_KEY: "6037A2A0-4901-44A8-845E-CCAA7E7B3C42",
    PUBLIC_API_DOMAIN: "pt.ftdi.com", //Actually Public EP-Gateway domain
    PUBLIC_UI_DOMAIN: "mercuryhq.com",
    GOOGLE_MAPS_API_KEY: "AIzaSyAPMdYXaLpwQM1nZIjr_GJyNKLe5ZLKzFU",
    MOL_GOOGLE_TRANSLATIONS_API_KEY: "AIzaSyB20Q4YoOt4iSdQgCGLwm8SrlxqaCA-6LU",
    DEMO_ACCOUNTS:
      "90-0134AP:90-0134AT:90-0795AY:90-0795AZ:90-0795AA:90-0795AB:90-0795AC:90-0795AD:90-0795AE:90-0795AF:90-0795AG:90-0795AH",
    GOOGLE_MAPS_APP_ID: "c797496f52b0285a",
    GIFT_CARDS_ENABLED: true,
    SHOW_DELIVERY_QUICK_FILTERS: false,
    ENABLE_PAYMENT_TERMINAL: "OFF",
  },
};

const Environment = {
  get(propertyName, defaultValue) {
    if (!propertyName || !isString(propertyName)) {
      throw new Error(
        `First parameter to Environment#get must be a non-empty string, but instead received: "${propertyName}".`
      );
    }

    const defaultEnv =
      Platform.OS === "web" // eslint-disable-next-line no-undef
        ? __DEV__
          ? process.env.REACT_NATIVE_ENV ?? "qa1"
          : "prod"
        : Config.ENV;

    const envName = EnvironmentStorage.getName() || defaultEnv;
    const config = EnvironmentStorage.getConfig();

    if (get(config, `${propertyName}`, "") !== "") {
      const value = get(config, `${propertyName}`, "");
      return value;
    } else {
      const value = get(
        EnvironmentConfig,
        `${envName}.${propertyName}`,
        defaultValue
      );
      return value;
    }
  },
};

export default Environment;
