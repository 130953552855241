/* eslint-disable no-extra-boolean-cast */
import React, { useEffect } from "react";
import { Image, View, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { PrintIframeAction } from "components/elements";

import { backgroundColors, colors, theme } from "styles/theme";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { formatPrice, trimSpecialChars } from "library/utils/formatter";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { selectShowRecipe } from "library/sagas/ongoing/order-details/selector";
import { setShowRecipe } from "library/sagas/ongoing/order-details/slice";

import {
  getRecipeInfo,
  fetchAddonInfo,
  formatProductSecondChoice,
} from "../helper";
import { generateDetailContent } from "../../helper";
import useStateIfMounted from "library/utils/useStateIfMounted";

const SelectedProductDetails = ({
  index,
  lineItemInfo: lineItemsInfo = {},
  global,
  occasion,
}) => {
  const dispatch = useDispatch();
  const showRecipe = useSelector(selectShowRecipe);
  const [recipeAccordionStatus, setRecipeAccordionStatus] =
    useStateIfMounted(false);

  const { messages: locMessages, Localise } = React.useContext(I18NContext);
  const { isMobile, isPotrait } = React.useContext(DeviceContext);
  const { values } = useFormikContext();
  const orderDetailResponse = useSelector(selectRecordData);

  const orderDetails = orderDetailResponse.orderItems[index];
  const updatedLineItemsPath = `orderItems.${index}.productInfo.updatedLineItems`;
  const updatedLineItems = get(values, updatedLineItemsPath, []);

  const {
    orderSource = "",
    sendingMember = {},
    receivingMember = {},
  } = orderDetails;

  if (!updatedLineItems) return null;

  const memberCode =
    orderSource === "MOL"
      ? sendingMember.originalSendingMemberCode
      : receivingMember.memberCode;

  const imageSize = isMobile && isPotrait ? 100 : 150;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (showRecipe) setRecipeAccordionStatus(showRecipe);
  }, [showRecipe]);

  return (
    <>
      {updatedLineItems.map((lineItem, index) => {
        let {
          productFirstChoiceCode,
          productFirstChoiceRefNumberId = "",
          productFirstChoiceDescription = "",
          productSecondChoiceCode = "",
          productSecondChoiceDescription = "",
          img = "",
          price,
          quantity = "1",
          type,
          isRemoved,
          designNotes,
        } = lineItem;

        const isProduct = type.toLowerCase() === "product";
        const lineItemInfo =
          lineItemsInfo[productFirstChoiceCode?.toLowerCase()] || {};
        let productDescription = "";
        const { isFTDOrder, dimension = "", actualPrice } = lineItemInfo || {};
        const srp = price || actualPrice;

        if (isProduct) {
          let productSecondChoice = formatProductSecondChoice(
            productSecondChoiceCode,
            productSecondChoiceDescription,
            false
          );

          productDescription = !!lineItemInfo.productName
            ? lineItemInfo.productName
            : productFirstChoiceDescription;

          // Displaying product second choice code and description along with product first choice description
          if (productSecondChoice.length > 0)
            productDescription = `${productDescription}, ${Localise(
              locMessages,
              "Second Choice"
            )}: ${productSecondChoice}`;
        } else {
          productDescription = productFirstChoiceDescription;
        }

        const addOnInfo =
          (!isProduct && fetchAddonInfo(productFirstChoiceCode, global)) || {};

        const image = isProduct
          ? img ||
            (!isEmpty(lineItemInfo) && lineItemInfo?.primaryImg) ||
            (!isEmpty(lineItemInfo) &&
              lineItemInfo?.media &&
              lineItemInfo?.media[memberCode] &&
              lineItemInfo?.media[memberCode][0]?.url)
          : img ||
            (!isEmpty(addOnInfo) && addOnInfo?.img) ||
            (!isEmpty(addOnInfo) &&
              addOnInfo?.media &&
              addOnInfo?.media[memberCode] &&
              addOnInfo?.media[memberCode][0]?.url);

        const imgUri = (image || "").includes("http")
          ? image
          : `https:${image}`;

        if (isRemoved) return null;

        const content = {
          Occasion: {
            label: "Occasion",
            value: occasion,
          },
          Product: {
            label: isMobile ? "" : "Product",
            value: productDescription,
          },
          ProductCode: {
            label: "Product Code",
            value: trimSpecialChars(
              productFirstChoiceRefNumberId || productFirstChoiceCode,
              "."
            ),
          },
          Qty: { label: "Qty", value: quantity },
          SizeStyle: { label: "Size / Style", value: dimension },
          SRP: {
            label: "SRP",
            value: formatPrice(srp),
            valueStyle: { isPrice: true },
          },
          ItemPrice: {
            label: "Item Price",
            value: price,
            valueStyle: { isPrice: true },
          },
          DesignNotes: {
            label: "Design Notes",
            value: designNotes,
          },
        };

        const hasRecipe = isProduct && lineItemInfo?.recipeList?.length > 0;

        return (
          <View
            key={index}
            style={[
              {
                padding: 15,
                marginBottom: 10,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: colors.grayScaleLight,
              },
            ]}
          >
            <View style={[tw("flex flex-row justify-between")]}>
              <View
                style={{
                  flex: 1,
                }}
              >
                {generateDetailContent(content, true)}
                {hasRecipe && (
                  <View style={tw("flex items-start my-2 pr-4")}>
                    <Text
                      style={{
                        color: colors.primary2,
                        borderColor: colors.primary2,
                        borderWidth: 1,
                        paddingLeft: 5,
                        padding: 5,
                        borderRadius: 10,
                        fontSize: 14,
                      }}
                      onPress={() => {
                        setRecipeAccordionStatus(!recipeAccordionStatus);
                        showRecipe && dispatch(setShowRecipe(false));
                      }}
                    >
                      {Localise(locMessages, `Recipe`)}
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={tw(`flex flex-row ${isMobile ? "items-center" : ""}`)}
              >
                <Image
                  style={{
                    width: imageSize,
                    height: imageSize,
                    borderWidth: 1,
                    borderColor: colors.light,
                  }}
                  resizeMode="cover"
                  source={image ? { uri: imgUri } : IMAGES[`quick-pick`]}
                />
              </View>
            </View>

            {recipeAccordionStatus && hasRecipe && (
              <View
                style={{
                  marginTop: 10,
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: colors.grayScaleLight,
                }}
              >
                <View style={{ padding: 10 }}>
                  <View style={tw("flex flex-col p-1 my-1")}>
                    {lineItemInfo?.recipeList?.length > 0 &&
                      lineItemInfo.recipeList.map((ele, index) => (
                        <View key={index} style={tw("flex flex-row p-1")}>
                          <Text style={tw(`w-1/4 ${ele.css || ""}`)}>
                            {Localise(locMessages, ele.quantity)}
                          </Text>
                          {!isFTDOrder && (
                            <Text
                              style={{
                                ...tw(`w-1/4 ${ele.css || ""}`),
                                paddingLeft: 2,
                              }}
                            >
                              {Localise(locMessages, ele.color) || "-"}
                            </Text>
                          )}
                          <Text style={tw(`w-2/4 ${ele.css || ""}`)}>
                            {Localise(locMessages, ele.item)}
                          </Text>
                        </View>
                      ))}
                  </View>
                  <View
                    style={{
                      flex: 1,
                      padding: 10,
                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* <View>
                        <Text style={[fonts.link1, { marginBottom: 5 }]}>
                          {Localise(locMessages, "Substitution")}
                        </Text>
                        {getDimensionAndSRP(
                          lineItemInfo,
                          price,
                          Localise,
                          locMessages
                        )}
                      </View> */}
                    <View
                      style={{
                        ...theme.Button.secondaryButtonStyle,
                        ...tw("flex-row mr-1 mt-1"),
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                      }}
                    >
                      <Text
                        style={{
                          ...theme.Button.secondaryTitleStyle,
                          color: backgroundColors.primary,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        testID="print_recipe"
                        accessibilityLabel="print_recipe"
                        onPress={() =>
                          PrintIframeAction(
                            getRecipeInfo(
                              {
                                productFirstChoiceCode,
                                occasion,
                                formattedPrice: formatPrice(
                                  lineItemInfo.actualPrice
                                ),
                                productFirstChoiceDescription,
                                img,
                                price,
                                ...lineItemInfo,
                              },
                              Localise,
                              locMessages,
                              isFTDOrder
                            )
                          )
                        }
                      >
                        {Localise(locMessages, "Print Recipe")}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        );
      })}
    </>
  );
};

export default SelectedProductDetails;
