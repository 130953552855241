import React from "react";
import { View, TouchableOpacity, Image, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "react-native-elements";
import { useFormikContext } from "formik";
import { useNavigation, CommonActions } from "@react-navigation/native";

import { colors, fonts } from "styles/theme";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import { Tooltip } from "components/elements";
import { Distance } from "components/elements/distance";
import {
  selectActiveTab,
  selectDSEligibilityFailureMessage,
} from "library/sagas/ongoing/current-orders/selector";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import AddressVerificationIndicator from "./component/address-indicator";
import { generateDetailContent } from "../helper";
import Clipboard from "@react-native-community/clipboard";
import countryList from "static/data/countries.json";
import { phoneNumberFormatter } from "library/utils/formatter";

const RecipientInfo = ({
  index,
  recordId,
  isSmallScreen,
  orderDetails,
  mapAccordionStatus,
  setMapAccordionStatus = () => {},
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const currentTab = useSelector(selectActiveTab);
  const dsEligibilityFailureDetails = useSelector(
    selectDSEligibilityFailureMessage
  );
  const dsEligibilityFailureMessage = Object.values(dsEligibilityFailureDetails)
    ?.filter(Boolean)
    ?.join("\n");

  const { values } = useFormikContext();

  const recipientInfoPath = `orderItems.${index}.deliveryInfo.recipientInfo`;
  const formikRecipientInfo = get(values, recipientInfoPath, {});

  const {
    orderItemId = "",
    direction = "",
    receivingMember: {
      latitude: fillerLatitude = "",
      longitude: fillerLongitude = "",
      country: fillerCountry = "",
      memberCode: receivingMemberCode = "",
    } = {},
    addressVerificationInfo: {
      isAvsPerformed = "",
      avsConfidence = "",
      isAvsSuggestedAddress = "",
    } = {},
    distanceInMiles,
    recipientInfo,
    messages = [],
  } = orderDetails;

  const {
    latitude: recipientLatitude = "",
    longitude: recipientLongitude = "",
  } = recipientInfo;

  const {
    firstName,
    lastName,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    zip,
    locationType,
    locationName,
  } = formikRecipientInfo;

  const fillerLocation = {
    locations: [
      {
        latitude: parseFloat(fillerLatitude || 0),
        longitude: parseFloat(fillerLongitude || 0),
        isFillingFlorist: true,
      },
    ],
  };

  const isAvsVerified =
    isAvsPerformed === "Y" &&
    isAvsSuggestedAddress === "Y" &&
    avsConfidence === "HIGH";

  const isValidLocation =
    !isEmpty(fillerLocation) &&
    get(fillerLocation, "locations", []).length === 1 &&
    get(fillerLocation, "locations.0.latitude", "") === 0;

  const isShowMap =
    !!recordId &&
    isAvsVerified &&
    !isValidLocation &&
    !!recipientLatitude &&
    !!recipientLongitude;

  const hasDSSubmittedMsg = !!messages.find(
    (e) =>
      e.messageType === "OLC" &&
      e.comments?.includes("Delivery Service Requested")
  );
  const showDSRequestsLink = direction === "INBOUND" && hasDSSubmittedMsg;

  const deliveryDistance = isNil(distanceInMiles)
    ? ""
    : `${distanceInMiles} miles`;

  const addressInfo = addressLine1 && city && state && zip;

  const { messages: locMessages, Localise } = React.useContext(I18NContext);

  const hasRecipientInfo = firstName.length > 0 || lastName.length > 0;

  const recipientCountry =
    countryList.find((obj) => obj.value === country)?.label || country;

  const fillingCountry =
    countryList.find((obj) => obj.value === fillerCountry)?.label ||
    fillerCountry;

  const content = {
    Name: { label: "", value: `${firstName} ${lastName}` },
    AddressLine1: { label: "", value: addressLine1 },
    AddressLine2: { label: "", value: addressLine2 },
    CityStateZip: { label: "", value: `${city}, ${state} ${zip}` },
    Country: {
      label: "",
      value: recipientCountry === fillingCountry ? "" : recipientCountry,
    },
    Phone: {
      label: "",
      value: phoneNumberFormatter(phone),
    },
    LocationType: { label: "Type", value: locationType },
    LocationName: { label: "Name", value: locationName },
  };

  const copyContent = `${firstName} ${lastName}, ${addressLine1}, ${city}, ${state} ${zip}`;

  const copyFunctionality = () => {
    if (Platform.OS === "web") {
      navigator.clipboard.writeText(copyContent);
    } else {
      Clipboard.setString(copyContent);
    }
  };

  const viewDSRequests = () => {
    dispatch(setSelectScreenKey("DS"));
    UserProfileStorage.setCurrentTab("DS");
    dispatch(setCurrentPage("DeliveryDashboard"));
    navigation.dispatch(
      CommonActions.navigate({
        name: "lmds-delivery",
        params: {
          referenceNumber: orderItemId,
          shopCode: receivingMemberCode,
          applyParamFilters: true,
          search: {
            title: orderItemId,
            value: orderItemId,
          },
        },
      })
    );
  };

  return (
    <View style={{ zIndex: -1, paddingBottom: 5 }}>
      <View style={{ minHeight: 100 }}>
        <Text
          style={{
            fontSize: 15,
            color: colors.primary,
            fontWeight: "bold",
            paddingVertical: 5,
          }}
        >
          {Localise(locMessages, "Recipient")}
        </Text>

        {hasRecipientInfo && generateDetailContent(content, true)}
      </View>
      <View style={tw(`flex flex-row flex-wrap items-center`)}>
        {/* Delivery Distance Display */}

        <Distance
          distanceText={deliveryDistance}
          isAvsVerified={isAvsVerified}
        />

        <View style={tw(`flex flex-row flex-wrap items-center`)}>
          {isShowMap && (
            <TouchableOpacity
              onPress={() => setMapAccordionStatus(!mapAccordionStatus)}
              testID="close"
              accessibilityLabel="close"
              style={tw("pr-3")}
            >
              <Tooltip
                text={Localise(locMessages, "Delivery Location")}
                renderForWebOnly={true}
              >
                <Image
                  style={{ width: 30, height: 30 }}
                  resizeMode="cover"
                  source={IMAGES["mapFolded"]}
                />
              </Tooltip>
            </TouchableOpacity>
          )}
          {hasRecipientInfo && (
            <TouchableOpacity
              onPress={copyFunctionality}
              testID="close"
              accessibilityLabel="close"
            >
              <Tooltip
                text={Localise(locMessages, "Copy Recipient Address")}
                renderForWebOnly={true}
              >
                <Image
                  style={{ width: 20, height: 30 }}
                  resizeMode="cover"
                  source={IMAGES["copy-content"]}
                />
              </Tooltip>
            </TouchableOpacity>
          )}
        </View>
      </View>
      {addressInfo.length > 0 && (
        <AddressVerificationIndicator
          containerStyle={{ paddingTop: 5 }}
          isVerified={isAvsVerified}
          isSmallScreen={isSmallScreen}
          unVerfifiedText={"Address Not Verified"}
        />
      )}

      {currentTab === "delivery" && showDSRequestsLink ? (
        <Text
          testID={"view_linked_DS_requests"}
          accessibilityLabel={"view_linked_DS_requests"}
          style={[
            fonts.link1,
            {
              textDecorationLine: "underline",
              marginTop: 20,
              marginLeft: 5,
              width: 300,
            },
          ]}
          onPress={() => viewDSRequests()}
        >
          {Localise(messages, "View Delivery Service Requests")}
        </Text>
      ) : null}

      {currentTab === "delivery" && dsEligibilityFailureMessage ? (
        <View style={tw("flex items-start mt-2 pr-4")}>
          <Text
            style={{
              fontSize: 14,
            }}
          >
            {Localise(
              locMessages,
              "This order is not eligible for Delivery Service due to"
            ) + ":"}
          </Text>
          <Text
            style={{
              ...tw("text-left"),
              padding: 5,
              fontSize: 14,
            }}
          >
            {dsEligibilityFailureMessage}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

export default RecipientInfo;
