const routes = {
  dashboard: { linkTo: "dashboard", title: "Dashboard" },
  orders: { linkTo: "orders", title: "Orders" },
  createOrder: { linkTo: "create-order", title: "Create Order" },
  editOrder: { linkTo: "create-order", title: "Edit Order" },
  events: { linkTo: "events", title: "Events" },
  editEvent: { linkTo: "create-event", title: "Edit Event" },
};

const CreateOrderMenu = {
  key: "createOrder",
  label: "Create Order",
  route: "create-order",
};
const CreateCardMenu = {
  key: "createCard",
  label: "Create Card",
  route: "create-card",
};
const CreateEventMenu = {
  key: "createEvent",
  label: "Create Event",
  route: "create-event",
};

export const CreateMenuOptions = (currentPage, hasAccessToCreateEvent) => {
  return ["create-event", "events", "qr-code-scan"].includes(currentPage)
    ? [CreateOrderMenu, CreateCardMenu]
    : ["create-order", "orders"].includes(currentPage)
    ? hasAccessToCreateEvent
      ? [CreateEventMenu, CreateCardMenu]
      : [CreateCardMenu]
    : ["create-card"].includes(currentPage)
    ? hasAccessToCreateEvent
      ? [CreateOrderMenu, CreateEventMenu]
      : [CreateOrderMenu]
    : ["help"].includes(currentPage)
    ? hasAccessToCreateEvent
      ? [CreateCardMenu, CreateEventMenu, CreateOrderMenu]
      : [CreateCardMenu, CreateOrderMenu]
    : [];
};

export const getBreadCrumbRoutes = (
  currentRouteInfo = {},
  previousRouteInfo = {},
  variant
) => {
  const { name: screenName, params = {} } = currentRouteInfo;
  let links = [];
  if (screenName === "orders") {
    links.push(routes.dashboard);
    if (variant !== "order-details") {
      links.push({ title: "Orders" });
    } else {
      links.push(routes.orders);
      links.push({ title: "Order Details" });
    }
  } else if (["events", "qr-code-scan"].includes(screenName)) {
    links.push(routes.dashboard);
    links.push({ title: screenName === "events" ? "Events" : "QR Scanning" });
  } else if (screenName === "create-order") {
    const { action = "" } = params;
    links.push(routes.dashboard);
    if (previousRouteInfo.name === "orders") links.push(routes.orders);
    links.push({ title: action === "edit" ? "Edit Order" : "Create Order" });
  } else if (screenName === "create-card") {
    links.push(routes.dashboard);
    if (previousRouteInfo.name === "orders") links.push(routes.orders);
    links.push({
      title: "Enclosure Card",
    });
  } else if (screenName === "create-event") {
    const { action = "" } = params;
    links.push(routes.dashboard);
    if (previousRouteInfo.name === "events") links.push(routes.events);
    links.push({ title: action === "edit" ? "Edit Event" : "Create Event" });
  }
  return links;
};
