import React, { useCallback, useEffect, useRef } from "react";
import { Text } from "react-native-elements";
import { View, Platform, ActivityIndicator } from "react-native";
import { connect, useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { ToasterHandler } from "components/elements";

import tw from "tailwind-rn";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

import { FormField, FormFieldPicker } from "components/elements/forms";

import { fonts, colors, shapes, theme } from "styles/theme";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";

import { request } from "library/utils/request";
import { giftCardProducts } from "library/utils/giftCardProducts";
import Environment from "library/utils/environment";
import {
  getPrice,
  getOrderSubTotal,
  getDiscount,
  getTotalPrice,
  getCountyForGivenAddress,
} from "library/utils/createOrder";
import { formatPrice } from "library/utils/formatter";
import { isCoreMember, isCoreConnectMember } from "library/utils/entitlements";

import {
  setUpdateCounty,
  setCalculatingTax,
  fetchOrderSummary,
  resetOrderSummary,
} from "library/sagas/views/home/drawer/create-order/slice";
import { selectRecalcTaxAndFees } from "library/sagas/ongoing/global-data/selector";
import { lockOrder } from "library/sagas/ongoing/order-details/slice";
import {
  selectIsEditOrder,
  selectUpdateCounty,
  selectCalculatingTax,
  selectRedeemedGiftCardsData,
  selectRefundSummary,
  selectIsOrderSummaryLoading,
} from "library/sagas/views/home/drawer/create-order/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { selectAddressVerificationInfo } from "library/sagas/ongoing/global-data/selector";

import { canCallTax, canCallSummary } from "./helper";
import {
  createOrderObject,
  editOrderObject,
} from "components/views/drawer/create-order/helper";
import { isPhoneOutOrder } from "../../order-details/delivery-info/helper";
import RefundPreview from "../../order-details/payment-details/order-details/refund-payment-preview";

const DOLLAR = "Dollar";
const PERCENTAGE = "Percentage";

let getTaxController;

export const OrderItems = ({
  order,
  index,
  selectedproducts,
  sendingMemberCode,
  isDeliveryFeeEditable,
  enablePromoCodes,
  isDesktop,
  quantityOptions,
  isPaymentEntitlementEnabled: isPaymentEnabled,
  isQuickSaleEnabled,
  isSubscriptionPage,
  orderSubTotal,
  feeRefundedAmount,
  taxRefundedAmount,
  totalTax,
  totalTaxRate,
  oldTax,
  taxExemptCode,
  updateNewTotalAmount,
  isProductGiftCard,
}) => {
  const orderDetailsResponse = useSelector(selectApiResponse);
  const isEditOrder = useSelector(selectIsEditOrder);
  const updateCounty = useSelector(selectUpdateCounty);
  const calculatingTax = useSelector(selectCalculatingTax);
  const reCalculateTax = useSelector(selectRecalcTaxAndFees);

  const dispatch = useDispatch();

  const { values, touched, setFieldValue } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);

  const unBlockTax = useRef(false);
  const shopLocations = UserProfileStorage.getAllShopLocation();

  const isCoreUser = isCoreMember(sendingMemberCode);
  const isCoreConnectUser = isCoreConnectMember(sendingMemberCode);

  const selectedShopPreferences =
    UserProfileStorage.getShopPreferences(sendingMemberCode);

  const TaxInfoPath = `orderItems.${index}.taxInfo`;
  const recipientInfoPath = `orderItems.${index}.recipientInfo`;

  const {
    lineItems = [],
    // applyTaxOnDF = false,
    deliveryFee = 0,
    retailDeliveryFee = 0,
    applyTaxOnRDF = false,
    relayFee = 0,
    serviceFee = 0,
    retransFee = 0,
    deliveryInfo: { deliveryMethod = "" },
    recipientInfo: {
      addressLine1: recipientAddressLine1 = "",
      city: recipientCity = "",
      state: recipientState = "",
      zip: recipientZipCode = "",
      country: recipientCountry = "",
      county: recipientCounty = "",
      //updateCounty = false,
    },
  } = order;

  const {
    city: shopCity = "",
    state: shopState = "",
    zip: shopZipCode = "",
    countryCode: shopCountry = "",
    county: shopCounty = "",
  } = shopLocations[sendingMemberCode] || {};

  const isStorePickUpOrWalkInOrder = ["STORE_PICKUP", "WALK_IN"].includes(
    deliveryMethod
  );
  const isFloristDelivered = deliveryMethod === "FLORIST_DELIVERED";
  const isWireOut = deliveryMethod === "FLORIST_PARTNER";
  const isPhoneOut = deliveryMethod === "PHONE_OUT";
  const showRelayFee =
    (isWireOut || isPhoneOut) && Environment.get("SHOW_RELAY_FEE", false); //From Env
  const orderSource = get(orderDetailsResponse, "orderItems.0.orderSource", "");
  const isEmailOrder = orderSource === "Email Order";

  const local_order_tax_settings = get(
    selectedShopPreferences,
    "local_order_tax_settings",
    "point_of_origin"
  );

  const wired_order_tax_settings = get(
    selectedShopPreferences,
    "wired_order_tax_settings",
    "point_of_origin"
  );

  const taxOnDF = get(selectedShopPreferences, "tax_delivery_fee", "false");

  let applyTaxOnDF = taxOnDF;
  applyTaxOnDF = applyTaxOnDF.toLowerCase() === "true";

  let applyTaxOnLocalDF =
    selectedShopPreferences?.tax_on_local_delivery_fee || taxOnDF;
  applyTaxOnLocalDF = applyTaxOnLocalDF.toLowerCase() === "true";

  let applyTaxOnOutsideLocalDF =
    selectedShopPreferences?.tax_on_outside_local_delivery_fee || taxOnDF;
  applyTaxOnOutsideLocalDF = applyTaxOnOutsideLocalDF.toLowerCase() === "true";

  let applyTaxOnServiceFee =
    selectedShopPreferences?.tax_on_service_fee || taxOnDF;
  applyTaxOnServiceFee = applyTaxOnServiceFee.toLowerCase() === "true";

  let applyTaxOnRelayFee = selectedShopPreferences?.tax_on_relay_fee || taxOnDF;
  applyTaxOnRelayFee = applyTaxOnRelayFee.toLowerCase() === "true";

  const getAddressForTaxCall = () => {
    if (
      isStorePickUpOrWalkInOrder ||
      ((isFloristDelivered || isPhoneOut) &&
        local_order_tax_settings === "point_of_origin") ||
      (isWireOut && wired_order_tax_settings === "point_of_origin")
    ) {
      return Promise.resolve({
        city: shopCity,
        county: shopCounty || null,
        country: shopCountry,
        state: shopState,
        zipCode: shopZipCode,
        isDestination: false,
      });
    } else {
      let recipientAddress = {
        city: recipientCity,
        county: recipientCounty,
        state: recipientState,
        country: recipientCountry,
        zipCode: recipientZipCode,
        isDestination: true,
      };

      if (updateCounty) {
        return getCountyForGivenAddress(
          {
            ...recipientAddress,
            addressLine1: recipientAddressLine1,
          },
          index
        )
          .then((res) => {
            setFieldValue(`${recipientInfoPath}.county`, res.county);
            dispatch(setUpdateCounty(false));

            return Promise.resolve({
              ...recipientAddress,
              county: res.county || null,
            });
          })
          .catch((err) => {
            console.log(
              "Getting error while getting county for given address :>> ",
              err
            );

            setFieldValue(`${recipientInfoPath}.county`, "");
            dispatch(setUpdateCounty(false));

            return Promise.resolve({
              ...recipientAddress,
              county: "",
            });
          });
      } else {
        return Promise.resolve(recipientAddress);
      }
    }
  };

  const getTaxOfOrder = () => {
    const currentProductId = get(lineItems, "0.productFirstChoiceCode", "");

    if (
      isEditOrder &&
      (isEmpty(touched) ||
        !canCallTax(
          index,
          values,
          orderDetailsResponse,
          unBlockTax,
          orderSource,
          reCalculateTax
        ))
    ) {
      if (isEmpty(orderDetailsResponse)) {
        dispatch(setCalculatingTax(false));
        return;
      }

      const orderItem = orderDetailsResponse.orderItems[index] || {};
      const { totalTax: orderTotalTax } = orderItem || {};
      setTimeout(() => {
        setFieldValue(TaxInfoPath, {
          taxAmount: orderTotalTax.amount,
          taxRate: orderTotalTax.rate,
        });
      }, 0);
      dispatch(setCalculatingTax(false));

      return;
    } else {
      /**
       * Updated below condition as per new requirements i.e. we will see the order tax value only after we have a valid delivery method and recipient
       * address (in case of Destination tax rule) while creating/modifying an order as we need city, state, zipcode and country to make Tax API Call.
       * Comment Ref Link - https://ftdcorp.atlassian.net/browse/MSOL-11910?focusedCommentId=225340
       */
      if (
        isProductGiftCard ||
        !isPaymentEnabled ||
        !currentProductId ||
        !shopZipCode ||
        taxExemptCode ||
        !deliveryMethod ||
        (!isStorePickUpOrWalkInOrder &&
          (!recipientCity ||
            !recipientState ||
            !recipientZipCode ||
            !recipientCountry))
      ) {
        let taxValues = get(values, TaxInfoPath);
        if (taxValues && taxValues?.taxAmount) {
          taxValues.taxAmount = 0.0;
          taxValues.taxRate = 0.0;
          setFieldValue(TaxInfoPath, taxValues);
        }
        updateNewTotalAmount();
        dispatch(setCalculatingTax(false));
      } else {
        const currentProductInfo = selectedproducts.find(
          (product) => product.productId === currentProductId
        );
        const {
          productType = "",
          productSubType = "",
          categoryName = "",
        } = currentProductInfo || {};

        const orderPrice = getOrderSubTotal(order);

        const apply_taxOnDeliveryFee = ["FLORIST_DELIVERED"].includes(
          deliveryMethod
        )
          ? applyTaxOnLocalDF
          : ["FLORIST_PARTNER", "PHONE_OUT"].includes(deliveryMethod)
          ? applyTaxOnOutsideLocalDF
          : false;

        // Calculating tax for fees, only when that particular fee is set to be taxable in shop settings.
        const amountForTaxCalc =
          orderPrice +
          (apply_taxOnDeliveryFee ? parseFloat(getPrice(deliveryFee)) : 0) +
          (applyTaxOnRDF ? parseFloat(getPrice(retailDeliveryFee)) : 0) +
          (applyTaxOnRelayFee ? parseFloat(getPrice(relayFee)) : 0) + //Using Tax Fees Toggle to apply tax on relay fee
          (applyTaxOnServiceFee ? parseFloat(getPrice(serviceFee)) : 0) + //Using Tax Fees Toggle to apply tax on Service fee
          (applyTaxOnDF ? parseFloat(getPrice(retransFee)) : 0); //Using Tax Fees Toggle to apply tax on Retrans fee
        getAddressForTaxCall().then((address) => {
          const req = {
            requests: [
              {
                siteId: "ftd",
                storeId: null,
                deliveryType: "domestic",
                amount: amountForTaxCalc,
                requestId: "Item" + Math.random() * 10000,
                feeType: "Base Fee",
                address,
                product: {
                  subType: productSubType,
                  taxCategoryId: null,
                  type: productType,
                },
                productCategoryName: categoryName,
                productId: currentProductId,
              },
            ],
          };

          getTaxController && getTaxController.abort();
          getTaxController = new AbortController();

          request("get-tax", req, getTaxController)
            .then((res) => {
              const { amount = 0.0, rate = 0.0 } = get(
                res,
                "responses.0.totalTax",
                0
              );
              const taxSplits = get(res, "responses.0.taxSplits", []);
              const taxInfo = {
                taxAmount: amount,
                taxRate: rate,
                taxSplits,
              };
              setFieldValue(TaxInfoPath, taxInfo);

              // If taxAmount is 0 then update the amountDues & payments - EDIT ORDER.

              const prevtaxamt = get(
                values,
                "orderItems.0.taxInfo.taxAmount",
                0
              );

              if (prevtaxamt === 0 && amount === 0) {
                updateNewTotalAmount();
              }

              dispatch(setCalculatingTax(false));
            })
            .catch((err) => {
              dispatch(setCalculatingTax(false));
              if (err !== "AbortError")
                console.log("Failed to get Taxes ---" + JSON.stringify(err));
            });
        });
      }
    }
  };

  const getTaxCallbackWithDelay = useCallback(debounce(getTaxOfOrder, 2000), [
    lineItems.length,
    shopZipCode,
    deliveryFee,
    serviceFee,
    retransFee,
    applyTaxOnDF,
    retailDeliveryFee,
    applyTaxOnRDF,
    relayFee,
    taxExemptCode,
    deliveryMethod,
    recipientCity,
    recipientState,
    recipientZipCode,
    recipientCountry,
  ]);

  useEffect(() => {
    !taxExemptCode && dispatch(setCalculatingTax(true));
    getTaxCallbackWithDelay();

    // Cancel the debounce on useEffect unmounting
    return getTaxCallbackWithDelay.cancel;
  }, [
    lineItems.length,
    shopZipCode,
    deliveryFee,
    serviceFee,
    retransFee,
    applyTaxOnDF,
    retailDeliveryFee,
    applyTaxOnRDF,
    relayFee,
    taxExemptCode,
    deliveryMethod,
    recipientCity,
    recipientState,
    recipientZipCode,
    recipientCountry,
  ]);

  const multiProductEligible = get(
    orderDetailsResponse,
    "orderItems.0.multiProductEligible",
    false
  );

  const allowMultipleProducts = isEditOrder
    ? multiProductEligible
    : !isCoreUser && !isCoreConnectUser && !isSubscriptionPage;

  const {
    totalDeliveryFee: oldDeliveryFee,
    retailDeliveryFee: oldRetailDeliveryFee,
    relayFee: oldRelayFee,
    serviceFee: oldServiceFee,
    retransFee: oldRetransFee,
    // subTotal: oldSubTotal,
  } = get(values, `orderItems.${index}.oldValues`, {});

  let refundedOrderSubTotal = 0;

  return (
    <View style={[tw("pb-2"), { paddingHorizontal: 5 }]}>
      {order.lineItems.length > 0 && (
        <View>
          {order.lineItems.map((lineItem, idx) => {
            const lineItemPrice =
              Number(lineItem?.originalPrice) -
              Number(lineItem?.refundedAmount);

            if (!lineItem?.newlyAdded) refundedOrderSubTotal += lineItemPrice;
            return (
              <OrderItemDetails
                key={idx}
                index={index}
                idx={idx}
                lineItem={lineItem}
                getTaxOfOrder={getTaxOfOrder}
                order={order}
                applyDiscounts={isPaymentEnabled}
                isDesktop={isDesktop}
                quantityOptions={quantityOptions}
                allowMultipleProducts={allowMultipleProducts}
                lineItemPrice={lineItemPrice}
                isProductGiftCard={isProductGiftCard}
              />
            );
          })}
        </View>
      )}

      <View style={tw("flex flex-row items-center pt-4")}>
        <View
          style={[
            tw(
              `flex flex-row items-${
                Platform.OS !== "web" ? "center" : "baseline"
              }`
            ),
            { width: isDesktop ? "75%" : "70%" },
          ]}
        >
          <Text
            style={[
              fonts.sectionHeading,
              tw("pr-4"),
              { color: colors.primary, fontSize: 13 },
            ]}
          >
            {Localise(messages, "Subtotal")}
          </Text>
        </View>
        <View
          style={[
            tw("flex flex-row items-center justify-end"),
            { width: isDesktop ? "25%" : "30%" },
          ]}
        >
          <Text
            style={[
              fonts.sectionHeading,
              { color: colors.primary, fontSize: 13 },
            ]}
            testID="orderSubTotal"
          >
            {!isEditOrder
              ? `$${formatPrice(getPrice(orderSubTotal))}`
              : `$${formatPrice(getPrice(refundedOrderSubTotal))}`}
          </Text>
          {isEditOrder && (
            <View>
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newSubTotal"}
                placeholder=""
                path={`orderItems.${index}`}
                value={formatPrice(orderSubTotal)}
                containerStyle={{
                  width: 80,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 15,
                }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                editable={false}
                grayedOutOnDisable={true}
                renderErrorMessage={false}
                errorStyle={{ paddingBottom: 0 }}
                testID="newOrderSubTotal"
              />
            </View>
          )}
        </View>
      </View>

      {enablePromoCodes && !isProductGiftCard && (
        <View
          style={tw(
            `flex flex-row items-center ${isDesktop ? "pt-4 pb-2" : "py-2"}`
          )}
        >
          <FormField
            name="promoCode"
            placeholder={Localise(messages, "Promo Code")}
            label={Localise(messages, "Promo Code")}
            labelStyle={{ fontWeight: "normal" }}
            path={`orderItems.${index}`}
            containerStyle={{
              width: isDesktop ? 190 : 110,
              paddingHorizontal: 0,
            }}
            errorStyle={{ paddingBottom: 0 }}
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="new-password"
            testID="promo_code"
            accessibilityLabel="promo_code"
          />
        </View>
      )}

      {!isQuickSaleEnabled &&
        !isStorePickUpOrWalkInOrder &&
        !isProductGiftCard && (
          <>
            <View
              style={tw(`flex flex-row items-center py-${isDesktop ? 2 : 0}`)}
            >
              <View
                style={tw(
                  `flex flex-row items-${
                    Platform.OS !== "web" ? "center" : "baseline"
                  } w-3/4`
                )}
              >
                <FormField
                  name="deliveryFee"
                  placeholder={Localise(messages, "Delivery Fee")}
                  label={Localise(messages, "Delivery Fee")}
                  labelStyle={{ fontWeight: "normal" }}
                  path={`orderItems.${index}`}
                  containerStyle={{
                    width: isDesktop ? 190 : 110,
                    paddingHorizontal: 0,
                  }}
                  errorStyle={{ paddingBottom: 0 }}
                  isPrice={true}
                  iconType="material-community"
                  iconName="currency-usd"
                  iconSize={14}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  keyboardType="numeric"
                  editable={isDeliveryFeeEditable}
                  grayedOutOnDisable={true}
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoComplete="new-password"
                  testID="delivery_fee"
                  accessibilityLabel="delivery_fee"
                />
              </View>
              <View style={tw("w-1/4 flex flex-row items-center justify-end")}>
                <Text
                  style={[fonts.heading4, { fontWeight: "normal" }]}
                  testID="deliveryFee"
                >
                  {!isEditOrder
                    ? `$${formatPrice(getPrice(order.deliveryFee))}`
                    : `$${formatPrice(
                        getPrice(oldDeliveryFee - feeRefundedAmount)
                      )}`}
                </Text>
                {isEditOrder && (
                  <View>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={"newDeliveryFee"}
                      path={`orderItems.${index}`}
                      value={formatPrice(order.deliveryFee)}
                      placeholder=""
                      containerStyle={{
                        width: 80,
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 15,
                      }}
                      isPrice={true}
                      keyboardType="numeric"
                      iconType="material-community"
                      iconName="currency-usd"
                      iconSize={14}
                      inputContainerStyle={{
                        padding: 6,
                      }}
                      leftIconContainerStyle={{ paddingRight: 0 }}
                      editable={false}
                      grayedOutOnDisable={true}
                      renderErrorMessage={false}
                      errorStyle={{ paddingBottom: 0 }}
                      testID="newDeliveryFee"
                    />
                  </View>
                )}
              </View>
            </View>
          </>
        )}

      {!isQuickSaleEnabled &&
        parseFloat(retailDeliveryFee) > 0 &&
        !isProductGiftCard && (
          <View
            style={tw(`flex flex-row items-center py-${isDesktop ? 2 : 1}`)}
          >
            <View
              style={tw(
                `flex flex-row items-${
                  Platform.OS !== "web" ? "center" : "baseline"
                } w-3/4`
              )}
            >
              <Text
                style={[fonts.heading4, tw("pr-4"), { fontWeight: "normal" }]}
              >
                {Localise(messages, `Retail Delivery Fee`)}
              </Text>
            </View>
            <View style={tw("w-1/4 flex flex-row items-center justify-end")}>
              <Text
                style={[
                  fonts.heading4,
                  { fontWeight: "normal", textAlign: "right" },
                ]}
                testID="retailDeliveryFee"
              >
                {!isEditOrder
                  ? `$${formatPrice(getPrice(retailDeliveryFee))}`
                  : `$${formatPrice(getPrice(oldRetailDeliveryFee))}`}
              </Text>
              {isEditOrder && (
                <View>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name={"newRetailDeliveryFee"}
                    path={`orderItems.${index}`}
                    testID="newRetailDeliveryFee"
                    value={formatPrice(getPrice(retailDeliveryFee))}
                    placeholder=""
                    containerStyle={{
                      width: 75,
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginLeft: 15,
                    }}
                    isPrice={true}
                    keyboardType="numeric"
                    iconType="material-community"
                    iconName="currency-usd"
                    iconSize={14}
                    inputContainerStyle={{
                      padding: 6,
                    }}
                    leftIconContainerStyle={{ paddingRight: 0 }}
                    editable={false}
                    grayedOutOnDisable={true}
                    renderErrorMessage={false}
                    errorStyle={{ paddingBottom: 0 }}
                  />
                </View>
              )}
            </View>
          </View>
        )}

      {!isQuickSaleEnabled &&
        (showRelayFee || (isEditOrder && isEmailOrder)) &&
        !isProductGiftCard && (
          <View
            style={tw(`flex flex-row items-center py-${isDesktop ? 2 : 1}`)}
          >
            <View
              style={tw(
                `flex flex-row items-${
                  Platform.OS !== "web" ? "center" : "baseline"
                } w-3/4`
              )}
            >
              <FormField
                name="relayFee"
                placeholder={Localise(messages, "Relay Fee")}
                label={Localise(messages, "Relay Fee")}
                labelStyle={{ fontWeight: "normal" }}
                path={`orderItems.${index}`}
                containerStyle={{
                  width: isDesktop ? 190 : 110,
                  paddingHorizontal: 0,
                }}
                errorStyle={{ paddingBottom: 0 }}
                isPrice={true}
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                keyboardType="numeric"
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password"
                testID="relay_fee"
                accessibilityLabel="relay_fee"
              />
            </View>
            <View style={tw("w-1/4 flex flex-row items-center justify-end")}>
              <Text
                style={[
                  fonts.heading4,
                  {
                    fontWeight: "normal",
                  },
                ]}
                testID="relayFee"
              >
                {!isEditOrder
                  ? `$${formatPrice(getPrice(relayFee))}`
                  : `$${formatPrice(getPrice(oldRelayFee))}`}
              </Text>
              {isEditOrder && (isPhoneOut || isEmailOrder) && (
                <View>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name={"newRelayFee"}
                    path={`orderItems.${index}`}
                    value={formatPrice(order.relayFee)}
                    placeholder=""
                    containerStyle={{
                      width: 80,
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginLeft: 15,
                    }}
                    isPrice={true}
                    keyboardType="numeric"
                    iconType="material-community"
                    iconName="currency-usd"
                    iconSize={14}
                    inputContainerStyle={{
                      padding: 6,
                    }}
                    leftIconContainerStyle={{ paddingRight: 0 }}
                    editable={false}
                    grayedOutOnDisable={true}
                    renderErrorMessage={false}
                    errorStyle={{ paddingBottom: 0 }}
                    testID="newRelayFee"
                  />
                </View>
              )}
            </View>
          </View>
        )}

      {isEditOrder && isEmailOrder && (
        <View style={tw(`flex flex-row items-center py-${isDesktop ? 2 : 1}`)}>
          <View
            style={tw(
              `flex flex-row items-${
                Platform.OS !== "web" ? "center" : "baseline"
              } w-3/4`
            )}
          >
            <FormField
              name="serviceFee"
              placeholder={Localise(messages, "Service Fee")}
              label={Localise(messages, "Service Fee")}
              labelStyle={{ fontWeight: "normal" }}
              path={`orderItems.${index}`}
              containerStyle={{
                width: isDesktop ? 190 : 110,
                paddingHorizontal: 0,
              }}
              errorStyle={{ paddingBottom: 0 }}
              isPrice={true}
              iconType="material-community"
              iconName="currency-usd"
              iconSize={14}
              inputContainerStyle={{
                padding: 6,
              }}
              leftIconContainerStyle={{ paddingRight: 0 }}
              keyboardType="numeric"
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password"
              testID="service_fee"
              accessibilityLabel="service_fee"
            />
          </View>
          <Text
            style={[
              fonts.heading4,
              tw("w-1/4 flex justify-end"),
              {
                fontWeight: "normal",
                textAlign: "right",
              },
            ]}
            testID="serviceFee"
          >
            {!isEditOrder
              ? `$${formatPrice(getPrice(serviceFee))}`
              : `$${formatPrice(getPrice(oldServiceFee))}`}
          </Text>
          {isEditOrder && isEmailOrder && (
            <View>
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newServiceFee"}
                path={`orderItems.${index}`}
                value={formatPrice(order.serviceFee)}
                placeholder=""
                containerStyle={{
                  width: 80,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 15,
                }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                editable={false}
                grayedOutOnDisable={true}
                renderErrorMessage={false}
                errorStyle={{ paddingBottom: 0 }}
                testID="newServiceFee"
              />
            </View>
          )}
        </View>
      )}

      {isEditOrder && isEmailOrder && (
        <View style={tw(`flex flex-row items-center py-${isDesktop ? 2 : 1}`)}>
          <View
            style={tw(
              `flex flex-row items-${
                Platform.OS !== "web" ? "center" : "baseline"
              } w-3/4`
            )}
          >
            <FormField
              name="retransFee"
              placeholder={Localise(messages, "Retrans Fee")}
              label={Localise(messages, "Retrans Fee")}
              labelStyle={{ fontWeight: "normal" }}
              path={`orderItems.${index}`}
              containerStyle={{
                width: isDesktop ? 190 : 110,
                paddingHorizontal: 0,
              }}
              errorStyle={{ paddingBottom: 0 }}
              isPrice={true}
              iconType="material-community"
              iconName="currency-usd"
              iconSize={14}
              inputContainerStyle={{
                padding: 6,
              }}
              leftIconContainerStyle={{ paddingRight: 0 }}
              keyboardType="numeric"
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password"
              testID="retrans_fee"
              accessibilityLabel="retrans_fee"
            />
          </View>
          <Text
            style={[
              fonts.heading4,
              tw("w-1/4 flex justify-end"),
              {
                fontWeight: "normal",
                textAlign: "right",
              },
            ]}
            testID="retransFee"
          >
            {!isEditOrder
              ? `$${formatPrice(getPrice(retransFee))}`
              : `$${formatPrice(getPrice(oldRetransFee))}`}
          </Text>
          {isEditOrder && isEmailOrder && (
            <View>
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newRetransFee"}
                path={`orderItems.${index}`}
                value={formatPrice(order.retransFee)}
                placeholder=""
                containerStyle={{
                  width: 80,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 15,
                }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                editable={false}
                grayedOutOnDisable={true}
                renderErrorMessage={false}
                errorStyle={{ paddingBottom: 0 }}
                testID="newRetransFee"
              />
            </View>
          )}
        </View>
      )}

      <View style={[tw("py-2")]}>
        <View style={tw("flex flex-row items-center")}>
          <View style={tw("flex flex-row w-3/4 items-center")}>
            <Text
              style={[fonts.heading4, tw("pr-1"), { fontWeight: "normal" }]}
            >
              {Localise(messages, "Tax")}
            </Text>
            <Text
              style={([fonts.heading4], { fontWeight: "normal" })}
              testID="taxRate"
            >
              {!isProductGiftCard
                ? `${formatPrice(totalTaxRate * 100)}%`
                : `0.00%`}
            </Text>
            {calculatingTax && (
              <>
                <Text
                  style={[
                    fonts.default,
                    tw("pr-1 pl-3"),
                    { fontWeight: "normal" },
                  ]}
                >
                  {Localise(messages, "Calculating Tax")}
                </Text>
                <ActivityIndicator
                  style={{ marginLeft: 5, marginTop: -5 }}
                  color={colors.activityIndicator}
                />
              </>
            )}
          </View>
          {taxExemptCode ? (
            <Text
              style={[
                tw("w-1/4 flex justify-end"),
                { fontWeight: "normal", textAlign: "center" },
              ]}
              testID="taxExempt"
            >
              {Localise(messages, "Tax Exempt")}
            </Text>
          ) : (
            <View style={tw("w-1/4 flex flex-row items-center justify-end")}>
              <Text
                style={[
                  fonts.heading4,
                  { fontWeight: "normal", textAlign: "right" },
                ]}
                testID="totalTax"
              >
                {!isEditOrder
                  ? `$${formatPrice(totalTax)}`
                  : `$${formatPrice(
                      getPrice(oldTax) - getPrice(taxRefundedAmount)
                    )}`}
              </Text>
              {isEditOrder && (
                <View>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name={"newTax"}
                    value={formatPrice(getPrice(totalTax))}
                    placeholder=""
                    containerStyle={{
                      width: 80,
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginLeft: 15,
                    }}
                    isPrice={true}
                    keyboardType="numeric"
                    iconType="material-community"
                    iconName="currency-usd"
                    iconSize={14}
                    inputContainerStyle={{
                      padding: 6,
                    }}
                    leftIconContainerStyle={{ paddingRight: 0 }}
                    editable={false}
                    grayedOutOnDisable={true}
                    renderErrorMessage={false}
                    errorStyle={{ paddingBottom: 0 }}
                    testID="newTotalTax"
                  />
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const OrderItemDetails = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);
  const isEditOrder = useSelector(selectIsEditOrder);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const dispatch = useDispatch();

  const {
    index,
    idx,
    lineItem,
    getTaxOfOrder,
    order,
    applyDiscounts,
    quantityOptions,
    allowMultipleProducts,
    lineItemPrice,
    isProductGiftCard = false,
  } = props;

  const pricePath = `orderItems.${index}.price.${idx}`;
  const productPath = `orderItems.${index}.lineItems.${idx}`;

  const {
    productFirstChoiceDescription = "",
    quantity = "1",
    actualPrice = 0,
    itemPrice = 0,
    // originalPrice = "0.0",
    originalQuantity = "1",
    isRemoved = false,
    newlyAdded = true,
  } = lineItem;

  const {
    value = 0,
    discount = 0,
    discountType = "",
    initialDiscountType = "",
    initialDiscount = 0,
  } = order.price[idx] || {};
  const productPrice = parseFloat(
    formatPrice(getPrice(value) * parseInt(quantity))
  );
  const productDiscount = getDiscount(discount, value, discountType, quantity);
  const priceAfterDiscount = parseFloat(
    formatPrice(productPrice - productDiscount)
  );

  const isPercentage = discountType === PERCENTAGE;

  const discountTypes = [
    { label: `$ ${Localise(messages, "Discount")}`, value: DOLLAR },
    { label: `% ${Localise(messages, "Discount")}`, value: PERCENTAGE },
  ];

  useEffect(() => {
    dispatch(setCalculatingTax(true));
    setFieldValue(
      `${pricePath}.discountedValue`,
      formatPrice(priceAfterDiscount)
    );

    if (isEditOrder) {
      const originalPrice = get(values, `${productPath}.originalPrice`, 0);
      const refundedAmount = get(values, `${productPath}.refundedAmount`, 0);
      const newItem = get(values, `${productPath}.newlyAdded`, true);

      // Below condition to set the newPrice value based on the below useEffect dependencies.
      if (
        newItem ||
        (!newItem &&
          formatPrice(originalPrice - refundedAmount) !==
            formatPrice(priceAfterDiscount))
      ) {
        setTimeout(() => {
          setFieldValue(
            `${pricePath}.newPrice`,
            formatPrice(priceAfterDiscount)
          );
        }, 0);
      } else {
        !newItem && setFieldValue(`${pricePath}.newPrice`, "");
      }
    }

    getTaxOfOrder();
  }, [discountType, discount, value, quantity, isRemoved]);

  return (
    <View key={idx}>
      <View
        style={tw("flex flex-row items-center justify-between pt-2 w-full")}
      >
        <View
          style={[tw("flex flex-row flex-wrap items-center"), { width: "75%" }]}
        >
          <Text
            style={[
              fonts.heading4,
              {
                fontWeight: "normal",
                width: isDesktop ? "35%" : "95%",
                paddingRight: 5,
                marginBottom: isDesktop ? 0 : 10,
                ...(isRemoved && { color: colors.grayScaleLight }),
              },
            ]}
            numberOfLines={1}
            testID="productDescription"
          >
            {productFirstChoiceDescription}
          </Text>

          {!isProductGiftCard && allowMultipleProducts && (
            <FormFieldPicker
              name={`quantity`}
              containerStyle={{
                width: isDesktop ? "15%" : "30%",
                maxWidth: isDesktop ? 60 : "auto",
                paddingHorizontal: 0,
                paddingBottom: 0,
              }}
              placeHolderExists={false}
              data={quantityOptions}
              path={productPath}
              disabled={isRemoved}
              testID="quantity"
            />
          )}

          {isProductGiftCard && (
            <FormField
              name="gc_quantity"
              labelStyle={{ fontWeight: "normal" }}
              value={`1`}
              containerStyle={{
                width: isDesktop ? "15%" : "30%",
                maxWidth: isDesktop ? 40 : "auto",
                paddingHorizontal: 0,
                paddingBottom: 0,
              }}
              testID="gc_quantity"
              grayedOutOnDisable={true}
              editable={false}
            />
          )}

          {!isProductGiftCard && applyDiscounts ? (
            <>
              <FormFieldPicker
                placeholder={{ label: "Discount" }}
                containerStyle={{
                  width: isDesktop ? "30%" : "50%",
                  maxWidth: 115,
                  paddingBottom: 0,
                }}
                inputContainerStyle={{ paddingVertical: 0 }}
                data={discountTypes}
                name="discountType"
                path={pricePath}
                disabled={isRemoved}
                onChange={(selectedDiscount) => {
                  if (selectedDiscount === "") {
                    setFieldValue(`${pricePath}.discount`, "0.00");
                  }
                }}
                testID="discountType"
              />

              {discountType ? (
                <FormField
                  name="discount"
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  containerStyle={{
                    width: isDesktop ? "19%" : "40%",
                    paddingHorizontal: 0,
                    marginTop: isDesktop ? 0 : 10,
                    ...(!isMobile && {
                      maxWidth: 100,
                    }),
                  }}
                  errorStyle={{ paddingBottom: 0 }}
                  keyboardType="numeric"
                  iconName={isPercentage ? "percent" : "currency-usd"}
                  iconPosition={!isPercentage}
                  iconSize={isPercentage ? 12 : 14}
                  iconType={
                    isPercentage ? "font-awesome" : "material-community"
                  }
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  placeholder={Localise(messages, "Discount")}
                  path={pricePath}
                  editable={!isRemoved}
                  grayedOutOnDisable={true}
                  testID={isPercentage ? "percent" : "usd"}
                />
              ) : (
                <View style={{ width: isDesktop ? "16%" : "40%" }}></View>
              )}
            </>
          ) : (
            <View style={{ width: isDesktop ? "46%" : "90%" }}></View>
          )}
        </View>
        <View
          style={{
            width: "25%",
            ...tw(
              `flex flex-${isMobile ? "col" : "row"} items-center justify-end`
            ),
          }}
        >
          <View style={{ textAlign: "right" }}>
            <>
              {((!isEditOrder && !!discountType && Number(discount) > 0) ||
                (isEditOrder &&
                  !newlyAdded &&
                  !!initialDiscountType &&
                  Number(initialDiscount) > 0)) && (
                <Text
                  style={tw("line-through")}
                  testID="actualProductPrice"
                >{`$${formatPrice(
                  isEditOrder ? actualPrice : productPrice
                )}`}</Text>
              )}
              <Text
                style={{ ...(isRemoved && { color: colors.grayScaleLight }) }}
                testID="discountedPrice"
              >
                {isEditOrder
                  ? !newlyAdded
                    ? `$${formatPrice(getPrice(lineItemPrice))}`
                    : `$${formatPrice(0)}`
                  : `$${formatPrice(priceAfterDiscount)}`}
              </Text>
            </>
          </View>

          {isRemoved && (
            <Text
              style={{
                color: colors.grayScaleLight,
                paddingRight: 12,
                marginLeft: 15,
                width: 75,
              }}
              testID="removedLabel"
            >
              {Localise(messages, "Removed")}
            </Text>
          )}

          {isEditOrder && !isRemoved && (
            <View>
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"newPrice"}
                path={pricePath}
                placeholder=""
                containerStyle={{
                  width: 80,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: isMobile ? 0 : 5,
                  ...(isMobile && {
                    marginTop: 10,
                  }),
                }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                isUpdateOnChange={true}
                testID="newPriceField"
                handleOnBlur={(updatedNewPrice = "") => {
                  if (
                    updatedNewPrice.length > 0 &&
                    updatedNewPrice !== productPrice
                  ) {
                    setFieldValue(
                      `${pricePath}.discountedValue`,
                      formatPrice(updatedNewPrice)
                    );
                    if (discountType !== "" && Number(discount) > 0) {
                      const newTotalPrice = isPercentage
                        ? (updatedNewPrice * 100) / parseFloat(100 - discount)
                        : parseFloat(updatedNewPrice) + parseFloat(discount);

                      setFieldValue(
                        `${pricePath}.value`,
                        formatPrice(
                          parseFloat(newTotalPrice) / parseInt(quantity)
                        )
                      );
                    } else {
                      setFieldValue(
                        `${pricePath}.value`,
                        formatPrice(
                          parseFloat(updatedNewPrice) / parseInt(quantity)
                        )
                      );
                    }
                  } else {
                    // For newlyAdded products actualPrice will be always for only one quantity so resetting quantity also.
                    if (newlyAdded) {
                      setFieldValue(
                        `${pricePath}.value`,
                        formatPrice(parseFloat(actualPrice))
                      );
                      setFieldValue(
                        `${pricePath}.newPrice`,
                        formatPrice(parseFloat(actualPrice))
                      );
                      setFieldValue(`${productPath}.quantity`, "1");
                    } else {
                      // for prepopulated items considering originalPrice & originalQuantity to prepopulate in case of clearning newPrice.
                      setFieldValue(`${pricePath}.value`, itemPrice);
                      setFieldValue(
                        `${productPath}.quantity`,
                        originalQuantity
                      );
                    }
                  }
                }}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const OrderSummary = (props) => {
  const dispatch = useDispatch();

  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const isEditOrder = useSelector(selectIsEditOrder);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const addressVerificationInfo = useSelector(selectAddressVerificationInfo);
  const redeemedGiftCardsData = useSelector(selectRedeemedGiftCardsData);
  const refundSummary = useSelector(selectRefundSummary) || [];
  const isPreviewLoading = useSelector(selectIsOrderSummaryLoading);

  const { paymentDetails = {} } = orderDetailsResponse;
  const orderDetails = get(orderDetailsResponse, `orderItems.0`, {});
  const {
    deliveryInfo: { deliveryMethod = "" } = {},
    direction: orderDirection = "",
    multiProductEligible = true,
    orderSource = "",
    orderItemId = "",
    receivingMember,
    sendingMember,
  } = orderDetails;

  const sourceMemberCode =
    orderDirection === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const { setFieldValue, values, setFieldTouched, setFieldError } =
    useFormikContext();
  const {
    index,
    orderInfo,
    selectedproducts,
    sendingMemberCode,
    isDeliveryFeeEditable = true,
    enablePromoCodes = true,
    isQuickSaleEnabled,
    isSubscriptionPage = false,
    isMultiOrderEligible,
    isPaymentEntitlementEnabled,
    customerInfo = {},
    memberCodes,
  } = props;

  const isCoreUser = isCoreMember(sendingMemberCode);
  const isCoreConnectUser = isCoreConnectMember(sendingMemberCode);

  const selectedShopPreferences =
    UserProfileStorage.getShopPreferences(sendingMemberCode);

  const showOrderSummary =
    (!isCoreUser && !isCoreConnectUser && !isEditOrder) ||
    (isEditOrder &&
      !isCoreConnectUser &&
      orderSource !== "MOL" &&
      !isEmpty(paymentDetails) &&
      (orderDirection !== "OUTBOUND" || isPhoneOutOrder(deliveryMethod)));

  const {
    orderItems = [],
    refundAvailable = "0.00",
    taxRefundedAmount = "0.00",
    feeRefundedAmount = "0.00",
  } = values;

  const productFirstChoiceCode = get(
    values,
    `orderItems.${index}.lineItems.0.productFirstChoiceCode`,
    ""
  );

  const isProductGiftCard = giftCardProducts.includes(productFirstChoiceCode);

  let taxExemptCode = customerInfo?.taxExemptCode || "";

  let totalTaxRate = taxExemptCode
    ? 0
    : orderItems[index]?.taxInfo?.taxRate || 0.0;

  let totalTax = isProductGiftCard
    ? 0
    : taxExemptCode
    ? 0
    : orderItems[index]?.taxInfo?.taxAmount || 0;

  const { orderTotal, orderSubTotal } = getTotalPrice(orderItems, index);

  const isRefundDue = useRef(false);
  const unBlockSummary = useRef(false);
  const refundAmountsError = useRef("");

  const quantityOptions = [];
  const maxQuantity = "99";

  if (!isSubscriptionPage) {
    for (var i = 1; i <= maxQuantity; i++) {
      quantityOptions.push({ label: i.toString(), value: i.toString() });
    }
  }

  const { totalTax: oldTax, orderTotal: oldOrderTotalAmount } = get(
    values,
    `orderItems.0.oldValues`,
    {}
  );

  const editItemTax = get(values, "orderItems.0.taxInfo.taxAmount", 0);
  const deliveryFee = parseFloat(get(values, "orderItems.0.deliveryFee", 0));
  const relayFee = parseFloat(get(values, "orderItems.0.relayFee", 0));
  const serviceFee = parseFloat(get(values, "orderItems.0.serviceFee", 0));
  const retransFee = parseFloat(get(values, "orderItems.0.retransFee", 0));
  let applyTaxOnDF = get(selectedShopPreferences, "tax_delivery_fee", "false");
  applyTaxOnDF = applyTaxOnDF === "true";
  const dependencies = [editItemTax];
  if (!applyTaxOnDF) {
    dependencies.push(deliveryFee);
    dependencies.push(relayFee);
    dependencies.push(serviceFee);
    dependencies.push(retransFee);
  }

  useEffect(() => {
    if (isEditOrder) {
      !showOrderSummary &&
        setFieldValue("paymentDetails.paymentMethod.0.enablePayment", false);

      const hasSettlementError = get(
        orderDetailsResponse,
        "orderItems.0.hasSettlementError",
        false
      );

      if (hasSettlementError) {
        setFieldValue("paymentDetails.paymentMethod.0.enablePayment", true);
        setFieldValue(`paymentDetails.isRefundDue`, false);
        setFieldTouched(
          `paymentDetails.paymentMethod.0.paymentMethodType`,
          true
        );
      }
    }
    return () => {
      dispatch(resetOrderSummary());
    };
  }, []);

  useEffect(() => {
    if (isEditOrder) {
      updateNewTotalAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, orderTotal]); // If there is no change in tax amount and orderTotal changes, we need to update the amount (amount due/ refund amt). So added orderTotal in the dependency

  useEffect(() => {
    if (
      isRefundDue.current !== undefined &&
      isRefundDue.current &&
      values.paymentDetails.newTotalAmount &&
      values.paymentDetails.newTotalAmount !== "0.00" &&
      canCallSummary(unBlockSummary)
    ) {
      getRefundSummary();
    } else {
      // If we already have refundTransactions in the formik and user increased the orderTotal, In that case we need to reset the refundTransactions
      !isEmpty(values.refundTransactions) &&
        !isRefundDue.current &&
        setFieldValue(`refundTransactions`, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paymentDetails.newTotalAmount, values.paymentDetails.isRefundDue]);

  useEffect(() => {
    if (
      !isEmpty(values.refundTransactions) &&
      refundSummary.refundTransactionDetails &&
      !isEqual(
        values.refundTransactions,
        refundSummary.refundTransactionDetails
      )
    ) {
      let totalRefundAmount = 0;
      values.refundTransactions.forEach((transaction) => {
        const { refundAmount = 0 } = transaction;
        const transactionRefundAmount = refundAmount ? refundAmount : 0;
        totalRefundAmount += parseFloat(transactionRefundAmount);
      });
      const differenceAmount =
        parseFloat(values.paymentDetails.newTotalAmount) - totalRefundAmount;

      if (
        !isNaN(totalRefundAmount) &&
        formatPrice(totalRefundAmount) !==
          formatPrice(parseFloat(values.paymentDetails.newTotalAmount)) &&
        isRefundDue.current &&
        Math.sign(differenceAmount) !== 0
      ) {
        setFieldValue("showError", true);
        refundAmountsError.current = `Incorrect total refund amount. Please ${
          Math.sign(differenceAmount) === -1 ? "remove" : "add"
        } $${Math.abs(differenceAmount).toFixed(
          2
        )} to match the total refund amount`;
      } else {
        setFieldValue("showError", false);
        refundAmountsError.current = "";
      }
    } else {
      setFieldValue("showError", false);
      refundAmountsError.current = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.refundTransactions]);

  const getRefundSummary = async () => {
    const memberTimezone = UserProfileStorage.getShopTimeZone(
      values.sendingMember || memberCodes[0]
    );

    const floristTechnology = UserProfileStorage.getFloristTechnology(
      values.sendingMember || memberCodes[0]
    );

    const createOrderReqObj = await createOrderObject({
      memberCodes,
      orderValues: values,
      addressVerificationInfo,
      memberTimezone,
      floristTechnology,
      redeemedGiftCardsData,
      allowMultipleProducts: !isEditOrder ? true : multiProductEligible,
      supportEdit: isEditOrder,
    });

    const editOrderReqObject = editOrderObject(
      redeemedGiftCardsData,
      createOrderReqObj,
      orderDetailsResponse,
      values,
      "",
      true
    );
    if (editOrderObject.length) {
      // Clearing these just before calling the fresh refund preview call
      setFieldValue(`refundTransactions`, []);
      dispatch(resetOrderSummary());
      setFieldValue(`showError`, false);

      dispatch(
        fetchOrderSummary({
          params: {
            recordId: orderItemId,
            deliveryMethod: deliveryMethod,
            orderUpdates: editOrderReqObject,
            sourceMemberCode,
          },
          resolve: (res) => {
            const refundTransactions =
              res?.summary?.refundTransactionDetails || [];

            setFieldValue("refundTransactions", refundTransactions);
          },
          reject: (err) => {
            console.log("err :>> ", err);
            // If lockexpired then locking again and fetching the refund summary
            if (err.includes("No matching lock found")) {
              dispatch(
                lockOrder({
                  params: {
                    recordId: orderItemId,
                    deliveryMethod: deliveryMethod,
                  },
                  resolve: () => {
                    getRefundSummary();
                  },
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        `Failed to get Refund Preview. Please try again`
                      )
                    );
                  },
                })
              );
            } else {
              ToasterHandler(
                "uh oh",
                Localise(
                  messages,
                  `Failed to get Refund Preview. Please try again`
                )
              );
            }
          },
        })
      );
    }
  };

  const updateNewTotalAmount = () => {
    if (isEditOrder && showOrderSummary) {
      const {
        orderItems = [],
        refundAvailable = "0.00",
        paymentDetails = {},
      } = values;

      const isPaymentPending =
        paymentDetails?.paymentStatus?.toLowerCase() === "pending";
      // In case of edit order we can allow only one orderItem edit so defaulting it to 0.
      const { orderTotal } = getTotalPrice(orderItems, 0);

      let amountDue = isPaymentPending ? orderTotal : 0;

      // const parsedOldOrderTotalAmount = parseFloat(oldOrderTotalAmount);
      const refundedOrderAmount = parseFloat(refundAvailable);
      const currentOrderTotal = parseFloat(orderTotal);

      if (currentOrderTotal >= refundedOrderAmount) {
        amountDue = isPaymentPending
          ? currentOrderTotal
          : currentOrderTotal - refundedOrderAmount;
        isRefundDue.current = false;
        setFieldTouched(
          `paymentDetails.paymentMethod.0.paymentMethodType`,
          true
        );
      } else {
        amountDue = isPaymentPending
          ? currentOrderTotal
          : refundedOrderAmount - currentOrderTotal;
        isRefundDue.current = isPaymentPending ? false : true;
      }

      setTimeout(() => {
        setFieldValue(
          `paymentDetails.isRefundDue`,
          amountDue === 0 && !isPaymentPending ? undefined : isRefundDue.current
        );

        // When isRefund is true, we are resetting payment method type to empty in order to stop triggering form validations
        // in case the user already selected any payment method
        if (isRefundDue.current !== undefined && isRefundDue.current) {
          setFieldValue(`paymentDetails.paymentMethod.0.paymentMethodType`, "");
        }

        setFieldValue(
          `paymentDetails.amountDue`,
          isRefundDue.current
            ? `(${formatPrice(getPrice(amountDue))})`
            : formatPrice(getPrice(amountDue))
        );

        setFieldValue(
          "paymentDetails.paymentMethod.0.enablePayment",
          isRefundDue.current ||
            (currentOrderTotal <= parseFloat(refundedOrderAmount) &&
              !isPaymentPending)
            ? false
            : isPaymentEntitlementEnabled
        );

        setFieldValue(
          `paymentDetails.newTotalAmount`,
          formatPrice(getPrice(amountDue))
        );

        if (formatPrice(getPrice(amountDue)) === "0.00")
          setFieldError(`paymentDetails.newTotalAmount`, "");
      }, 0);
    }
  };

  return (
    <>
      <View
        style={[
          {
            marginTop: 15,
            zIndex: -1,
          },
          !isMultiOrderEligible && { ...shapes.sectionBorder },
        ]}
      >
        <View>
          {showOrderSummary && (
            <>
              <View
                style={[
                  tw("flex flex-row pb-3 justify-between"),
                  { paddingHorizontal: 5 },
                ]}
              >
                <Text style={[fonts.sectionHeading]}>
                  {Localise(messages, "Order Summary")}
                </Text>
                {isEditOrder && (
                  <Text
                    style={[
                      fonts.heading4,
                      { fontSize: 15, color: colors.sectionTitle },
                    ]}
                  >
                    {Localise(messages, "New Price")}
                  </Text>
                )}
              </View>

              <OrderItems
                key={index}
                order={orderInfo}
                index={index}
                selectedproducts={selectedproducts}
                sendingMemberCode={sendingMemberCode}
                isDeliveryFeeEditable={isDeliveryFeeEditable}
                enablePromoCodes={enablePromoCodes}
                isDesktop={isDesktop}
                quantityOptions={quantityOptions}
                isPaymentEntitlementEnabled={isPaymentEntitlementEnabled}
                isQuickSaleEnabled={isQuickSaleEnabled}
                isSubscriptionPage={isSubscriptionPage}
                orderSubTotal={orderSubTotal}
                feeRefundedAmount={feeRefundedAmount}
                taxRefundedAmount={taxRefundedAmount}
                totalTax={totalTax}
                oldTax={oldTax}
                totalTaxRate={totalTaxRate}
                taxExemptCode={taxExemptCode}
                updateNewTotalAmount={updateNewTotalAmount}
                isProductGiftCard={isProductGiftCard}
              />
            </>
          )}

          <View style={[tw("py-2"), { paddingHorizontal: 5 }]}>
            <View style={tw("flex flex-row items-center")}>
              <View
                style={[
                  tw("flex flex-row"),
                  { width: isDesktop ? "75%" : "70%" },
                ]}
              >
                <Text
                  style={[
                    fonts.sectionHeading,
                    tw("pr-4"),
                    { color: colors.primary },
                  ]}
                >
                  {Localise(messages, "Total")}
                </Text>
              </View>
              <View
                style={[
                  tw("flex flex-row items-center justify-end"),
                  { width: isDesktop ? "25%" : "30%" },
                ]}
              >
                <Text
                  style={[fonts.sectionHeading, { color: colors.primary }]}
                  testID="orderTotal"
                >
                  {!isEditOrder
                    ? `$${formatPrice(orderTotal)}`
                    : showOrderSummary
                    ? `$${formatPrice(refundAvailable)}`
                    : `$${formatPrice(oldOrderTotalAmount)}`}
                </Text>
                {isEditOrder && showOrderSummary && (
                  <View>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={"newTotal"}
                      placeholder=""
                      containerStyle={{
                        width: 80,
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 15,
                      }}
                      value={formatPrice(orderTotal)}
                      isPrice={true}
                      keyboardType="numeric"
                      iconType="material-community"
                      iconName="currency-usd"
                      iconSize={14}
                      inputContainerStyle={{
                        padding: 6,
                      }}
                      leftIconContainerStyle={{ paddingRight: 0 }}
                      editable={false}
                      grayedOutOnDisable={true}
                      renderErrorMessage={false}
                      errorStyle={{ paddingBottom: 0 }}
                      testID={"newTotal"}
                    />
                  </View>
                )}
              </View>
            </View>
          </View>
          {isEditOrder && showOrderSummary && (
            <>
              {isRefundDue.current &&
                refundSummary?.paymentIndicator === "REFUND" && (
                  <RefundPreview
                    isLoading={isPreviewLoading}
                    transactions={refundSummary?.refundTransactionDetails}
                    refundEditable={true}
                    variant="editOrder"
                    formikPath={`refundTransactions`}
                  />
                )}
              {isRefundDue.current && isPreviewLoading && (
                <View style={tw("flex flex-row items-center")}>
                  <Text
                    style={[
                      fonts.default,
                      tw("px-1"),
                      { fontWeight: "normal" },
                    ]}
                  >
                    {Localise(messages, "Generating Refund Preview")}
                  </Text>
                  <ActivityIndicator color={colors.activityIndicator} />
                </View>
              )}
              <View style={[tw("py-2"), { paddingHorizontal: 5 }]}>
                <View style={tw("flex flex-row items-center")}>
                  <View style={tw("flex flex-row w-3/4")}>
                    <Text
                      style={[fonts.heading4, tw("pr-4"), { fontSize: 15 }]}
                    >
                      {Localise(messages, "Amount Due")}
                    </Text>
                  </View>
                  <View
                    style={tw("flex flex-row w-1/4 items-center justify-end")}
                  >
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={"amountDue"}
                      path={`paymentDetails`}
                      placeholder=""
                      containerStyle={{
                        width: 80,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      inputStyle={{
                        color: isRefundDue.current
                          ? colors.red
                          : colors.primary,
                      }}
                      isPrice={true}
                      keyboardType="numeric"
                      iconType="material-community"
                      iconName="currency-usd"
                      iconSize={14}
                      inputContainerStyle={{
                        padding: 6,
                      }}
                      leftIconContainerStyle={{ paddingRight: 0 }}
                      editable={false}
                      grayedOutOnDisable={true}
                      renderErrorMessage={false}
                      errorStyle={{ paddingBottom: 0 }}
                      isUpdateOnChange={true}
                      testID={"amountDue"}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
          {get(values, "showError", false) &&
            values.paymentDetails.amountDue !== 0.0 && (
              <Text
                style={{ ...theme.SubmitButton.errorStyle, paddingLeft: 5 }}
              >
                {refundAmountsError.current}
              </Text>
            )}
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    selectedproducts: state.mhq.ongoing.global.selectedproducts,
  };
};

export default connect(mapStateToProps, null)(OrderSummary);
