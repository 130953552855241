let client = null;

export const initWebSocketConnection = (url) => {
  client = new WebSocket(url);
  return client;
};

export const getWebSocketConnection = (url) => {
  if (!client) {
    client = initWebSocketConnection(url);
  }

  return Promise.resolve(client);
};

export const resetWebSocketConnection = () => {
  if (client !== null) client = null;
};
