import React, { forwardRef, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Platform,
  View,
  Linking,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Input, Image, Text, Button, CheckBox } from "react-native-elements";
import moment from "moment";
import QuillEditor from "components/elements/react-quill-editor";

import {
  Picker,
  Divider,
  Tooltip,
  DraggableTreeView,
  TabBar,
  RichTextEditor,
  AutoComplete,
  ToasterHandler,
} from "components/elements";
import {
  Section,
  CollectionSearch,
  LabelField,
  SelectAll,
  DateField,
  SecondaryButton,
  ConfirmModal,
} from "components/wrappers";
import { Form, SubmitButton, FormFieldSwitch } from "components/elements/forms";
import { RadioButton } from "components/elements/radio-button";
import UserProfileContext from "library/contexts/userProfile";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  selectSyncStoreStatus,
  selectAppInstallationStatus,
  selectAppInstallationUrl,
  selectMemberResults,
  selectMemberSearchError,
  selectMemberSearchText,
  selectAllCollections,
  selectSemRushStatus,
  selectSemRushMemberResults,
  selectSemRushMemberSearchError,
  selectSemRushMemberSearchText,
  selectCheckoutAppInstallUrl,
  selectCheckoutAppInstallationStatus,
  selectSearchText,
  selectActions,
  selectApiResponse,
  selectStoreSync,
  selectScheduledContentScreen,
  selectLanguages,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/selector";
import {
  triggerStoreSync,
  setMemberSearchText,
  createSEMRushProject,
  setStoreSync,
  setScheduledContentScreenTabs,
  setOverriddenShopsAction,
  triggerManualSync,
  setLanguages,
  saveLanguages,
} from "library/sagas/views/home/drawer/ftd-admin/mol-settings/slice";
import {
  selectShopCode,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/mercury-online/selector";
import Search from "components/containers/search";
import { theme, fonts, colors } from "styles/theme";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";
import { Field, useFormikContext } from "formik";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import orderBy from "lodash/orderBy";
import capitalize from "lodash/capitalize";
import isArray from "lodash/isArray";
import toUpper from "lodash/toUpper";
import cloneDeep from "lodash/cloneDeep";
import UAParser from "ua-parser-js";
import UserProfileStorage from "library/storage/userProfile";

export const Header = ({ title }) => {
  const { isMobile, isTablet } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <View>
      <Text
        style={[
          fonts.heading1,
          tw("pb-3"),
          isMobile ? tw("pt-5") : tw("pt-4"),
          isTablet && { fontSize: 16, fontWeight: "400" },
        ]}
      >
        {Localise(messages, title)}
      </Text>
      {!isMobile && <Divider />}
    </View>
  );
};

export const ExtendedScreenHeader = ({
  title,
  onClose,
  category,
  component,
  subTitle = "",
}) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const { scheduledContent = "" } = useSelector(selectApiResponse);
  const {
    components: scheduledContentComponents,
    tabs = [],
    activeTab = "",
  } = useSelector(selectScheduledContentScreen);

  const dispatch = useDispatch();

  let parsedScheduledContent = {};

  try {
    parsedScheduledContent = JSON.parse(scheduledContent);
  } catch {
    //Do nothing
  }

  const isScheduledContentActive = moment(
    parsedScheduledContent?.startDate
  ).isSameOrBefore(moment().format().split("T")[0]);

  return (
    <View>
      <View
        style={[
          tw("flex flex-row items-start justify-between"),
          {
            backgroundColor: colors.navBarActive,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        ]}
      >
        <View style={[tw("flex flex-col flex-wrap m-5 flex-auto")]}>
          <LabelField
            heading={true}
            style={fonts.heading4}
            text={Localise(
              messages,
              activeTab === "default-content" ||
                activeTab === "scheduled-content"
                ? title
                : `${title} - Shops not in Global Settings`
            )}
          />

          <Text style={{ ...fonts.style2, paddingTop: 4, paddingBottom: 8 }}>
            {subTitle}
          </Text>
        </View>
        {isDesktop && (
          <View style={tw("m-4")}>
            <TouchableOpacity onPress={onClose} testID="close">
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {scheduledContentComponents.includes(component) &&
        scheduledContent !== "" && (
          <View
            style={[
              tw("flex flex-row items-center justify-center"),
              {
                backgroundColor: colors.priceBanner,
                height: 35,
              },
            ]}
          >
            <Text
              style={[
                tw("flex flex-row items-center justify-center"),
                {
                  ...fonts.heading4,
                  fontSize: 14,
                },
              ]}
            >
              {Localise(
                messages,
                `${
                  isScheduledContentActive ? "Temporary" : "Default"
                } content is active `
              )}{" "}
            </Text>
          </View>
        )}
      {category !== "MOL Operations Support" &&
        scheduledContentComponents.includes(component) && (
          <View style={tw("mx-5 mt-5")}>
            <TabBar
              containerStyle={tw("w-full")}
              tabNames={tabs}
              currentTab={activeTab}
              setCurrentTab={(activeTab) =>
                dispatch(setScheduledContentScreenTabs({ value: activeTab }))
              }
              style={{
                marginTop: -10,
                zIndex: 0,
              }}
            />
          </View>
        )}
    </View>
  );
};

export const EmptyScreen = () => null;

function saveCancelButtons({ disableOnDirty = true, onCancel }, ref) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { dirty, resetForm } = useFormikContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <View style={tw("justify-end flex-row")}>
      {dirty && (
        <View style={{ marginRight: 10, justifyContent: "center" }}>
          <TouchableOpacity
            onPress={() => {
              onCancel && onCancel();
              resetForm();
            }}
            onResponderStart={() =>
              setTimeout(() => {
                onCancel && onCancel();
                resetForm();
              }, 0)
            }
            testID={"cancel"}
            accessibilityLabel={"cancel"}
          >
            <View>
              <Text style={fonts.link1}>{Localise(messages, "Cancel")}</Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
      <SubmitButton
        containerStyle={{ width: 100, marginLeft: 0 }}
        title={Localise(messages, "Save")}
        disableOnDirty={disableOnDirty}
        refFromProp={ref}
      />
    </View>
  );
}
export const SaveCancelButtons = forwardRef(saveCancelButtons);

export const CustomRTE = ({
  name,
  value,
  setValue,
  placeholder,
  onEditorStateChange,
}) => {
  const { isDesktop } = React.useContext(DeviceContext);
  const quillRef = useRef(null);

  const handleQuillRef = (quill) => {
    if (quill) {
      quillRef.current = quill.getEditor();
    }
  };

  return (
    <View style={{ paddingHorizontal: 5 }}>
      {isDesktop ? (
        <>
          <QuillEditor
            key="customRTE"
            testID={"html_content"}
            accessibilityLabel="customRTE"
            placeholder={placeholder}
            value={value}
            showMaxLengthError={false}
            blockTagName={"DIV"}
            onEditorChange={(editorValue) => {
              if (editorValue !== value) {
                setValue(editorValue);
                onEditorStateChange();
              }
            }}
            handleRef={handleQuillRef}
            modules={{
              clipboard: {
                matchVisual: false,
              },
              toolbar: [
                [
                  {
                    font: [
                      "arial",
                      "georgia",
                      "impact",
                      "verdana",
                      "tahoma",
                      "times",
                    ],
                  },
                ],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["link"],
              ],
            }}
          />
        </>
      ) : (
        <RichTextEditor
          name={name}
          testID={"html_content"}
          value={value}
          setValue={setValue}
          style={{
            minHeight: 350,
          }}
          editorStyle={{
            padding: 5,
            border: 1,
            borderStyle: "solid",
            borderColor: colors.light,
            minHeight: 350,
            ...fonts.default,
          }}
          scrollEnabled={true}
          placeholder={placeholder}
          textAlignment={"left"}
          toolbarOptions={
            Platform.OS === "web"
              ? {
                  options: ["link", "inline"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }
              : ["link", "bold", "italic", "underline"]
          }
          onEditorStateChange={onEditorStateChange}
        />
      )}
    </View>
  );
};

export const ABTesting = ({ field, enabled }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    setFieldValue,
    values: { [field]: content },
  } = useFormikContext();

  const title = Localise(messages, "Enable Integrated Add-ons on Product Page");

  let result = { integratedAddons: false };
  try {
    result = JSON.parse(content);
  } catch {
    // do nothing
  }

  const onPress = (newVal) => {
    setFieldValue(
      field,
      JSON.stringify({
        integratedAddons: newVal,
      })
    );
  };

  return (
    <View style={tw("flex flex-col pl-1")}>
      <View style={tw("flex flex-row items-center")}>
        <CheckBox
          containerStyle={[
            theme.CheckBox.inputContainerStyle,
            { paddingRight: 3 },
          ]}
          size={18}
          checked={result.integratedAddons}
          onPress={() => onPress(!result.integratedAddons)}
          testID={title}
          accessibilityLabel={title}
          disabled={!enabled}
        />
        <Text>{Localise(messages, title)}</Text>
      </View>
    </View>
  );
};

export const CustomSelect = ({ field, title, value, options, onSelect }) => {
  const [pickerValue, setPickerValue] = useState(value);

  useEffect(() => {
    setPickerValue(value);
  }, [value]);

  return (
    <View style={tw("flex flex-col")}>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {title}
      </Text>
      <Picker
        containerStyle={{ paddingHorizontal: 0 }}
        items={options}
        placeholder={{}}
        value={pickerValue}
        onValueChange={(e) => {
          setPickerValue(e);
          onSelect(field, e);
        }}
      ></Picker>
    </View>
  );
};

export const CustomInput = ({
  field,
  title,
  input = "",
  readOnly,
  labelStyle = {},
  onChangeText,
  style = {},
  ...otherProps
}) => {
  const [text, setText] = useState();
  const { Localise, messages } = React.useContext(I18NContext);
  useEffect(() => {
    setText(input);
  }, [input]);

  return React.useMemo(() => {
    return (
      <View style={[tw("flex flex-col"), style]}>
        <Text
          style={{
            ...fonts.heading4,
            padding: 5,
            paddingLeft: 5,
            ...labelStyle,
          }}
        >
          {Localise(messages, title)}
        </Text>
        <Input
          value={text}
          onChangeText={(txt) => {
            setText(txt);
            onChangeText(field, txt);
          }}
          placeholder={Localise(messages, `Enter ${title}`)}
          disabled={readOnly}
          {...otherProps}
        />
      </View>
    );
  }, [text, field, title, input, readOnly, onChangeText]);
};

export const ImageUploadField = ({
  field,
  title,
  input,
  setImageValue,
  dimensionsInfo = "",
  dimensions = "1920x1280",
  imageResizeMode = "cover",
  imageType = "thumbnail",
  logo = false,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const [image, setImage] = useState();
  const { isMobile } = React.useContext(DeviceContext);
  const isThumbnail = imageType === "thumbnail";

  useEffect(() => {
    setImage(input);
  }, [input]);

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (Platform.OS !== "web") {
      const base64Image = await FileSystem.readAsStringAsync(result.uri, {
        encoding: "base64",
      });

      result.uri = base64Image.startsWith("data:image")
        ? base64Image
        : `data:image/png;base64,${base64Image}`;
    }
    if (result.type == "success") {
      setImage(result.uri);
      setImageValue(field, result.uri);
    }
  };

  return (
    <View style={[tw(`flex flex-col ${logo && !isMobile ? "w-2/5" : ""}`)]}>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {title}
      </Text>
      <View
        style={tw(
          `flex ${
            isMobile || logo
              ? "flex-col"
              : `flex-row justify-${
                  isThumbnail ? "between" : "start"
                } items-center`
          }`
        )}
      >
        <View style={tw(isThumbnail && !isMobile ? "flex-1" : "pr-2")}>
          {image !== "" ? (
            <Tooltip text={image} height={isMobile ? null : 40}>
              <Image
                style={{
                  height: isThumbnail ? 100 : 200,
                  width: isThumbnail ? "95%" : 200,
                  borderWidth: 0.5,
                  borderColor: colors.light,
                }}
                containerStyle={{ margin: "auto" }}
                source={{ uri: image }}
                resizeMode={imageResizeMode}
              />
            </Tooltip>
          ) : (
            <Text
              style={[
                tw("flex flex-row justify-center items-center"),
                {
                  borderWidth: 1,
                  borderColor: "#CCC",
                  height: isThumbnail ? 100 : 200,
                  width: isThumbnail ? "95%" : 200,
                },
              ]}
            >
              {Localise(messages, "No Image")}
            </Text>
          )}
        </View>
        <View style={tw("flex flex-row items-end")}>
          <Button
            testID={"choose_image"}
            accessibilityLabel="choose_image"
            titleStyle={{
              ...theme.Button.secondaryTitleStyle,
              ...fonts.heading5,
            }}
            buttonStyle={{
              ...theme.Button.secondaryButtonStyle,
              paddingVertical: 3,
            }}
            containerStyle={{
              margin: 0,
              marginTop: isMobile || logo ? 10 : 0,
              paddingLeft: 0,
              width: "100%",
              maxWidth: 100,
            }}
            title={Localise(messages, image != "" ? "Replace" : "Upload")}
            onPress={pickDocument}
          />
          {logo && image ? (
            <Image
              onPress={() => {
                setImage("");
                setImageValue(field, "");
              }}
              style={{
                width: 25,
                height: 25,
                marginLeft: 5,
                marginTop: 5,
              }}
              source={require("static/assets/trash-can.png")}
            />
          ) : null}
        </View>
      </View>
      {dimensions ? (
        <Text style={{ ...fonts.style2, paddingTop: 4, paddingBottom: 8 }}>
          {dimensionsInfo ||
            `Please upload images of ${
              logo ? "height" : "size"
            } ${dimensions} for a better quality rendering.`}
        </Text>
      ) : null}
    </View>
  );
};

export const HTMLTextEditor = ({ field }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    setFieldValue,
    values: { content, contentStatus },
  } = useFormikContext();

  return (
    <CustomRTE
      name={field}
      value={content}
      setValue={(val) => {
        setFieldValue(field, val);
      }}
      placeholder={Localise(messages, "Enter HTML Content")}
      onEditorStateChange={() => {
        if (contentStatus) return;
        setFieldValue("contentStatus", true);
      }}
    />
  );
};

export const SEOTextEditor = ({ field }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    values: { content: value, contentStatus },
    setFieldValue,
  } = useFormikContext();

  let seoContent = {};
  try {
    seoContent = JSON.parse(value);
  } catch {
    seoContent = {
      title: "",
      entry: "",
      homepageMetaTitle: "",
      homepageMetaDescription: "",
    };
  }

  const { title, entry, homepageMetaTitle, homepageMetaDescription } =
    seoContent;

  const setSEOValue = (newVal = {}) => {
    setFieldValue(
      field,
      JSON.stringify({
        ...seoContent,
        ...newVal,
      })
    );
  };

  return (
    <View style={tw("flex flex-col")}>
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Homepage Title")}
      </Text>
      <CustomInput
        input={homepageMetaTitle}
        onChangeText={(_, txt) => {
          setSEOValue({ homepageMetaTitle: txt });
        }}
        placeholder={Localise(messages, "Enter Title")}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Homepage Meta Description")}
      </Text>
      <CustomInput
        input={homepageMetaDescription}
        onChangeText={(_, txt) => {
          setSEOValue({ homepageMetaDescription: txt });
        }}
        placeholder={Localise(messages, "Enter Description")}
        multiline={true}
        numberOfLines={4}
        inputStyle={{
          ...theme.Input.inputStyle,
          minHeight: "auto",
          height: "auto",
        }}
        inputContainerStyle={{
          minHeight: "auto",
          height: "auto",
        }}
      />
      <Text style={{ ...fonts.heading4, padding: 5, paddingLeft: 5 }}>
        {Localise(messages, "Title")}
      </Text>
      <CustomInput
        input={title}
        onChangeText={(_, txt) => {
          setSEOValue({ title: txt });
        }}
        placeholder={Localise(messages, "Enter Title")}
      />
      <Text
        style={{
          ...fonts.heading4,
          padding: 5,
          paddingLeft: 5,
          marginTop: 10,
        }}
      >
        {Localise(messages, "Description")}
      </Text>
      <CustomRTE
        value={entry}
        placeholder={Localise(messages, "Enter Description")}
        setValue={(val) => {
          setSEOValue({
            entry: val,
          });
        }}
        onEditorStateChange={() => {
          if (contentStatus) return;
          setFieldValue("contentStatus", true);
        }}
      />
    </View>
  );
};

export const BannerMetaData = ({
  group = "",
  props: { dimensions = "1920x1280" } = {},
  singleBanner = false,
  buttonRequired = true,
  allowVideoBanner = false,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { activeTab = "" } = useSelector(selectScheduledContentScreen);
  const field =
    activeTab === "default-content" ? "content" : "scheduledContent";

  const {
    values: { [field]: value, component },
    errors,
    dirty,
    setFieldValue,
  } = useFormikContext();

  const [tabNames, setTabNames] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [bannerType, setBannerType] = useState("");
  const selectBannerType = [
    {
      id: 1,
      name: Localise(messages, "Static Banner"),
      key: "static",
      selected: false,
    },
    ...(allowVideoBanner
      ? [
          {
            id: 2,
            name: Localise(messages, "Video Banner"),
            key: "video",
            selected: false,
          },
        ]
      : [
          {
            id: 2,
            name: Localise(messages, "Rotating Banner"),
            key: "rotating",
            selected: false,
          },
        ]),
  ];

  const bannerFields = {
    image: "",
    title: "",
    subTitle: "",
    altText: "",
    ...(buttonRequired ? { buttonLabel: "", buttonLink: "" } : {}),
  };

  const staticBannerImage = bannerFields;
  const rotatingBannerImages = [bannerFields, bannerFields, bannerFields];

  let parsedValues = {};
  let bannerMetaData =
    singleBanner || allowVideoBanner
      ? { staticBannerImage: staticBannerImage, bannerType: "static" }
      : {
          staticBanner: staticBannerImage,
          rotatingBanner: rotatingBannerImages,
          bannerType: activeTab === "scheduled-content" ? "" : "static",
        };

  try {
    parsedValues = JSON.parse(value);
    const bannerData = group.length > 0 ? parsedValues[group] : parsedValues;

    if (!bannerData.staticBanner) {
      bannerMetaData.staticBanner = bannerData.image
        ? bannerData
        : bannerFields;
    } else {
      bannerMetaData = {
        ...bannerData,
        staticBanner: {
          ...bannerData.staticBanner,
        },
        ...(!singleBanner && !allowVideoBanner
          ? {
              rotatingBanner: bannerData.rotatingBanner.map((obj) => ({
                ...obj,
              })),
            }
          : {}),
      };
    }
  } catch {
    // do nothing
  }
  useEffect(() => {
    setBannerType(bannerMetaData.bannerType);
  }, [bannerMetaData.bannerType]);

  useEffect(() => {
    if (bannerType && bannerMetaData.bannerType != bannerType) {
      const bannerData = {
        ...bannerMetaData,
        bannerType,
      };
      setFieldValue(
        field,
        JSON.stringify(
          group.length > 0
            ? {
                ...parsedValues,
                [group]: bannerData,
              }
            : bannerData
        )
      );
    }
  }, [bannerType]);

  useEffect(() => {
    const newData = bannerMetaData?.rotatingBanner?.map((_val, index) => {
      const isRemovable = index > 2;
      return {
        key: index,
        title: `Slide ${index + 1}`,
        isRemovable,
      };
    });
    setTabNames(newData);
  }, [bannerMetaData?.rotatingBanner?.length]);

  useEffect(() => {
    !dirty && setCurrentTab(0);
  }, [dirty]);

  const AddNewSlide = () => {
    return (
      <View
        style={[
          tw(`flex flex-row items-center justify-start`),
          { flexGrow: 1 },
        ]}
      >
        <TouchableOpacity
          testID={"addNewSlide"}
          accessibilityLabel={"addNewSlide"}
          style={{ padding: 5 }}
        >
          <Text
            style={fonts.link1}
            onPress={() => {
              const newTabNames = tabNames.map((_value, index) => {
                return {
                  key: index,
                  title: `Slide ${index + 1}`,
                };
              });

              setTabNames([
                ...newTabNames,
                { key: tabNames.length, title: `Slide ${tabNames.length + 1}` },
              ]);
              setCurrentTab(tabNames.length);
              const bannerData = {
                ...bannerMetaData,
                rotatingBanner: [
                  ...bannerMetaData.rotatingBanner,
                  bannerFields,
                ],
              };
              setFieldValue(field, JSON.stringify(bannerData));
            }}
          >
            {"[+] Add New Slide"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const removeTabs = (key) => {
    const updatedTabs = tabNames
      .filter((tab) => tab.key !== key)
      .map((_value, index) => {
        return {
          key: index,
          title: `Slide ${index + 1}`,
        };
      });

    setTabNames(updatedTabs);

    setCurrentTab(updatedTabs.length - 1);
    const rotatingBannerData = bannerMetaData.rotatingBanner.filter(
      (_value, index) => index !== key
    );

    const bannerData = {
      ...bannerMetaData,
      rotatingBanner: rotatingBannerData,
    };

    setFieldValue(field, JSON.stringify(bannerData));
  };

  return (
    <>
      {activeTab === "scheduled-content" && (
        <View
          style={[
            {
              zIndex: 200,
              marginBottom: 20,
            },
          ]}
        >
          <View style={[tw("flex flex-row items-end")]}>
            <DateField
              field={"startDate"}
              endDatePath={"endDate"}
              dateValueFormat="YYYY-MM-DD"
              defaultValue={bannerMetaData?.startDate}
              label={"Start Date"}
              customDateField={true}
            />
            <DateField
              field={"endDate"}
              endDatePath={"StartDate"}
              dateValueFormat="YYYY-MM-DD"
              defaultValue={bannerMetaData?.endDate}
              label={"End Date"}
              customDateField={true}
            />
            <SecondaryButton
              title={"Clear"}
              action={() => {
                setFieldValue(
                  field,
                  JSON.stringify({
                    ...bannerMetaData,
                    startDate: "",
                    endDate: "",
                  })
                );
              }}
              style={tw("flex flex-row items-start pb-1")}
            />
          </View>
          {errors &&
            errors["scheduledContent"]?.toLowerCase().includes("date") && (
              <Text style={{ ...theme.Input.errorStyle }}>
                {Localise(messages, errors["scheduledContent"])}
              </Text>
            )}
        </View>
      )}
      {singleBanner ? (
        <StaticBanner
          field={field}
          group={group}
          props={dimensions}
          bannerMetaData={bannerMetaData}
          bannerType={bannerType}
          buttonRequired={buttonRequired}
        />
      ) : (
        <View>
          <Text style={{ ...fonts.heading4, padding: 5 }}>
            {Localise(messages, "Select Banner Type")}
          </Text>

          <RadioButton
            data={selectBannerType.map((e) => ({
              ...e,
              selected: e.key === bannerMetaData.bannerType,
            }))}
            onPress={(evt) => {
              setBannerType(evt.key);
            }}
          />
          {bannerType === "static" ? (
            <StaticBanner
              field={field}
              group={group}
              props={dimensions}
              bannerMetaData={bannerMetaData}
              bannerType={bannerType}
            />
          ) : null}
          {bannerType === "rotating" ? (
            <>
              <>
                <TabBar
                  containerStyle={tw("w-full")}
                  tabNames={tabNames}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  style={{
                    marginTop: 10,
                  }}
                  removeTab={tabNames.length > 3}
                  setTabsCount={removeTabs}
                  siblings={() =>
                    component === "homepageBannerMetadata" &&
                    tabNames.length < 6 && <AddNewSlide />
                  }
                />
              </>
              {
                <View>
                  <RotatingBanner
                    data={bannerMetaData.rotatingBanner[currentTab]}
                    index={currentTab}
                    field={field}
                    group={group}
                    props={dimensions}
                    bannerMetaData={bannerMetaData}
                    bannerType={bannerType}
                  />
                  <Divider
                    style={{
                      shadowOffset: { width: 0, height: 0 },
                      shadowOpacity: 0,
                      shadowRadius: 0,
                      borderBottomWidth: 2,
                      marginVertical: 5,
                    }}
                  />
                </View>
              }
            </>
          ) : null}
          {bannerType === "video" && allowVideoBanner ? (
            <VideoComponent
              field={field}
              group={group}
              bannerMetaData={bannerMetaData}
              bannerType={bannerType}
            />
          ) : null}
          {errors && !errors[field]?.toLowerCase()?.includes("date") ? (
            <Text style={theme.CheckBox.errorStyle}>{errors[field]}</Text>
          ) : null}
        </View>
      )}
    </>
  );
};

const VideoComponent = ({ field, group, bannerMetaData, bannerType }) => {
  const { Localise, messages } = React.useContext(I18NContext);

  const {
    setFieldValue,
    values: { [field]: value },
  } = useFormikContext();
  const { video } = bannerMetaData;
  let parsedValues = {};
  try {
    parsedValues = JSON.parse(value);
  } catch {
    // do nothing
  }
  const setGlobalValue = (name, value) => {
    const bannerData = {
      ...bannerMetaData,
      video: { ...video, [name]: value },
      ...(bannerType ? { bannerType } : {}),
    };
    setFieldValue(
      field,
      JSON.stringify(
        group.length > 0
          ? {
              ...parsedValues,
              [group]: bannerData,
            }
          : bannerData
      )
    );
  };

  return (
    <>
      <View>
        <CustomInput
          input={video?.title ?? ""}
          field={"title"}
          title={Localise(messages, "Video Title")}
          onChangeText={setGlobalValue}
          placeholder={Localise(messages, "Enter Video Title")}
        />
        <CustomInput
          input={video?.subTitle ?? ""}
          field={"subTitle"}
          title={Localise(messages, "Video Subtitle")}
          onChangeText={setGlobalValue}
          placeholder={Localise(messages, "Enter Video Subtitle")}
        />
        <CustomInput
          input={video?.link ?? ""}
          field={"link"}
          title={Localise(messages, "Video Link")}
          onChangeText={setGlobalValue}
          placeholder={Localise(messages, "Enter Video Link")}
        />
        <CustomInput
          field={"buttonLabel"}
          title={Localise(messages, "Button Label")}
          input={video?.buttonLabel ?? ""}
          onChangeText={setGlobalValue}
        />
        <CustomInput
          field={"buttonLink"}
          title={Localise(messages, "Button Link")}
          input={video?.buttonLink ?? ""}
          onChangeText={setGlobalValue}
        />
      </View>
    </>
  );
};

const StaticBanner = ({
  field,
  group,
  props: { dimensions = "1920x1280" },
  bannerMetaData,
  bannerType,
  buttonRequired = true,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);

  const {
    setFieldValue,
    values: { [field]: value },
  } = useFormikContext();
  const { staticBanner: bannerImage } = bannerMetaData;
  let parsedValues = {};

  try {
    parsedValues = JSON.parse(value);
  } catch {
    // do nothing
  }

  const setGlobalValue = (name, value) => {
    const bannerData = {
      ...bannerMetaData,
      staticBanner: { ...bannerImage, [name]: value },
      ...(bannerType ? { bannerType } : {}),
    };
    setFieldValue(
      field,
      JSON.stringify(
        group.length > 0
          ? {
              ...parsedValues,
              [group]: bannerData,
            }
          : bannerData
      )
    );
  };

  return (
    <View style={tw("flex flex-col")}>
      <ImageUploadField
        field={"image"}
        title={Localise(messages, "Banner Image")}
        input={bannerImage?.image}
        setImageValue={setGlobalValue}
        dimensions={dimensions}
      />
      <CustomInput
        field={"altText"}
        title={Localise(messages, "Alt Text")}
        input={bannerImage?.altText}
        onChangeText={setGlobalValue}
      />
      <CustomInput
        field={"title"}
        title={Localise(messages, "Title")}
        input={bannerImage?.title}
        onChangeText={setGlobalValue}
      />
      <CustomInput
        field={"subTitle"}
        title={Localise(messages, "Subtitle")}
        input={bannerImage?.subTitle}
        onChangeText={setGlobalValue}
      />
      {buttonRequired ? (
        <>
          <CustomInput
            field={"buttonLabel"}
            title={Localise(messages, "Button Label")}
            input={bannerImage?.buttonLabel}
            onChangeText={setGlobalValue}
          />
          <CustomInput
            field={"buttonLink"}
            title={Localise(messages, "Button Link")}
            input={bannerImage?.buttonLink}
            onChangeText={setGlobalValue}
          />
        </>
      ) : null}
    </View>
  );
};

const RotatingBanner = ({
  field,
  props: { dimensions = "1920x1280" },
  index,
  data,
  group,
  bannerMetaData,
  bannerType,
}) => {
  const { rotatingBanner: bannerImages } = bannerMetaData;
  const {
    setFieldValue,
    values: { [field]: value },
  } = useFormikContext();
  let parsedValues = {};

  try {
    parsedValues = JSON.parse(value);
  } catch {
    // do nothing
  }

  const setGlobalValue = (name, value) => {
    const newData = bannerImages.map((bi, i) => ({
      ...bi,
      ...(i === index && { [name]: value }),
    }));

    const bannerData = {
      ...bannerMetaData,
      rotatingBanner: newData,
      bannerType,
    };

    setFieldValue(
      field,
      JSON.stringify(
        group.length > 0
          ? {
              ...parsedValues,
              [group]: bannerData,
            }
          : bannerData
      )
    );
  };

  return (
    <View style={tw("flex flex-col mt-3 mb-3")}>
      <ImageUploadField
        field={"image"}
        title={`Slide ${index + 1} Image`}
        input={data?.image}
        setImageValue={setGlobalValue}
        dimensions={dimensions}
      />
      <CustomInput
        field={"altText"}
        title={`Slide ${index + 1} Image Alt Text`}
        input={data?.altText}
        onChangeText={setGlobalValue}
      />
      <CustomInput
        field={`title`}
        title={`Slide ${index + 1} Title`}
        input={data?.title}
        onChangeText={setGlobalValue}
      />
      <CustomInput
        field={`subTitle`}
        title={`Slide ${index + 1} Sub Title`}
        input={data?.subTitle}
        onChangeText={setGlobalValue}
      />
      <CustomInput
        field={"buttonLabel"}
        title={`Slide ${index + 1} Button Label`}
        input={data?.buttonLabel}
        onChangeText={setGlobalValue}
      />
      <CustomInput
        field={"buttonLink"}
        title={`Slide ${index + 1} Button Link`}
        input={data?.buttonLink}
        onChangeText={setGlobalValue}
      />
    </View>
  );
};

export const SortableNavigation = ({ field, props: fieldProps }) => {
  const { activeTab } = useSelector(selectScheduledContentScreen);

  return (
    <Field name={field}>
      {({ field, form }) => {
        const { name, value } = field;
        const { setFieldValue, errors } = form;
        const { menuDepth } = fieldProps;

        let sortedData = value || "{}";
        const sortedJSONData = JSON.parse(
          sortedData?.replace(/"links":\[/g, '"children":[')
        );

        const setFormData = (treeData) => {
          const newData = treeData.map((item) => {
            if (menuDepth !== 1 && item.title === "About Us") {
              const isTitleExists = item?.children?.find(
                (val) => val.title === "Customer Reviews"
              );
              const index = item?.children?.findIndex(
                (val) => val.title === "Customer Reviews"
              );
              if (
                item.children &&
                isTitleExists &&
                index !== item.children.length - 1
              ) {
                const removedItem = item.children.splice(index, 1);
                return {
                  ...item,
                  children: item.children.concat(removedItem),
                };
              }
            }
            return item;
          });

          let formData = JSON.stringify({
            title: sortedJSONData.title,
            links: newData,
            startDate: sortedJSONData.startDate,
            endDate: sortedJSONData.endDate,
          });
          const finalFormData = formData.replace(/"children":\[/g, '"links":[');
          setFieldValue(name, finalFormData);
        };

        return (
          <>
            {activeTab === "scheduled-content" && (
              <View
                style={[
                  {
                    zIndex: 200,
                    marginBottom: 20,
                  },
                ]}
              >
                <View style={[tw("flex flex-row items-end")]}>
                  <DateField
                    field={"startDate"}
                    endDatePath={"endDate"}
                    dateValueFormat="YYYY-MM-DD"
                    defaultValue={sortedJSONData?.startDate}
                    label={"Start Date"}
                    customDateField={true}
                  />
                  <DateField
                    field={"endDate"}
                    endDatePath={"StartDate"}
                    dateValueFormat="YYYY-MM-DD"
                    defaultValue={sortedJSONData?.endDate}
                    label={"End Date"}
                    customDateField={true}
                  />
                  <SecondaryButton
                    title={"Clear"}
                    action={() => {
                      setFieldValue(
                        name,
                        JSON.stringify({
                          ...sortedJSONData,
                          startDate: "",
                          endDate: "",
                        })
                      );
                    }}
                    style={tw("flex flex-row items-start pb-1")}
                  />
                </View>
                {errors &&
                  errors["scheduledContent"]
                    ?.toLowerCase()
                    .includes("date") && (
                    <Text style={{ ...theme.Input.errorStyle }}>
                      {errors["scheduledContent"]}
                    </Text>
                  )}
              </View>
            )}
            <DraggableTreeView
              sortableData={sortedJSONData?.children}
              setValue={(data) => setFormData(data)}
              addMenuComponent={AddItems}
              maxDepth={menuDepth}
            />
          </>
        );
      }}
    </Field>
  );
};

export const AnnouncementBanner = ({ field }) => {
  const {
    setFieldValue,
    values: { [field]: value },
    errors,
  } = useFormikContext();
  const { activeTab } = useSelector(selectScheduledContentScreen);

  let announcementBanner;
  try {
    announcementBanner = JSON.parse(value);
  } catch {
    announcementBanner = { title: "" };
  }

  const setValue = (name, value) => {
    setFieldValue(
      field,
      JSON.stringify({
        ...announcementBanner,
        [name]: value,
      })
    );
  };

  return (
    <>
      <View style={[tw("flex flex-col"), { zIndex: 1 }]}>
        {activeTab === "scheduled-content" && (
          <View
            style={[
              {
                marginBottom: 20,
                zIndex: 200,
              },
            ]}
          >
            <View style={[tw("flex flex-row items-end")]}>
              <DateField
                field={"startDate"}
                endDatePath={"endDate"}
                dateValueFormat="YYYY-MM-DD"
                defaultValue={announcementBanner?.startDate}
                label={"Start Date"}
                customDateField={true}
              />
              <DateField
                field={"endDate"}
                endDatePath={"StartDate"}
                dateValueFormat="YYYY-MM-DD"
                defaultValue={announcementBanner?.endDate}
                label={"End Date"}
                customDateField={true}
              />
              <SecondaryButton
                title={"Clear"}
                action={() => {
                  setFieldValue(
                    field,
                    JSON.stringify({
                      ...announcementBanner,
                      startDate: "",
                      endDate: "",
                    })
                  );
                }}
                style={tw("flex flex-row items-start pb-1")}
              />
            </View>
            {errors &&
              errors["scheduledContent"]?.toLowerCase().includes("date") && (
                <Text style={{ ...theme.Input.errorStyle }}>
                  {errors["scheduledContent"]}
                </Text>
              )}
          </View>
        )}
      </View>
      <CustomInput
        field={"title"}
        title={"Announcement Banner Title"}
        input={announcementBanner.title}
        onChangeText={setValue}
        style={{ zIndex: -1 }}
      />
    </>
  );
};

export const ShopperApprovedReviews = ({ field, enabled }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    setFieldValue,
    values: { [field]: value },
  } = useFormikContext();
  const readOnly = !enabled;

  let shopperApprovedConfig = {};
  try {
    shopperApprovedConfig = JSON.parse(value);
  } catch {
    shopperApprovedConfig = { merchantId: "", apiToken: "", surveyToken: "" };
  }

  const setValue = (key, val) => {
    setFieldValue(
      field,
      JSON.stringify({
        ...shopperApprovedConfig,
        [key]: val,
      })
    );
  };

  const openURL = (link) =>
    Platform.OS === "web" ? window.open(link) : Linking.openURL(link);

  return (
    <View style={tw("flex flex-col")}>
      <View style={tw("flex-row p-1 pb-3")}>
        <Text
          style={{
            ...fonts.heading3,
          }}
        >
          <Text>{Localise(messages, "Click this link to manage")} </Text>
          <Text
            style={fonts.link1}
            onPress={() =>
              openURL("https://www.shopperapproved.com/account/dashboard")
            }
            numberOfLines={1}
            ellipsizeMode={"tail"}
          >
            {Localise(messages, "Reviews")}
          </Text>
          <Text> {Localise(messages, "on the Shopper Approved website")}</Text>
        </Text>
      </View>
      <CustomInput
        field={"merchantId"}
        title={"Site ID"}
        input={shopperApprovedConfig?.merchantId}
        onChangeText={setValue}
        readOnly={readOnly}
      />
      <CustomInput
        field={"apiToken"}
        title={"Api Token"}
        input={shopperApprovedConfig?.apiToken}
        onChangeText={setValue}
        readOnly={readOnly}
      />
      <CustomInput
        field={"surveyToken"}
        title={"Survey Token"}
        input={shopperApprovedConfig?.surveyToken}
        onChangeText={setValue}
        readOnly={readOnly}
      />
    </View>
  );
};

export const SyncStore = () => {
  const showSemRush = false;
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();

  const appStatus = useSelector(selectAppInstallationStatus);
  const checkoutAppStatus = useSelector(selectCheckoutAppInstallationStatus);
  const syncStatus = useSelector(selectSyncStoreStatus);
  const appInstallUrl = useSelector(selectAppInstallationUrl);
  const checkoutAppInstallUrl = useSelector(selectCheckoutAppInstallUrl);
  const memberResults = useSelector(selectMemberResults);
  const error = useSelector(selectMemberSearchError);
  const memberText = useSelector(selectMemberSearchText);

  const [memberCode, setMemberCode] = useState("");

  const pollInterval = 5 * 1000; // 5 secs
  let pollTimer = useRef(null);

  useEffect(() => {
    if (appInstallUrl) {
      if (window) {
        setTimeout(() => {
          window.open(appInstallUrl, "_blank", "noopener,noreferrer");
        }, [4000]);
      }

      setTimeout(() => {
        const hardStop = new Date().getTime() + 1 * 60 * 1000;

        pollTimer = setInterval(() => {
          if (new Date().getTime() > hardStop) {
            clearInterval(pollTimer);
            dispatch(
              setStoreSync({
                appStatus: "error",
                syncStatus: "error",
                appInstallUrl: "",
              })
            );
          } else callStoreSync(true);
        }, pollInterval);
      }, [10000]);
    } else if (checkoutAppInstallUrl) {
      if (window) {
        setTimeout(() => {
          window.open(checkoutAppInstallUrl, "_blank", "noopener,noreferrer");
        }, [4000]);

        setTimeout(() => {
          const hardStop = new Date().getTime() + 1 * 60 * 1000;

          pollTimer = setInterval(() => {
            if (new Date().getTime() > hardStop) {
              clearInterval(pollTimer);
              dispatch(
                setStoreSync({
                  checkoutAppStatus: "error",
                  syncStatus: "error",
                  checkoutAppInstallUrl: "",
                })
              );
            } else callStoreSync(true);
          }, pollInterval);
        }, [10000]);
      }
    } else {
      clearInterval(pollTimer);
    }

    return () => {
      clearInterval(pollTimer);
    };
  }, [appInstallUrl, checkoutAppInstallUrl]);

  const callStoreSync = (polling) => {
    memberCode &&
      dispatch(
        triggerStoreSync({
          params: { memberCode, polling },
          resolve: () => {
            ToasterHandler(
              "Looks Good!",
              Localise(messages, `Store successfully synced`)
            );
            dispatch(
              setStoreSync({
                memberSearchText: "",
              })
            );
          },
          reject: () => {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
          },
        })
      );
  };

  return (
    <>
      <View style={tw("flex flex-col my-5")}>
        <Text style={{ ...fonts.heading4, paddingLeft: 5 }}>
          {Localise(messages, "Enter FTD Member Number")}
        </Text>
        <Text
          style={{
            ...fonts.style2,
            padding: 5,
            paddingLeft: 5,
            paddingTop: 10,
          }}
        >
          {Localise(
            messages,
            "Sync Products & Collections, Theme and Store Settings."
          )}
        </Text>

        <AutoComplete
          placeholder={Localise(messages, "Example:  11-1111AA")}
          onBlur={() => {}}
          onChangeText={(val) => {
            dispatch(setMemberSearchText({ section: "storeSync", value: val }));
          }}
          data={orderBy(
            memberResults?.filter((e) =>
              toUpper(e.memberCode).includes(toUpper(memberText))
            ),
            "",
            "asc"
          )}
          initialDataLength={memberResults?.length}
          listDisplayValues={["memberCode", "shopName", "city", "state"]}
          onSelect={(val) => {
            dispatch(
              setMemberSearchText({
                section: "storeSync",
                value: `${val.memberCode}, ${val.shopName}, ${val.city}, ${val.state}`,
              })
            );
            setMemberCode(val.memberCode);
          }}
          value={memberText}
          onOptionChange={() => {}}
          testID="membercode-suggestions"
          showOnFocus={true}
          outerContainerStyle={{ paddingLeft: 5, zIndex: 1 }}
          error={error}
        />

        {memberCode ? (
          <CheckListStatus
            appStatus={appStatus}
            syncStatus={syncStatus}
            checkoutAppStatus={checkoutAppStatus}
          />
        ) : null}

        <Button
          testID={"sync_store"}
          accessibilityLabel="sync_store"
          containerStyle={{
            margin: 0,
            paddingLeft: 5,
            paddingTop: 10,
            maxWidth: 150,
          }}
          title={Localise(messages, "Sync Store")}
          onPress={callStoreSync}
          loading={
            syncStatus === "inprogress" ||
            appStatus === "inprogress" ||
            checkoutAppStatus === "inprogress" ||
            syncStatus === "partial_inprogress"
          }
          disabled={!memberText}
        />
      </View>
      <View style={tw("mt-5")}>
        {showSemRush && (
          <Section title={"Create SEMRush Project"} defaultOpen={true}>
            <CreateSEMRushProject />
          </Section>
        )}
      </View>
    </>
  );
};

export const ManualSyncStore = () => {
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();
  const [radioButtonValue, setRadioButtonValue] = useState("");
  const memberResults = useSelector(selectMemberResults);
  const error = useSelector(selectMemberSearchError);
  const showStuckOrder = false;
  const {
    setting = "",
    manualSyncStatus = "",
    manualSyncMemberSearchText = "",
  } = useSelector(selectStoreSync);

  const [memberCode, setMemberCode] = useState("");
  const radioButtonOptions = [
    {
      id: 1,
      name: Localise(messages, "Products and Collections"),
      key: "product",
      selected: true,
    },
    {
      id: 2,
      name: Localise(messages, "Theme"),
      key: "theme",
      selected: false,
    },
    {
      id: 3,
      name: Localise(messages, "Store Settings"),
      key: "store",
      selected: false,
    },
    ...(showStuckOrder
      ? [
          {
            id: 4,
            name: Localise(messages, "Stuck Order Listing"),
            key: "stuckOrderListing",
            selected: false,
          },
        ]
      : []),
  ];

  const disableButton =
    radioButtonValue === "stuckOrderListing" ||
    (radioButtonValue && !!manualSyncMemberSearchText);

  const updateSettings = () => {
    (memberCode || radioButtonValue === "stuckOrderListing") &&
      dispatch(
        triggerManualSync({
          params: {
            memberCode:
              radioButtonValue === "stuckOrderListing" ? "ftd" : memberCode,
            setting: radioButtonValue,
          },
          resolve: () => {
            ToasterHandler(
              "Looks Good!",
              Localise(
                messages,
                radioButtonValue === "stuckOrderListing"
                  ? "Stuck Orders fetched successfully"
                  : radioButtonValue === "theme"
                  ? `Theme Successfully Resynced`
                  : radioButtonValue === "product"
                  ? `Products and Collections have been Resynced`
                  : `Store Successfully Resynced`
              )
            );
            dispatch(
              setStoreSync({
                manualSyncMemberSearchText: "",
              })
            );
            setMemberCode("");
            setRadioButtonValue("");
          },
          reject: () => {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
          },
        })
      );
  };
  return (
    <>
      <View style={tw("flex flex-col my-5")}>
        {radioButtonValue !== "stuckOrderListing" && (
          <>
            <Text
              style={{ ...fonts.heading4, paddingLeft: 5, marginBottom: 10 }}
            >
              {Localise(messages, "Enter FTD Member Number")}
            </Text>
            <AutoComplete
              placeholder={Localise(messages, "Example:  11-1111AA")}
              onBlur={() => {}}
              onChangeText={(val) => {
                dispatch(
                  setMemberSearchText({
                    section: "storeSync",
                    value: val,
                    type: "manual",
                  })
                );
              }}
              data={orderBy(
                memberResults?.filter((e) =>
                  toUpper(e.memberCode).includes(
                    toUpper(manualSyncMemberSearchText)
                  )
                ),
                "",
                "asc"
              )}
              initialDataLength={memberResults?.length}
              listDisplayValues={["memberCode", "shopName", "city", "state"]}
              onSelect={(val) => {
                dispatch(
                  setMemberSearchText({
                    section: "storeSync",
                    value: `${val.memberCode}, ${val.shopName}, ${val.city}, ${val.state}`,
                    type: "manual",
                  })
                );
                setMemberCode(val.memberCode);
              }}
              value={manualSyncMemberSearchText}
              onOptionChange={() => {}}
              testID="membercode-suggestions"
              showOnFocus={true}
              outerContainerStyle={{ paddingLeft: 5, zIndex: 1 }}
              error={error}
            />
          </>
        )}

        {memberCode || radioButtonValue !== "stuckOrderListing" ? (
          <CheckListStatus manualSyncStatus={manualSyncStatus} />
        ) : null}
        <View>
          <RadioButton
            data={radioButtonOptions.map((e) => ({
              ...e,
              selected: e.key === radioButtonValue,
            }))}
            onPress={(value) => {
              setRadioButtonValue(value.key);
            }}
          />
        </View>
        <Button
          testID={"sync_store"}
          accessibilityLabel="sync_store"
          containerStyle={{
            margin: 0,
            marginBottom: 20,
            paddingLeft: 5,
            paddingTop: 10,
            maxWidth: 150,
          }}
          title={
            radioButtonValue !== "stuckOrderListing"
              ? Localise(messages, "Resync")
              : Localise(messages, "Get Stuck Orders List")
          }
          onPress={updateSettings}
          loading={manualSyncStatus === "inprogress"}
          disabled={!disableButton}
        />
        {setting === "stuckOrderListing" && manualSyncStatus === "completed" ? (
          <StuckOrderListing />
        ) : null}
      </View>
    </>
  );
};

const StuckOrderListing = () => {
  const { stuckOrderResponse = "" } = useSelector(selectStoreSync);

  return (
    <View style={tw("flex flex-col m-5")}>
      {isArray(stuckOrderResponse) && stuckOrderResponse.length > 0 ? (
        <>
          <View style={[tw("flex flex-row items-center justify-between")]}>
            <Text>{"Member Code"}</Text>
            <Text>{"Fulfillment Status"}</Text>
            <Text>{"Delivery Date"}</Text>
            <Text>{"Order Id"}</Text>
            <Text>{"MHQ Order Id"}</Text>
          </View>
          {stuckOrderResponse?.map((value, index) => {
            return (
              <View
                key={index}
                style={[tw("flex flex-row items-center justify-around")]}
              >
                <Text style={{ margin: 5 }}>{value?.memberCode}</Text>
                <Text style={{ margin: 5 }}>{value?.fulfillmentStatus}</Text>
                <Text style={{ margin: 5 }}>{value?.deliveryDate}</Text>
                <Text style={{ margin: 5 }}>{value?.orderId}</Text>
                <Text style={{ margin: 5 }}>{value?.mhqOrderNumber}</Text>
              </View>
            );
          })}
        </>
      ) : (
        <Text>No Stuck Orders at this moment</Text>
      )}
    </View>
  );
};

const CreateSEMRushProject = () => {
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();

  const memberResults = useSelector(selectSemRushMemberResults);
  const error = useSelector(selectSemRushMemberSearchError);
  const memberText = useSelector(selectSemRushMemberSearchText);
  const status = useSelector(selectSemRushStatus);
  const [memberCode, setMemberCode] = useState("");

  const createProject = () => {
    memberCode &&
      dispatch(
        createSEMRushProject({
          params: { memberCode },
          resolve: () => {
            ToasterHandler(
              "looks good",
              Localise(messages, `SEM Rush Project content has been created.`)
            );
          },
          reject: () => {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
          },
        })
      );
  };

  return (
    <View style={tw("flex flex-col")}>
      <Text style={{ ...fonts.heading4, paddingLeft: 5 }}>
        {Localise(
          messages,
          "Create the project after the domain has been connected to Shopify and site is live."
        )}
      </Text>
      <Text style={{ ...fonts.heading4, paddingLeft: 5, marginVertical: 10 }}>
        {Localise(messages, "Enter Store Member Code")}
      </Text>

      <AutoComplete
        placeholder={Localise(messages, "Enter Member Code")}
        onBlur={() => {}}
        onChangeText={(val) => {
          dispatch(setMemberSearchText({ section: "semRush", value: val }));
        }}
        data={orderBy(
          memberResults?.filter((e) =>
            toUpper(e.memberCode).includes(toUpper(memberText))
          ),
          "",
          "asc"
        )}
        initialDataLength={memberResults?.length}
        listDisplayValues={["memberCode", "shopName", "city", "state"]}
        onSelect={(val) => {
          dispatch(
            setMemberSearchText({
              section: "semRush",
              value: `${val.memberCode}, ${val.shopName}, ${val.city}, ${val.state}`,
            })
          );
          setMemberCode(val.memberCode);
        }}
        value={memberText}
        onOptionChange={() => {}}
        testID="membercode-suggestions"
        showOnFocus={true}
        outerContainerStyle={{ paddingLeft: 5, zIndex: 10 }}
        error={error}
      />
      {status === "error" ? (
        <Text style={{ color: colors.error }}>
          {"Something went wrong, please try again"}
        </Text>
      ) : null}
      <Button
        testID={"semRush"}
        accessibilityLabel="semRush"
        containerStyle={{
          margin: 0,
          paddingLeft: 5,
          paddingTop: 10,
          maxWidth: 150,
        }}
        title={Localise(messages, "Create Project")}
        onPress={createProject}
        loading={status === "inprogress"}
        disabled={!memberText}
      />
    </View>
  );
};

const CheckListStatus = ({
  appStatus = "",
  syncStatus = "",
  checkoutAppStatus = "",
  manualSyncStatus = "",
}) => {
  const { isCatalogSync = true, setting: component = "" } =
    useSelector(selectStoreSync);
  const colorCodes = {
    inprogress: colors.highlighter,
    partial_inprogress: colors.highlighter,
    partial_error: colors.error,
    error: colors.error,
    completed: colors.success,
  };

  const icons = {
    inprogress: require("static/assets/accept-deadline.png"),
    partial_inprogress: require("static/assets/accept-deadline.png"),
    partial_error: require("static/assets/suspend-alert.png"),
    error: require("static/assets/notSynced.png"),
    completed: require("static/assets/address-verification-check-mark-fill.png"),
  };

  const syncMessages = {
    inprogress: "Syncing Store",
    partial_inprogress: !isCatalogSync
      ? "Products and Collections Sync Unsuccessful, retrying...."
      : "Theme and Store Sync Unsuccessful, retrying...",
    partial_error: !isCatalogSync
      ? "Products and Collections Sync Unsuccessful, Please email to support team -"
      : "Theme and Store Sync Unsuccessful, Please email to support team -",
    error: "Store Sync Unsuccessful, please email to support team -",
    completed: "Store Successfully Synced",
  };

  const manualSyncMessages = {
    inprogress: "Syncing Store",
    error: `${capitalize(
      component
    )} Sync Unsuccessful, Please email to support team -`,
    completed: `${capitalize(component)} Successfully Synced`,
  };

  const appMessages = {
    inprogress:
      "Installing Middleware. When prompted, click Install App button on Shopify Admin page to complete. If new tab does not open, enable browser pop-up",
    error: "Store configuration failed, please try again",
    completed: "Store successfully configured ",
  };

  const checkoutAppMessages = {
    inprogress:
      "Installing Checkout App. When prompted, click Install App button on Shopify Admin page to complete. If new tab does not open, enable browser pop-up",
    error: "Checkout app configuration failed, please try again",
    completed: "Checkout app successfully configured ",
  };

  return [
    { status: appStatus, type: "appInstall" },
    { status: checkoutAppStatus, type: "checkoutAppInstall" },
    { status: syncStatus, type: "syncStore" },
    { status: manualSyncStatus, type: "manualSync" },
  ].map(({ type, status }) => {
    return status && component !== "stuckOrderListing" ? (
      <View key={type} style={tw("flex flex-row items-center pl-1 py-2")}>
        {status === "inprogress" || status === "partial_inprogress" ? (
          <ActivityIndicator size={12} />
        ) : (
          <Image
            style={{
              width: 15,
              height: 15,
            }}
            resizeMode="cover"
            source={icons[status]}
          />
        )}
        <Text
          style={{
            ...fonts.heading5,
            color: colorCodes[status],
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          {type === "appInstall"
            ? appMessages[status]
            : type === "checkoutAppInstall"
            ? checkoutAppMessages[status]
            : type === "syncStore"
            ? syncMessages[status]
            : manualSyncMessages[status]}
        </Text>
        {status === "partial_error" || status === "error" ? (
          <Text
            style={{
              ...fonts.heading5,
              color: colorCodes[status],
              paddingLeft: 5,
              paddingRight: 5,
              textDecorationLine: "underline",
            }}
            onPress={() =>
              Linking.openURL(
                "mailto:MOLOnboarding@ftdi.com?subject=SendMail&body=Description"
              )
            }
          >
            MOLOnboarding@ftdi.com
          </Text>
        ) : null}
      </View>
    ) : null;
  });
};

const AddItems = ({ treeData, setValue, section, maxDepth }) => {
  const [title, setTitle] = useStateIfMounted("");
  const [url, setUrl] = useStateIfMounted("");
  const [showNewOptions, setShowNewOptions] = useStateIfMounted(false);
  const { messages, Localise } = React.useContext(I18NContext);

  const [collectionQuery, setCollectionQuery] = useStateIfMounted("");
  const [isButtonSelected, setIsButtonSelected] = useStateIfMounted(null);

  const setInputData = ({ title = "", url = "", query = "" }) => {
    setTitle(title);
    setUrl(url);
    setCollectionQuery(query);
  };
  const {
    values: { component },
  } = useFormikContext();

  const childrenData = section?.children || [];
  const radioButtonOptions = [
    {
      id: 1,
      name: Localise(messages, "Choose from Collections"),
      selected: true,
    },
    {
      id: 2,
      name: Localise(messages, "Enter Manually"),
      selected: false,
    },
  ];

  let updatedMenus = cloneDeep(treeData);

  const getAllCollectionsTitles = (members) => {
    let children = [];
    return members
      .map((m) => {
        if (m.children && m.children.length) {
          children = [...children, ...m.children];
        }
        return m;
      })
      .concat(children.length ? getAllCollectionsTitles(children) : children);
  };
  const children = getAllCollectionsTitles(updatedMenus);

  const isCollectionExists =
    children.findIndex(
      (e) => e.url.toLowerCase() === url.toLowerCase().trim()
    ) > -1;
  const isReviewsAllowed =
    title.toLowerCase().trim() === "Customer Reviews".toLowerCase();

  const disableAddMenu =
    /^(?=.*\S).+$/.test(title) &&
    /^(?=.*\S).+$/.test(url) &&
    !isCollectionExists &&
    !isReviewsAllowed;

  const onAddMenu = () => {
    if (Platform.OS !== "web" && section) {
      let addedMenu = cloneDeep(childrenData);
      const updatedInnerMenus = treeData.map((menu) =>
        menu.title === section.title && menu.url === section.url
          ? {
              children: [...addedMenu, { title: title, url: url }],
              title: section.title,
              url: section.url,
            }
          : menu
      );
      setValue(updatedInnerMenus);
    } else {
      setValue([...updatedMenus, ...[{ title: title, url: url }]]);
    }
    setInputData("");
  };

  useEffect(() => {
    if (!showNewOptions) {
      setIsButtonSelected(radioButtonOptions[0]);
    }
  }, [showNewOptions]);

  return (
    <View style={[{ zIndex: 10 }, tw("mb-2 pl-4 flex-col")]}>
      <View style={{ marginTop: 10, marginRight: 10 }}>
        <TouchableOpacity
          testID={"AddNewMenu"}
          accessibilityLabel={"AddNewMenu"}
        >
          <Text
            style={fonts.link1}
            onPress={() => {
              setShowNewOptions(!showNewOptions);
              if (!showNewOptions) {
                setInputData("");
              }
            }}
          >
            {"[+] "}
            {!section
              ? Localise(messages, "Add New Menu")
              : Localise(messages, "Add New Sub-Menu")}
          </Text>
        </TouchableOpacity>
      </View>

      {showNewOptions && (
        <View
          style={[
            {
              marginTop: 10,
            },
            Platform.OS === "web" &&
              tw("flex flex-row justify-between items-end"),
          ]}
        >
          {maxDepth > 1 ||
          section?.title === "Occasions" ||
          section?.title === "Products" ? (
            <>
              <View style={{ maxWidth: 500 }}>
                <RadioButton
                  data={radioButtonOptions.map((e) => ({
                    ...e,
                    selected: e.id === isButtonSelected?.id,
                  }))}
                  onPress={(value) => {
                    setIsButtonSelected(value);
                  }}
                />
                <View>
                  {(isButtonSelected?.id === 1 && (
                    <View style={tw("mt-3 ml-0")}>
                      {component === "mainNavigation" && (
                        <ShowCollectionsField
                          setInputData={setInputData}
                          treeData={treeData}
                          collectionQuery={collectionQuery}
                          containerStyle={{ paddingHorizontal: 0 }}
                        />
                      )}
                    </View>
                  )) ||
                    (isButtonSelected?.id === 2 && (
                      <View style={tw("mt-3 ml-0")}>
                        <Input
                          placeholder={Localise(messages, "Menu Title")}
                          testID="menu-title"
                          value={title}
                          onChangeText={(val) => setTitle(val)}
                          inputContainerStyle={{ width: 250 }}
                          containerStyle={{ paddingHorizontal: 0 }}
                        />
                        <Input
                          placeholder={Localise(messages, "Paste a link")}
                          testID="menu-url"
                          value={url}
                          onChangeText={(val) => setUrl(val)}
                          inputContainerStyle={{ width: 250 }}
                          containerStyle={{ paddingHorizontal: 0 }}
                        />
                        {isCollectionExists ? (
                          <Text style={theme.Picker.errorStyle}>
                            URL already exists in the menu, Please try another
                          </Text>
                        ) : null}
                        {isReviewsAllowed ? (
                          <Text style={theme.Picker.errorStyle}>
                            Cannot add Customer Reviews manually
                          </Text>
                        ) : null}
                      </View>
                    ))}
                </View>
              </View>
            </>
          ) : (
            <View>
              <Input
                placeholder={Localise(messages, "Menu Title")}
                testID="menu-title"
                value={title}
                onChangeText={(val) => setTitle(val)}
                inputContainerStyle={{ width: 250 }}
                containerStyle={{ paddingHorizontal: 0 }}
              />
              <Input
                placeholder={Localise(messages, "Paste a link")}
                testID="menu-url"
                value={url}
                onChangeText={(val) => setUrl(val)}
                inputContainerStyle={{ width: 250 }}
                containerStyle={{ paddingHorizontal: 0 }}
              />
            </View>
          )}
          <View>
            <Button
              titleStyle={theme.Button.secondaryTitleStyle}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 5,
                paddingHorizontal: 5,
              }}
              containerStyle={{
                justifyContent: "center",
                alignItems: "flex-start",
                marginTop: 10,
                marginLeft: 0,
              }}
              onPress={onAddMenu}
              disabled={!disableAddMenu}
              title={
                !section
                  ? Localise(messages, "Add New Menu")
                  : Localise(messages, "Add New Sub-Menu")
              }
              testID={"Add Menu Item"}
              accessibilityLabel={"Add Menu Item"}
            />
          </View>
        </View>
      )}
    </View>
  );
};

const ShowCollectionsField = ({ setInputData, treeData, collectionQuery }) => {
  const { data, error } = useSelector(selectAllCollections);

  const collectionsData = treeData.filter((val) =>
    val.url.includes("/collections")
  );

  const getAllCollectionsTitles = (members) => {
    let children = [];
    return members
      .map((m) => {
        if (m.children && m.children.length) {
          children = [...children, ...m.children];
        }
        return m;
      })
      .concat(children.length ? getAllCollectionsTitles(children) : children);
  };

  const childrenCollections = getAllCollectionsTitles(collectionsData);
  const mainCollections = treeData.filter(
    (val) => val.url.includes("/collections") && !val.children
  );

  const existingMenuCollections = [...childrenCollections, ...mainCollections];

  return (
    <CollectionSearch
      setInputData={(val) => {
        setInputData({
          title: val?.title,
          url: val?.seoUrl,
          query: val?.query || val?.title,
        });
      }}
      selectorData={data}
      error={error}
      inputValue={collectionQuery}
      type="title"
      referenceData={existingMenuCollections}
      popperPlacement={"top"}
    />
  );
};

export const BotDetectionRules = ({ field }) => {
  const {
    setFieldValue,
    values: { [field]: value },
  } = useFormikContext();
  const [input, setInput] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [browserVersion, setBrowserVersion] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [result, setResult] = useState("");
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  let botDetectionRules;
  try {
    botDetectionRules = `${value}`;
  } catch {
    botDetectionRules = "";
  }

  useEffect(() => {
    if (Platform.OS === "web") {
      setInput(navigator.userAgent);
      onApplyRules(navigator.userAgent);
    }
  }, []);

  const setValue = (_name, value) => {
    setFieldValue(field, value.trim());
  };

  const onApplyRules = (ua = input) => {
    const parser = new UAParser(ua);
    const { name: osName = "", version: osVersion = "" } = parser.getOS();
    const { model = "", vendor = "" } = parser.getDevice();
    const {
      name: browser = "",
      version: browserVersion = "",
      major: browserMajorVersion,
    } = parser.getBrowser();

    setUserAgent(ua);
    setOperatingSystem(`${osName} ${osVersion}`);
    setDeviceModel(`${model} ${vendor}`);
    setBrowserName(browser);
    setBrowserVersion(browserVersion);

    let botRulesJSON = [],
      res = "";
    try {
      botRulesJSON = JSON.parse(botDetectionRules);
    } catch {
      res =
        "We are seeing an error in rule execution, please verify your rules json once";
    }

    if (botRulesJSON.length) {
      botRulesJSON.forEach((rule = {}) => {
        const {
          osRegex,
          deviceRegex,
          browserRegex,
          browserVersionRegex,
          lastBrowserVersionSupported,
          userAgentRegex,
          result,
        } = rule;

        if (!res) {
          if (osRegex && !new RegExp(osRegex, "i").test(operatingSystem))
            return;
          if (deviceRegex && !new RegExp(deviceRegex, "i").test(deviceModel))
            return;
          if (browserRegex && !new RegExp(browserRegex, "i").test(browser))
            return;
          if (
            browserVersionRegex &&
            !new RegExp(browserVersionRegex, "i").test(browserVersion)
          )
            return;
          if (
            lastBrowserVersionSupported &&
            parseFloat(lastBrowserVersionSupported) <
              parseFloat(browserMajorVersion)
          )
            return;
          if (
            userAgentRegex &&
            !new RegExp(userAgentRegex, "i").test(userAgent)
          )
            return;

          res = result;
        }
      });
      setResult(res || browser);
    }
  };

  return (
    <View style={tw("flex flex-col")}>
      <CustomInput
        field={"entry"}
        title={"Configure JSON Rules"}
        input={botDetectionRules}
        onChangeText={setValue}
        multiline={true}
        numberOfLines={50}
        labelStyle={{ fontSize: 14 }}
        inputContainerStyle={{ height: 350 }}
        inputStyle={{ height: 350, marginTop: 20 }}
      />
      <Section title={"Rule Usage & Definition"} defaultOpen={false}>
        <View style={tw("flex flex-col")}>
          <View style={[tw("flex flex-col"), { padding: 5 }]}>
            <Text style={{ padding: 5, paddingBottom: 15 }}>
              BOT Detection Rules is a JSON array with multiple rules consisting
              of facts and values. A rule must contain atleast one fact-value
              and result fields as mandatory. RulesEngine execute rules in the
              order of the array and will not proceed further once a rule is
              passed. A rule will be passed when all the fact-value checks
              involved are valid.
            </Text>
          </View>
          <View style={[tw("flex flex-col"), { padding: 5 }]}>
            <Text
              style={{
                ...fonts.heading4,
                padding: 5,
                textDecorationLine: "underline",
              }}
            >
              {Localise(messages, "Facts & Values:")}
            </Text>
            <View style={[tw("flex flex-col"), { padding: 5 }]}>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  requestType:
                </Text>{" "}
                This string holds request type being DTS (
                {'"Direct To Site traffic"'}) or not. Allowed values are -{" "}
                {'"DTS"'} or blank.
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  osRegex:
                </Text>{" "}
                This regex applies on {'"Operating System"'} field dervied from
                user-agent value. Sample regex: {'"Linux|Macintosh|Windows"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  deviceRegex:
                </Text>{" "}
                This regex applies on {'"Device Model"'} field dervied from
                user-agent value. Especially used for mobile/tablet devices.
                Sample: {'"Apple iPad|Samsung"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  browserRegex:
                </Text>{" "}
                This regex applies on {'"Browser Name"'} field derived from
                user-agent value. Sample: {'"Chrome|Safari|Firefox"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  browserVersionRegex:
                </Text>{" "}
                This regex applies on {'"Browser Version"'} field derived from
                user-agent value. This goes in conjunction with browserRegex
                condition. Sample: {'"115.0.0.0|89.0.14.12"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  lastBrowserVersionSupported:
                </Text>{" "}
                This string holds the last browser version that is currently
                being supported, helps in creating BOT rules. This goes in
                conjunction with browserRegex condition. Sample: {'"110"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  screenResolutionRegex:
                </Text>{" "}
                This regex applies on {'"Screen Resolution"'} field derived from
                browser window dimensions. Sample: {'"320x460|1024x768"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  userAgentRegex:
                </Text>{" "}
                This regex applies on entire {'"User Agent"'} string. Sample:{" "}
                {'"yahoo|searchme|webalta|crawler"'}
              </Text>
              <Text style={{ paddingBottom: 10 }}>
                <Text
                  style={{ paddingBottom: 4, minWidth: 110, fontWeight: "600" }}
                >
                  result:
                </Text>{" "}
                This string is the end result seen in GA4 under{" "}
                {'"Custom -> User Agent"'} of a request when all rule conditions
                are met. Possible values - {'"BOT"'} or {'"SuspectedToBeBOT"'}
              </Text>
            </View>
          </View>
        </View>
      </Section>
      {isDesktop ? (
        <Section title={"Test Rules with User-Agent"} defaultOpen={false}>
          <View style={tw("flex flex-col")}>
            <View style={[tw("flex flex-col"), { padding: 5 }]}>
              <View style={tw("flex flex-row p-1")}>
                <Text>
                  NOTE: screenResolutionRegex and requestType facts are skipped
                  from rule testing as those values are not derived from
                  user-agent.
                </Text>
              </View>
              <View style={tw("flex flex-row items-baseline justify-around")}>
                <Input
                  placeholder={Localise(
                    messages,
                    "Enter user-agent full string here"
                  )}
                  testID="user-agent"
                  numberOfLines={2}
                  value={input}
                  onChangeText={(val) => setInput(val)}
                  containerStyle={{
                    paddingHorizontal: 0,
                    paddingRight: 10,
                    flex: 1,
                  }}
                />
                <Button
                  titleStyle={theme.Button.secondaryTitleStyle}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                  }}
                  containerStyle={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: 10,
                    marginLeft: 0,
                  }}
                  onPress={onApplyRules}
                  title={"Apply Rules"}
                />
              </View>
              <View
                style={[tw("flex flex-col"), { padding: 5, paddingBottom: 15 }]}
              >
                <View style={tw("flex flex-row")}>
                  <Text
                    style={{
                      paddingBottom: 4,
                      minWidth: 110,
                      fontWeight: "600",
                    }}
                  >
                    User Agent:
                  </Text>
                  <Text>{userAgent}</Text>
                </View>
                <View style={tw("flex flex-row")}>
                  <Text
                    style={{
                      paddingBottom: 4,
                      minWidth: 110,
                      fontWeight: "600",
                    }}
                  >
                    Browser Name:{" "}
                  </Text>
                  <Text>{browserName}</Text>
                </View>
                <View style={tw("flex flex-row")}>
                  <Text
                    style={{
                      paddingBottom: 4,
                      minWidth: 110,
                      fontWeight: "600",
                    }}
                  >
                    Browser Version:{" "}
                  </Text>
                  <Text>{browserVersion}</Text>
                </View>
                <View style={tw("flex flex-row")}>
                  <Text
                    style={{
                      paddingBottom: 4,
                      minWidth: 110,
                      fontWeight: "600",
                    }}
                  >
                    Operating System:{" "}
                  </Text>
                  <Text>{operatingSystem}</Text>
                </View>
                <View style={tw("flex flex-row")}>
                  <Text
                    style={{
                      paddingBottom: 4,
                      minWidth: 110,
                      fontWeight: "600",
                    }}
                  >
                    Device Model:
                  </Text>
                  <Text>{deviceModel}</Text>
                </View>
                <View style={tw("flex flex-row pt-1")}>
                  <Text
                    style={{
                      paddingBottom: 4,
                      minWidth: 110,
                      fontWeight: "600",
                    }}
                  >
                    RESULT:
                  </Text>
                  <Text
                    style={{
                      paddingBottom: 4,
                      paddingRight: 5,
                      minWidth: 110,
                      fontWeight: "600",
                      textDecorationLine: "underline",
                    }}
                  >
                    {result}
                  </Text>
                  <Text>{" (GA4 - Custom.UserAgent)"}</Text>
                </View>
              </View>
            </View>
          </View>
        </Section>
      ) : null}
    </View>
  );
};

export const BrandSelector = ({ field }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    setFieldValue,
    values: { [field]: value },
    dirty,
    errors,
  } = useFormikContext();
  const { isMobile } = React.useContext(DeviceContext);

  const [tabNames, setTabNames] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  let brandSelectorConfig = {};
  const defaultBrandConfig = {
    logo: "",
    brand_name: "",
    brand_url: "",
    width: 50,
    height: 50,
  };
  try {
    brandSelectorConfig = JSON.parse(value);
  } catch {
    brandSelectorConfig = {
      show_brand_selector: true,
      show_on_homepage_only: true,
      banner_background_color: "#FFFFFF",
      brands: [
        defaultBrandConfig,
        defaultBrandConfig,
        defaultBrandConfig,
        defaultBrandConfig,
      ],
    };
  }

  const AddNewSlide = () => {
    return (
      <View
        style={[
          tw(`flex flex-row items-center justify-start`),
          { flexGrow: 1 },
        ]}
      >
        <TouchableOpacity
          testID={"addNewBrand"}
          accessibilityLabel={"addNewBrand"}
          style={{ padding: 5 }}
        >
          <Text
            style={fonts.link1}
            onPress={() => {
              const newTabNames = tabNames.map((_value, index) => {
                return {
                  key: index,
                  title: `Brand ${index + 1}`,
                };
              });

              setTabNames([
                ...newTabNames,
                { key: tabNames.length, title: `Brand ${tabNames.length + 1}` },
              ]);
              setCurrentTab(tabNames.length);
              const brandSelectorConfigData = {
                ...brandSelectorConfig,
                brands: [...brandSelectorConfig.brands, defaultBrandConfig],
              };
              setFieldValue("content", JSON.stringify(brandSelectorConfigData));
            }}
          >
            {"[+] Add New Brand"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const removeTabs = (key) => {
    const updatedTabs = tabNames
      .filter((tab) => tab.key !== key)
      .map((_value, index) => {
        return {
          key: index,
          title: `Brand ${index + 1}`,
        };
      });

    setTabNames(updatedTabs);

    setCurrentTab(updatedTabs.length - 1);
    const brandsArray = brandSelectorConfig.brands.filter(
      (_value, index) => index !== key
    );

    const brandSelectorConfigData = {
      ...brandSelectorConfig,
      brands: brandsArray,
    };

    setFieldValue("content", JSON.stringify(brandSelectorConfigData));
  };
  const brandData = brandSelectorConfig?.brands.length
    ? brandSelectorConfig?.brands[currentTab]
    : "";

  useEffect(() => {
    const newData = brandSelectorConfig?.brands?.map((_val, index) => {
      const isRemovable = index > 0;
      return {
        key: index,
        title: `Brand ${index + 1}`,
        isRemovable,
      };
    });
    setTabNames(newData);
  }, [brandSelectorConfig?.brands?.length]);

  useEffect(() => {
    !dirty && setCurrentTab(0);
  }, [dirty]);

  const setValue = (name, value) => {
    let brandData = {};
    if (name === "banner_background_color") {
      brandData = {
        ...brandSelectorConfig,
        [name]: value,
      };
    } else {
      const newData = brandSelectorConfig.brands.map((item, index) => ({
        ...item,
        ...(index === currentTab && { [name]: value }),
      }));
      brandData = {
        ...brandSelectorConfig,
        brands: newData,
      };
    }
    setFieldValue(field, JSON.stringify(brandData));
  };

  return (
    <View style={tw("flex flex-col mt-3")}>
      <View style={tw("flex flex-row mb-5 items-center ml-1")}>
        <Text
          style={{
            ...fonts.heading4,
            marginRight: 10,
            width: "50%",
          }}
        >
          {Localise(messages, `Enable Brand Selector`)}
        </Text>
        <FormFieldSwitch
          value={brandSelectorConfig?.show_brand_selector}
          onValueChange={() => {
            setFieldValue(
              field,
              JSON.stringify({
                ...brandSelectorConfig,
                show_brand_selector: !brandSelectorConfig.show_brand_selector,
              })
            );
          }}
        />
      </View>
      {brandSelectorConfig?.show_brand_selector && (
        <>
          <View style={tw("flex flex-row mb-3 items-center ml-1")}>
            <Text
              style={{
                ...fonts.heading4,
                marginRight: 10,
                width: "50%",
              }}
            >
              {Localise(messages, `Show Brand Selector on home page only`)}
            </Text>
            <FormFieldSwitch
              value={brandSelectorConfig?.show_on_homepage_only}
              onValueChange={() => {
                setFieldValue(
                  field,
                  JSON.stringify({
                    ...brandSelectorConfig,
                    show_on_homepage_only:
                      !brandSelectorConfig.show_on_homepage_only,
                  })
                );
              }}
            />
          </View>
          <View style={tw("flex flex-row items-center mb-3 ml-1")}>
            <Text
              style={{
                ...fonts.heading4,
                marginRight: 10,
                width: "50%",
              }}
            >
              {Localise(
                messages,
                `Brand Selector banner background color code`
              )}
            </Text>
            <CustomInput
              input={brandSelectorConfig?.banner_background_color || ""}
              field={`banner_background_color`}
              onChangeText={setValue}
              containerStyle={{ width: 150 }}
              maxLength={7}
              placeholder={Localise(messages, "Ex: #FFFFFF")}
            />
          </View>
          <View>
            <TabBar
              containerStyle={tw("w-full")}
              tabNames={tabNames}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              style={{
                marginTop: 10,
              }}
              removeTab={tabNames.length > 0}
              setTabsCount={removeTabs}
              siblings={() => <AddNewSlide />}
            />
            <View style={tw(`flex ${isMobile ? "flex-col" : "flex-row"}`)}>
              <ImageUploadField
                field={"logo"}
                title={Localise(messages, "Brand Logo")}
                input={brandData?.logo || ""}
                setImageValue={setValue}
                logo={true}
                dimensions={"50px"}
              />
              <View style={tw("flex flex-col")}>
                <CustomInput
                  input={brandData?.brand_name || ""}
                  field={`brand_name`}
                  onChangeText={setValue}
                  title={"Brand Name (Optional)"}
                  containerStyle={{ width: 200 }}
                  placeholder={Localise(messages, "Enter Name")}
                />
                <CustomInput
                  input={brandData?.brand_url || ""}
                  field={`brand_url`}
                  onChangeText={setValue}
                  title={"Brand URL"}
                  containerStyle={{ width: 200 }}
                  placeholder={Localise(messages, "Enter Link")}
                />
              </View>
            </View>
          </View>
        </>
      )}
      {errors ? (
        <Text style={theme.CheckBox.errorStyle}>{errors.content}</Text>
      ) : null}
    </View>
  );
};

export const ShopsNotInGlobalSettings = () => {
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const { selectedMembers = [] } = useSelector(selectActions);
  const { overriddenShops = {} } = useSelector(selectApiResponse);
  const searchText = useSelector(selectSearchText) || "";
  const [vWidth, setVWidth] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const updatedOrder =
    Object.keys(overriddenShops)?.sort((a, b) =>
      sortOrder
        ? new Date(overriddenShops[a].lastUpdateDate) -
          new Date(overriddenShops[b].lastUpdateDate)
        : new Date(overriddenShops[b].lastUpdateDate) -
          new Date(overriddenShops[a].lastUpdateDate)
    ) || [];

  const searchProps = {
    type: "fullWidth",
    containerStyle: {
      paddingHorizontal: 0,
      paddingRight: 10,
      maxWidth: 440,
    },
    icon: "search",
    placeholder: "Search Shops",
    selectors: {
      selectValue: selectSearchText,
    },
    actions: {
      setAction: setOverriddenShopsAction,
    },
  };

  const openURL = (code) => {
    const shopifyDomainMap = {
      "12-1036AA": "ftd-flower-shop-demo",
      "12-1033ZA": "ftd-employee-flower-shop-qa1",
    };
    const url = shopifyDomainMap[code] ? shopifyDomainMap[code] : `${code}-mol`;
    const link = `https://${url}.myShopify.com`;
    Platform.OS === "web" ? window.open(link) : Linking.openURL(link);
  };

  return (
    <View
      style={[tw("flex flex-col mx-10")]}
      onLayout={({ nativeEvent }) => setVWidth(nativeEvent.layout.width)}
    >
      {updatedOrder.length > 0 ? (
        <>
          <Search {...searchProps} />
          <View style={tw("flex flex-row items-center justify-between my-5")}>
            <SelectAll
              value={selectAll}
              count={selectedMembers.length}
              onChange={(value) => {
                dispatch(
                  setOverriddenShopsAction({
                    type: "selection",
                    value: value ? "all" : "",
                  })
                );
                setSelectAll(value);
              }}
            />
            <View style={tw("flex flex-row")}>
              <LabelField
                text={"Last Updated"}
                heading={false}
                style={{ ...fonts.heading5, marginRight: 10 }}
              />
              <Image
                style={{
                  width: 20,
                  height: 20,
                  marginBottom: 5,
                  transform: [{ rotate: sortOrder ? "180deg" : "0deg" }],
                }}
                onPress={() => setSortOrder(!sortOrder)}
                source={IMAGES["collapsed-section"]}
              />
            </View>
          </View>
          {updatedOrder
            ?.filter((val) => {
              if (searchText) {
                if (overriddenShops[val].memberCode.includes(searchText)) {
                  return val;
                }
              } else return val;
            })
            ?.map((value, index) => {
              const checked = selectedMembers.includes(
                overriddenShops[value].memberCode
              );
              return (
                <View
                  style={[
                    tw("flex flex-row items-center justify-between mb-2"),
                  ]}
                  key={index}
                >
                  <View
                    style={[
                      tw("flex flex-row items-center"),
                      { maxWidth: "80%" },
                    ]}
                  >
                    <CheckBox
                      containerStyle={[
                        theme.CheckBox.inputContainerStyle,
                        { paddingRight: 3 },
                      ]}
                      size={18}
                      testID={"Member"}
                      accessibilityLabel={"Member"}
                      checked={!!checked}
                      onPress={() =>
                        dispatch(
                          setOverriddenShopsAction({
                            type: "selection",
                            value: overriddenShops[value].memberCode,
                          })
                        )
                      }
                    />
                    <TouchableOpacity
                      onPress={() => openURL(overriddenShops[value].memberCode)}
                      style={[
                        tw(
                          `flex flex-row flex-wrap ${
                            vWidth < 400 && vWidth > 270
                              ? `w-2/3`
                              : vWidth < 270
                              ? `w-1/3`
                              : ""
                          }`
                        ),
                        { textDecoration: "underline" },
                      ]}
                    >
                      <Text>
                        {overriddenShops[value].memberCode}
                        {" - "}
                      </Text>
                      <Text>{overriddenShops[value].shopName}</Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginRight: 10 }}>
                    {overriddenShops[value].lastUpdateDate?.split("T")[0]}
                  </Text>
                </View>
              );
            })}
        </>
      ) : (
        <Text>No Shops Available with local content</Text>
      )}
    </View>
  );
};

export const Languages = ({ fieldProps }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const [showPopup, setShowPopup] = useState(false);

  const {
    userProfile: { type: userType },
  } = React.useContext(UserProfileContext);

  const { ftdAdminOnly } = fieldProps;
  const isFTDUser = userType === "FTD_USER";
  const enabled = ftdAdminOnly && isFTDUser;

  const primaryLanguage = "en";
  const { languages } = useSelector(selectLanguages);

  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const { languages: supportedLanguages = [primaryLanguage] } =
    UserProfileStorage.getShopPreferences(
      shopCode === "all" ? memberCodes[0] : shopCode
    );

  useEffect(() => {
    const updated = languages.map((item) => ({
      ...item,
      selected: supportedLanguages.includes(item.code),
    }));
    dispatch(setLanguages(updated));
  }, []);

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveLanguages({
        params: { values },
        resolve: () => {
          ToasterHandler(
            "looks good",
            Localise(messages, `Languages has been updated.`)
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <>
      <Form
        initialValues={{ languages }}
        onSubmit={allowSyndicate ? () => setShowPopup(!showPopup) : onSubmit}
        render={({ values, setFieldValue, ...formikBag }) => {
          const { languages = [] } = values;
          const onPress = (selected, code) => {
            const final = languages.map((val) => {
              if (val.code === code) {
                return { ...val, selected };
              } else return val;
            });

            setFieldValue("languages", final);
          };

          const openURL = () => {
            const { shopify_store_url: shopifyAdmin } =
              UserProfileStorage.getShopPreferences(
                shopCode === "all" ? memberCodes[0] : shopCode
              );
            const adminUrl = shopifyAdmin.split("//")[1].split(".myshopify")[0];
            const link = `https://admin.shopify.com/store/${adminUrl}/settings/languages`;

            Platform.OS === "web" ? window.open(link) : Linking.openURL(link);
          };

          return (
            <View style={tw("mt-3 flex flex-col")}>
              <Text
                style={{
                  ...fonts.style2,
                  marginRight: 10,
                }}
              >
                {Localise(
                  messages,
                  `To further auto-translate and publish the languages, please go to `
                )}
                <Text
                  style={{
                    ...fonts.style2,
                    textDecorationLine: "underline",
                  }}
                  onPress={openURL}
                >
                  {Localise(messages, `Shopify Admin`)}
                </Text>
              </Text>
              <View style={tw("flex flex-row")}>
                {languages.map((value, index) => {
                  return (
                    <View
                      style={tw("flex flex-col mx-3 mt-3")}
                      key={`${index}`}
                    >
                      <View style={tw("flex flex-row items-center")}>
                        <CheckBox
                          containerStyle={[theme.CheckBox.inputContainerStyle]}
                          size={18}
                          checked={value.selected}
                          onPress={() =>
                            !value.readOnly &&
                            onPress(!value.selected, value.code)
                          }
                          testID={value.title}
                          accessibilityLabel={value.title}
                          disabled={!enabled}
                        />
                        <Text>{Localise(messages, value.title)}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <SaveCancelButtons />
              <ConfirmModal
                modalVisible={!!showPopup}
                handlePrimary={() => {
                  onSubmit(values, formikBag);
                  setShowPopup(!showPopup);
                }}
                handleSecondary={() => {
                  setShowPopup(!showPopup);
                  formikBag.setSubmitting(false);
                }}
                data={{
                  modal: {
                    primary: "Update",
                    content: Localise(
                      messages,
                      "This change will apply to All shops and will override any local shop changes."
                    ),
                  },
                }}
              />
            </View>
          );
        }}
      />
    </>
  );
};

export const CheckoutCustomizations = ({ field }) => {
  const { Localise, messages } = React.useContext(I18NContext);
  const {
    setFieldValue,
    values: { [field]: value },
  } = useFormikContext();

  let checkoutCustomizationsConfig = {};
  try {
    checkoutCustomizationsConfig = JSON.parse(value);
  } catch {
    checkoutCustomizationsConfig = {
      specialInstructionsPlaceholder: "",
      deliveryToolTipLabel: "",
      deliveryToolTipText: "",
    };
  }

  const setValue = (key, val) => {
    setFieldValue(
      field,
      JSON.stringify({
        ...checkoutCustomizationsConfig,
        [key]: val,
      })
    );
  };

  return (
    <View style={tw("flex flex-col mt-2")}>
      <CustomInput
        field={"specialInstructionsPlaceholder"}
        title={Localise(messages, "Special Instructions Placeholder")}
        input={checkoutCustomizationsConfig?.specialInstructionsPlaceholder}
        onChangeText={setValue}
      />
      <CustomInput
        field={"deliveryToolTipLabel"}
        title={Localise(messages, "Delivery Note")}
        input={checkoutCustomizationsConfig?.deliveryToolTipLabel}
        onChangeText={setValue}
      />
      <CustomInput
        field={"deliveryToolTipText"}
        title={Localise(messages, "Delivery Tooltip")}
        input={checkoutCustomizationsConfig?.deliveryToolTipText}
        onChangeText={setValue}
        multiline={true}
        numberOfLines={4}
        inputStyle={{
          ...theme.Input.inputStyle,
          minHeight: "auto",
          height: "auto",
        }}
        inputContainerStyle={{
          minHeight: "auto",
          height: "auto",
        }}
      />
    </View>
  );
};
