import React, { useState } from "react";
import { useSelector } from "react-redux";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { FieldArray, useFormikContext } from "formik";
import {
  Picker,
  DraggableFlatList as DraggableFlatListForDesktop,
} from "components/elements";
import DraggableFlatList from "react-native-draggable-flatlist";
import { Currency } from "components/wrappers";
import {
  selectAllAddons,
  selectShopCode,
  selectAllCollections,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import startCase from "lodash/startCase";
import { colors, fonts, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";
import tw from "tailwind-rn";

const SuggestedAddons = () => {
  const allAddons = useSelector(selectAllAddons);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const [scrollEnable, setScrollEnable] = useState(true);
  const collectionsData = useSelector(selectAllCollections);
  const addonsCollection = collectionsData?.find(
    (collection) => collection.handle === "addons"
  );
  const { manualSortProductOrder = [] } = addonsCollection || {};

  const {
    values: { addons = [], isAddonSorted = false },
    setFieldValue,
  } = useFormikContext();

  const selectedAddons = !isAddonSorted
    ? addons.sort(
        (a, b) =>
          manualSortProductOrder.indexOf(a) - manualSortProductOrder.indexOf(b)
      )
    : addons;

  return (
    <View style={tw("flex flex-col")}>
      <FieldArray
        name="addons"
        render={(arrayHelpers) => {
          const remainingAddons = allAddons.filter(
            (addon) => !selectedAddons?.find((e) => e === addon.productId)
          );
          const DraggableComponent = isDesktop
            ? DraggableFlatListForDesktop
            : DraggableFlatList;

          return (
            <>
              <DraggableComponent
                containerStyle={{
                  flex: 1,
                  height:
                    !isDesktop && selectedAddons.length > 3 ? 400 : "100%",
                }}
                data={selectedAddons}
                onDragEnd={(prop) => {
                  const addonData = isDesktop ? prop : prop.data;
                  setFieldValue("addons", addonData);
                  setFieldValue("isAddonSorted", true);
                  setScrollEnable(true);
                }}
                keyExtractor={(item) => item}
                onDragBegin={() => {
                  setScrollEnable(false);
                }}
                autoscrollThreshold={100}
                dragDropIcon={true}
                activationDistance={scrollEnable ? 10 : 1}
                renderItem={(data, i) => {
                  const addon = isDesktop ? data : data.item;
                  const index = isDesktop ? i : data.index;

                  const addonData = allAddons.find(
                    (e) => e.productId === addon
                  );
                  return (
                    <View style={tw("flex flex-row items-center")}>
                      {!isDesktop && (
                        <View
                          style={tw(
                            "flex flex-col items-center justify-between"
                          )}
                        >
                          <Image
                            style={{ width: 18, height: 10 }}
                            source={IMAGES["drag-drop-icon"]}
                          />
                          <View
                            style={{
                              backgroundColor: backgroundColors.primary,
                              width: 15,
                              height: 15,
                              borderRadius: 15,
                              margin: 5,
                            }}
                          >
                            <Text
                              style={{
                                color: "#fff",
                                textAlign: "center",
                                fontWeight: "700",
                                fontSize: 10,
                              }}
                            >
                              {index + 1}
                            </Text>
                          </View>
                        </View>
                      )}
                      <Addon
                        key={index}
                        index={index}
                        value={addonData}
                        options={remainingAddons}
                        arrayHelpers={arrayHelpers}
                        drag={!isDesktop && data.drag}
                      />
                    </View>
                  );
                }}
              />
              {remainingAddons.length > 0 ? (
                <View
                  style={[
                    tw("flex flex-col items-center justify-center p-3"),
                    {
                      borderWidth: 1,
                      borderColor: colors.light,
                      width: isMobile ? "100%" : "50%",
                      height: 93,
                      maxWidth: 400,
                      marginTop: 10,
                    },
                  ]}
                >
                  <Picker
                    placeholder={{
                      label: "Select Item",
                    }}
                    containerStyle={{
                      width: "100%",
                      height: "auto",
                      paddingHorizontal: 0,
                      paddingBottom: 0,
                    }}
                    items={remainingAddons.map((e, index) => ({
                      label: e.name,
                      value: e.productId,
                      key: `${e.productId}-${index}`,
                    }))}
                    name={"newAddon"}
                    value={""}
                    onValueChange={(val) => {
                      if (val) arrayHelpers.push(val);
                    }}
                  />
                </View>
              ) : selectedAddons.length == 0 ? (
                <View>
                  <Text>No Add-ons available at this moment</Text>
                </View>
              ) : null}
            </>
          );
        }}
      />
    </View>
  );
};

const Addon = ({ value, index, arrayHelpers, drag }) => {
  const shopCode = useSelector(selectShopCode);
  const currency = Currency(shopCode);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const { name, image, price, status, productId } = value;
  const { Localise, messages } = React.useContext(I18NContext);

  return (
    <TouchableOpacity
      style={[
        tw(`flex flex-col mb-3`),
        {
          borderWidth: 1,
          borderColor: colors.light,
          width: isMobile ? "100%" : "50%",
          maxWidth: 400,
          cursor: "grab",
        },
      ]}
      onLongPress={drag && drag}
      disabled={isDesktop && true}
    >
      <View
        key={index}
        style={tw("flex flex-row items-center justify-between p-2 w-full")}
      >
        <View style={tw("flex flex-col")}>
          <Text
            style={[
              fonts.heading4,
              { marginLeft: 10, maxWidth: !isDesktop ? 150 : 250 },
            ]}
          >
            {Localise(messages, name)}
          </Text>
          <Text style={[fonts.style2, { marginLeft: 10 }]}>
            {Localise(messages, `Price : $${price} ${currency}`)}
          </Text>
          <Text style={[fonts.style2, { marginLeft: 10 }]}>
            {Localise(messages, `ID : ${productId}`)}
          </Text>
          <Text style={[fonts.style2, { marginLeft: 10 }]}>
            {Localise(messages, `Status : ${startCase(status)}`)}
          </Text>
        </View>

        <View style={tw("flex flex-row items-start mr-4")}>
          <Image
            style={{
              width: 75,
              height: 75,
              borderWidth: 1,
              borderColor: colors.light,
            }}
            resizeMode="cover"
            source={{ uri: image }}
          />
          <TouchableOpacity
            onPress={() => arrayHelpers.remove(index)}
            testID={"Remove Add-on"}
            style={tw("pl-1")}
          >
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES["close"]}
            />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default SuggestedAddons;
