import * as Yup from "yup";
import { orderStatusSpecificTokenMenu } from "library/utils/notificationTokens";

// To apply validation for sms character limit
Yup.addMethod(Yup.string, "smsCharLimitRules", function (errorMessage) {
  return this.test(`sms-char-limit-rules`, errorMessage, function (value = "") {
    if (value && value?.length > 120) {
      return false;
    } else {
      return true;
    }
  });
});

// To apply validation for invalid tokens
Yup.addMethod(
  Yup.string,
  "smsTokenRules",
  function (errorMessage, currentNotificationTab) {
    return this.test(`sms-token-rules`, errorMessage, function (value = "") {
      const tokensList =
        orderStatusSpecificTokenMenu[0]?.[currentNotificationTab]?.smsTokenMenu;

      let smsTokens = [];
      tokensList.forEach((eachToken) => {
        const token = eachToken?.label?.toLowerCase()?.replaceAll(" ", "_");
        smsTokens.push(token);
      });
      const tokens = value
        ?.toLowerCase()
        ?.replaceAll(" ", "_")
        ?.match(/\[([^[\]]+)]/g);

      let isInvertedBracesPresent = [];

      for (let char of value) {
        if (char === "[") {
          // If an opening bracket is found, push it onto the isInvertedBracesPresent
          isInvertedBracesPresent.push(char);
        } else if (char === "]") {
          // If a closing bracket is found, check if there is a matching opening bracket
          if (isInvertedBracesPresent.length === 0) {
            return false; // Unmatched closing bracket
          }
          isInvertedBracesPresent.pop(); // Matching opening bracket found
        }
      }

      const openSquareBracketsCount = value?.match(/\[/g)?.length;
      const closeSquareBracketsCount = value?.match(/\]/g)?.length;

      const isTokenValid =
        tokens?.length > 0
          ? tokens?.every((value) => smsTokens.includes(value))
          : true;
      if (
        isTokenValid &&
        openSquareBracketsCount === closeSquareBracketsCount
      ) {
        return true;
      } else {
        return false;
      }
    });
  }
);

export const smsSettingsSchema = (
  messages,
  Localise,
  currentNotificationTab
) => {
  return Yup.object({
    smsMessage: Yup.string()
      .smsCharLimitRules(Localise(messages, "Limit exceeded more than 120"))
      .smsTokenRules(
        Localise(messages, "Invalid Token Format"),
        currentNotificationTab
      ),
  });
};
