import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { theme, fonts } from "styles/theme";
import tw from "tailwind-rn";

export const RadioButton = ({ onPress, data }) => {
  const selectHandler = (value) => {
    onPress(value);
  };

  return (
    <>
      {data.map((item) => {
        const selected = item.selected;

        return (
          <View
            key={item.id}
            style={[tw("flex flex-row pl-0"), theme.Radio.containerStyle]}
          >
            <TouchableOpacity
              onPress={() => selectHandler(item)}
              style={theme.Radio.inputStyle}
            >
              {selected ? <View style={theme.Radio.selectedStyle} /> : null}
            </TouchableOpacity>
            <TouchableOpacity onPress={() => selectHandler(item)}>
              <Text
                style={[
                  fonts.default,
                  { fontSize: 13, marginLeft: 10, marginTop: 4 },
                ]}
              >
                {item.name}
              </Text>
            </TouchableOpacity>
          </View>
        );
      })}
    </>
  );
};
