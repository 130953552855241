const SHOP_SETTINGS = Object.freeze({
  ADDON_SETTINGS_SAVED: "Your addon settings are saved",
  CODE: "Code",
  CODES: "Codes",
  NO_PROMO_CODES: "No Promo Codes available. Please create one.",
  PROMO_CODE_INVALID: "Code must be between 2 to 15 characters long",
  PROMO_CODE_REQUIRED: "Code is required",
  PROMO_CODES: "Promo Codes",
  PROMO_CONFIRM_MODAL_CONTENT: "Do you want to delete the Promo code?",
  PROMO_CREATION_SUCCESS: "PromoCode has been created successfully",
  PROMO_DELETION_SUCCESS: "PromoCode has been deleted successfully",
  PROMO_DUPLICATE: "Promo code exists. Please update the date",
  PROMO_HEADING: "Create Promo Code",
  PROMO_INFO_TOOLTIP:
    "Enter an alphanumeric code, up to 15 characters. Special characters are not allowed.",
  PROMO_TITLE: "Promo Code",
  PROMO_UPDATE_SUCCESS: "PromoCode has been updated successfully",
  PROMO_ZERO_AMOUNT:
    "Promo code value cannot be zero. Please enter a valid amount.",
});

export default SHOP_SETTINGS;
