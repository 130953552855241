import React from "react";
import {
  View,
  Text,
  Platform,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { CheckBox, Button } from "react-native-elements";
import { ToasterHandler, Tooltip } from "components/elements";
import {
  Form,
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
  FormFieldSwitch,
} from "components/elements/forms";
import { ConfirmModal, SaveCancelButtons } from "components/wrappers";
import AddressVerificationModal from "components/views/drawer/create-order/address-verification-modal";

import { verifyAddress } from "library/utils/createOrder";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  fetchAddressSuggestions,
  saveCustomerInfo,
  fetchCustomerDetails,
  fetchAllCustomersBySearch,
  mergeCustomerProfiles,
  setApiResponse,
  setPageData,
} from "library/sagas/views/home/drawer/customer-directory/slice";

import {
  selectAddressSuggestions,
  selectOriginalCustomerDetails,
  selectMergeRecordsSearchCustomers,
  selectCustomersToMerge,
} from "library/sagas/views/home/drawer/customer-directory/selector";

import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { fonts, backgroundColors, shapes, theme, colors } from "styles/theme";

import tw from "tailwind-rn";
import * as Yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import isUndefined from "lodash/isUndefined";
import moment from "moment";
import states from "static/data/states.json";
import IMAGES from "static/assets/images";
import {
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";
import Environment from "library/utils/environment";

const CustomerInput = ({ data, isCreate, initialAddress }) => {
  const { formFieldType, showField = true, ...inputProps } = data;
  if (!showField) return null;
  return formFieldType === "Text" ? (
    <TextField {...inputProps} isCreate={isCreate} />
  ) : formFieldType === "CustomAddress" ? (
    <AddressField {...inputProps} initialAddress={initialAddress} />
  ) : !isCreate && formFieldType === "customerContacts" ? (
    <ContactField {...inputProps} isCreate={isCreate} />
  ) : formFieldType === "Picker" ? (
    <PickerField {...inputProps} />
  ) : formFieldType === "customerName" ? (
    <CustomerNameField {...inputProps} />
  ) : formFieldType === "TextArea" ? (
    <TextAreaField {...inputProps} />
  ) : formFieldType === "ToggleField" ? (
    <ToggleField {...inputProps} />
  ) : null;
};

const TextAreaField = ({ name, title, formFieldProps }) => {
  return (
    <View
      style={[
        tw(
          `flex flex-row justify-start items-center pb-2 items-${
            Platform.OS !== "web" ? "center" : "baseline"
          }`
        ),
        { zIndex: -1 },
      ]}
    >
      <View
        style={[
          tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
          { width: "100%" },
        ]}
      >
        <FormField
          name={name}
          label={title}
          {...formFieldProps}
          inputContainerStyle={{
            backgroundColor: formFieldProps.editable
              ? "auto"
              : backgroundColors.greyColor,
            ...formFieldProps.inputContainerStyle,
          }}
        />
      </View>
    </View>
  );
};

const CustomerNameField = ({ formInputFields }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const { values } = useFormikContext();

  return (
    <>
      {formInputFields
        .filter((item) => item.customerType === values.customerType)
        .map(({ name, title, formFieldProps }) => {
          return (
            <>
              <View
                style={tw(
                  `flex flex-row justify-start items-center pb-2 items-${
                    Platform.OS !== "web" ? "center" : "baseline"
                  }`
                )}
              >
                <Text
                  style={{
                    height: 40,
                    width: isMobile ? "25%" : "30%",
                    ...fonts.heading5,
                  }}
                >
                  {title}
                </Text>
                <View
                  style={[
                    tw(
                      "flex flex-row justify-start items-center pb-2 flex-wrap"
                    ),
                    { width: isMobile ? "75%" : "70%" },
                  ]}
                >
                  <FormField
                    name={name}
                    {...formFieldProps}
                    inputContainerStyle={{
                      backgroundColor: formFieldProps.editable
                        ? "auto"
                        : backgroundColors.greyColor,
                      height: 35,
                      ...formFieldProps.inputContainerStyle,
                    }}
                  />
                </View>
              </View>
            </>
          );
        })}
    </>
  );
};

const TextField = ({ name, title, formFieldProps, isCreate }) => {
  const { isMobile } = React.useContext(DeviceContext);
  const {
    values: { customerId = "", houseAccountInfo: { houseAccountId = "" } = {} },
  } = useFormikContext();

  name === "referralType" &&
    (formFieldProps.editable = isCreate && customerId === "");

  if (name === "houseAccountInfo.houseAccountId" && houseAccountId === "")
    return null;

  return (
    <View
      style={{
        ...tw(
          `flex flex-row justify-start items-center pb-2 items-${
            Platform.OS !== "web" ? "center" : "baseline"
          }`
        ),
        zIndex: -1,
      }}
    >
      <Text
        style={{
          height: 40,
          width: isMobile ? "25%" : "30%",
          ...fonts.heading5,
        }}
      >
        {title}
      </Text>
      <View
        style={[
          tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
          { width: isMobile ? "75%" : "70%" },
        ]}
      >
        <FormField
          name={name}
          {...formFieldProps}
          inputContainerStyle={{
            backgroundColor: formFieldProps.editable
              ? "auto"
              : backgroundColors.greyColor,
            height: 35,
            ...formFieldProps.inputContainerStyle,
          }}
          transformText={(text = "") => {
            return name === "phone" ? phoneNumberFormatter(text) : text;
          }}
        />
      </View>
    </View>
  );
};

const AddressField = ({
  name,
  title,
  formInputFields,
  initialAddress,
  showAddressText = true,
}) => {
  const dispatch = useDispatch();
  const addressData = useSelector(selectAddressSuggestions);

  const [invalidAddress, setInvalidAddress] = useStateIfMounted();
  const [verifiedAddress, setVerifiedAddress] = useStateIfMounted();
  //const [isInitalRender, setInitialRender] = useStateIfMounted(true);

  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const { values, setFieldValue, setValues } = useFormikContext();
  const updatedValues = cloneDeep(values);
  const {
    addressLine1: initialAddressLine1 = "",
    city: initialCity = "",
    state: initialState = "",
    zipcode: initialZipCode = "",
  } = initialAddress;

  const {
    address: { addressLine1, city, country, state, zipcode },
  } = values;

  React.useEffect(() => {
    setFieldValue(
      "isAddressRequired",
      Object.values(omit(values.address, ["addressType", "isPrimary"])).filter(
        (val) => !!val
      ).length > 0
    );

    // Changing the country as per user current timezone
    if (isUndefined(values.address.country)) {
      const canadaTimeZone = moment.tz.zonesForCountry("CA");
      const USATimeZone = moment.tz.zonesForCountry("US");
      const currentZone = moment.tz.guess(true);
      setFieldValue(
        "address.country",
        canadaTimeZone.includes(currentZone)
          ? "CA"
          : USATimeZone.includes(currentZone)
          ? "US"
          : ""
      );
    }
  }, [values.address]);

  React.useEffect(() => {
    if (
      addressLine1 === initialAddressLine1 &&
      city === initialCity &&
      state === initialState &&
      zipcode === initialZipCode
    )
      return;
    if (["US"].includes(country) && addressLine1 && city && state && zipcode) {
      verifyAddress(
        { addressLine1, city, state, zip: zipcode, country },
        null
      ).then((result) => {
        if (!result.isValid) {
          setInvalidAddress(result);
        }
      });
    }
  }, [addressLine1, city, state, zipcode]);

  React.useEffect(() => {
    if (!verifiedAddress) return;
    const updatedValues = cloneDeep(values);
    //update formik where suggested addresses are selected.
    const isAvsSuggestedAddress = verifiedAddress
      ? verifiedAddress.selectedIndex === 1
      : false;

    if (isAvsSuggestedAddress) {
      const data = get(updatedValues, name);
      const { addressLine1, city, state, zip, country } =
        verifiedAddress.suggestedAddress;
      set(updatedValues, name, {
        ...data,
        addressLine1,
        city,
        state,
        zipcode: country === "US" ? zip?.substring(0, 5) : zip,
        country,
      });
    }
    setValues(updatedValues);
  }, [verifiedAddress]);

  return (
    <View
      style={[
        tw(
          `flex flex-row justify-start items-${
            Platform.OS !== "web" ? "center" : "baseline"
          } pb-2`
        ),
        { marginLeft: showAddressText ? 0 : -5 },
      ]}
    >
      {showAddressText && (
        <Text
          style={{
            height: 40,
            width: isMobile ? "25%" : "30%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
      )}
      <View
        style={[
          tw(
            `flex flex-row justify-start items-${
              Platform.OS !== "web" ? "center" : "baseline"
            } pb-2 flex-wrap`
          ),
          { width: showAddressText ? (isMobile ? "75%" : "70%") : "100%" },
        ]}
      >
        <View style={[tw("flex flex-row flex-wrap"), { width: "100%" }]}>
          {formInputFields.map(
            ({ name: field, formFieldProps, formFieldType }) => {
              const isAutoComplete = formFieldType === "AutoComplete";
              const isPicker = formFieldType === "Picker";
              const width = isMobile
                ? {
                    addressLine1: "100%",
                    addressLine2: "55%",
                    country: "45%",
                    city: "50%",
                    state: "50%",
                    zipcode: "50%",
                  }
                : {
                    addressLine1: "60%",
                    addressLine2: "40%",
                    country: "40%",
                    city: "25%",
                    state: "35%",
                    zipcode: "50%",
                  };
              if (field === "state") {
                formFieldProps.options = states[values.address.country] || [];
              }
              const other =
                country === "US" && field === "zipcode" ? { maxLength: 5 } : {};

              return isAutoComplete ? (
                <>
                  <FormFieldAutoComplete
                    autoCapitalize="none"
                    autoCorrect={false}
                    name={`${name}.${field}`}
                    fsClass="fs-exclude"
                    loading={false}
                    placeholder={formFieldProps.placeholder}
                    data={addressData}
                    onChangeText={(addressLine1) =>
                      dispatch(
                        fetchAddressSuggestions({
                          country: values.address.country,
                          addressLine1,
                        })
                      )
                    }
                    outerContainerStyle={{
                      width: width[field],
                      zIndex: 1,
                    }}
                    listDisplayValues={[
                      "addressLine1",
                      "cityLocality",
                      "stateProvince",
                    ]}
                    updateOnBlur={true}
                    onSelect={(selectedValue) => {
                      set(updatedValues, name, {
                        ...get(updatedValues, name),
                        addressLine1: selectedValue.addressLine1,
                        city: selectedValue.cityLocality,
                        zipcode: selectedValue.postalCode,
                        state: selectedValue.stateProvince,
                        country: selectedValue.countryRegion,
                      });
                      setValues(updatedValues);
                    }}
                    showErrorOnTouched={true}
                    {...formFieldProps}
                  />
                  <AddressVerificationModal
                    showModal={!!invalidAddress}
                    onClose={() => setInvalidAddress()}
                    invalidAddresses={invalidAddress ? [invalidAddress] : []}
                    onContinue={(addresses) => {
                      setVerifiedAddress(addresses[0]);
                      setInvalidAddress();
                    }}
                    isSmallScreen={!isDesktop}
                  />
                </>
              ) : isPicker ? (
                <FormFieldPicker
                  name={`${name}.${field}`}
                  containerStyle={{ width: width[field] }}
                  placeholder={formFieldProps.placeholder}
                  data={formFieldProps.options}
                  disabledFieldTouch={true}
                  {...formFieldProps}
                />
              ) : (
                <FormField
                  name={`${name}.${field}`}
                  key={`${name}.${field}`}
                  containerStyle={{
                    width: width[field],
                  }}
                  inputContainerStyle={{
                    backgroundColor: formFieldProps.editable
                      ? "auto"
                      : backgroundColors.greyColor,
                  }}
                  {...formFieldProps}
                  {...other}
                />
              );
            }
          )}
        </View>
      </View>
    </View>
  );
};

const PickerField = ({ name, title, formFieldProps }) => {
  const { isMobile } = React.useContext(DeviceContext);

  return (
    <>
      <View
        style={tw(
          `flex flex-row justify-start items-center pb-2 items-${
            Platform.OS !== "web" ? "center" : "baseline"
          }`
        )}
      >
        <Text
          style={{
            height: 40,
            width: isMobile ? "25%" : "30%",
            ...fonts.heading5,
          }}
        >
          {title}
        </Text>
        <View
          style={[
            tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
            { width: isMobile ? "75%" : "70%" },
          ]}
        >
          <FormFieldPicker
            name={name}
            containerStyle={{ width: "100%" }}
            placeholder={formFieldProps.placeholder}
            data={formFieldProps.options}
            disabledFieldTouch={true}
          />
        </View>
      </View>
    </>
  );
};

const ToggleField = ({
  name,
  title,
  disabled = false,
  containerStyles = {},
}) => {
  const {
    values,
    values: { smsOptIn, smsProviderOptIn } = {},
    setFieldValue,
  } = useFormikContext();
  return (
    <View
      style={[
        tw(`flex flex-row justify-start items-center pb-3`),
        containerStyles,
      ]}
    >
      <View
        style={{
          minWidth: "20%",
          ...fonts.heading5,
        }}
      >
        <Text style={[fonts.heading5]}>{title}</Text>
      </View>
      <View
        style={[
          { paddingHorizontal: 5 },
          disabled ? { cursor: "not-allowed" } : {},
        ]}
      >
        <FormFieldSwitch
          name={name}
          value={
            name === "smsOptIn" ? smsOptIn && smsProviderOptIn : values[name]
          }
          onValueChange={(val) => {
            if (disabled) {
              return;
            }
            if (name === "smsOptIn" && val) {
              setFieldValue("smsProviderOptIn", val);
            }
            setFieldValue(name, val);
          }}
          testID={name}
        />
      </View>
    </View>
  );
};

const ContactField = ({ title, formInputFields, isCreate }) => {
  const dispatch = useDispatch();
  const {
    values,
    initialValues: { customerContacts },
  } = useFormikContext();
  const [isFormVisible, setFormVisible] = useStateIfMounted(false);
  const [editindex, setEditIndex] = useStateIfMounted(null);
  const [showConfirmModal, setShowConfirmModal] = useStateIfMounted(false);

  const [isMergeRecords, setMergeRecords] = useStateIfMounted(false);
  const deleteIndexRef = React.useRef(null);

  const [loading, setLoading] = useStateIfMounted(false);
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const showCustomerMergeAndPurge = Environment.get(
    "SHOW_CUSTOMER_MERGE_AND_PURGE",
    false
  );

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };

  const getValidationSchema = (Localise, messages) =>
    Yup.object().shape({
      firstName: Yup.string().required(
        Localise(messages, "Please enter first name")
      ),
      lastName: Yup.string(),
      phone: Yup.string().matches(
        new RegExp(
          "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
        ),
        Localise(messages, "Enter valid Phone Number")
      ),
      email: Yup.string().email("Enter a valid email"),
    });

  const addOrEditContact = (index = null) => {
    setEditIndex(index);
    setFormVisible(true);
  };

  const deleteContact = (index) => {
    setLoading(true);
    const updatedData = customerContacts.filter((_, i) => i !== index);
    dispatch(
      saveCustomerInfo({
        params: { ...values, customerContacts: updatedData, isCreate },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Contact has been deleted")
          );
          closeForm();
          setLoading(false);
        },
        reject: (error) => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              error ||
                "We were unable to process your request, please try again."
            )
          );
          setLoading(false);
        },
      })
    );
  };

  const closeForm = () => {
    setFormVisible(false);
    setEditIndex(null);
  };

  const onSubmit = (formValues, formikBag) => {
    const contact = {
      ...formValues,
      phone: formatPhoneForPayload(formValues.phone || "") || "",
    };
    setLoading(true);
    const updatedCustomerContacts = [...customerContacts];
    editindex !== null
      ? (updatedCustomerContacts[editindex] = contact)
      : updatedCustomerContacts.push(contact);
    dispatch(
      saveCustomerInfo({
        params: {
          ...values,
          customerContacts: updatedCustomerContacts,
          isCreate,
        },
        resolve: () => {
          const toastMessage =
            editindex !== null
              ? "Contact has been updated"
              : "Contact has been created";
          ToasterHandler("success", Localise(messages, toastMessage));
          formikBag.setSubmitting(false);
          closeForm();
          setLoading(false);
        },
        reject: (error) => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              error ||
                "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
          setLoading(false);
        },
      })
    );
  };

  return (
    <View style={tw("mb-3")}>
      <View style={tw("flex flex-row")}>
        <Text style={fonts.sectionHeading}>{Localise(messages, title)}</Text>
        {loading && (
          <ActivityIndicator
            style={tw("mx-2")}
            color={colors.activityIndicator}
          />
        )}
      </View>

      {customerContacts &&
        customerContacts.map(
          (
            { firstName = "", lastName = "", phone = "", email = "" },
            index,
          ) => (
            <View
              key={`${firstName}_${lastName}_${index}`}
              style={[
                shapes.sectionBorder,
                tw(
                  "flex flex-row flex-wrap my-1 px-3 py-2 rounded-sm items-center",
                ),
              ]}>
              <View style={tw(`${isMobile ? "flex-col" : "flex-row"} w-11/12`)}>
                <Text
                  style={[
                    fonts.default,
                    tw(`${isMobile ? "w-full" : "w-1/3"} px-1`),
                  ]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}>{`${firstName} ${lastName}`}</Text>
                <Text
                  style={[
                    fonts.default,
                    tw(`${isMobile ? "w-full" : "w-1/6"} px-1`),
                  ]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}>
                  {phoneNumberFormatter(phone)}
                </Text>
                <Text
                  style={[
                    fonts.default,
                    tw(`${isMobile ? "w-full" : "w-1/3"} px-1`),
                  ]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}>
                  {email}
                </Text>
              </View>
              <View
                style={tw("flex-row items-center flex-1 justify-end w-1/12")}>
                <TouchableOpacity
                  onPress={() => addOrEditContact(index)}
                  testID="edit_customer_contact"
                  accessibilityLabel="edit_customer_contact">
                  <Image style={tw("w-6 h-6")} source={IMAGES["edit"]} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    deleteIndexRef.current = index;
                    setShowConfirmModal(true);
                  }}
                  testID="delete_customer_contact"
                  accessibilityLabel="delete_customer_contact">
                  <Image style={tw("w-5 h-5 ml-2")} source={IMAGES["delete"]} />
                </TouchableOpacity>
              </View>
            </View>
          ),
        )}
      <ConfirmModal
        modalVisible={showConfirmModal}
        handlePrimary={() => {
          deleteContact(deleteIndexRef.current);
          setShowConfirmModal(false);
        }}
        handleSecondary={() => {
          deleteIndexRef.current = null;
          setShowConfirmModal(false);
        }}
        data={{
          modal: {
            primary: "Yes",
            secondary: "No",
            content: Localise(
              messages,
              "Are you sure you want to delete this contact?",
            ),
          },
        }}
        contentStyle={tw("p-0 text-center")}
        isReduceBtnSpacing={true}
      />
      {isFormVisible && (
        <Form
          initialValues={
            editindex !== null
              ? {
                  ...customerContacts[editindex],
                  phone:
                    phoneNumberFormatter(customerContacts[editindex]?.phone) ||
                    "",
                }
              : initialValues
          }
          onSubmit={onSubmit}
          validationSchema={getValidationSchema(Localise, messages)}
          validateOnBlur={false}
          render={() => (
            <View
              style={[shapes.sectionBorder, tw("my-3 px-2 py-3 rounded-sm")]}>
              <View style={tw("flex m-1 items-end")}>
                <TouchableOpacity
                  onPress={() => closeForm()}
                  testID="close_customer_contact_form"
                  accessibilityLabel="close_customer_contact_form">
                  <Image source={IMAGES["cancel"]} style={tw("w-5 h-5")} />
                </TouchableOpacity>
              </View>
              <View style={tw(`flex flex-row flex-wrap`)}>
                {formInputFields.map((props) => (
                  <FormField
                    key={props.name}
                    name={`${props.name}`}
                    label={props.title}
                    {...props.formFieldProps}
                    labelStyle={tw("font-normal")}
                    placeholder={props.title}
                    inputContainerStyle={{
                      backgroundColor: props.formFieldProps.editable
                        ? "auto"
                        : backgroundColors.greyColor,
                      ...props.formFieldProps.inputContainerStyle,
                    }}
                    containerStyle={tw(`${isMobile ? "w-full" : "w-1/2"}`)}
                    testID={`contact_${props.name}`}
                    accessibilityLabel={`contact_${props.name}`}
                    transformText={(text = "") => {
                      return props.name === "phone"
                        ? phoneNumberFormatter(text)
                        : text;
                    }}
                  />
                ))}
              </View>
              <SaveCancelButtons
                buttonTitle={Localise(messages, "Save")}
                disableOnDirty={true}
                customContainerStyles={{
                  width: 100,
                  marginLeft: 0,
                }}
                cancelTestId="clear_customer_contact_form"
                saveTestId="save_customer_contact"
              />
            </View>
          )}
        />
      )}
      <View style={tw("flex flex-row items-center")}>
        <Tooltip text={Localise(messages, "Add Contact")} renderForWebOnly = {true}>
          <TouchableOpacity
            style={{ width: 30, height: 30, ...tw("my-3 mr-3") }}
            onPress={() => addOrEditContact()}
            disabled={isFormVisible}
            testID="add_customer_contact"
            accessibilityLabel="add_customer_contact">
            <Image
              style={tw("w-full h-full")}
              source={IMAGES["create-order"]}
            />
          </TouchableOpacity>
        </Tooltip>
        {showCustomerMergeAndPurge &&
          Platform.OS === "web" &&
          (isMergeRecords ? (
            <TouchableOpacity
              style={{
                width: 28,
                height: 28,
                ...tw("my-3 items-center justify-center rounded-sm"),
                backgroundColor: theme.Button.secondaryTitleStyle.color,
              }}
              disabled={isMergeRecords}>
              <Image
                style={{ width: 24, height: 24 }}
                source={IMAGES["close-merge"]}
              />
            </TouchableOpacity>
          ) : (
            <Tooltip text={Localise(messages, "Merge Contact")} renderForWebOnly = {true}>
              <TouchableOpacity
                style={{
                  width: 28,
                  height: 28,
                  ...tw("my-3 mr-3 items-center justify-center"),
                }}
                onPress={() => setMergeRecords(true)}
                testID="open_merge_records"
                accessibilityLabel="open_merge_records">
                <Image
                  style={tw("w-full h-full")}
                  source={IMAGES["open-merge"]}
                />
              </TouchableOpacity>
            </Tooltip>
          ))}
      </View>
      {isMergeRecords && <MergeCustomers closeMerge={setMergeRecords} />}
    </View>
  );
};

const MergeCustomers = ({ closeMerge }) => {
  const dispatch = useDispatch();
  const mainCustomerDetails = useSelector(selectOriginalCustomerDetails);
  const {
    customerId,
    storeOrigin,
    firstName,
    lastName,
    businessName = "",
    isBusinessProfile,
  } = mainCustomerDetails;
  const mergeRecordsSearchCustomers = useSelector(
    selectMergeRecordsSearchCustomers
  );
  const customersToMerge = useSelector(selectCustomersToMerge);
  const [selectedCustomer, setSelectedCustomer] = useStateIfMounted(null);
  const [mergeLoader, setMergeLoader] = useStateIfMounted(false);
  const [searchLoader, setSearchLoader] = useStateIfMounted(false);
  const [showConfirmModal, setShowConfirmModal] = useStateIfMounted(false);
  const { messages, Localise } = React.useContext(I18NContext);

  const onSubmit = async () => {
    setMergeLoader(true);
    const params = [
      {
        ...mainCustomerDetails,
        isMainProfile: true,
      },
      {
        ...filterSelectedCustomers(customersToMerge, selectedCustomer),
        isMainProfile: false,
      },
    ];
    dispatch(
      mergeCustomerProfiles({
        params,
        resolve: () => {
          const filteredCustomers = customersToMerge.filter(
            (item) => item.customerId !== selectedCustomer
          );
          dispatch(setPageData({ customersToMerge: filteredCustomers }));
          setSelectedCustomer(null);
          ToasterHandler(
            "success",
            Localise(
              messages,
              "Merge started and will be completed in a few minutes."
            )
          );
          setMergeLoader(false);
        },

        reject: (error) => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              error ||
                "We were unable to process your request, please try again."
            )
          );
          setMergeLoader(false);
        },
      })
    );
  };

  const filterSelectedCustomers = (array, customerId) => {
    return array.find((obj) => obj.customerId === customerId);
  };

  const filterSearchCustomers = (
    allCustomers,
    customerId,
    selectedCustomers
  ) => {
    const filteredCustomers = allCustomers
      ?.filter(
        (customer) =>
          customer.customerId !== customerId &&
          !selectedCustomers.some(
            (selectedCustomer) =>
              selectedCustomer.customerId === customer.customerId
          )
      )
      .map((customer) => ({
        ...customer,
        ...(customer.phones
          ? { phones: phoneNumberFormatter(customer.phones[0]) }
          : {}),
      }));
    return filteredCustomers;
  };

  return (
    <View style={[shapes.sectionBorder, tw("rounded-sm")]}>
      <View style={tw("flex flex-row my-2 justify-between")}>
        <View style={tw("flex flex-row justify-start")}>
          <Text style={fonts.sectionHeading}>
            {Localise(messages, "Merge Records")}
          </Text>
          {mergeLoader && (
            <ActivityIndicator
              style={tw("mx-2")}
              color={colors.activityIndicator}
            />
          )}
        </View>

        <TouchableOpacity
          onPress={() => closeMerge(false)}
          testID="close_merge_records"
          accessibilityLabel="close_merge_records"
        >
          <Image source={IMAGES["cancel"]} style={tw("w-5 h-5")} />
        </TouchableOpacity>
      </View>
      <Text style={[fonts.default, tw("text-justify")]}>
        Merge selected records with this{" "}
        <Text style={tw("font-bold")}>
          {isBusinessProfile ? businessName : `${firstName} ${lastName}`}
        </Text>{" "}
        profile. Existing contact, order history, house accounts, etc will be
        saved under this main profile.
      </Text>
      <View
        style={tw("flex flex-row justify-between items-center my-2 w-full")}
      >
        <View style={[tw("w-3/4")]}>
          <FormFieldAutoComplete
            autoCapitalize="none"
            autoCorrect={false}
            name="searchCustomersToMerge"
            label="Search Records"
            loading={searchLoader}
            path="searchCustomers"
            labelStyle={tw("font-normal text-xs")}
            placeholder={Localise(
              messages,
              "Search and select record to merge"
            )}
            data={filterSearchCustomers(
              mergeRecordsSearchCustomers,
              customerId,
              customersToMerge
            )}
            onChangeText={(text) => {
              if (text.trim().length < 2) {
                dispatch(
                  setApiResponse({
                    section: "mergeRecordsSearchCustomers",
                    content: [],
                  })
                );
                return;
              }
              setSearchLoader(true);
              dispatch(
                fetchAllCustomersBySearch({
                  type: "mergeRecordsSearch",
                  value: text,
                  query: {
                    section: "mergeRecordsSearchCustomers",
                    offset: 0,
                    limit: 10,
                    includeTotalCount: false,
                  },
                  resolve: () => {
                    setSearchLoader(false);
                  },
                  reject: (error) => {
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        error ||
                          "We were unable to process your request, please try again."
                      )
                    );
                    setSearchLoader(false);
                  },
                })
              );
            }}
            outerContainerStyle={[tw("w-full pl-0"), { zIndex: 1 }]}
            listDisplayValues={[
              "businessName",
              "firstName",
              "lastName",
              "email",
              "phones",
            ]}
            onSelect={(selectedValue, setValue) => {
              setValue("");
              setSearchLoader(true);
              dispatch(
                fetchCustomerDetails({
                  customerId: selectedValue.customerId,
                  storeOrigin: storeOrigin,
                  isCurrentCustomer: false,
                  resolve: () => {
                    setSearchLoader(false);
                  },
                  reject: () => {
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        "We were unable to process your request, please try again."
                      )
                    );
                    setSearchLoader(false);
                  },
                })
              );
              selectedCustomer !== null && setSelectedCustomer(null);
            }}
            popperPlacement={"bottom"}
          />
        </View>

        <View style={tw("w-1/4 pl-2 mt-3")}>
          <Button
            titleStyle={{
              ...tw("text-xs"),
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            buttonStyle={tw("p-2")}
            containerStyle={tw("m-0")}
            title={Localise(messages, "Merge Selected")}
            testID="merge_selected_customers"
            accessibilityLabel="merge_selected_customers"
            onPress={() => setShowConfirmModal(true)}
            disabled={selectedCustomer === null || mergeLoader}
          />
        </View>
        <ConfirmModal
          modalVisible={showConfirmModal}
          handlePrimary={() => {
            onSubmit();
            setShowConfirmModal(false);
          }}
          handleSecondary={() => {
            setShowConfirmModal(false);
          }}
          data={{
            modal: {
              primary: "Yes",
              secondary: "No",
              content: Localise(
                messages,
                "Would you like to merge these profiles? This action cannot be reversed"
              ),
            },
          }}
          contentStyle={tw("p-0 text-center")}
          isReduceBtnSpacing={true}
        />
      </View>
      {customersToMerge.length > 0 &&
        customersToMerge.map((customer, index) => {
          const {
            firstName = "",
            lastName = "",
            phones = [""],
            email,
            addresses = [],
          } = customer;
          return (
            <View
              key={`${firstName}_${lastName}_${index}`}
              style={[tw("flex flex-row flex-wrap"), { zIndex: -1 }]}
            >
              <View
                style={[tw("flex items-start justify-center"), { width: "7%" }]}
              >
                <CheckBox
                  containerStyle={tw("m-0 p-0")}
                  checkedIcon={
                    <Image
                      source={IMAGES["checkbox-checked"]}
                      style={{ width: 20, height: 20 }}
                    />
                  }
                  uncheckedIcon={
                    <Image
                      source={IMAGES["checkbox-unchecked"]}
                      style={{ width: 20, height: 20 }}
                    />
                  }
                  checked={selectedCustomer === customer.customerId}
                  onPress={() => {
                    setSelectedCustomer(
                      customer.customerId === selectedCustomer
                        ? null
                        : customer.customerId
                    );
                  }}
                  testID="check_selected_customer"
                  accessibilityLabel="check_selected_customer"
                />
              </View>
              <View
                style={[
                  shapes.sectionBorder,
                  tw(
                    "flex flex-row flex-wrap my-1 px-3 py-2 rounded-sm items-center justify-between"
                  ),
                  { width: "93%" },
                ]}
              >
                <Text
                  style={[fonts.default, { width: "20%" }]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {customer.isBusinessProfile
                    ? customer.businessName
                    : `${firstName} ${lastName}`}
                </Text>
                <Text
                  style={[fonts.default, { width: "15%" }]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {phoneNumberFormatter(phones[0])}
                </Text>
                <Text
                  style={[fonts.default, { width: "20%" }]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {email}
                </Text>
                <Text
                  style={[fonts.default, { width: "20%" }]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {addresses[0]?.addressLine1}
                </Text>
                <Text
                  style={[fonts.default, { width: "20%" }]}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {addresses[0]?.state}, {addresses[0]?.city}
                </Text>
                <TouchableOpacity
                  style={{ width: "5%" }}
                  onPress={() => {
                    const filteredCustomers = customersToMerge.filter(
                      (item) => item.customerId !== customer.customerId
                    );
                    dispatch(
                      setPageData({
                        customersToMerge: filteredCustomers,
                      })
                    );
                    setSelectedCustomer(null);
                  }}
                  testID="remove_selected_customer"
                  accessibilityLabel="remove_selected_customer"
                >
                  <Image source={IMAGES["cancel"]} style={tw("w-5 h-5")} />
                </TouchableOpacity>
              </View>
            </View>
          );
        })}
    </View>
  );
};
export default CustomerInput;
