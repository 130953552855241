import { Platform } from "react-native";
import get from "lodash/get";

const CHARS_TO_SKIP_FOR_AUTOFILL = [];

const formatPrice = (price = 0) => {
  if (price === 0 || price === "" || isNaN(price))
    return parseFloat(0).toFixed(2);

  return parseFloat(price).toFixed(2);
};

const trimSpecialChars = (val = "", char = "") => {
  if (!val) return "";
  let result = val;
  if (char && result.startsWith(char)) {
    result = result.substring(1);
  }
  CHARS_TO_SKIP_FOR_AUTOFILL.forEach((char) => {
    result = result.split(char).join("");
  });
  return result;
};

const getCustomRange = (filters) => {
  return filters.find(
    (obj) =>
      obj["Date Range"] &&
      ["Custom Range", "This Month"].includes(obj["Date Range"].title)
  );
};

const isCustomDateRangeToggle = (prevFilters = [], currentFilters = []) => {
  const customRangeInPast = getCustomRange(prevFilters);
  const customRangeInPresent = getCustomRange(currentFilters);
  const {
    startDate: prevStartDate,
    dateType: prevDateType,
    endDate: prevEndDate,
  } = (customRangeInPast && customRangeInPast["Date Range"].value) || {};
  const {
    startDate: presentStartDate,
    dateType: presentDateType,
    endDate: presentEndDate,
  } = (customRangeInPresent && customRangeInPresent["Date Range"].value) || {};

  return (
    (!!customRangeInPast && !customRangeInPresent) ||
    (!customRangeInPast && !!customRangeInPresent) ||
    (customRangeInPast &&
      customRangeInPresent &&
      (prevStartDate != presentStartDate ||
        prevEndDate != presentEndDate ||
        prevDateType !== presentDateType))
  );
};

const isMasked = (cardNumber) => {
  return cardNumber.substring(0, 3) === "***";
};

const getMaskedCardNumber = (cardNumber = "") => {
  const cardNumLength = cardNumber.length;
  if (!cardNumber) return "";
  if (isNaN(cardNumber.split(" ").join(""))) {
    return cardNumber;
  }
  let lastFour = cardNumber.substring(cardNumLength - 4, cardNumLength);
  let beforeLastFour = cardNumber.substring(0, cardNumLength - 4);
  return beforeLastFour.replace(/\d/g, "*") + lastFour;
};

const isValidTime = (inputTime) => {
  // Define a regular expression pattern for the "09:30am" format
  var timePattern = /^(0[1-9]|1[0-2]):([0-5][0-9])([ap]m)$/i;

  // Use the test method to check if the input matches the pattern
  return timePattern.test(inputTime);
};

const getMaskedPhysicalGiftCard = (cardNumber = "") => {
  const cardNumLength = cardNumber.length;
  if (!cardNumber) return "";
  let lastFour = cardNumber.substring(cardNumLength - 4, cardNumLength);
  let beforeLastFour = cardNumber.substring(0, cardNumLength - 4);
  return beforeLastFour.replace(/./g, "*") + lastFour;
};

const isValidJSON = (str) => {
  if (typeof str !== "string") {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

const getStringBetweenChars = (inputString, char1, char2) => {
  let startIndex = inputString.indexOf(char1);
  if (startIndex === -1) return ""; // char1 not found

  startIndex++; // Move past char1
  let endIndex = inputString.indexOf(char2, startIndex);
  if (endIndex === -1) return ""; // char2 not found after char1

  return inputString.substring(startIndex, endIndex);
};

const phoneNumberFormatter = (phone) => {
  return phone
    ?.replace(/[^0-9]/g, "")
    ?.replace(/^(\d{0,3})(\d{0,3})?(\d{0,6})?/, function (_, p1, p2, p3) {
      // Format the number as the user types
      let formattedNumber = "";
      if (p1) formattedNumber += p1;
      if (p2) formattedNumber += "-" + p2;
      if (p3) formattedNumber += "-" + p3;
      return formattedNumber;
    });
};

const formatPhoneForPayload = (phone) => {
  return phone?.replace(/-/g, "");
};

const formatQuillEditorStyles = (htmlText) => {
  return htmlText
    ?.replace(
      /class="ql-align-center"/g,
      'style="text-align: center; margin: 0px"'
    )
    .replace(
      /class="ql-align-right"/g,
      'style="text-align: right; margin: 0px"'
    )
    .replace(
      /class="ql-align-justify"/g,
      'style="text-align: justify; margin: 0px"'
    )
    .replace(/<p>/g, '<p style="margin: 0px">')
    .replace(/class="ql-font-arial"/g, 'style="font-family: Arial;"')
    .replace(/class="ql-font-georgia"/g, 'style="font-family: Georgia;"')
    .replace(/class="ql-font-impact"/g, 'style="font-family: Impact;"')
    .replace(/class="ql-font-tahoma"/g, 'style="font-family: Tahoma;"')
    .replace(/class="ql-font-times"/g, 'style="font-family: Times New Roman;"')
    .replace(/class="ql-font-verdana"/g, 'style="font-family: Verdana;"');
};
const formatCardMessage = (message) => {
  return message.replace(/<p class="([^"]*)"><br><\/p>/g, "\n\n");
};

const cardMessageFormatter = (message) => {
  return message
    .replace(/<div class="pre">/g, "\n")
    .replace(/^\s*\n/, "")
    .replace(/<div class="pre">(<br\s*\/?>\s*)*<\/div>/g, "\n")
    .replace(/<\/div>/g, "")
    .replace(/<br\s*\/?>/g, "\n\n") // Replace <br> tags with double newline characters
    .replace(/<\/?p[^>]*>/g, "")
    .replace(/<\/?[^>]+(>|$)/g, "");
};

const macAddressBannerExists = () => {
  const macBannerExists =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true" &&
    !document.getElementById("macAddress").value;
  return macBannerExists;
};

const getMaxHeight = (proxyUser, suspendedMembers) => {
  const macAddressBannerHeight = macAddressBannerExists() ? 50 : 0;
  const suspendedHeaderExists = suspendedMembers.length > 0;
  const headerHeight = 150;
  let bannerHeight = proxyUser || suspendedHeaderExists ? 50 : 0;

  return Platform.OS === "web"
    ? `calc(100vh - ${headerHeight}px - ${bannerHeight}px - ${macAddressBannerHeight}px)`
    : "100%";
};

const compareVersions = (v1, v2) => {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const part1 = v1Parts[i] || 0;
    const part2 = v2Parts[i] || 0;

    if (part1 > part2) return 1;
    if (part1 < part2) return -1;
  }

  return 0;
};

const concatenateStrings = (initial, additional, count = 1) => {
  let i = 1;
  let finalHtml = initial;
  while (count > i) {
    finalHtml += additional;
    i += 1;
  }
  return finalHtml;
};

//returns cost from costs list based on shop preferred currency
const getCostInCurrency = ({
  Currency = () => {},
  costs = [], // eg: [{value:10, currency:"USD"},{value:20, currency:"CAD"}]
  shopCode = "",
}) => {
  const preferredCurrencyCode = Currency(shopCode, "CODE");
  let currencyIndex;
  let currencySymbol = ``;

  const preferredIndex = costs.findIndex(
    (each) => each.currency === preferredCurrencyCode
  );
  if (preferredIndex > 0) {
    currencyIndex = preferredIndex;
    currencySymbol = ` ${Currency(shopCode, "SYMBOL")}`;
  } else {
    currencyIndex = costs.findIndex((each) => each.currency === "USD"); // default show USD price if we don;t have shop preffered cureency price
  }

  const priceValue = get(costs, `${currencyIndex}.value`, "")
    ? parseFloat(get(costs, `${currencyIndex}.value`, "")).toFixed(2)
    : " ";
  return `$${priceValue}${currencySymbol}`;
};

export {
  formatPrice,
  trimSpecialChars,
  isCustomDateRangeToggle,
  getMaskedCardNumber,
  isMasked,
  isValidTime,
  getMaskedPhysicalGiftCard,
  isValidJSON,
  getStringBetweenChars,
  phoneNumberFormatter,
  formatPhoneForPayload,
  formatQuillEditorStyles,
  formatCardMessage,
  cardMessageFormatter,
  getMaxHeight,
  macAddressBannerExists,
  compareVersions,
  concatenateStrings,
  getCostInCurrency,
};
