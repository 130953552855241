import * as React from "react";
import { useEffect, useRef } from "react";
import { View, Text, Image, Platform, ScrollView } from "react-native";
import { bindActionCreators } from "redux";
import { Button } from "react-native-elements";
import { connect, useDispatch } from "react-redux";
import tw from "tailwind-rn";
import { IMAGES } from "static/assets/images";
import { request } from "library/utils/request";
import moment from "moment";
import { RoutesUIConfig, InitViewMoreCounts } from "./config";
import { setRecordId } from "library/sagas/ongoing/current-orders/slice";
import {
  setShopCodeSelected,
  fetchNonRoutedOrders,
} from "library/sagas/views/home/drawer/delivery/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import isNil from "lodash/isNil";
import { theme } from "styles/theme";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { Picker } from "components/elements";
import { Form, FormFieldDatePicker } from "components/elements/forms";
import UserProfileContext from "library/contexts/userProfile";

import SplitScreen from "components/containers/split-screen";

//components
import RoutesListing from "components/views/drawer/delivery/routes-dashboard/routes-listing";
import get from "lodash/get";

const RoutesDashboard = ({
  route,
  currentOrdersInfo,
  selectedShopCode,
  listingType = "delivery",
  setLoading,
  ...userActions
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const shopCodeInStorage = UserProfileStorage.getSelectedShopCode();
  const shopNames = UserProfileStorage.getAllShopNames();
  const routeDeliveryDate = UserProfileStorage.getRouteDeliveryDate();

  //contexts
  const { proxyUser } = React.useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const { isDesktop, isMobile } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;

  const [pageLoading, setPageLoading] = useStateIfMounted(false);
  const [shopCode, setShopCode] = useStateIfMounted(selectedShopCode);
  const [routes, setRoutes] = useStateIfMounted([]);
  const [viewMoreCounts, setViewMoreCounts] =
    useStateIfMounted(InitViewMoreCounts);
  const [deliveryDate, setDeliveryDate] = useStateIfMounted(
    routeDeliveryDate !== "" ? routeDeliveryDate : moment().format("YYYY-MM-DD")
  );
  const startRange = parseInt(
    Environment.get("ROUTES_DEFAULT_START_RANGE", "7")
  );
  const endRange = parseInt(Environment.get("ROUTES_DEFAULT_END_RANGE", "3"));

  const startDate = moment(deliveryDate)
    .subtract(startRange, "days")
    .format("YYYY-MM-DD");
  const endDate = moment(deliveryDate)
    .add(endRange, "days")
    .format("YYYY-MM-DD");

  const [timeStamp, setTimestamp] = useStateIfMounted(false);
  const deliverytimerToClear = useRef(null);
  const { setRecordId, setCurrentPage } = userActions;

  const filteredShopCodes = [];

  //filter shops based on permissions
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.ROUTES_TAB in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });
  const pollInterval = 2 * 60 * 1000; // 2 mins

  const selectedShopPermissions = get(permissions, shopCodeInStorage, {});

  const considerLocalOrders = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_CONSIDER_LOCAL_ORDERS);

  useEffect(() => {
    const routeIdInStorage = UserProfileStorage.getSelectedRouteId();

    if (!shopCode?.toLowerCase().includes("all")) {
      UserProfileStorage.setSelectedShopCode(shopCode);
    } else {
      shopCodeInStorage.length && setShopCode(shopCodeInStorage);
      !shopCodeInStorage.length &&
        filteredShopCodes.length === 1 &&
        UserProfileStorage.setSelectedShopCode(filteredShopCodes[0]);
      setLoading(false);
    }
    UserProfileStorage.setRouteDeliveryDate(deliveryDate);
    routeIdInStorage.length && UserProfileStorage.setSelectedRouteId("");

    // clear the recordId before leaving the screen.
    return () => {
      setRecordId();
    };
  }, []);

  useEffect(() => {
    dispatch(setShopCodeSelected(shopCode));
  }, [shopCode]);

  useEffect(() => {
    clearTimeout(deliverytimerToClear.current);
    if (!shopCode?.toLowerCase().includes("all")) {
      getRoutes();
    } else {
      filteredShopCodes.length === 1 && setShopCode(filteredShopCodes[0]);
    }
  }, [shopCode, deliveryDate]);

  useEffect(() => {
    clearTimeout(deliverytimerToClear.current);
    if (!shopCode?.toLowerCase().includes("all") && timeStamp && pollInterval) {
      deliverytimerToClear.current = setTimeout(() => {
        getRoutes();
      }, pollInterval);
    } else {
      filteredShopCodes.length === 1 && setShopCode(filteredShopCodes[0]);
    }
    return () => {
      clearTimeout(deliverytimerToClear.current);
    };
  }, [timeStamp]);

  useEffect(() => {
    //get all orders for routeDeliveryDate to get rush order details to show rush icon on route tile
    if (shopCode !== "all") {
      dispatch(
        fetchNonRoutedOrders({
          startDate,
          endDate,
          shopCode,
          deliveryDate: moment(routeDeliveryDate).format("YYYY-MM-DD"),
          considerLocalOrders,
        })
      );
    }
  }, [shopCode, routeDeliveryDate]);

  const getAutoRouteData = async (id) => {
    try {
      const res = await request("get-autoroutes-data", {
        shopCode: shopCode,
        autoRouteId: id,
      });
      return {
        capacity: res.capacity,
      };
    } catch (e) {
      return {
        capacity: "",
      };
    }
  };

  const getRoutes = () => {
    setLoading(true);
    setPageLoading(true);
    request("get-routes", {
      shopCode: shopCode,
      deliveryDates: moment(deliveryDate).format("YYYY-MM-DD"),
    })
      .then(async (res) => {
        const autoRouteDetails = res.map((data) => {
          if (data.route.autoRouteId !== undefined) {
            return getAutoRouteData(data.route.autoRouteId);
          }
        });
        const matchedAutoRoutes = await Promise.all(autoRouteDetails);
        const rts = res.map((data, index) => {
          if (data.route.autoRouteId !== undefined) {
            const matchedObject = matchedAutoRoutes[index];
            data.route.capacity = matchedObject.capacity;
          }
          return data;
        });
        setRoutes(rts);
        setTimestamp(moment().utc().format());
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
        setPageLoading(false);
      });
  };

  const createRoute = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: `create-route`,
      })
    );
    UserProfileStorage.setAutoRouteId(undefined);
    setCurrentPage("create-route");
  };

  return (
    <>
      <SplitScreen
        subHeaderExists={!isDesktop}
        tabHeaderExists={true}
        proxyHeaderExists={proxyUser ? true : false}
        showTwoScreens={false}
      >
        <View fsClass="fs-unmask">
          <ScrollView>
            <View fsClass="fs-unmask" style={[tw("pt-3 pb-3")]}>
              <>
                <View
                  style={{
                    ...tw("flex-row items-center"),
                    paddingBottom: 10,
                  }}
                >
                  <Button
                    title={Localise(messages, "Create Route")}
                    titleStyle={theme.Button.secondaryTitleStyle}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingVertical: 6,
                      paddingHorizontal: 10,
                      marginTop: 0,
                      width: 130,
                      height: 35,
                    }}
                    containerStyle={{
                      margin: 0,
                      marginBottom: 10,
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      createRoute();
                    }}
                    testID={"create_route"}
                    accessibilityLabel={"create_route"}
                  />
                </View>
                <Form
                  initialValues={{
                    newDeliveryDate: routeDeliveryDate,
                  }}
                  render={({ values }) => (
                    <View style={tw("items-center flex-row flex-wrap")}>
                      {filteredShopCodes.length > 1 && (
                        <View
                          style={{
                            ...tw("flex-row items-center"),
                            paddingBottom: 10,
                            marginRight: 30,
                          }}
                        >
                          <Text style={{ paddingRight: 15 }}>
                            {Localise(messages, "Shop")}:
                          </Text>
                          <Picker
                            containerStyle={{
                              flexDirection: "row",
                              justifyContent: "center",
                              paddingBottom: 0,
                            }}
                            innerContainerStyle={{
                              width: isSmallScreen ? 120 : 150,
                            }}
                            items={[
                              {
                                label: "Select Shop",
                                value: "all",
                              },
                              ...filteredShopCodes.map((code) => ({
                                label: `${code} ${shopNames[code]}`,
                                value: code,
                              })),
                            ]}
                            placeholder={{}}
                            value={shopCode}
                            onValueChange={(val) => {
                              setShopCode(val);
                              UserProfileStorage.setSelectedShopCode(val);
                            }}
                          ></Picker>
                        </View>
                      )}
                      <View style={tw("flex-row items-center")}>
                        <Text style={{ marginBottom: 15, paddingRight: 15 }}>
                          {Localise(messages, "Select Date")}:
                        </Text>
                        <FormFieldDatePicker
                          dateValueFormat="YYYY-MM-DD"
                          name="newDeliveryDate"
                          placeholder="MM/DD/YYYY"
                          minDate={new Date(null)}
                          containerStyle={{
                            zIndex: 20,
                            paddingLeft: 0,
                            marginLeft: -5,
                            marginTop: 0,
                            width: isMobile ? "55%" : 154,
                            height: Platform.OS !== "web" ? 40 : "auto",
                          }}
                          alignIconRight={true}
                          onValueChange={(val) => {
                            if (!isNil(val)) {
                              setDeliveryDate(moment(val).format("YYYY-MM-DD"));
                              UserProfileStorage.setRouteDeliveryDate(
                                moment(val).format("YYYY-MM-DD")
                              );
                            }
                          }}
                          iconName="calendar"
                          iconType="font-awesome"
                        />
                      </View>
                    </View>
                  )}
                />
                {(filteredShopCodes.length > 1 &&
                  !shopCode?.toLowerCase().includes("all")) ||
                filteredShopCodes.length === 1 ? (
                  <View
                    style={{
                      minHeight: 500,
                      zIndex: -1,
                      maxWidth: isDesktop ? 840 : "100%",
                      marginBottom: 10,
                    }}
                  >
                    {RoutesUIConfig.map((eachItem, index) => {
                      const { label, title, displayStatus } = eachItem;
                      return (
                        <View key={index}>
                          <RoutesListing
                            title={title}
                            label={label}
                            shopCode={shopCodeInStorage}
                            loading={pageLoading}
                            setLoading={(val) => {
                              setLoading(val);
                              setPageLoading(val);
                            }}
                            routes={routes}
                            getRoutes={getRoutes}
                            viewMoreCounts={viewMoreCounts}
                            setViewMoreCounts={setViewMoreCounts}
                            defaultOpen={displayStatus === "open"}
                            filteredShopCodes={filteredShopCodes}
                          />
                        </View>
                      );
                    })}
                  </View>
                ) : (
                  <View
                    style={{
                      flex: 1,
                      marginTop: Platform.OS === "web" ? 0 : 20,
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: Platform.OS === "web" ? 500 : 200,
                      zIndex: -1,
                    }}
                  >
                    <Image
                      style={{
                        width: 100,
                        height: 90,
                      }}
                      resizeMode="cover"
                      source={IMAGES["select-shop"]}
                    />
                    <Text
                      style={{
                        width: 180,
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "400",
                        lineHeight: 23,
                        marginTop: 40,
                      }}
                    >
                      {Localise(messages, "Select a shop above to get started")}
                    </Text>
                  </View>
                )}
              </>
            </View>
          </ScrollView>
        </View>
        <></>
      </SplitScreen>
    </>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentOrdersInfo: state.mhq.ongoing.currentOrders,
    selectedShopCode: state.mhq.ongoing.global.shopCode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecordId,
      setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoutesDashboard);
