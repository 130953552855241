/* eslint-disable react-hooks/rules-of-hooks */
import { request } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { Platform } from "react-native";
import { backgroundColors } from "styles/theme";
import { Entitlements } from "library/utils/entitlements";
import { phoneNumberFormatter } from "library/utils/formatter";

export const getLocalPermissions = (
  permissions,
  selectedShopPermissions,
  filteredShopCodes,
  multiSelectedShopCodes = []
) => {
  let localPermissions = {
    isOptimizeEnabled: false,
    considerLocalOrders: false,
    adhocStops: false,
    printManifest: false,
    tbtDirections: false,
    showNonRoutedOrders: false,
    allowEditDriver: false,
    enableStopsMoves: false,
    mapAddRoute: false,
    mapRemoveRoute: false,
    mapDrawTool: false,
    multiShopRoute: false,
    isCFROptimiseEnabled: false, // Optimise using Google Cloud Fleet Routing(CFR)
    showQRScan: false,
  };

  const hasPermission = (permisssionName) => {
    return filteredShopCodes.some(
      (memberCode) =>
        permissions[memberCode][Entitlements.DELIVERY]?.includes(
          Entitlements.DELIVERY_PAGE[permisssionName]
        ) ||
        permissions[memberCode][Entitlements.ROUTES]?.includes(
          Entitlements.ROUTES_PAGE[permisssionName]
        )
    );
  };

  const hasPermissionForAllShops = (permisssionName) => {
    return multiSelectedShopCodes.every(
      (memberCode) =>
        permissions[memberCode][Entitlements.DELIVERY]?.includes(
          Entitlements.DELIVERY_PAGE[permisssionName]
        ) ||
        permissions[memberCode][Entitlements.ROUTES]?.includes(
          Entitlements.ROUTES_PAGE[permisssionName]
        )
    );
  };

  const hasRemoveStopPermission = () => {
    const permisssionName = "ROUTE_REMOVE_STOP";
    return filteredShopCodes.some(
      (memberCode) =>
        permissions[memberCode][Entitlements.DELIVERY]?.includes(
          Entitlements.DELIVERY_PAGE[permisssionName]
        ) ||
        permissions[memberCode][Entitlements.DRIVER_DASHBOARD]?.includes(
          Entitlements.DRIVER_DASHBOARD_PAGE[permisssionName]
        )
    );
  };

  localPermissions.isCFROptimiseEnabled = hasPermissionForAllShops(
    "ROUTE_CFR_OPTIMIZATION"
  );

  localPermissions.isOptimizeEnabled =
    hasPermission("ROUTE_OPTIMIZATION") ||
    localPermissions.isCFROptimiseEnabled;

  localPermissions.considerLocalOrders = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_CONSIDER_LOCAL_ORDERS);

  localPermissions.showNonRoutedOrders = hasPermission(
    "ROUTE_NON_ROUTED_ORDERS"
  );
  localPermissions.enableStopsMoves = hasPermission("ROUTE_STOPS_REORDER");

  localPermissions.allowEditDriver = hasPermission("EDIT_DRIVER");

  localPermissions.adhocStops = hasPermission("ROUTE_ADHOC_STOPS");

  localPermissions.printManifest = hasPermission("ROUTE_PRINT_MANIFEST");

  localPermissions.tbtDirections = hasPermission("ROUTE_TBT_DIRECTIONS");

  localPermissions.removeStop = hasRemoveStopPermission();

  localPermissions.mapAddRoute = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_MAP_ADD_ROUTE);

  localPermissions.mapRemoveRoute = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_MAP_REMOVE_ROUTE);

  localPermissions.mapDrawTool = selectedShopPermissions[
    Entitlements.DELIVERY
  ]?.includes(Entitlements.DELIVERY_PAGE.ROUTE_MAP_DRAW_TOOL);

  localPermissions.multiShopRoute = hasPermission("ROUTE_MULTISHOP_OWNERSHIP");

  localPermissions.showQRScan = hasPermission("QR_SCAN");

  return localPermissions;
};

const adhocStopStatusMap = {
  NEW: "Added Stop",
  COMPLETED: "Completed",
  INCOMPLETE: "Not Completed",
};

const getDeliveredDateTime = (stopDeliveryDate, actualArrivalTime) => {
  let deliveredDateTimeFormat = "MM/DD/YYYY h:mm A";
  const actualArrivalDate = moment(actualArrivalTime).format("MM/DD/YYYY");

  if (
    actualArrivalTime &&
    stopDeliveryDate &&
    actualArrivalDate === stopDeliveryDate
  ) {
    deliveredDateTimeFormat = `h:mm A`;
  }

  const actualDeliveredDateTime =
    `Delivered at ` +
    moment(moment.utc(actualArrivalTime).toDate()).format(
      deliveredDateTimeFormat
    );
  return actualDeliveredDateTime;
};

const buildStopInOrderFormat = (stop, routeData) => {
  const {
    type = "CUSTOM",
    orderId = "",
    stopName = "",
    address = {},
    status = "NEW",
    instructions = "",
    siteId = "",
    stopAdditionMethod = "MANUAL",
    orderSequence = 0,
    actualArrivalTime,
  } = stop;
  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    zip = "",
    country = "",
    geoLocation = {},
    addressVerificationInfo = {},
  } = address;
  const { latitude = "", longitude = "" } = geoLocation;
  const displayDeliveryDate = moment(
    routeData.deliveryDate,
    "YYYY-MM-DD"
  ).format("MM/DD/YYYY");

  const deliveredDateTime = getDeliveredDateTime(
    displayDeliveryDate,
    actualArrivalTime
  );

  const showDeliveredDateTime =
    actualArrivalTime &&
    ["CUSTOM", "PICKUP"].includes(stop.type) &&
    status === "COMPLETED";

  return {
    orderSequence,
    routeId: routeData.routeId,
    ...(stop.type === "PICKUP" && {
      receivingMember: {
        memberCode: siteId,
      },
    }),
    ...(showDeliveredDateTime && { deliveredDateTime }),
    actualArrivalTime,
    stopType: type,
    stopAdditionMethod,
    orderItemId: orderId,
    stopName: stopName,
    messageNumber: stopName,
    recipientAddressLine: addressLine1,
    recipientAddressLine2: addressLine2,
    recipientCity: city ? `${city}, ${state}` : "",
    recipientCityOnly: city,
    recipientState: state,
    recipientZip: zip,
    recipientLocation: { latitude, longitude },
    zip: zip,
    recipientCountry: country,
    status: status,
    imageName: "map-pin",
    statusColor: backgroundColors.primary,
    displayOrderStatus: adhocStopStatusMap[status],
    displayDeliveryDate,
    stopDescription: instructions,
    productFirstChoiceDescription: instructions,
    addressVerificationInfo,
  };
};

//get Order Details when deliveryDate is different from routeDeliveryDate
export const getOrderStopsDetails = (
  ordersInRoute,
  ordersNeedFetching,
  fetchOrders,
  resolve = () => {}
) => {
  fetchOrders({
    params: { fetchStops: ordersNeedFetching },
    resolve: (processedOrders) => {
      const ordersBelongsToRoute = ordersInRoute?.map((stop) => {
        const { orderId, actualArrivalTime } = stop;
        const replaceOrder = processedOrders.find(
          (ord) => orderId === ord.orderItemId
        );
        if (replaceOrder) return { ...replaceOrder, actualArrivalTime };
        else return { ...stop };
      });
      resolve(ordersBelongsToRoute);
    },
  });
};

export const getOrdersInRoute = (selectedRouteId, route, nonRoutedOrders) => {
  return route?.stops?.map((stop) => {
    const {
      orderId,
      stopAdditionMethod = "MANUAL",
      type: stopType = "ORDER",
      orderSequence = 0,
      actualArrivalTime,
    } = stop;
    if (stopType === "ORDER") {
      const order = nonRoutedOrders?.find(
        (ord) =>
          ord.routeId &&
          selectedRouteId === ord.routeId &&
          orderId === ord.orderItemId
      );
      if (order)
        return {
          ...order,
          stopAdditionMethod,
          stopType,
          orderSequence,
          actualArrivalTime,
        };
      else {
        return { ...stop, orderItemId: orderId, fetchOrder: true };
      }
    } else if (["CUSTOM", "PICKUP"].includes(stopType)) {
      return buildStopInOrderFormat(stop, route);
    }
  });
};

/**
 * This function will assign the orders to route from nonRoutedOrders based on selectedRouteId
 * @param {*} selectedRouteId - selectedRouteId value in edit route case.
 * @param {*} nonRoutedOrders - nonRoutedOrders has all order for routeDeliveryDate
 * @param {*} setOrdersList - setter function for setting ordersList(ordersInRoute,nonRoutedOrders)
 */
export const assignOrdersToRoutes = (
  selectedRouteId,
  nonRoutedOrders,
  setOrdersList,
  localPermissions,
  route,
  setLoading,
  fetchOrders
) => {
  //formattng Route Stops
  let ordersBelongsToRoute = getOrdersInRoute(
    selectedRouteId,
    route,
    nonRoutedOrders
  );

  const ordersNeedFetching = ordersBelongsToRoute?.filter(
    (ord) => ord.fetchOrder
  );

  const ordersNotBelongsToRoute = nonRoutedOrders?.filter(
    (ord) =>
      (!ord.routeId || ord.routeId === "null") &&
      !["DELIVERED", "CANCELLED"].includes(ord.status)
  );

  let filteredNonRoutedOrders = [...ordersNotBelongsToRoute];

  if (
    !localPermissions?.considerLocalOrders &&
    ordersNotBelongsToRoute?.length
  ) {
    filteredNonRoutedOrders = ordersNotBelongsToRoute.filter(
      (ord) => ord.deliveryInfo?.deliveryMethod !== "FLORIST_DELIVERED" // filter local orders
    );
  }

  const updatedList = {
    ordersInRoute: ordersBelongsToRoute,
    nonRoutedOrders: filteredNonRoutedOrders,
  };

  if (ordersNeedFetching?.length > 0) {
    getOrderStopsDetails(
      ordersBelongsToRoute,
      ordersNeedFetching,
      fetchOrders,
      (ordersInRoute) => {
        setOrdersList({ ...updatedList, ordersInRoute });
        setLoading(false);
      }
    );
  } else {
    setOrdersList({ ...updatedList });
    setLoading(false);
  }
};

/**
 * pull shop details incase if we don't receive shopNames
 */
export const getShopDetails = () => {
  request("get-shop-details").then((allMemberDetails = []) => {
    allMemberDetails.forEach((memberDetail) => {
      UserProfileStorage.setShopName(
        memberDetail.memberCode,
        memberDetail.businessName
      );
    });
  });
};

/**
 * Fetch RouteNames
 * @param {*} shopCode - get the selected shopCode value from state
 * @param {*} setRouteName - setter to set routeName.
 */
export const getRouteName = (shopCode, setRouteName) => {
  const selectedShopCode = UserProfileStorage.getSelectedShopCode();
  if (
    (selectedShopCode === "all" && shopCode === "all") ||
    (!selectedShopCode && !shopCode)
  )
    return;
  request("get-route-name", {
    members: shopCode === "all" ? selectedShopCode : shopCode,
  })
    .then((res) => {
      res && setRouteName(res.name);
    })
    .catch(() => {
      console.log("error");
    });
};

/**
 * Fetch drivers
 * @param {*} shopCode - get the selected shopCode value from state
 * @param {*} setLoading - setter to set the loading state.
 * @param {*} setDriversData - setter to set the driversData.
 * @param {*} members - all selected shopCodes.
 * @param {*} commonUsers - get commonUsers: boolean.
 */
export const getDrivers = (
  shopCode,
  setLoading,
  setDriversData,
  members,
  commonUsers = false,
  callBack = () => {}
) => {
  const selectedShopCode = UserProfileStorage.getSelectedShopCode();
  if (
    (selectedShopCode === "all" && shopCode === "all") ||
    (!selectedShopCode && !shopCode)
  )
    return;

  const memberCode = shopCode === "all" ? selectedShopCode : shopCode;
  setLoading(true);
  request("get-drivers", {
    memberCode,
    members: members ? members.join(",") : memberCode,
    commonUsers,
  })
    .then((res) => {
      res && setDriversData(res);
      callBack(res);
    })
    .catch((err) => {
      console.log("get-drivers error", err);
      setLoading(false);
    });
};

/**
 * To set order sequences as per the route data after assigning orders to the route.
 * @param {*} routeData - routeData value from state
 * @param {*} ordersList - ordersList has ordersInRoute, nonRoutedOrders
 * @param {*} setOrdersList - setter function for setting ordersList(ordersInRoute,nonRoutedOrders)
 */
export const setOrderSequences = (
  routeData,
  ordersList,
  setOrdersList,
  setLoading
) => {
  // creating orderSequence map from the updated routeData. Pattern - {orderId: sequence}
  let orderSequenceMap = {};
  !isEmpty(routeData) &&
    routeData.stops?.map((stop) => {
      orderSequenceMap = {
        ...orderSequenceMap,
        [stop.orderId]: stop.orderSequence,
      };
    });

  // Adding updated order sequence id to each order for the orders in ordersInRoute.
  let newOrdersInRoute = ordersList.ordersInRoute.map((ord) => {
    return { ...ord, orderSequence: orderSequenceMap[ord.orderItemId] };
  });

  // sorting based on the orderSequence attribute
  newOrdersInRoute = sortBy(newOrdersInRoute, "orderSequence");
  setOrdersList({ ...ordersList, ordersInRoute: newOrdersInRoute });
  setLoading(false);
};

/**
 * Helper function for adjusting array indexes when we move orders using arrows.
 * @param {*} arr - supplies array
 * @param {*} old_index - supplies old index
 * @param {*} new_index - suplies new index to update
 * @returns
 */
export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

const getStopInfo = (stop, ordersInformation) => {
  const { orderId, orderSequence } = stop;

  const order = ordersInformation.find(
    (order) => order.orderItemId === orderId
  );

  if (order) {
    const {
      recipientInfo: {
        firstName = "",
        lastName = "",
        locationName = "",
        addressLine1 = "",
        addressLine2 = "",
        city = "",
        phone = "",
        state = "",
        zip = "",
      },
      deliveryInfo: { deliveryInstructions },
      lineItems,
    } = order;

    return {
      orderSequence,
      orderId,
      locationName,
      addressLine1,
      addressLine2,
      addressLine3: [city, state, zip].join(","),
      name: [firstName, lastName].join("&nbsp;"),
      phone,
      lineItems,
      deliveryInstructions,
    };
  } else {
    const {
      stopName = "",
      address: {
        addressLine1 = "",
        addressLine2 = "",
        city = "",
        state = "",
        zip = "",
        phone = "",
      } = {},
    } = stop;

    return {
      orderSequence,
      orderId,
      addressLine1,
      addressLine2,
      addressLine3: [city, state, zip].join(","),
      name: stopName,
      phone,
    };
  }
};

export const getPrintHTML = (routeData, Localise = () => {}, messages = {}) => {
  const shopNames = UserProfileStorage.getAllShopNames();

  const {
    routeInfo: {
      routeName = "",
      driver: { name: driverName = "" },
      deliveryDate = "",
      departureTime = "",
      stops = [],
      memberCodes = [],
    },
    ordersInformation = {},
  } = routeData;

  return `<!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <title>Mercury HQ</title>
    </head>
    <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
      <div style="display: none">
        <style type="text/css" media="print,screen">
          .page-header,
          .page-header-space {
            height: 90px;
          }
          .page-footer,
          .page-footer-space {
            height: 50px;
          }
          .page-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
          }
          .page-header {
            position: fixed;
            top: 0;
            width: 100%;
          }
          .head-container {
            display: flex;
            justify-content: space-between;
            height: 50px;
            border-bottom: 1px solid black;
            width: 100%;
          }
          .foot-container {
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 50px;
          }
          .header-title {
            font-size: 20px;
          }
          .footer-title {
            font-size: 18px;
          }
          .header-route-name {
            font-size: 20px;
            font-weight: bold;
          }
          .table-responsive td:first-child {
            text-align: left;
            width: 4%;
          }
          .table-responsive td:nth-child(2) {
            text-align: left;
            width: 20%;
            word-break: break-word;
          }
          .table-responsive td:nth-child(3) {
            text-align: left;
            width: 25%;
            word-break: break-word;
          }
          .table-responsive td:nth-child(4) {
            text-align: left;
            width: 21%;
            word-break: break-word;
          }
          .table-responsive td:nth-child(5) {
            text-align: left;
            width: 30%;
          }
          .blank {
            padding-left:60px;
            padding-right:60px;
            border-bottom: 1px solid black;
          }
          .tableHeader {
            text-align: left;
            border: 1px solid black;
          }
          .driver {
            padding-left:120px;
            padding-right:120px;
          }
          @page {           
            margin: 20px;
            size: A4 landscape;
            -webkit-margin-before: 30px;
            -webkit-margin-after: 50px;
          }
          @media print {
            thead {
              display: table-header-group;
            }
            tfoot {
              display: table-footer-group;
            }
            button {
              display: none;
            }
            body {
              margin: 0;
              font-family: Helvetica, sans-serif;
            }
            .table tbody > tr > td,
            .table > tbody > tr > th,
            .table > tfoot > tr > td,
            .table > tfoot > tr > th,
            .table > thead > tr > td,
            .table > thead > tr > th {
              padding: 8px;
              line-height: 1.3;
              vertical-align: top;
            }
            .table {
              border-collapse: collapse;
              width: 100%;
              max-width: 100%;
              margin-bottom: 10px;
              border-spacing: 0px;
            }
          }
        </style>
      </div>
      <div class=${
        Platform.OS == "ios" ? "" : "page-header"
      } style="text-align: center;width: 100%;">
        <div class="head-container">
          <div><p class="header-title">Route Manifest</p></div>
          <div><p class="header-route-name">${routeName}</p></div>
          <div><p class="header-title">Driver: ${
            driverName
              ? `<span>${driverName}</span>`
              : `<span class="driver"></span>`
          }</p></div>
        </div>
        <div class="table-responsive" style="margin-top: 10px;width: 100%;">
          <table class="table">
            <tbody>
              <tr
                style="font-size: 14px !important; font-weight: bold !important"
              >
                <td class="tableHeader">
                  <label>${Localise(messages, "Stop")}</label>
                </td>
                <td class="tableHeader">
                  <label>${Localise(messages, "Deliver To")}</label>
                </td>
                <td class="tableHeader">
                  <label>${Localise(messages, "Product(s)")}</label>
                </td>
                <td class="tableHeader">
                  <label>${Localise(messages, "Delivery Instructions")}</label>
                </td>
                <td class="tableHeader">
                  <label>${Localise(messages, "Signature")}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      ${
        Platform.OS == "ios"
          ? ""
          : `<div class="page-footer">
        <div class="foot-container">
        <div class="footer-title" style="display:flex">
          Departure Time : &nbsp;<span class=${
            departureTime !== "" ? "" : "blank"
          }>${
              departureTime !== ""
                ? moment(departureTime).format("hh:mm A")
                : ""
            }</span>
        </div>
        <div class="footer-title">
          Delivery Date : <span>${moment(deliveryDate).format(
            "MM/DD/YYYY"
          )}</span>
        </div>
      </div>
      </div>`
      }
      <div style="margin-top: 0px !important" class="page-break-flow-root">
        <div class="table-responsive">
          <table class="table">
          ${
            Platform.OS == "ios"
              ? ""
              : `<thead>
              <tr>
                <th>
                  <!--place holder for the fixed-position header-->
                  <div class="page-header-space">&nbsp;</div>
                </th>
              </tr>
            </thead>`
          }
            <tbody>
              ${stops
                .map((stop) => {
                  const {
                    type,
                    instructions = "",
                    orderId,
                    siteId = "",
                  } = stop;
                  const isStop = ["CUSTOM", "PICKUP"].includes(type);
                  const {
                    orderSequence,
                    locationName,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    name,
                    phone,
                    lineItems,
                    deliveryInstructions = "",
                  } = getStopInfo(stop, ordersInformation);
                  const stopShopName = shopNames[siteId] || "";

                  return `<tr style="border-bottom:1px solid black;border-top:1px solid black;page-break-inside:avoid !important; ${
                    Platform.OS == "ios"
                      ? "display:inline-block;vertical-align:top;width:100%"
                      : ""
                  }">
                <td>
                  <span>${orderSequence + 1}</span>
                </td>
                <td>
                  <div class="">
                    <span>${name}</span><br />
                    ${!locationName ? "" : `<span>${locationName}</span><br/>`}
                    <span>${addressLine1}</span><br/>
                    <span>${addressLine2}</span>${addressLine2 && `<br/>`}
                    <span>${addressLine3}</span><br/>
                    <span>${phoneNumberFormatter(phone)}</span>
                  </div>
                </td>
                <td>
                  <div>
                    ${
                      !isStop
                        ? lineItems
                            .map((item) => {
                              const {
                                productFirstChoiceDescription,
                                totalQuantity,
                                accessories,
                              } = item;
                              return `<span>
                      <span>Total Items: ${totalQuantity}</span><br />
                      <span>${productFirstChoiceDescription}</span><br />
                      <span>${accessories
                        .map((addon) => {
                          return `<span>${addon.accessoryDesc}</span>`;
                        })
                        .join("<br />")}</span></span>`;
                            })
                            .join("<br />")
                        : instructions
                    }
                  </div>
                </td>
                <td>
                  <div>${deliveryInstructions}</div>
                </td>
                <td>
                ${
                  !isStop
                    ? `<div>Received By : &nbsp;<span class="blank"></span></div><br/><div>Order ID : ${orderId}</div>${
                        memberCodes?.length > 1
                          ? `<div>${Localise(
                              messages,
                              "Shop"
                            )} : ${siteId} - ${stopShopName}</div>`
                          : ``
                      }`
                    : ``
                }
                </td>
              </tr>`;
                })
                .join("")}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <!--place holder for the fixed-position footer-->
                  <div class="page-footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        ${
          Platform.OS == "ios"
            ? `<div class="foot-container">
          <div class="footer-title" style="display:flex">
            Departure Time : &nbsp;<span class=${
              departureTime !== "" ? "" : "blank"
            }>${
                departureTime !== ""
                  ? moment(departureTime).format("hh:mm A")
                  : ""
              }</span>
          </div>
          <div class="footer-title">
            Delivery Date : <span>${moment(deliveryDate).format(
              "MM/DD/YYYY"
            )}</span>
          </div>
        </div>`
            : ""
        }
      </div>
    </body>
  </html>  
     `;
};

//save routeMemberPreferences in userPreferences
export const saveUserPreferences = ({
  reqPayload = {},
  resolve = () => {},
  reject = () => {},
}) => {
  request("edit-user-profile", reqPayload)
    .then((res) => {
      resolve(res);
    })
    .catch(() => {
      console.log("error");
      reject();
    });
};

export const splitArray = (list, condition) => {
  let matchedList = [];
  let unmatchedList = [];
  list.forEach((order) => {
    if (condition(order)) {
      matchedList.push(order);
    } else {
      unmatchedList.push(order);
    }
  });
  return { matchedList, unmatchedList };
};
