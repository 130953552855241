import React, { useContext } from "react";
import I18NContext from "library/contexts/i18N";
import { View, Text } from "react-native";
import { Icon } from "react-native-elements";
import {
  Form,
  FormField,
  FormFieldPicker,
  FormFieldDatePicker,
} from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import tw from "tailwind-rn";
import { fonts, colors } from "styles/theme";
import { DeviceContext } from "library/contexts/appSettings";
import { ToasterHandler, Tooltip } from "components/elements";
import has from "lodash/has";
import { useDispatch, useSelector } from "react-redux";
import { savePromoCode } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { discountTypes, initialValues, validationSchema } from "./config";
import { SHOP_SETTINGS, COMMON } from "library/constants";
import { isAnyFieldEmpty, isPlatformWeb, promoParams } from "./helper";
import { ERROR_MESSAGES } from "library/constants";
import { selectShopCode } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { fetchPromoCodes } from "library/sagas/views/home/drawer/shop-settings/common/slice";

const CustomLabel = ({ label }) => {
  const { messages, Localise } = useContext(I18NContext);
  return (
    <View style={tw("flex flex-row mb-1 items-center")}>
      <Text style={[fonts.default, tw("font-normal")]}>
        {Localise(messages, label)}
      </Text>
      <Tooltip text={Localise(messages, SHOP_SETTINGS.PROMO_INFO_TOOLTIP)}>
        <Icon
          name="info"
          type="simple-line-icon"
          color={colors.primary}
          size={15}
          style={{ marginLeft: 5 }}
        />
      </Tooltip>
    </View>
  );
};

const CreatePromoCode = () => {
  const { messages, Localise } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const memberCode = useSelector(selectShopCode);
  const dispatch = useDispatch();

  const onSubmit = (values, formikBag) => {
    const { setSubmitting, resetForm } = formikBag;
    if (Number(values.discountValue) !== 0) {
      dispatch(
        savePromoCode({
          params: { ...values, memberCode },
          resolve: () => {
            dispatch(
              fetchPromoCodes({
                params: promoParams(),
                resolve: () => {
                  resetForm();
                  ToasterHandler(
                    "success",
                    Localise(messages, SHOP_SETTINGS.PROMO_CREATION_SUCCESS)
                  );
                  setSubmitting(false);
                },
                reject: () => {
                  //Displaying success toaster even if fetch call fails
                  resetForm();
                  ToasterHandler(
                    "success",
                    Localise(messages, SHOP_SETTINGS.PROMO_CREATION_SUCCESS)
                  );
                  setSubmitting(false);
                },
              })
            );
          },
          reject: (error) => {
            ToasterHandler(
              "uh oh",
              Localise(
                messages,
                error === ERROR_MESSAGES.NOT_ACCEPTABLE
                  ? SHOP_SETTINGS.PROMO_DUPLICATE
                  : ERROR_MESSAGES.GENERAL_ERROR
              )
            );
            setSubmitting(false);
          },
        })
      );
    } else {
      setSubmitting(false);
      ToasterHandler(
        "uh oh",
        Localise(messages, SHOP_SETTINGS.PROMO_ZERO_AMOUNT)
      );
    }
  };

  return (
    <View>
      <Text style={[tw("mb-5"), fonts.heading3]}>
        {Localise(messages, SHOP_SETTINGS.PROMO_HEADING)}
      </Text>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema(Localise, messages)}
        render={({ values, setFieldValue, dirty, errors }) => {
          const { discountType = "", startDate = "" } = values;
          const isPercentage = discountType === COMMON.PERCENTAGE;

          return (
            <View style={tw("px-2")}>
              <View style={[tw("flex flex-row flex-wrap mb-1"), { gap: 10 }]}>
                <FormField
                  name="promoCode"
                  placeholder={Localise(messages, SHOP_SETTINGS.PROMO_TITLE)}
                  label={
                    isPlatformWeb ? (
                      <CustomLabel label={SHOP_SETTINGS.CODE} />
                    ) : (
                      Localise(messages, SHOP_SETTINGS.CODE)
                    )
                  }
                  labelStyle={tw("font-normal")}
                  containerStyle={{
                    width: isDesktop ? "15%" : "100%",
                    paddingHorizontal: 0,
                  }}
                  errorStyle={{ paddingBottom: 0 }}
                  autoCapitalize={"characters"}
                  autoCorrect={false}
                  autoComplete="new-password"
                  testID="promo_code"
                  accessibilityLabel="promo_code"
                  onChangeText={(text) =>
                    setFieldValue(
                      "promoCode",
                      text.toUpperCase().replace(/[^A-Z0-9]/g, "")
                    )
                  }
                  maxLength={15}
                />
                <FormFieldPicker
                  name="discountType"
                  containerStyle={{
                    width: isDesktop ? "13%" : "100%",
                    paddingHorizontal: 0,
                    paddingBottom: 0,
                    ...(!isPlatformWeb &&
                      !has(errors, "discountType") && { marginBottom: 12 }),
                  }}
                  label={Localise(messages, COMMON.DISCOUNT_TYPE)}
                  labelStyle={tw("font-normal")}
                  disabledFieldTouch={true}
                  inputContainerStyle={{ paddingVertical: 0 }}
                  errorStyle={{ paddingBottom: 0 }}
                  placeholder={{
                    label: Localise(messages, COMMON.SELECT_DISCOUNT),
                    value: "",
                  }}
                  data={discountTypes(Localise, messages)}
                  testID="discount_type"
                  accessibilityLabel="discount_type"
                />
                <FormField
                  name="discountValue"
                  containerStyle={{
                    width: isDesktop ? "10%" : "100%",
                    paddingHorizontal: 0,
                  }}
                  label={Localise(messages, COMMON.AMOUNT)}
                  labelStyle={tw("font-normal")}
                  errorStyle={{ paddingBottom: 0 }}
                  {...(discountType && {
                    iconName: isPercentage ? "percent" : "currency-usd",
                    iconPosition: !isPercentage,
                    iconSize: isPercentage ? 12 : 14,
                    iconType: isPercentage
                      ? "font-awesome"
                      : "material-community",
                  })}
                  isPrice={true}
                  editable={!!discountType}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  placeholder="0.00"
                  autoCapitalize="none"
                  autoCorrect={false}
                  grayedOutOnDisable={true}
                  testID="discount_value"
                  accessibilityLabel="discount_value"
                />
                <FormFieldDatePicker
                  dateValueFormat="YYYY-MM-DD"
                  name="startDate"
                  label={Localise(messages, COMMON.START)}
                  labelStyle={tw("font-normal")}
                  placeholder="MM/DD/YYYY"
                  containerStyle={{
                    width: isDesktop ? "13%" : "100%",
                    zIndex: 30,
                  }}
                  customInputContainerStyle={{
                    paddingHorizontal: 0,
                  }}
                  errorStyle={{ paddingBottom: 0 }}
                  testID="start_date"
                  accessibilityLabel="start_date"
                />
                <FormFieldDatePicker
                  dateValueFormat="YYYY-MM-DD"
                  name="endDate"
                  label={Localise(messages, COMMON.END)}
                  labelStyle={tw("font-normal")}
                  placeholder="MM/DD/YYYY"
                  containerStyle={{
                    width: isDesktop ? "13%" : "100%",
                    paddingHorizontal: 0,
                    zIndex: 15,
                  }}
                  minDate={startDate ? new Date(startDate) : null}
                  errorStyle={{ paddingBottom: 0 }}
                  customInputContainerStyle={{
                    paddingHorizontal: 0,
                  }}
                  testID="end_date"
                  accessibilityLabel="end_date"
                />
              </View>

              <SaveCancelButtons
                buttonTitle={Localise(messages, COMMON.SAVE)}
                disableOnDirty={false}
                disableCancel={!dirty}
                disableSubmit={
                  isAnyFieldEmpty(values) || Object.keys(errors).length > 0
                }
                submitContainerStyle={{ marginLeft: 0, marginRight: 0 }}
                saveCancelContainerStyles={{ zIndex: -1 }}
                saveTestId="save_promo_code"
                cancelTestId="cancel_promo_code"
              />
            </View>
          );
        }}
      />
    </View>
  );
};

export default CreatePromoCode;
