import {
  select,
  all,
  call,
  put,
  delay,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import qs from "querystring";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import difference from "lodash/difference";
import moment from "moment";
import request from "../request";
import EnvironmentStorage from "library/storage/environment";
import Environment from "library/utils/environment";

import he from "he";

import {
  fetchMiddlewareData,
  saveMiddlewareData,
  deleteMiddlewareData,
  setApiResponse,
  fetchAllCollections,
  setCollectionResponse,
  setStoreSync,
  triggerAppInstall,
  triggerStoreSync,
  triggerManualSync,
  setMemberResults,
  setMemberSearchError,
  setMemberSearchText,
  createSEMRushProject,
  setSemRushStatus,
  setTranslationCorrections,
  setScheduledContentScreenTabs,
  saveLanguages,
  setLanguages,
} from "./slice";
import { replacePlaceHolders } from "library/sagas/views/home/drawer/helper";
import {
  selectWebsiteSettings,
  selectShopCode,
} from "library/sagas/views/home/drawer/mercury-online/selector";
import {
  selectAppInstallationStatus,
  selectCheckoutAppInstallationStatus,
  selectLandingPageAccordionContent,
  selectIsScheduledContentComponent,
  selectIsLandingPageComponent,
  selectApiResponse,
  selectParentComponent,
  selectTranslatableKeys,
  selectIsTranslatableComponent,
  selectLanguages,
  selectTranslatableComponents,
} from "./selector";
import { compare as generateJSONDiff, applyPatch } from "fast-json-patch";
import UserProfileStorage from "library/storage/userProfile";

const primaryLanguage = "en";

function* handleFetchMiddlewareData(action = {}) {
  const {
    component,
    context,
    assetId,
    forceTranslateLanguages = [],
  } = get(action, "payload", "");
  const isMTACLogin = context === "global";

  const shopCode = yield select(selectShopCode);
  const supportedLanguages = yield call(
    getComponentLanguages,
    component,
    context
  );
  const translationLanguages = supportedLanguages.filter(
    (lang) => lang != primaryLanguage
  );

  const isScheduledContentComponent = yield select(
    selectIsScheduledContentComponent(component)
  );

  const getScheduledComponent = (component) =>
    isMTACLogin ? `${component}ScheduledGlobal` : `${component}Scheduled`;

  const serviceContentRequest = (
    component,
    language = primaryLanguage,
    shopOverride
  ) => {
    if (!supportedLanguages.includes(language)) return {};

    return request("get-mol-content", {
      component: `${component}_${language}`.replace("_en", ""),
      context,
      assetId,
      shopCode: shopOverride ? shopOverride : isMTACLogin ? "ftd" : shopCode,
    }).catch((error) => {
      return error === "NOT_FOUND" ? { data: "" } : "";
    });
  };

  const serviceContentMetaRequest = (component) =>
    request("get-mol-content-meta", {
      component,
      context,
      assetId,
      shopCode: isMTACLogin ? "ftd" : shopCode,
    }).catch((error) => {
      return error === "NOT_FOUND" ? {} : "";
    });

  try {
    const fetchContentCalls = {
      defaultInfo: {},
      scheduledInfo: {},
      extraInfo: {},
      content: {}, // en
      scheduledContent: {}, // en
      translations: {
        content: {},
        scheduledContent: {},
      }, // fr, es
      scheduledContentShopOverrides: {},
    };

    fetchContentCalls.defaultInfo = call(serviceContentMetaRequest, component);
    if (isScheduledContentComponent) {
      fetchContentCalls.scheduledInfo = call(
        serviceContentMetaRequest,
        getScheduledComponent(component)
      );
    }

    fetchContentCalls.content = call(serviceContentRequest, component);
    if (isScheduledContentComponent) {
      fetchContentCalls.scheduledContent = call(
        serviceContentRequest,
        getScheduledComponent(component)
      );
    }

    translationLanguages.forEach((language) => {
      fetchContentCalls[`translations_content_${language}`] = call(
        serviceContentRequest,
        component,
        language
      );

      if (isScheduledContentComponent) {
        fetchContentCalls[`translations_scheduledContent_${language}`] = call(
          serviceContentRequest,
          getScheduledComponent(component),
          language
        );
      }
    });

    if (isMTACLogin && isScheduledContentComponent) {
      fetchContentCalls.scheduledContentShopOverrides = call(
        serviceContentRequest,
        `${component}Scheduled`,
        undefined,
        "ftd"
      );
    }

    if (component === "mainNavigation") {
      fetchContentCalls.extraInfo = call(
        serviceContentRequest,
        "shopperApprovedConfig"
      );
    }

    let response = yield all(fetchContentCalls);
    if (!response.content) throw "CONTENT_ERROR";
    if (!response.defaultInfo) throw "CONTENT_META_ERROR";

    // process translations object
    response = processTranslationsContent(response);

    // process scheduled component's tab content here
    if (isScheduledContentComponent) {
      response = yield call(
        processScheduledContent,
        component,
        context,
        response
      );
    }

    const {
      actualContext,
      content,
      scheduledContent,
      translations,
      overriddenShops = [],
    } = yield call(processMiddlewareResponse, response, context);

    yield put(
      setApiResponse({
        component,
        actualContext,
        context,
        assetId,
        content,
        scheduledContent,
        translations,
        ...(isMTACLogin ? { overriddenShops } : {}),
      })
    );

    yield call(
      handleTranslationCorrections,
      component,
      forceTranslateLanguages
    );

    if (isScheduledContentComponent) {
      yield put(
        setScheduledContentScreenTabs({
          type: scheduledContent ? "add" : "delete",
          operation: "update",
          value: {
            key: "scheduled-content",
            title: "Temporary Content",
          },
        })
      );
    }
  } catch (error) {
    console.log("error", error);
    yield put(
      setApiResponse({
        error: "Something went wrong, please try again",
      })
    );
  }
}

function* handleTranslationCorrections(component, languagesAdded = []) {
  const isLandingPage = yield select(selectIsLandingPageComponent(component));
  const landingPageContent = yield select(
    selectLandingPageAccordionContent(component)
  );

  const {
    context,
    actualContext,
    assetId,
    content,
    scheduledContent,
    translations,
  } = isLandingPage ? landingPageContent : yield select(selectApiResponse);

  const supportTranslation = yield select(
    selectIsTranslatableComponent(component)
  );
  if (!supportTranslation) return;

  if (actualContext === "global") return;

  const supportedLanguages = yield call(
    getComponentLanguages,
    component,
    context
  );
  const translationLanguages = supportedLanguages.filter(
    (lang) => lang !== primaryLanguage
  );

  const forceTranslateLanguages = translationLanguages
    .filter(
      (lang) =>
        (content && !translations.content[lang]) ||
        (scheduledContent && !translations.scheduledContent[lang])
    )
    .concat(languagesAdded);

  if (forceTranslateLanguages.length) {
    const payload = {
      params: {
        values: {
          component,
          context,
          assetId,
          content,
          scheduledContent,
        },
        type: scheduledContent ? "scheduled-content" : "default-content",
        refresh: false,
        forceTranslateLanguages,
      },
    };
    yield call(handleSaveMiddlewareData, { payload });
  }
}

function processOverriddenShopsResponse(
  defualtOverriddenShops,
  scheduledOverriddenShops
) {
  try {
    const overriddenShops = [
      ...defualtOverriddenShops,
      ...scheduledOverriddenShops,
    ].reduce((acc, cur) => {
      const startDate =
        JSON.parse(cur.body).startDate || moment().format("YYYY-MM-DD");
      const endDate = JSON.parse(cur.body).endDate || "2100-01-01";
      const isContentActive =
        moment(startDate).isSameOrBefore(moment().format("YYYY-MM-DD")) &&
        moment(endDate).isSameOrAfter(moment().format("YYYY-MM-DD"));

      if (isContentActive) {
        acc[cur.memberCode] = { ...cur, startDate, endDate };
      }

      return acc;
    }, {});

    return overriddenShops;
  } catch {
    //Do nothing
  }
}

function processTranslationsContent(input) {
  return Object.keys(input).reduce((accum, curr) => {
    if (curr.startsWith("translations_")) {
      const [, type, language] = curr.split("_");
      if (!accum.translations[type]) accum.translations[type] = {};
      accum.translations[type] = {
        ...accum.translations[type],
        [language]: input[curr],
      };
    } else accum[curr] = input[curr];
    return accum;
  }, {});
}

function* processScheduledContent(component, context, input = {}) {
  let {
    content = {},
    scheduledContent = {},
    defaultInfo = {},
    scheduledInfo = {},
    scheduledContentShopOverrides = {},
    translations,
    extraInfo = {},
  } = input;
  const shopCode = yield select(selectShopCode);
  const isMTACLogin = context === "global";

  const supportedLanguages = yield call(
    getComponentLanguages,
    component,
    context
  );

  const { componentName = "", context: componentContext = "" } = defaultInfo;
  const { context: scheduledContext = "" } = scheduledInfo;
  const isScheduledContentComponent = yield select(
    selectIsScheduledContentComponent(componentName)
  );

  const serviceContentRequest = (component, language) => {
    if (!supportedLanguages.includes(language)) return {};

    return request("get-mol-content", {
      component: `${component}_${language}`.replace("_en", ""),
      context,
      assetId: defaultInfo.assetId,
      shopCode: isMTACLogin ? "ftd" : shopCode,
    }).catch((error) => {
      return error === "NOT_FOUND" ? { data: "" } : "";
    });
  };
  try {
    // if Scheduled Content component
    if (isScheduledContentComponent) {
      if (scheduledContent.body) {
        const { endDate } = JSON.parse(scheduledContent.body);
        const scheduledContentExpired = moment(endDate).isBefore(
          moment().format().split("T")[0]
        );
        scheduledContent.overriddenShops =
          scheduledContentShopOverrides.overriddenShops;

        // clean up if scheduled component is expired
        if (scheduledContentExpired) {
          yield put(
            deleteMiddlewareData({
              params: {
                component: componentName,
                context,
                assetId: defaultInfo.assetId,
                type: "scheduled-content",
              },
            })
          );
          scheduledContent = {};
          translations.scheduledContent = {};
        }

        // At member level, if scheduled content is inherting from global
        if (!isMTACLogin && scheduledContext === "global") {
          scheduledContent = {};
          translations.scheduledContent = {};
        }
      }

      // Check if for member activeTab, content is inherited from Scheduled Global
      if (!isMTACLogin && componentContext === "global") {
        const globalContentCalls = {};
        supportedLanguages.forEach((language) => {
          globalContentCalls[language] = call(
            serviceContentRequest,
            `${componentName}ScheduledGlobal`,
            language
          );
        });

        const globalResponse = yield all(globalContentCalls);

        if (globalResponse) {
          let startDate = "",
            endDate = "";
          try {
            ({ startDate, endDate } = JSON.parse(
              get(globalResponse, `${primaryLanguage}.body`, "")
            ));
          } catch {
            startDate = "";
            endDate = "";
          }

          // check if global scheduled content is active
          const isGlobalScheduledContentActive =
            startDate && endDate
              ? moment(startDate).isSameOrBefore(
                  moment().format().split("T")[0]
                ) &&
                moment(endDate).isSameOrAfter(moment().format().split("T")[0])
              : false;

          if (isGlobalScheduledContentActive) {
            supportedLanguages.forEach((language) => {
              try {
                const globalContent = {
                  ...globalResponse[language],
                  body: JSON.stringify(
                    omit(
                      JSON.parse(get(globalResponse, `${language}.body`, "")),
                      ["startDate", "endDate"]
                    )
                  ),
                };

                if (translations.content[language]) {
                  translations.content[language] = globalContent; //fr,es
                } else content = globalContent; //en
              } catch {
                //
              }
            });
          }
        }
      }
    }

    return {
      content,
      scheduledContent,
      translations,
      defaultInfo,
      scheduledInfo,
      extraInfo,
    };
  } catch (err) {
    //Do nothing
  }
}

function* handleSaveMiddlewareData(action) {
  const {
    resolve,
    reject,
    params: {
      values = "",
      type = "",
      refresh = true,
      forceTranslateLanguages = [],
    },
  } = get(action, "payload", {});

  const {
    component,
    context,
    assetId,
    content: defaultContent,
    pageControls = "",
    scheduledContent = "",
  } = values;

  const isMTACLogin = context === "global";
  const content =
    type === "scheduled-content" ? scheduledContent : defaultContent;

  const shopCode = yield select(selectShopCode);
  const isLandingPage = yield select(selectIsLandingPageComponent(component));
  const isScheduledContentComponent = yield select(
    selectIsScheduledContentComponent(component)
  );

  const serviceRequest = (payload) => request("save-mol-content", payload);

  const contentTransform = yield call(
    handlePageImageUploads,
    component,
    content,
    context,
    assetId
  );

  try {
    if (isLandingPage && pageControls) {
      const { content: oldPageControls } = yield select(selectApiResponse);
      if (pageControls != oldPageControls) {
        let payload = prepareMiddlewarePayload({
          component: "landingPageControls",
          context,
          assetId,
          content: pageControls,
          mimeType: getMimeType(assetId, pageControls),
          shopCode: isMTACLogin ? "ftd" : shopCode,
        });
        yield call(serviceRequest, payload);
        yield put(setApiResponse({ content: pageControls }));

        const parentComponent = yield select(selectParentComponent(component));
        if (parentComponent) {
          const { content: landingPageContent } = yield select(
            selectLandingPageAccordionContent(parentComponent)
          );

          payload = {
            params: {
              values: {
                component: parentComponent,
                context,
                assetId,
                content: landingPageContent,
              },
              type,
              refresh: true,
            },
          };

          yield call(handleSaveMiddlewareData, { payload });
        }
      }
    }

    const finalContent = isScheduledContentComponent
      ? JSON.stringify({
          ...JSON.parse(contentTransform),
          context,
        })
      : contentTransform;

    const payload = prepareMiddlewarePayload({
      component:
        type === "scheduled-content"
          ? isMTACLogin
            ? `${component}ScheduledGlobal`
            : `${component}Scheduled`
          : component,
      context,
      assetId,
      content: finalContent,
      mimeType: getMimeType(assetId, finalContent),
      shopCode: isMTACLogin ? "ftd" : shopCode,
    });

    const supportedLanguages = yield call(
      getComponentLanguages,
      component,
      context
    );

    const translations = yield call(
      generateTranslations,
      component,
      type,
      supportedLanguages,
      finalContent,
      forceTranslateLanguages
    );

    const saveContentCalls = [call(serviceRequest, payload)];

    Object.keys(translations).forEach((lang) => {
      saveContentCalls.push(
        call(serviceRequest, {
          ...payload,
          component: `${payload.component}_${lang}`,
          body: JSON.stringify({
            ...translations[lang],
            context,
          }),
        })
      );
    });

    yield all(saveContentCalls);

    if (refresh)
      yield call(handleFetchMiddlewareData, {
        payload: {
          component,
          context,
          assetId,
        },
      });

    resolve && resolve();
  } catch (error) {
    console.log("error", error);
    yield put(
      setApiResponse({
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

function* generateTranslations(
  component,
  type,
  languages,
  newContent,
  forceTranslateLanguages
) {
  const googleTranslateKey = Environment.get(
    "MOL_GOOGLE_TRANSLATIONS_API_KEY",
    "AIzaSyArTtgcvg6jUQUHhDPAf_zdnzi2OJy_Wiw"
  );
  const translateRequest = async (language, strings) => {
    const placeholders = /(<a\s+(?:(?!href=)[\s\S])*?)#([^#]+)#/gi;
    const reversal = /<span translate="no">(\w+)<\/span>/gi;

    try {
      const queryParams = qs.stringify({
        key: googleTranslateKey,
      });
      const requestBody = JSON.stringify({
        target: language,
        source: primaryLanguage,
        q: strings.map((s) =>
          s.replaceAll(placeholders, '<span translate="no">$1</span>')
        ),
      });
      const res = await fetch(
        `https://translation.googleapis.com/language/translate/v2?${queryParams}`,
        { method: "POST", body: requestBody }
      );
      const response = await res.json();
      const translations = get(response, `data.translations`, []);
      return translations.map((t) =>
        he.decode(t.translatedText.replaceAll(reversal, "#$1#"))
      );
    } catch (err) {
      return {};
    }
  };

  const translatedLanguages = languages.filter(
    (lang) => lang != primaryLanguage
  );
  if (!translatedLanguages.length) return {};

  const isLandingPage = yield select(selectIsLandingPageComponent(component));
  const landingPageContent = yield select(
    selectLandingPageAccordionContent(component)
  );

  const { content, scheduledContent, translations } = isLandingPage
    ? landingPageContent
    : yield select(selectApiResponse);

  const translatableKeys = yield select(selectTranslatableKeys(component));

  let translatedResponse = {};
  translatedLanguages.forEach((lang) => {
    translatedResponse[lang] = translations.content[lang];

    if (type === "scheduled-content" && scheduledContent) {
      translatedResponse[lang] = translations.scheduledContent[lang];
    }
  });

  forceTranslateLanguages.forEach((lang) => (translatedResponse[lang] = ""));

  let mainContentDifferences = [];
  let mainContentNew, mainContentOld;
  try {
    mainContentNew = JSON.parse(newContent);
    mainContentOld = JSON.parse(content);
    mainContentDifferences = generateJSONDiff(mainContentOld, mainContentNew);
  } catch (err) {
    console.log("error:", err);
  }

  for (const index in translatedLanguages) {
    const language = translatedLanguages[index];
    let jsonDiff = mainContentDifferences;

    let currentContent = translatedResponse[language];
    try {
      currentContent = currentContent ? JSON.parse(currentContent) : "";
    } catch {
      currentContent = "";
    }

    if (currentContent) {
      translatedResponse[language] = applyPatch(
        currentContent,
        jsonDiff
      ).newDocument;
    } else {
      jsonDiff = generateJSONDiff({}, mainContentNew);
      translatedResponse[language] = applyPatch({}, jsonDiff).newDocument;
    }

    // extract strings to be translated
    const strings = [];
    for (const obj in jsonDiff) {
      const { op = "", path = "", value = {} } = jsonDiff[obj];

      if (op === "replace") {
        const hashKey = path.split("/").pop();
        const exists = translatableKeys.includes(hashKey);
        exists && strings.push({ path, value });
      } else if (op === "add") {
        const collectTranslatableStrings = (path, value) => {
          if (typeof value === "string") {
            const hashKey = path.split("/").pop();
            const matched = translatableKeys.includes(hashKey);
            matched && strings.push({ path, value });
          } else
            for (const key in value) {
              const entry = value[key];
              if (isObject(entry)) {
                collectTranslatableStrings(`${path}/${key}`, entry);
              } else {
                const matched = translatableKeys.includes(key);
                matched &&
                  strings.push({
                    path: `${path}/${key}`,
                    value: entry,
                  });
              }
            }
        };

        collectTranslatableStrings(path, value);
      }
    }

    if (strings.length) {
      // prepare translated json
      if (translatedResponse[language]) {
        const translatedStrings = yield call(
          translateRequest,
          language,
          strings.map((s) => s.value)
        );
        const patch = strings.map(({ path }, index) => {
          return {
            op: "replace",
            path,
            value: translatedStrings[index],
          };
        });

        translatedResponse[language] = applyPatch(
          translatedResponse[language],
          patch
        ).newDocument;
      }
    }
  }

  return translatedResponse;
}

function* handleImageUpload(params) {
  const getImageContext = (context) => {
    const isMTACLogin = context === "global";
    const isProd = EnvironmentStorage.getName() === "prod";

    return isMTACLogin ? (isProd ? "12-1036AA" : "12-1033ZA") : context;
  };

  const imageUploadRequest = (handle, context, image) => {
    let contentType = "text/plain";
    let fileExt = "";

    if (image.startsWith("data:image")) {
      fileExt = image.substring("data:image/".length, image.indexOf(";base64"));
      contentType = `image/${fileExt}`;
    }

    return request("upload-MOL-image", {
      imageContent: image.split(",")[1],
      imageName: `${handle}_Image.${fileExt}`,
      imageCategory: "website",
      imageSource: "shopify",
      imageContentType: contentType,
      memberCode: getImageContext(context),
    }).then((response) => {
      return uploadImageShopify(context, response);
    });
  };

  const uploadImageShopify = (context, response) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        request("upload-image-to-shopify-cdn", {
          alt: get(response, "imageDetails.ImageName", ""),
          id: get(response, "imageDetails.gid", ""),
          memberCode: getImageContext(context),
        }).then((newResponse) => {
          if (
            newResponse === undefined ||
            !newResponse?.imageDetails?.ShopifyImageURL
          ) {
            // retry until response has shopifyImageURL
            resolve(uploadImageShopify(context, response));
          } else {
            resolve(newResponse);
          }
        });
      }, 2000);
    });
  };

  const { component, context, image } = params;

  if (image.startsWith("data:image")) {
    const result = yield call(imageUploadRequest, component, context, image);
    if (!result || result.imageDetails.ShopifyImageURL.startsWith("data:image"))
      throw "IMAGE_UPLOAD_ERROR";
    return result;
  }

  return image;
}

export function* handleDeleteMiddlewareData(action) {
  const { resolve, reject, params } = get(action, "payload", {});
  const {
    component,
    context,
    assetId,
    type = "",
    selectedMembers = [],
  } = params;
  const isMTACLogin = context === "global";

  const getScheduledComponent = (component) =>
    type === "scheduled-content" && isMTACLogin
      ? `${component}ScheduledGlobal`
      : `${component}Scheduled`;

  const serviceRequest = (component, language) =>
    request(
      type === "revert-to-global"
        ? "delete-mol-overridden-shops"
        : "delete-mol-content",
      {
        component: `${component}_${language}`.replace("_en", ""),
        context,
        assetId,
        shopCode: isMTACLogin ? "ftd" : shopCode,
        ...(type === "revert-to-global" ? { body: selectedMembers } : {}),
      }
    ).catch((error) => {
      return error === "NOT_FOUND" ? { data: "" } : "";
    });

  const isLandingPage = yield select(selectIsLandingPageComponent(component));
  const shopCode = yield select(selectShopCode);
  const supportedLanguages = yield call(
    getComponentLanguages,
    component,
    context
  );

  try {
    let deleteContentCalls;
    if (type === "revert-to-global" && isMTACLogin) {
      deleteContentCalls = supportedLanguages
        .map((lang) => call(serviceRequest, component, lang))
        .concat(
          supportedLanguages.map((lang) =>
            call(serviceRequest, getScheduledComponent(component), lang)
          )
        );
    } else if (type === "scheduled-content") {
      deleteContentCalls = supportedLanguages.map((lang) =>
        call(serviceRequest, getScheduledComponent(component), lang)
      );
    } else {
      deleteContentCalls = supportedLanguages.map((lang) =>
        call(serviceRequest, component, lang)
      );
    }
    yield all(deleteContentCalls);

    if (isLandingPage) {
      yield call(serviceRequest, "landingPageControls", primaryLanguage);
      yield call(handleFetchMiddlewareData, {
        payload: { component: "landingPageControls", context, assetId },
      });
    }

    yield call(handleFetchMiddlewareData, {
      payload: { component, context, assetId },
    });

    resolve && resolve();
  } catch (error) {
    yield put(
      setApiResponse({
        error: "Something went wrong, please try again",
      })
    );
    reject && reject();
  }
}

function* processMiddlewareResponse(response, context) {
  let {
    content = {},
    scheduledContent = {},
    defaultInfo = {},
    extraInfo = {},
    translations = {},
  } = response;

  const isMTACLogin = context === "global";
  const { content: websiteSettings = {} } = yield select(selectWebsiteSettings);
  const { context: actualContext } = defaultInfo;

  try {
    content = get(response, "content.body", "");
    scheduledContent = get(response, "scheduledContent.body", "");
    translations = Object.keys(translations).reduce((final, type) => {
      final[type] = Object.keys(translations[type]).reduce(
        (final, language) => {
          final[language] = get(translations, `${type}.${language}.body`, "");
          return final;
        },
        {}
      );
      return final;
    }, {});
    extraInfo = get(extraInfo, "body", "");

    if (isMTACLogin) {
      const defaultOverriddenShops = get(
        response,
        "content.overriddenShops",
        []
      );
      const scheduledOverriddenShops = get(
        response,
        "scheduledContent.overriddenShops",
        []
      );
      const overriddenShops = processOverriddenShopsResponse(
        defaultOverriddenShops,
        scheduledOverriddenShops
      );
      return {
        content,
        scheduledContent,
        translations,
        actualContext,
        overriddenShops,
      };
    }

    const isMainNavigation = defaultInfo.componentName === "mainNavigation";
    const contentBody = isMainNavigation
      ? addReviewsToNavigation(content, extraInfo)
      : content;

    const scheduledContentBody = isMainNavigation
      ? addReviewsToNavigation(scheduledContent, extraInfo)
      : scheduledContent;

    return {
      actualContext,
      content: replacePlaceHolders(contentBody, websiteSettings, true),
      scheduledContent: replacePlaceHolders(
        scheduledContentBody,
        websiteSettings,
        true
      ),
      translations,
    };
  } catch (error) {
    //do nothing
  }
}

const prepareNavigationContent = (treeData) => {
  let mainNavData = {};
  try {
    mainNavData = JSON.parse(treeData);
  } catch {
    //Do nothing
  }
  const { links = [] } = mainNavData;
  mainNavData.links = links.map((item) => {
    if (item.title === "About Us") {
      const isTitleExists = item.links.find(
        (val) => val.title === "Customer Reviews"
      );
      const filterReviews = item.links.filter(
        (val) => val.title !== "Customer Reviews"
      );

      if (isTitleExists) {
        return {
          ...item,
          links: filterReviews,
        };
      }
    }
    return item;
  });
  return JSON.stringify(mainNavData);
};

const addReviewsToNavigation = (navigationResponse, reviewsResponse) => {
  let shopperReviews = {};
  let mainNavData = {};

  try {
    mainNavData = JSON.parse(navigationResponse);
    shopperReviews = JSON.parse(reviewsResponse);
  } catch {
    //Do nothing
  }

  const { links = [] } = mainNavData;
  const { merchantId = "", token = "" } = shopperReviews;
  const isReviewsEnabled = merchantId !== "" && token !== "";

  if (isEmpty(mainNavData)) return "";

  if (isReviewsEnabled) {
    mainNavData.links = links.map((item) => {
      if (item.title === "About Us") {
        const isTitleExists = item.links.find(
          (val) => val.title === "Customer Reviews"
        );

        if (!isTitleExists) {
          return {
            ...item,
            links: [
              ...item.links,
              {
                url: "/pages/reviews",
                title: "Customer Reviews",
                expanded: true,
              },
            ],
          };
        }
      }
      return item;
    });
  }

  return JSON.stringify(mainNavData);
};

const prepareMiddlewarePayload = (params) => {
  const { content, component, ...rest } = params;
  const updatedContent =
    component === "mainNavigation"
      ? prepareNavigationContent(content)
      : content;

  // handle relative links gracefully
  const body = updatedContent
    .replace(
      new RegExp("https?://[^/]+/ftd-mol-settings#FLORISTWEBSITE#", "mg"),
      "#FLORISTWEBSITE#"
    )
    .replace(
      new RegExp("https?://[^/]+/mercury-online#FLORISTWEBSITE#", "mg"),
      "#FLORISTWEBSITE#"
    );

  return {
    body,
    component,
    ...rest,
  };
};

export function* handleFetchAllCollections(action = {}) {
  const { memberCodes } = get(action, "payload", "");
  const isFloristAdmin = memberCodes?.length === 0;
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (offset, collectionsSize) =>
    request(
      isFloristAdmin ? "get-all-collections-admin" : "get-all-collections",
      { offset, collectionsSize, shopCode }
    );
  try {
    const collections = yield call(serviceRequest, 0, 500);
    const content = processCollectionsResponse(collections);

    yield put(
      setCollectionResponse({
        content,
      })
    );
  } catch (error) {
    yield put(
      setCollectionResponse({
        error: "Something went wrong, please try again",
      })
    );
  }
}

const processCollectionsResponse = (response = {}) => {
  const collections = response.content || [];

  return orderBy(
    collections
      .filter(
        (c) =>
          c.handle !== "addons" &&
          c.handle !== "moltax" &&
          c.status === "active"
      )
      .map((c) => {
        return {
          seoUrl: `/collections/${c.handle.toLowerCase()}`,
          title: c.name,
          status: c.status,
          handle: c.handle,
        };
      }),
    "name",
    "asc"
  );
};

function* handleAppInstallChecks(memberCode) {
  const appStatus = yield select(selectAppInstallationStatus);
  const serviceRequest = (params) => request("ping-middleware", params);
  try {
    const middleware = yield call(serviceRequest, {
      memberCode,
    });
    if (!middleware) throw "NO_APP_INSTALLED";

    if (appStatus === "inprogress") {
      yield put(
        setStoreSync({
          syncStatus: "inprogress",
          appStatus: "completed",
          appInstallUrl: "",
        })
      );
    }
    return "installed";
  } catch {
    if (appStatus !== "inprogress") {
      yield put(
        triggerAppInstall({ params: { memberCode, app: "middleware" } })
      );
    }
  }
}

function* handleCheckoutAppInstallChecks(memberCode) {
  const checkoutAppStatus = yield select(selectCheckoutAppInstallationStatus);
  const serviceRequest = (params) =>
    request("ping-middleware-mol-checkout-app", params);
  try {
    const middleware = yield call(serviceRequest, {
      memberCode,
    });
    if (!middleware) throw "NO_APP_INSTALLED";

    if (checkoutAppStatus === "inprogress") {
      yield put(
        setStoreSync({
          syncStatus: "inprogress",
          checkoutAppStatus: "completed",
          checkoutAppInstallUrl: "",
        })
      );
    }
    return "installed";
  } catch (err) {
    if (checkoutAppStatus !== "inprogress") {
      yield put(triggerAppInstall({ params: { memberCode, app: "checkout" } }));
    }
  }
}

function* handleSyncStore(action) {
  const { params, resolve, reject } = get(action, "payload", {});
  const { memberCode } = params;

  const serviceRequest = (params) => request("sync-store-settings", params);
  const accessInfoRequest = (params) => request("get-store-accessinfo", params);
  const weddingBlogRequest = (params) => request("create-wedding-blog", params);

  try {
    const appInstalled = yield call(handleAppInstallChecks, memberCode);
    let checkOutAppInstalled = "";
    if (appInstalled) {
      checkOutAppInstalled = yield call(
        handleCheckoutAppInstallChecks,
        memberCode
      );
    }
    if (!checkOutAppInstalled) return;
    if (!appInstalled) return;

    const response = yield call(serviceRequest, {
      memberCode,
    });

    const {
      collectionSync = false,
      productSync = false,
      storeSettingSync = false,
      themeSync = false,
    } = response;

    const isCatalogSync = collectionSync && productSync;
    const isStoreSync = themeSync && storeSettingSync;

    if (!isCatalogSync || !isStoreSync) {
      yield put(
        setStoreSync({
          syncStatus: "partial_inprogress",
          isCatalogSync,
          isStoreSync,
        })
      );
      //retrying again in 2secs

      yield delay(2000);
      const {
        collectionSync: retryCollectionSync,
        productSync: retryProductSync,
        themeSync: retryThemeSync,
        storeSettingSync: retryStoreSettingSync,
      } = yield call(serviceRequest, {
        memberCode,
        ...(!isCatalogSync
          ? { isCatalogSync: true }
          : !isStoreSync
          ? { isStoreSync: true }
          : {}),
      });
      const isCatalogReSync = retryCollectionSync && retryProductSync;
      const isStoreReSync = retryThemeSync && retryStoreSettingSync;
      if (isCatalogReSync && isStoreReSync) {
        yield put(
          setStoreSync({
            syncStatus: "completed",
            isCatalogSync: true,
            isStoreSync: true,
          })
        );
      } else {
        yield put(
          setStoreSync({
            syncStatus: "partial_error",
            isCatalogSync: isCatalogReSync,
            isStoreSync: isStoreReSync,
          })
        );
      }
    }

    if (isCatalogSync && isStoreSync) {
      const accessInfo = yield call(accessInfoRequest, { memberCode });
      yield call(weddingBlogRequest, {
        shopId: accessInfo.shopId,
        accessToken: accessInfo.accessToken,
      });
      yield put(
        setStoreSync({
          syncStatus: "completed",
          isCatalogSync: true,
          isStoreSync: true,
        })
      );
    }
    resolve && resolve();
  } catch (res) {
    yield put(
      setStoreSync({
        syncStatus: "error",
      })
    );
    reject && reject();
  }
}

function* handleManualSync(action) {
  const { params, resolve, reject } = get(action, "payload", {});
  const { memberCode, setting } = params;

  const serviceRequest = (params) => request("manual-sync-settings", params);
  const stuckOrderServiceRequest = (params) =>
    request("stuck-order-listing", params);

  try {
    const response =
      setting === "stuckOrderListing"
        ? yield call(stuckOrderServiceRequest, {
            memberCode,
            setting,
          })
        : yield call(serviceRequest, {
            memberCode,
            setting,
          });

    if (response) {
      yield put(
        setStoreSync({
          manualSyncStatus: "completed",
          setting,
          stuckOrderResponse:
            setting === "stuckOrderListing" ? response?.source : "",
        })
      );
    }
    resolve && resolve();
  } catch (res) {
    yield put(
      setStoreSync({
        manualSyncStatus: "error",
        setting,
      })
    );
    reject && reject();
  }
}

function* handleSEMRush(action) {
  const { resolve, reject, params } = get(action, "payload", {});
  const { memberCode } = params;

  const serviceRequest = (params) => request("create-sem-rush-project", params);

  try {
    yield call(serviceRequest, {
      memberCode,
    });
    yield put(
      setSemRushStatus({
        status: "completed",
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setSemRushStatus({
        status: "error",
      })
    );

    reject && reject();
  }
}

function* handleAppInstall(action) {
  const { params } = get(action, "payload", {});
  const { memberCode, app } = params;

  const serviceRequest = (params) => request("install-middleware", params);
  const checkoutAppServiceRequest = (params) =>
    request("install-mol-checkout-app", params);
  try {
    let response = {};
    let checkoutAppResponse = {};
    if (app === "middleware") {
      response = yield call(serviceRequest, {
        memberCode,
      });
    }
    if (app === "checkout") {
      checkoutAppResponse = yield call(checkoutAppServiceRequest, {
        memberCode,
      });
    }

    const { redirectUrl } = response;
    const { redirectUrl: checkoutRedirectUrl } = checkoutAppResponse;

    yield put(
      setStoreSync({
        ...(app === "checkout"
          ? { checkoutAppInstallUrl: checkoutRedirectUrl }
          : { appInstallUrl: redirectUrl }),
      })
    );
  } catch (res) {
    yield put(
      setStoreSync({
        syncStatus: "error",
        ...(app === "checkout"
          ? { checkoutAppStatus: "error" }
          : { appStatus: "error" }),
      })
    );
  }
}

function* handleMemberSearch(action) {
  const { section, value, type = "" } = get(action, "payload", "");
  const shopCode = value.trim().split(/,/)[0];

  if (shopCode.length < 3) return;

  const serviceRequest = (params) =>
    request("get-florist-search", { memberCode: "FTD", ...params });
  try {
    const results = yield call(serviceRequest, { shopCode });
    if (results.length)
      yield put(setMemberResults({ section, value: results, type }));
    else yield put(setMemberSearchError({ section, value: "No Member found" }));
  } catch {
    yield put(
      setMemberSearchError({ section, value: "Failed to fetch Member codes" })
    );
  }
}

function* handlePageImageUploads(component, content, context, assetId) {
  if (assetId === "html") return content;

  let formatted;
  try {
    formatted = JSON.parse(content);
  } catch {
    formatted = {};
  }

  const isLandingPageComponent = yield select(
    selectIsLandingPageComponent(component)
  );

  if (
    component === "homepageBannerMetadata" ||
    component === "brandSelectorConfig"
  ) {
    const definePaths = (param) => {
      return component === "homepageBannerMetadata"
        ? [
            `staticBanner.${param}`,
            `rotatingBanner.0.${param}`,
            `rotatingBanner.1.${param}`,
            `rotatingBanner.2.${param}`,
            `rotatingBanner.3.${param}`,
            `rotatingBanner.4.${param}`,
            `rotatingBanner.5.${param}`,
          ]
        : formatted.brands.map((_item, index) => {
            return `brands.${index}.${param}`;
          });
    };

    const results = yield all(
      definePaths(
        `${component === "brandSelectorConfig" ? "logo" : "image"}`
      ).map((imagePath) => {
        const image = get(formatted, imagePath, "");
        if (image.startsWith("data:image")) {
          return call(handleImageUpload, {
            component,
            context,
            image,
          });
        } else return image;
      })
    );

    results.forEach((imageUrl, index) => {
      const imageURL = imageUrl.imageDetails
        ? imageUrl.imageDetails.ShopifyImageURL
        : imageUrl;
      if (imageUrl.imageDetails) {
        set(
          formatted,
          definePaths("width")[index],
          imageUrl.imageDetails.width
        );
        set(
          formatted,
          definePaths("height")[index],
          imageUrl.imageDetails.height
        );
      }
      if (imageUrl !== "" && component !== "brandSelectorConfig") {
        set(formatted, definePaths("image")[index], imageURL);
      }
      if (component === "brandSelectorConfig") {
        set(formatted, definePaths("logo")[index], imageURL);
      }
    });
  }

  if (isLandingPageComponent) {
    const definePaths = (param) => {
      return component !== "weddingScheduleConsultation"
        ? [
            `banner.staticBanner.${param}`,
            `banner.rotatingBanner.0.${param}`,
            `banner.rotatingBanner.1.${param}`,
            `banner.rotatingBanner.2.${param}`,
            ...(component === "funeralSympathy"
              ? [
                  `collectionSets.0.${param}`,
                  `collectionSets.1.${param}`,
                  `collectionSets.2.${param}`,
                ]
              : component === "wedding"
              ? [
                  `collectionSets.0.${param}`,
                  `collectionSets.1.${param}`,
                  `collectionSets.2.${param}`,
                  `eventsPortfolio.staticBanner.${param}`,
                ]
              : []),
          ]
        : component === "weddingScheduleConsultation"
        ? [
            `banner.staticBanner.${param}`,
            `eventsPortfolio.staticBanner.${param}`,
          ]
        : [];
    };

    const results = yield all(
      definePaths("image").map((imagePath) => {
        const image = get(formatted, imagePath, "");

        if (image.startsWith("data:image")) {
          return call(handleImageUpload, {
            component,
            context,
            image,
          });
        } else return image;
      })
    );

    results.forEach((imageUrl, index) => {
      const imageURL = imageUrl.imageDetails
        ? imageUrl.imageDetails.ShopifyImageURL
        : imageUrl;
      if (imageUrl.imageDetails) {
        set(
          formatted,
          definePaths("width")[index],
          imageUrl.imageDetails.width
        );
        set(
          formatted,
          definePaths("height")[index],
          imageUrl.imageDetails.height
        );
      }
      set(formatted, definePaths("image")[index], imageURL);
    });
  }

  return JSON.stringify(formatted);
}

function* getComponentLanguages(component, context) {
  const shopCode = yield select(selectShopCode);
  const supportTranslation = yield select(
    selectIsTranslatableComponent(component)
  );

  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const memberCode = shopCode === "all" ? memberCodes[0] : shopCode;
  const isMTACLogin = context === "global";

  const { languages: supportedLanguages = [] } = isMTACLogin
    ? { languages: ["en", "es", "fr"] }
    : UserProfileStorage.getShopPreferences(memberCode);
  return supportTranslation ? supportedLanguages.sort() : [primaryLanguage];
}

function getMimeType(type, content) {
  return type === "html"
    ? "text/html"
    : type === "json"
    ? "application/json"
    : type === "image"
    ? content && content.startsWith("data:image") && content.includes("base64")
      ? content.substring("data:".length, content.indexOf(";base64"))
      : "image/jpeg"
    : "text/plain";
}

function* handleSaveLanguages(action = {}) {
  const {
    params: {
      values: { languages: content },
    },
    resolve,
    reject,
  } = action.payload;

  try {
    const { languages: prevContent } = yield select(selectLanguages);
    const shopCode = yield select(selectShopCode);
    const saveLanguagesRequest = (payload) =>
      request("save-language-settings", payload);

    const newLanguages = content
      .filter((val) => val.selected)
      .map((item) => item.code);
    const prevLanguages = prevContent
      .filter((val) => val.selected)
      .map((item) => item.code);

    const response = yield call(saveLanguagesRequest, {
      preferences: [
        {
          id: "languages",
          values: newLanguages,
        },
      ],
      shopCode,
    });

    if (response) {
      const memberCodes = UserProfileStorage.getProfileMemberCodes();

      if (shopCode === "all") {
        memberCodes.forEach((shop) => {
          UserProfileStorage.setShopPreferences(shop, {
            languages: newLanguages,
          });
        });
      } else {
        UserProfileStorage.setShopPreferences(shopCode, {
          languages: newLanguages,
        });
      }
    }

    const languagesAdded = difference(newLanguages, prevLanguages);
    if (languagesAdded.length) {
      const translationRequiredComponents = yield select(
        selectTranslatableComponents
      );
      const serviceContentMetaRequest = (component) =>
        request("get-mol-content-meta", {
          component,
          context: "",
          assetId: "json",
          shopCode,
        }).catch((error) => {
          return error === "NOT_FOUND" ? {} : "";
        });

      for (const component of translationRequiredComponents) {
        const metaDataResponse = yield call(
          serviceContentMetaRequest,
          component
        );
        const { componentName, context: componentContext } = metaDataResponse;
        if (componentContext !== "global") {
          yield call(handleFetchMiddlewareData, {
            payload: {
              component: componentName,
              context: componentContext,
              assetId: "json",
              forceTranslateLanguages: languagesAdded,
            },
          });
        }
      }
    }
    yield put(setLanguages(content));

    resolve && resolve();
  } catch (error) {
    console.log("error:", error);
    reject && reject();
  }
}
/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeEvery(fetchMiddlewareData.type, handleFetchMiddlewareData);
  yield takeLatest(saveMiddlewareData.type, handleSaveMiddlewareData);
  yield takeLatest(deleteMiddlewareData.type, handleDeleteMiddlewareData);
  yield takeLatest(fetchAllCollections.type, handleFetchAllCollections);
  yield takeLatest(createSEMRushProject.type, handleSEMRush);
  yield takeLatest(triggerStoreSync.type, handleSyncStore);
  yield takeLatest(triggerManualSync.type, handleManualSync);
  yield takeLatest(triggerAppInstall.type, handleAppInstall);
  yield takeLatest(setMemberSearchText.type, handleMemberSearch);
  yield takeLatest(
    setTranslationCorrections.type,
    handleTranslationCorrections
  );
  yield takeLatest(saveLanguages.type, handleSaveLanguages);
}

export default watchSaga;
