import * as Yup from "yup";

export const TerminalSettingsKeys = ["terminal_settings"];

export const apiKeyMappings = {
  serialNumber: "serial_number",
  macAddress: "mac_address",
  ipAddress: "ip_address",
  terminalName: "name",
};

export const IPV4RegEx = new RegExp(
  "^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$"
);
export const IPV6RegEx = new RegExp(
  "^((([0-9a-fA-F]){1,4}):){7}([0-9a-fA-F]){1,4}$"
);

export const getInitialValues = (initialSettings, macAddress) => {
  return {
    macAddress: initialSettings?.mac_address || macAddress,
    serialNumber: initialSettings?.serial_number || "",
    ipAddress: initialSettings?.ip_address || "",
    terminalName: initialSettings?.name || "",
  };
};

Yup.addMethod(Yup.string, "isValidIPAddress", function (errorMessage) {
  return this.test(`is-valid-ip-address`, errorMessage, function (value = "") {
    if (!value) return true;

    const ipAddress = value.trim();

    const isValidIP = IPV4RegEx.test(ipAddress) || IPV6RegEx.test(ipAddress);

    return isValidIP;
  });
});

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    serialNumber: Yup.string()
      .required(Localise(messages, "Please enter Serial Number"))
      .max(30, Localise(messages, "Invalid Serial Number"))
      .matches(/^\d*$/, Localise(messages, "Invalid Serial Number")),
    ipAddress: Yup.string()
      .required(Localise(messages, "Please enter IP Address"))
      .isValidIPAddress(Localise(messages, "Please enter valid IP Address")),
  });
};
