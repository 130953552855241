/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext, useRef } from "react";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  ScrollView,
} from "react-native";
import { Divider, Text } from "react-native-elements";
import { FieldArray, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import tw from "tailwind-rn";
import moment from "moment";
import SplitScreen from "components/containers/split-screen";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import SubHeader from "components/containers/header/sub-header";
import { PrintIframeRenderer } from "components/elements";
import OrderSummary from "components/views/drawer/create-order/order-summary";
//Adding a separator line to differentiate libraries to custom components
import {
  Form,
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
  SubmitButton,
} from "components/elements/forms";
import { fonts, backgroundColors, colors, theme } from "styles/theme";
import { Accordion } from "components/elements";
import { validationSchema, initialVals, basicPaymentInfo } from "./config";
import RecipientInfo from "components/views/drawer/create-order/recipient-info";
import ProductInfo from "components/views/drawer/create-order/product-info";
import ProductSelection from "components/views/drawer/create-order/product-selection";
import SubscriptionDetails from "components/views/drawer/create-subscription/subscription";
import PaymentDetails from "components/views/drawer/create-order/payment-details";
import { request } from "library/utils/request";
import {
  createOrderObject,
  getEligibleDeliveryMethods,
} from "components/views/drawer/create-order/helper";
import { getCreateSubscriptionReqObj, isModifyCheck } from "./helper";
import { keyMap } from "components/views/drawer/order-details/helper";
import { ToasterHandler, CustomModal } from "components/elements";
import {
  getPrice,
  getDiscount,
  processQuickPicks,
} from "library/utils/createOrder";
import AbbreviationCodes from "components/views/drawer/create-order/abbreviation-codes";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchAddons,
  setLoading,
  resetCurrentProduct,
  setCreateSubscriptionShopCode,
} from "library/sagas/ongoing/global-data/slice";
import { createTransaction } from "library/sagas/session/slice";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";
import {
  getTokenizePaymentPayload,
  sendCommandToPAXTerminalV1,
  TransactionTypes,
  getRandomInt,
  getCancelTerminalTxnMessage,
} from "library/utils/payment-options";
import { processShopSettingsResponse } from "library/utils/shopSettings";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
//import { setCurrentCustomer } from "library/sagas/views/home/drawer/customer-directory/slice";
import {
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";

import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";
import { selectCalculatingTax } from "library/sagas/views/home/drawer/create-order/selector";

const ProductDetails = React.memo((props) => {
  return (
    <View>
      <ProductInfo {...props} isFromSubscription={true} />
    </View>
  );
});

const RecipientDetails = React.memo((props) => {
  return (
    <View testID="subscription_recipient_info">
      <RecipientInfo
        {...props}
        canUpdateDeliveryMethod={false}
        isSubscriptionPage={true}
      />
    </View>
  );
});

const UIConfig = {
  subscriptionDetails: {
    title: "Subscription",
    displayStatus: "open",
    component: SubscriptionDetails,
    overrideZindex: 9,
    accordionWrap: true,
  },
  productDetails: {
    title: "Product Details",
    displayStatus: "open",
    component: ProductDetails,
    overrideZindex: 8,
    accordionWrap: true,
  },
  recipient: {
    title: "Recipient",
    displayStatus: "open",
    component: RecipientDetails,
    overrideZindex: 7,
    accordionWrap: true,
    groupName: "recipientInfo",
  },
};

const CustomerProfileInfo = React.memo(
  ({ isSmallScreen, memberCodes, values, permissions, setTaxes }) => {
    const { setFieldValue } = useFormikContext();
    const dispatch = useDispatch();
    const { messages, Localise } = React.useContext(I18NContext);
    const shopNames = UserProfileStorage.getAllShopNames();
    const path = "customerInfo";
    const [selectShopQuery, setSelectShopQuery] = useStateIfMounted("");

    const sendingOriginData = memberCodes.map((each) => {
      const shopName = shopNames[each] || "";
      return { code: each, shopName: shopName };
    });

    const { billingInformation = {} } = values.customerInfo;

    useEffect(() => {
      if (!isEmpty(billingInformation)) {
        setFieldValue(
          "paymentDetails.paymentMethod.0.billingInformation",
          billingInformation
        );
      }
    }, [billingInformation]);

    useEffect(() => {
      if (values.customerInfo.phone) {
        setFieldValue(
          "customerInfo.phone",
          phoneNumberFormatter(values.customerInfo.phone)
        );
      }
    }, [values.customerInfo.phone]);
    return (
      <View>
        {memberCodes.length > 1 && (
          <View style={[tw("flex pb-2 w-full"), { zIndex: 2 }]}>
            <Text
              style={[
                fonts.heading4,
                { fontSize: 11, paddingHorizontal: 5, paddingBottom: 5 },
              ]}
            >
              {Localise(messages, "Sender Origin")}
            </Text>
            <FormFieldAutoComplete
              autoCapitalize="none"
              name="sendingMemberField"
              placeholder={Localise(messages, "Select Shop")}
              data={sendingOriginData.filter((each) => {
                const { code = "", shopName = "" } = each;
                return (
                  code.toLowerCase().includes(selectShopQuery.toLowerCase()) ||
                  shopName.toLowerCase().includes(selectShopQuery.toLowerCase())
                );
              })}
              onChangeText={(text) => {
                setSelectShopQuery(text);
              }}
              outerContainerStyle={{
                width: "100%",
                zIndex: 1,
              }}
              showOnFocus={true}
              clearTextOnBackTab={true}
              isMultiSelect={false}
              listDisplayValues={["code", "shopName"]}
              popperPlacement={"bottom"}
              onSelect={(selectedValue) => {
                if (!selectedValue) return;

                const { code, shopName } = selectedValue;
                dispatch(setCreateSubscriptionShopCode(code));
                setFieldValue("sendingMember", code);
                setFieldValue("sendingMemberField", `${code} ${shopName}`);

                const selectedShopPermissions = get(permissions, code, {});

                const isPaymentEntitlementEnabled = selectedShopPermissions[
                  Entitlements.CREATE_ORDER
                ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

                setFieldValue("savePayment", true);
                setFieldValue(
                  "paymentDetails",
                  basicPaymentInfo(isPaymentEntitlementEnabled)
                );
                setFieldValue("orderItems.0.applyTaxOnDF", false);
                setFieldValue("orderItems.0.applyTaxOnRDF", false);
                setFieldValue(`orderItems.0.taxInfo`, {
                  taxAmount: 0,
                  taxRate: 0.0,
                });
                setFieldValue(
                  "paymentDetails.paymentMethod.0.enablePayment",
                  isPaymentEntitlementEnabled
                );
                setSelectShopQuery("");
              }}
              onClear={() => {
                setFieldValue("sendingMember", "");
                setFieldValue("sendingMemberField", "");
              }}
            />
          </View>
        )}
        <React.Fragment>
          <Accordion
            title={`${Localise(messages, "Customer Info")}`}
            defaultOpen={true}
            handleOnPress={() => {}}
            contentStyle={{ paddingTop: 20 }}
            separatorAlways={true}
            labelStyle={{
              marginVertical: 5,
              backgroundColor: backgroundColors.secondary,
              paddingHorizontal: 0,
              marginTop: 10,
            }}
            titleStyle={{
              ...fonts.heading4,
              fontWeight: "600",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: -5,
                marginTop: isSmallScreen ? 5 : 1,
                zIndex: 5,
                width: "100%",
              }}
            >
              <FormFieldPicker
                placeholder={{ label: "Select Customer Type" }}
                containerStyle={{ width: isSmallScreen ? "100%" : "40%" }}
                data={[
                  { label: "Individual", value: "Individual" },
                  { label: "Business", value: "Business" },
                ]}
                name="customerType"
                label={Localise(messages, "Customer Type")}
                path={path}
                testID="customer_type"
                accessibilityLabel="customer_type"
                disabled={true}
              />

              {values?.customerInfo?.customerType !== "Business" ? (
                <>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password" //hack for autoComplete off
                    name="firstName"
                    label={Localise(messages, "Customer First Name")}
                    path={path}
                    containerStyle={{ width: isSmallScreen ? "100%" : "30%" }}
                    placeholder={Localise(
                      messages,
                      "Enter Customer First Name"
                    )}
                    testID="customer_firstName"
                    accessibilityLabel="customer_firstName"
                    editable={false}
                    grayedOutOnDisable={true}
                  />
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password" //hack for autoComplete off
                    name="lastName"
                    label={Localise(messages, "Customer Last Name")}
                    path={path}
                    containerStyle={{ width: isSmallScreen ? "100%" : "30%" }}
                    placeholder={Localise(messages, "Enter Customer Last Name")}
                    testID="customer_lastName"
                    accessibilityLabel="customer_lastName"
                    editable={false}
                    grayedOutOnDisable={true}
                  />
                </>
              ) : (
                <FormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoComplete="new-password" //hack for autoComplete off
                  name="businessName"
                  label={Localise(messages, "Customer Business Name")}
                  path={path}
                  containerStyle={{ width: isSmallScreen ? "100%" : "60%" }}
                  placeholder={Localise(
                    messages,
                    "Enter Customer Business Name"
                  )}
                  testID="customer_businessName"
                  accessibilityLabel="customer_businessName"
                  editable={false}
                  grayedOutOnDisable={true}
                />
              )}

              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password" //hack for autoComplete off
                name="phone"
                fsClass="fs-exclude"
                label={Localise(messages, "Customer Phone")}
                path={path}
                maxLength={15}
                containerStyle={{ width: isSmallScreen ? "100%" : "50%" }}
                placeholder={"123-456-7890"}
                editable={false}
                grayedOutOnDisable={true}
                transformText={(text = "") => {
                  return phoneNumberFormatter(text);
                }}
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password" //hack for autoComplete off
                name="email"
                fsClass="fs-exclude"
                label={Localise(messages, "Customer Email")}
                path={path}
                containerStyle={{ width: isSmallScreen ? "100%" : "50%" }}
                placeholder={Localise(messages, "Enter Customer Email")}
                editable={false}
                grayedOutOnDisable={true}
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password" //hack for autoComplete off
                name="customerNotes"
                label={Localise(messages, "Customer Notes")}
                path={path}
                containerStyle={{ width: "100%" }}
                placeholder={Localise(messages, "Enter Customer Notes")}
                multiline={true}
                numberOfLines={2}
                maxNumberOfLines={5}
                editable={false}
                grayedOutOnDisable={true}
                spellCheck={true}
              />
            </View>
          </Accordion>
        </React.Fragment>
      </View>
    );
  }
);

const OrderItem = ({
  index,
  isSmallScreen,
  orderInfo,
  setOrderPositions,
  orderPositions,
  values,
  setSideCarOpen,
  setScrollEnabled,
  abbreviationCodes,
  setValues,
  addons,
  quickProducts,
  isAutoFill,
  setProductInfo,
  errors,
  setErrors,
  sideCarKey,
  memberCodes,
  sendingMemberCode,
  setValidationOnChange,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const selectedShopPermissions = get(permissions, sendingMemberCode, {});
  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

  const eligibleDeliveryMethods = getEligibleDeliveryMethods(
    permissions,
    sendingMemberCode
  );

  return (
    <>
      <View
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          orderPositions[index] = layout.y;
          setOrderPositions([...orderPositions]);
        }}
      >
        <View>
          {Object.keys(UIConfig).map((key) => {
            const {
              title,
              displayStatus = "close",
              component: Content,
              overrideZindex = 0,
              accordionWrap,
              groupName = "",
            } = UIConfig[key];

            const contentComponent = (
              <Content
                isSmallScreen={isSmallScreen}
                key={key}
                index={index}
                setSideCarOpen={setSideCarOpen}
                orderInfo={orderInfo}
                setScrollEnabled={setScrollEnabled}
                setOrderPositions={setOrderPositions}
                abbreviationCodes={abbreviationCodes}
                values={values}
                setValues={setValues}
                addons={addons}
                quickProducts={quickProducts}
                isAutoFill={isAutoFill}
                setProductInfo={setProductInfo}
                sideCarKey={sideCarKey}
                messages={messages}
                Localise={Localise}
                memberCodes={memberCodes}
                eligibleDeliveryMethods={eligibleDeliveryMethods}
                sendingMemberCode={sendingMemberCode}
                isPaymentEntitlementEnabled={isPaymentEntitlementEnabled}
                setValidationOnChange={setValidationOnChange}
              />
            );

            if (accordionWrap) {
              return (
                <Accordion
                  key={key}
                  title={Localise(messages, title)}
                  defaultOpen={displayStatus === "open"}
                  errors={errors}
                  path={
                    groupName !== "" && errors
                      ? `orderItems.${index}.${groupName}`
                      : ""
                  }
                  setErrors={setErrors}
                  handleOnPress={() => {}}
                  overrideZindex={overrideZindex}
                  contentStyle={{ paddingTop: 20 }}
                  separatorAlways={true}
                  labelStyle={{
                    marginVertical: 5,
                    backgroundColor: backgroundColors.secondary,
                    paddingHorizontal: 0,
                    marginTop: 10,
                  }}
                  titleStyle={{
                    ...fonts.heading4,
                    fontWeight: "600",
                  }}
                >
                  {contentComponent}
                </Accordion>
              );
            }

            return (
              <View
                style={{
                  zIndex: overrideZindex ? overrideZindex : -1,
                }}
                key={key}
              >
                {contentComponent}
              </View>
            );
          })}
          <Divider style={{ marginVertical: 20 }} />
        </View>
      </View>
    </>
  );
};

const CreateSubscriptionScreen = ({
  setSideCarOpen,
  isSmallScreen,
  selectedProduct,
  setProductInfo,
  productInfo,
  setFormValues,
  setSelectedProduct,
  scrollViewRef,
  isFormSubmitting,
  setFormSubmitting,
  memberCodes,
  props,
  setShowSideCar,
  sideCarKey,
  subTotals,
  setSubTotals,
  discounts,
  setDiscounts,
  permissions,
  sendingMemberCode,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { setParams } = navigation;

  const buttonRef = useRef(null);
  const formRef = useRef();
  const sendingMemberRef = useRef("");
  const terminalTxnCancellingRef = useRef(false);
  const showPaymentRef = useRef(false);

  const [orderPositions, setOrderPositions] = useStateIfMounted([]);
  const [scrollEnabled, setScrollEnabled] = useStateIfMounted(false);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [abbreviationCodes, setAbbreviationCodes] = useStateIfMounted([]);
  const [quickProducts, setQuickProducts] = useStateIfMounted([]);
  const { isDesktop, isTablet, isMobile } = useContext(DeviceContext);

  const { messages, Localise } = React.useContext(I18NContext);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useStateIfMounted("");
  const [isPaymentNeeded, setIsPaymentNeeded] = useStateIfMounted(false);
  const [paymentOptions, setPaymentOptions] = useStateIfMounted([]);
  const { firstName: operator = "" } = UserProfileStorage.getUser();
  const calculatingTax = useSelector(selectCalculatingTax);

  const { params: { customerDetails = {}, subscriptionData = {} } = {} } =
    props.route;
  const [preloadCustomerDetails, setPreloadCustomerDetails] = useStateIfMounted(
    {}
  );
  const [subscriptionDetails, setSubscriptionDetails] = useStateIfMounted({});
  const {
    global,
    global: { addons = [], addressVerificationInfo = {}, isAutoFill, shopCode },
    fetchAddons,
    session: { sessionId, memberId } = {},
  } = props;

  const shopNames = UserProfileStorage.getAllShopNames();

  const selectedShopPermissions = get(permissions, sendingMemberCode, {});
  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

  // const enablePayment = isPaymentEntitlementEnabled;

  const orderChannel =
    Platform.OS === "web" ? (isTablet ? "Tablet" : "Web") : "Mobile";

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  useEffect(() => {
    request("get-card-messages", {}).then((res = []) => {
      setAbbreviationCodes(res);
    });
    request("get-quickPicks").then((res) => {
      const products = processQuickPicks(res);
      setQuickProducts(products);
    });
    !addons.length && fetchAddons();

    if (!isEmpty(customerDetails)) {
      setPreloadCustomerDetails(customerDetails);
      setParams({ customerDetails: [] });
    }

    if (!isEmpty(subscriptionData)) {
      setSubscriptionDetails(subscriptionData);
      setParams({ subscriptionData: [] });
    }
  }, []);

  const navigateToCustomerDirectory = (response) => {
    // TO DO - Need to open side car also to see subscription which is created recently for selected customer
    // if (response?.subscription?.customerId) {
    //   dispatch(
    //     setCurrentCustomer({
    //       type: "customerId",
    //       value: response.subscription.customerId,
    //     })
    //   );
    // }
    // Commented above code in order to stop unnecessary calls as opening a particular customer is also not happening any how

    // Navigating users to customer directory screen
    navigation.dispatch(
      CommonActions.navigate({
        name: "customer-directory",
      })
    );
  };

  const createSubscriptionOrder = (
    createOrderReqObj,
    formikBag,
    creditCardId
  ) => {
    const createSubscriptionReqObj = getCreateSubscriptionReqObj(
      createOrderReqObj,
      creditCardId,
      subscriptionDetails
    );
    const isModifyFlow = subscriptionDetails?.isModify;

    request(isModifyFlow ? "modify-subscription" : "create-subscription", {
      ...createSubscriptionReqObj,
    })
      .then((response) => {
        if (response) {
          setValidationOnChange(false);
          setFormSubmitting(false);
          formikBag.setSubmitting(false);
          setScrollEnabled(true);
          setOrderPositions([0]);

          //Create a activity
          const {
            subscription: { memberCode, orderTemplate, id },
          } = response;

          const { isBusinessProfile, businessName, firstName, lastName } =
            createOrderReqObj?.customerInfo || {};

          if (sessionId && memberId === memberCode) {
            const amountChargedToCustomer =
              orderTemplate.orderAmounts?.find(
                (obj) => obj.name === "amountChargedToCustomer"
              )?.value || 0;
            const paymentInfo = get(
              orderTemplate,
              "paymentDetails.paymentMethod.0",
              ""
            );
            const {
              paymentMethodType: paymentType,
              paymentMethodDetails: {
                tenderedAmount = "",
                changeDueAmount = "",
              },
            } = paymentInfo;

            const isCashOrCheck = paymentType === "CASH_OR_CHECK";
            let userNote = {
              payment: keyMap[paymentType],
              customerName: isBusinessProfile
                ? businessName
                : `${firstName} ${lastName}`,
            };
            if (isCashOrCheck) {
              userNote = {
                ...userNote,
                tenderedAmount,
                changeDueAmount,
              };
            }
            props.createTransaction({
              params: {
                amount: amountChargedToCustomer,
                type: isCashOrCheck ? "Cash" : "Non-Cash",
                memberId: memberCode,
                macAddress: document.getElementById("macAddress").value,
                user: operator,
                userNote: JSON.stringify(userNote),
                orderId: id,
              },
              resolve: (resp) => {
                // const transactionContent = getSessionReceiptContent(resp);
                // ipcRenderer.send("printOrderInfo", 1, [transactionContent]);
                console.log(
                  "Success - ",
                  `Successfully created ${paymentType} ransaction`
                );
              },
              reject: () => {
                console.log("Error - ", "Failed to create transaction");
              },
            });
          }

          ToasterHandler(
            "nice",
            Localise(
              messages,
              isModifyFlow
                ? "Your subscription was modified"
                : "Your subscription was submitted"
            )
          );

          navigateToCustomerDirectory(response);
        } else {
          showError("No Response", formikBag);
        }
      })
      .catch((error) => {
        showError("", formikBag);
      });
  };

  const saveCustomerCreditCard = (
    reqPayload,
    successCallback,
    errorCallback
  ) => {
    request("save-customer-credit-card", reqPayload)
      .then((saveResp) => {
        if (saveResp.success) {
          successCallback(saveResp.id);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  const showError = (error, formikBag, isPaymentError = false) => {
    let errorMessage =
      "Please check your network connection and submit the order again.";

    if (isPaymentError) {
      setCurrentPaymentMethod("");
      errorMessage =
        error ||
        "Please provide an email address to proceed with this subscription order.";
    }

    setFormSubmitting(false);
    formikBag.setSubmitting(false);
    ToasterHandler("uh oh", Localise(messages, errorMessage));
  };

  const paymentError = (error, formikBag) => {
    showError(
      error === "CREDIT_CARD_PROCESS_FAILURE"
        ? Localise(
            messages,
            "There was a credit card processing error. Contact us at (800) 788-9000 to resolve the issue."
          )
        : "",
      formikBag,
      true
    );
  };

  const validateAndSubmitOrder = (values, formikBag) => {
    if (formikBag.isSubmitting) return;
    setFormSubmitting(true);

    const { savePayment, paymentMethodType = "" } = get(
      values,
      "paymentDetails.paymentMethod.0",
      {}
    );

    const memberTimezone = UserProfileStorage.getShopTimeZone(
      values.sendingMember || memberCodes[0]
    );

    const floristTechnology = UserProfileStorage.getFloristTechnology(
      values.sendingMember || memberCodes[0]
    );
    values.customerInfo = {
      ...values.customerInfo,
      phone: formatPhoneForPayload(values.customerInfo.phone),
    };
    values.orderItems[0].recipientInfo = {
      ...values.orderItems[0].recipientInfo,
      phone: formatPhoneForPayload(values.orderItems[0].recipientInfo.phone),
    };

    const createOrderReqObj = createOrderObject({
      memberCodes,
      orderValues: values,
      addressVerificationInfo,
      memberTimezone,
      floristTechnology,
      allowMultipleProducts: false,
      isSubscriptionPage: true,
    });

    const successCallback = (creditCardId = "") => {
      if (savePayment || paymentMethodType === "SAVED_CARD") {
        set(
          createOrderReqObj,
          "paymentDetails.paymentMethod.0.paymentMethodDetails.creditCardId",
          creditCardId
        );
      }

      setCurrentPaymentMethod("");

      createSubscriptionOrder(createOrderReqObj, formikBag, creditCardId);
    };

    const errorCallback = (error, isFormikError) => {
      if (isFormikError) {
        formikBag.setErrors(error);
        setCurrentPaymentMethod("");
        setFormSubmitting(false);
        formikBag.setSubmitting(false);
      } else {
        paymentError(error, formikBag);
      }
    };

    // Generating merchant reference id like below as Heartland is accepting merchantRefId's in this pattern [0-9A-Za-z_-]+
    // If we are sending customerId as merchant reference id from Terminal then Heartland won't accept it as customerId is alpha numeric
    const merchantReferenceId = `${moment()}${getRandomInt(0, 9)}`;

    if (
      paymentMethodType === "CREDIT_CARD" ||
      paymentMethodType === "SAVED_CARD"
    ) {
      setCurrentPaymentMethod(paymentMethodType);

      TokenizeAndSavePayment(
        values,
        merchantReferenceId,
        sendingMemberCode,
        successCallback,
        errorCallback
      );
    } else if (
      paymentMethodType === "PAYMENT_TERMINAL" &&
      showPaymentRef.current
    ) {
      setCurrentPaymentMethod(paymentMethodType);

      const orderTotal =
        createOrderReqObj?.orderAmounts?.find(
          (obj) => obj.name === "amountChargedToCustomer"
        )?.value || 0;

      sendCommandToPAXTerminalV1({
        transactionType: TransactionTypes.TOKENIZATION,
        sendingMember: values.sendingMember,
        merchantReferenceId,
        amount: orderTotal,
        requestMultiUseToken: true,
        callback: ({ terminalResponse, paymentMethodDetails = {} }) => {
          setCurrentPaymentMethod("");

          if (terminalResponse.ResponseCode === "00") {
            const billingAddress = get(
              createOrderReqObj,
              "paymentDetails.paymentMethod.0.billingInformation",
              {}
            );

            const { addressLine1, suite, city, state, country, zip } =
              billingAddress;

            const { customerId, storeOrigin } = values?.customerInfo;

            const {
              nameOnCard,
              creditCardType,
              creditCardNumber,
              creditCardExpireMonth,
              creditCardExpireYear,
              tokenId,
            } = paymentMethodDetails;

            const requestPayload = {
              customerId,
              billingAddress: {
                addressLine1,
                addressLine2: suite || "",
                city,
                state,
                country,
                zipcode: zip,
              },
              storeOrigin,
              nameOnCard,
              cardType: creditCardType,
              expirationMonth: creditCardExpireMonth,
              expirationYear: creditCardExpireYear,
              lastFourDigits: creditCardNumber,
              merchantReferenceId,
              tokenId,
            };

            saveCustomerCreditCard(
              requestPayload,
              successCallback,
              errorCallback
            );
          } else {
            showError(terminalResponse.ResponseText, formikBag, true);
          }
        },
        cancelBtnHandler: ({ txnStatus }) => {
          setCurrentPaymentMethod(txnStatus);
        },
      });
    } else {
      createSubscriptionOrder(createOrderReqObj, formikBag);
    }
  };

  const TokenizeAndSavePayment = (
    values,
    merchantReferenceId,
    partnerCode,
    successCallback,
    errorCallback
  ) => {
    const tokenizePayload = getTokenizePaymentPayload(
      values,
      merchantReferenceId,
      partnerCode
    );

    request("tokenize-credit-card", {
      requestPayload: tokenizePayload,
    })
      .then((tokenizeResp = {}) => {
        if (!tokenizeResp.tokenId || tokenizeResp.status === "declined") {
          const newErrors = {};
          set(
            newErrors,
            "paymentDetails.paymentMethod.0.paymentMethodDetails.cardNumber",
            tokenizeResp.errorMessages[0]
          );
          errorCallback(newErrors, true);
          return;
        } else {
          //call save call
          const paymentInfo = get(values, "paymentDetails.paymentMethod.0", {});
          const {
            billTo: { address1, city, country, state, zipCode },
            creditCard: { cardType, expirationMonth, expirationYear },
          } = tokenizePayload.paymentMethod;

          const { name, cardNumber } = paymentInfo.paymentMethodDetails;
          const lastFourDigits = cardNumber.substr(cardNumber.length - 4);

          const payload = {
            customerId: values.customerInfo.customerId,
            billingAddress: {
              addressLine1: address1,
              addressLine2: "",
              city,
              country,
              state,
              zipcode: zipCode,
            },
            storeOrigin: values.customerInfo.storeOrigin,
            nameOnCard: name,
            cardType: cardType?.toUpperCase(),
            expirationMonth,
            expirationYear:
              expirationYear.length === 2
                ? "20" + expirationYear
                : expirationYear,
            lastFourDigits,
            merchantReferenceId,
            tokenId: tokenizeResp.tokenId,
          };

          saveCustomerCreditCard(payload, successCallback, errorCallback);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  const cancelTerminalTxn = () => {
    if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = true;

    setCurrentPaymentMethod("");

    sendCommandToPAXTerminalV1({
      transactionType: TransactionTypes.CANCEL,
      sendingMember: sendingMemberRef ? sendingMemberRef.current : "",
      callback: ({ terminalResponse }) => {
        console.log(
          "Cancel Txn Response in create/modify subscription :>> ",
          terminalResponse
        );

        setFormSubmitting(false);
        if (formRef) formRef.current.setSubmitting(false);

        ToasterHandler(
          "uh oh",
          Localise(
            messages,
            getCancelTerminalTxnMessage(terminalResponse?.ResponseText)
          )
        );

        if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = false;
      },
    });
  };

  const waitMessage = terminalTxnCancellingRef.current
    ? "Canceling transaction."
    : subscriptionDetails?.isModify
    ? "We are saving your edits. Just a moment."
    : "Creating your subscription. Just a moment.";

  return (
    <View
      testID="create-subscription-screen"
      style={isSmallScreen ? { paddingBottom: 100 } : { paddingBottom: 5 }}
    >
      <View
        style={[
          tw("pb-3 justify-between flex-row"),
          isMobile ? tw("pt-5") : tw("pt-4"),
        ]}
      >
        <Text
          style={{
            ...fonts.heading1,
            ...(isTablet && { fontSize: 16, fontWeight: "400" }),
          }}
        >
          {Localise(
            messages,
            `${
              subscriptionDetails?.isModify ? "Modify" : "Create"
            } Subscription`
          )}
        </Text>
      </View>
      {!isMobile && (
        <View
          style={{
            borderBottomColor: colors.light,
            borderBottomWidth: 1,
            marginBottom: 15,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.4,
            shadowRadius: 7,
          }}
        />
      )}

      {currentPaymentMethod.length > 0 && (
        <CustomModal
          modalVisible={currentPaymentMethod.length > 0}
          modalContent={{
            content: (
              <View style={[tw("items-center")]}>
                <View style={tw("flex flex-row")}>
                  <ActivityIndicator
                    style={{ marginLeft: 5, marginTop: -5 }}
                    color={colors.activityIndicator}
                  />
                  <Text style={{ marginLeft: 10 }}>
                    {Localise(messages, "Processing Payment")}
                  </Text>
                </View>
                {currentPaymentMethod === "PAYMENT_TERMINAL" && (
                  <View style={{ marginTop: 16 }}>
                    <Text>
                      {Localise(
                        messages,
                        "Cancel transaction from Terminal using [X] key or click Cancel button below."
                      )}
                    </Text>
                  </View>
                )}
              </View>
            ),
            ...(currentPaymentMethod === "PAYMENT_TERMINAL" && {
              buttons: [
                {
                  type: "primary",
                  title: Localise(messages, "Cancel"),
                },
              ],
            }),
          }}
          primaryhandler={cancelTerminalTxn}
          contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
        ></CustomModal>
      )}

      {isFormSubmitting && currentPaymentMethod.length === 0 && (
        <CustomModal
          modalVisible={isFormSubmitting}
          modalContent={{
            content: (
              <View style={[tw("items-center")]}>
                <View style={tw("flex flex-row")}>
                  <ActivityIndicator
                    style={{ marginLeft: 5, marginTop: -5 }}
                    color={colors.activityIndicator}
                  />
                  <Text style={{ marginLeft: 10 }}>
                    {`${Localise(messages, waitMessage)}`}
                  </Text>
                </View>
              </View>
            ),
          }}
          contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          }
        />
      )}

      <Form
        innerRef={formRef}
        initialValues={initialVals({
          memberCodes,
          shopCode,
          orderChannel,
          shopNames,
          enablePayment: isPaymentEntitlementEnabled,
          customerDetails: preloadCustomerDetails,
          subscriptionDetails,
          global,
        })}
        onSubmit={(values, formikBag) => {
          validateAndSubmitOrder(values, formikBag);
        }}
        validationSchema={validationSchema(messages, Localise)}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={({
          errors,
          initialValues,
          resetForm,
          setValues,
          status,
          submitCount,
          values,
          setErrors,
          setFieldValue,
        }) => {
          const { orderItems, sendingMember, paymentDetails = {} } = values;

          const paymentMethodType = get(
            paymentDetails,
            "paymentMethod.0.paymentMethodType",
            ""
          );

          useEffect(() => {
            if (sendingMember && isMHQNonCoreMember(sendingMember)) {
              // const isElectronApp =
              //   Platform.OS === "web" &&
              //   document.getElementById("isElectronApp").value === "true";
              // if (isElectronApp && !sessionId) {
              //   setPaymentOptions([]);
              //   return;
              // }
              request("get-shop-settings", {
                ids: [
                  "credit_card",
                  "house_account_invoice",
                  "delivery_fee",
                  "tax_delivery_fee",
                  "retail_delivery_fee",
                  "tax_retail_delivery_fee",
                  "relay_fee",
                  "payment_terminal",
                  "local_order_tax_settings",
                  "wired_order_tax_settings",
                  "tax_on_local_delivery_fee",
                  "tax_on_outside_local_delivery_fee",
                  "tax_on_service_fee",
                  "tax_on_relay_fee",
                ],
                memberCode: sendingMember,
              })
                .then(async (res) => {
                  if (res && res.preferences && res.preferences.length) {
                    let result = processShopSettingsResponse(res);

                    setFieldValue(
                      "orderItems.0.applyTaxOnDF",
                      result["tax_delivery_fee"].toLowerCase() === "true"
                    );

                    setFieldValue(
                      "orderItems.0.applyTaxOnRDF",
                      result["tax_retail_delivery_fee"]?.toLowerCase() ===
                        "true"
                    );

                    let paymentOptions = [];

                    if (isElectronApp) {
                      const paymentTerminalEnabledForMembercode =
                        result["payment_terminal"] === "true";

                      const enablePaymentTerminal = Environment.get(
                        "ENABLE_PAYMENT_TERMINAL",
                        ""
                      ).split(",");

                      const hasAccessToPaymentTerminal =
                        enablePaymentTerminal.includes("ALL") ||
                        enablePaymentTerminal.includes(sendingMember);

                      if (
                        paymentTerminalEnabledForMembercode &&
                        hasAccessToPaymentTerminal
                      ) {
                        const { terminal_settings = [] } =
                          UserProfileStorage.getShopPreferences(sendingMember);

                        const { currentPOSSettings = {} } =
                          await getCurrentPOSSettings(terminal_settings);

                        if (
                          currentPOSSettings?.serial_number &&
                          currentPOSSettings?.ip_address
                        ) {
                          paymentOptions.unshift({
                            label: Localise(messages, "Payment Terminal"),
                            value: "PAYMENT_TERMINAL",
                          });

                          setFieldValue(
                            "paymentDetails.paymentMethod.0.paymentMethodType",
                            "PAYMENT_TERMINAL"
                          );

                          setFieldValue(
                            "paymentDetails.paymentMethod.0.savePayment",
                            true
                          );
                        }
                      }
                    }

                    result["credit_card"] === "true" &&
                      paymentOptions.push({
                        label: Localise(messages, "Credit Card"),
                        value: "CREDIT_CARD",
                      });

                    setPaymentOptions(paymentOptions);

                    UserProfileStorage.setShopPreferences(sendingMember, {
                      credit_card: result["credit_card"] || "",
                      house_account_invoice:
                        result["house_account_invoice"] || "",
                      delivery_fee: result["delivery_fee"] || "0.00",
                      tax_delivery_fee: result["tax_delivery_fee"] || "false",
                      retail_delivery_fee:
                        result["retail_delivery_fee"] || "0.00",
                      tax_retail_delivery_fee:
                        result["tax_retail_delivery_fee"] || "false",
                      relay_fee: result["relay_fee"] || "0.00",
                      local_order_tax_settings:
                        result["local_order_tax_settings"] || "point_of_origin",
                      wired_order_tax_settings:
                        result["wired_order_tax_settings"] || "point_of_origin",
                      tax_on_local_delivery_fee:
                        result["tax_on_local_delivery_fee"] || "",
                      tax_on_outside_local_delivery_fee:
                        result["tax_on_outside_local_delivery_fee"] || "",
                      tax_on_service_fee: result["tax_on_service_fee"] || "",
                      tax_on_relay_fee: result["tax_on_relay_fee"] || "",
                    });
                  }
                })
                .catch((err) => {
                  console.log(
                    "Error occurred while getting shop preferences :>> ",
                    err
                  );
                });
            } else {
              setFieldValue("orderItems.0.deliveryFee", "0.00");
              setFieldValue("orderItems.0.applyTaxOnDF", "false");
              setFieldValue("orderItems.0.retailDeliveryFee", "0.00");
              setFieldValue("orderItems.0.applyTaxOnRDF", "false");
              setFieldValue("orderItems.0.relayFee", "0.00");

              setPaymentOptions([]);
            }

            dispatch(setCreateSubscriptionShopCode(sendingMember));
            sendingMemberRef.current = sendingMember;
          }, [sendingMember]);

          useEffect(() => {
            const ordersCount = values.orderItems.length;
            if (ordersCount === orderPositions.length && scrollEnabled) {
              scrollViewRef &&
                scrollViewRef.current.scrollTo({
                  x: 0,
                  y: orderPositions[ordersCount - 1],
                  animated: true,
                });
              setScrollEnabled(false);
            }
          }, [orderPositions]);

          useEffect(() => {
            if (productInfo && hasLineItems) {
              productInfo.productName &&
                setFieldValue(
                  `orderItems.0.lineItems.${productInfo.index}.productFirstChoiceDescription`,
                  `${productInfo.productName}`
                );

              // Updating main product image here as we are facing re-rendering issues.
              productInfo.image &&
                setFieldValue(
                  `orderItems.0.lineItems.${productInfo.index}.img`,
                  `${productInfo.image}`
                );
            }
          }, [productInfo]);

          useEffect(() => {
            if (!isEmpty(selectedProduct)) {
              const {
                actionType,
                product: { pid, price, name, img, refNumberId },
                formIKPricePath,
                formIKPath,
              } = selectedProduct;
              const isAddOrReplace = ["Add", "Replace"].includes(actionType);

              let products = get(values, formIKPath, []);
              if (products.length) {
                products = products.slice(1);
              }

              const updatedProducts = isAddOrReplace
                ? [
                    {
                      productFirstChoiceCode: pid,
                      productFirstChoiceDescription: name,
                      productSecondChoiceCode: "NONE",
                      productSecondChoiceDescription: "NONE",
                      productFirstChoiceRefNumberId: refNumberId || pid,
                      img,
                      actualPrice: price,
                    },
                    ...products,
                  ]
                : [];

              setFieldValue(formIKPath, updatedProducts);

              let prices = get(values, formIKPricePath, []);
              if (prices.length) {
                prices = prices.slice(1);
              }

              const actualCustomerInfo = get(values, "actualCustomerInfo");

              const updatedPrices = isAddOrReplace
                ? [
                    {
                      name: "orderTotal",
                      value: price,
                      discount: actualCustomerInfo?.discountPercentage
                        ? parseFloat(
                            actualCustomerInfo.discountPercentage
                          ).toFixed(2)
                        : "0.00",
                      discountType: actualCustomerInfo?.discountPercentage
                        ? "Percentage"
                        : null,
                    },
                    ...prices,
                  ]
                : [];

              setFieldValue(formIKPricePath, updatedPrices);

              if (actionType) {
                setShowSideCar("");
              }
            }
          }, [selectedProduct]);

          useEffect(() => {
            setFormValues(values);
            let totals = [];
            let discounts = [];
            for (let orderInfo of values.orderItems) {
              let orderTotal = 0;
              let orderDiscount = 0;
              orderInfo.price.forEach((price, index) => {
                const { value = 0, discount = 0, discountType } = price;
                const { quantity = 1 } = orderInfo?.lineItems[index];
                const productDiscount = parseFloat(
                  getDiscount(discount, value, discountType, quantity)
                );
                orderDiscount = orderDiscount + productDiscount;
                orderTotal =
                  orderTotal + parseFloat(getPrice(value)) - productDiscount;
              });
              totals.push(orderTotal);
              discounts.push(orderDiscount);
            }
            setDiscounts(discounts);
            setSubTotals(totals);
          }, [values]);

          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount, status]);

          const hasLineItems = orderItems[0].lineItems.length > 0;
          const recipientInfo = orderItems[0].recipientInfo;

          useEffect(() => {
            if (!hasLineItems) {
              setFieldValue(
                "paymentDetails",
                basicPaymentInfo(isPaymentEntitlementEnabled)
              );
              setFieldValue(
                "paymentDetails.paymentMethod.0.enablePayment",
                isPaymentEntitlementEnabled
              );
              setFieldValue(`orderItems.0.taxInfo`, {
                taxAmount: 0,
                taxRate: 0.0,
              });
            }
          }, [hasLineItems]);

          useEffect(() => {
            const {
              country,
              deliveryMethod,
              hasRecipientInfo: prevHasRecipientInfo,
              ...other
            } = recipientInfo;

            const hasRecipientInfo =
              Object.values(other).filter((val) => !!val).length > 0;

            if (prevHasRecipientInfo !== hasRecipientInfo) {
              setFieldValue("orderItems.0.recipientInfo", {
                ...recipientInfo,
                hasRecipientInfo,
              });
            }
          }, [recipientInfo]);

          const { permissions = {} } = React.useContext(AppSettingsContext);

          useEffect(() => {
            setFieldValue("savePayment", isPaymentNeeded);
            setFieldValue("enablePayment", isPaymentNeeded);
            setFieldValue(
              "paymentDetails.paymentMethod.0.enablePayment",
              isPaymentNeeded
            );
          }, [isPaymentNeeded]);

          const showPayment =
            isPaymentEntitlementEnabled &&
            isModifyCheck(
              values,
              subTotals,
              subscriptionDetails,
              setIsPaymentNeeded
            );

          showPaymentRef.current = showPayment;

          return (
            <>
              <CustomerProfileInfo
                isSmallScreen={isSmallScreen}
                memberCodes={memberCodes}
                values={values}
                setValues={setValues}
                permissions={permissions}
              />
              <FieldArray
                name="orderItems"
                render={(arrayHelpers) => (
                  <View>
                    {values.orderItems.map((order, index) => (
                      <OrderItem
                        index={index}
                        isSmallScreen={isSmallScreen}
                        orderInfo={order}
                        key={`order-${index}`}
                        setOrderPositions={setOrderPositions}
                        orderPositions={orderPositions}
                        arrayHelpers={arrayHelpers}
                        values={values}
                        setSideCarOpen={setSideCarOpen}
                        setScrollEnabled={setScrollEnabled}
                        setSelectedProduct={setSelectedProduct}
                        abbreviationCodes={abbreviationCodes}
                        setValues={setValues}
                        addons={props.global.addons}
                        quickProducts={quickProducts}
                        orderItemsLength={values.orderItems.length}
                        isAutoFill={isAutoFill}
                        setProductInfo={setProductInfo}
                        errors={errors}
                        setErrors={setErrors}
                        sideCarKey={sideCarKey}
                        subTotal={subTotals[index]}
                        discount={discounts[index]}
                        memberCodes={memberCodes}
                        sendingMemberCode={sendingMemberCode}
                        setValidationOnChange={setValidationOnChange}
                      />
                    ))}
                  </View>
                )}
              />
              {isPaymentEntitlementEnabled && (
                <OrderSummary
                  index={0}
                  customerInfo={values?.customerInfo}
                  orderInfo={values?.orderItems[0]}
                  sendingMemberCode={sendingMemberCode}
                  isDeliveryFeeEditable={false}
                  enablePromoCodes={false}
                  isSubscriptionPage={true}
                  isPaymentEntitlementEnabled={isPaymentEntitlementEnabled}
                />
              )}

              {showPayment && (
                <View testID="subscription_payment">
                  <PaymentDetails
                    isSmallScreen={isSmallScreen}
                    values={values}
                    setValues={setValues}
                    setFieldValue={setFieldValue}
                    subTotals={subTotals}
                    paymentOptions={paymentOptions}
                    sendingMemberCode={sendingMemberCode}
                    mandatorySave={true}
                    isSubscriptionPage={true}
                  />

                  {isElectronApp && paymentMethodType === "PAYMENT_TERMINAL" ? (
                    <View
                      style={[
                        tw("flex flex-row flex-wrap"),
                        { marginTop: 15, zIndex: -1 },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            ...fonts.heading6,
                            fontWeight: "normal",
                          },
                        ]}
                      >
                        {`* ${Localise(
                          messages,
                          "Digital payments like Apple Pay, Google Pay, Samsung Pay, and PayPal are not available when creating and modifying Subscriptions."
                        )}`}
                      </Text>
                    </View>
                  ) : null}
                </View>
              )}

              <View style={[tw("flex flex-col flex-wrap")]}>
                {!isEmpty(errors) ? (
                  <Text
                    style={[theme.SubmitButton.errorStyle, tw("text-right")]}
                  >
                    {Localise(
                      messages,
                      "Please complete required fields to create this subscription order"
                    )}
                  </Text>
                ) : (
                  <></>
                )}
                <View style={[tw("flex flex-row items-center justify-end")]}>
                  <View
                    style={{
                      ...theme.Button.cancelButtonStyle,
                      width: isDesktop ? 72 : 80,
                      height: 35,
                      marginRight: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        if (subscriptionDetails?.isModify) {
                          navigateToCustomerDirectory();
                        } else {
                          props.resetCurrentProduct();
                          resetForm({
                            ...initialValues,
                            customerInfo: values.customerInfo,
                          });
                          setValues({
                            ...initialValues,
                            customerInfo: values.customerInfo,
                          });
                          setScrollEnabled(true);
                          setOrderPositions([0]);
                          isDesktop &&
                            [
                              "florist_selection",
                              "product_selection",
                              "abbreviation_codes",
                            ].includes(sideCarKey) &&
                            setSideCarOpen("");
                        }
                      }}
                      testID="cancel_order"
                      accessibilityLabel="cancel_order"
                    >
                      <Text
                        style={{
                          ...theme.Button.secondaryTitleStyle,
                          color: colors.highlighter,
                          fontSize: 12,
                        }}
                      >
                        {Localise(messages, "Cancel")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity ref={buttonRef}>
                    <SubmitButton
                      containerStyle={{
                        width: isDesktop ? 78 : 90,
                        height: 35,
                        margin: isSmallScreen ? 10 : 2,
                      }}
                      isSubmitOnEnter={true}
                      title={Localise(messages, "Submit")}
                      buttonRef={buttonRef}
                      disabled={calculatingTax}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </>
          );
        }}
      />
    </View>
  );
};

const CreateSubscriptionTemplate = (props) => {
  const dispatch = useDispatch();
  const [showSideCar, setShowSideCar] = useStateIfMounted("");
  const [sideCarInfo, setSideCarInfo] = useStateIfMounted({});
  const [selectedProduct, setSelectedProduct] = useStateIfMounted({});
  const [productInfo, setProductInfo] = useStateIfMounted({});
  const [formValues, setFormValues] = useStateIfMounted({});
  const [isFormSubmitting, setFormSubmitting] = useStateIfMounted(false);
  const [subTotals, setSubTotals] = useStateIfMounted([0]);
  const [discounts, setDiscounts] = useStateIfMounted([0]);
  const { isDesktop } = useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { proxyUser } = React.useContext(UserProfileContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const shopCodes = UserProfileStorage.getProfileMemberCodes();

  const filteredShopCodes = [];
  shopCodes.map((memberCode) => {
    if (isMHQNonCoreMember(memberCode)) filteredShopCodes.push(memberCode);
  });

  const showSideCarArr = showSideCar ? showSideCar.split("-") : [];
  const sideCarKey = showSideCarArr[0];
  const orderIndex = showSideCarArr[1] || 0;
  const formIKPath = `orderItems.${orderIndex}`;

  const setSideCarOpen = React.useCallback((key, value = {}) => {
    setShowSideCar(key);
    setSideCarInfo(value);
  });

  const screenOneScrollViewRef = useRef();

  const { hasMultipleShops = false, sendingMember } = formValues;

  const sendingMemberCode = hasMultipleShops
    ? sendingMember || filteredShopCodes[0]
    : filteredShopCodes[0];

  useEffect(() => {
    dispatch(setCurrentPage("create-subscription"));
    return () => dispatch(setCurrentPage(""));
  }, []);

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  return (
    <SplitScreen
      showTwoScreens={showSideCar}
      isFormSubmitting={isFormSubmitting}
      subHeaderExists={isSmallScreen}
      proxyHeaderExists={proxyUser ? true : false}
      sessionBannerExists={isElectronApp && !props?.session?.sessionId}
    >
      <View>
        <SubHeader />
        <View />

        <View
          style={[
            {
              height: Platform.OS === "web" ? `calc(100% - 90px)` : "100%",
            },
          ]}
        >
          {/* Providing height and ScrollView for create subscription Temporarily to fix height issue if we have 2 or more banners. In future this part will be moved to create order screen, we won't see this issue */}
          <ScrollView
            key={"CreateSubscriptionScreenContent"}
            ref={screenOneScrollViewRef}
          >
            <CreateSubscriptionScreen
              setSideCarOpen={setSideCarOpen}
              selectedProduct={selectedProduct}
              isSmallScreen={isSmallScreen}
              formValues={formValues}
              setFormValues={setFormValues}
              productInfo={productInfo}
              setProductInfo={setProductInfo}
              props={props}
              isFormSubmitting={isFormSubmitting}
              setFormSubmitting={setFormSubmitting}
              memberCodes={filteredShopCodes}
              setShowSideCar={setShowSideCar}
              sideCarKey={sideCarKey}
              subTotals={subTotals}
              setSubTotals={setSubTotals}
              discounts={discounts}
              setDiscounts={setDiscounts}
              permissions={permissions}
              sendingMemberCode={sendingMemberCode}
              scrollViewRef={screenOneScrollViewRef}
            />
          </ScrollView>
        </View>
      </View>
      <View>
        <SubHeader
          title={Localise(messages, "Create Subscription")}
          onTrigger={() => {
            setShowSideCar("");
          }}
        />
        <SideCar
          setShowSideCar={setShowSideCar}
          formValues={formValues}
          sideCarKey={sideCarKey}
          sideCarInfo={sideCarInfo}
          formIKPath={formIKPath}
          setSelectedRecord={setSelectedProduct}
          isSmallScreen={isSmallScreen}
          subTotals={subTotals}
          memberCodes={filteredShopCodes}
          sendingMemberCode={sendingMemberCode}
        />
      </View>
    </SplitScreen>
  );
};

const SideCar = ({
  setShowSideCar,
  sideCarKey,
  sideCarInfo,
  formValues,
  formIKPath,
  scrollViewRef,
  setSelectedRecord,
  isSmallScreen,
  // taxes,
  // subTotals,
  memberCodes,
  sendingMemberCode,
}) => {
  useEffect(() => {
    scrollViewRef &&
      scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
  }, []);

  return (
    <View>
      {sideCarKey === "product_selection" && (
        <ProductSelection
          onComplete={() => setShowSideCar("")}
          sideCarInfo={sideCarInfo}
          formValues={formValues}
          formIKPath={formIKPath}
          onSelect={(product, actionType) => {
            setSelectedRecord({
              product,
              actionType,
              formIKPath: `${formIKPath}.lineItems`,
              formIKPricePath: `${formIKPath}.price`,
              quantity: 1,
            });
          }}
          isSmallScreen={isSmallScreen}
          memberCodes={memberCodes}
          sendingMemberCode={sendingMemberCode}
          isFromSubscription={true}
        />
      )}
      {sideCarKey === "abbreviation_codes" && (
        <AbbreviationCodes
          onComplete={() => setShowSideCar("")}
          sideCarInfo={sideCarInfo}
        />
      )}
      <PrintIframeRenderer testID={"create-subscription"} />
    </View>
  );
};
const mapStateToProps = (state = {}) => {
  return {
    global: state.mhq.ongoing.global,
    session: state.mhq.session,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAddons,
      setLoading,
      resetCurrentProduct,
      createTransaction,
      setCreateSubscriptionShopCode,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSubscriptionTemplate);
