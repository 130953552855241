import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
} from "react-native";
import { CheckBox } from "react-native-elements";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import get from "lodash/get";
import IMAGES from "static/assets/images";
import { theme } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";

const FormFieldCheckBox = ({
  containerStyle,
  inputContainerStyle,
  isChecked = false,
  iconRight,
  label,
  name,
  path,
  size,
  title,
  isGroup = false,
  defaultChecked = false,
  textStyle = {},
  onChangehandler,
  isEditable = true,
  errorStyle = {},
  testID,
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const key = (path && `${path}.${name}`) || name;
  const touchedVal = get(touched, key);
  const errorMsg = touchedVal && get(errors, key);

  const value = get(values, key);
  let fieldValue = defaultChecked
    ? defaultChecked
    : isGroup
    ? value
    : isBoolean(value)
    ? value
    : isChecked;
  const titles = isArray(title) ? title : [title];
  const { messages, Localise } = React.useContext(I18NContext);

  // useEffect(() => {
  //   if (defaultChecked) setFieldValue(key, defaultChecked);
  // }, []);

  const handleOnPress = useCallback(
    (title) => {
      if (isGroup) {
        const index = fieldValue.indexOf(title);
        if (index === -1) {
          setFieldValue(key, [...fieldValue, title]);
        } else {
          const data = [...fieldValue];
          data.splice(index, 1);
          setFieldValue(key, [...data]);
        }
      } else {
        setFieldValue(key, !fieldValue);
      }
    },
    [fieldValue]
  );

  return useMemo(() => {
    return (
      <View
        style={[
          tw("flex flex-col"),
          theme.CheckBox.containerStyle,
          containerStyle,
        ]}
      >
        {label && (
          <Text style={[theme.CheckBox.labelStyle]}>
            {Localise(messages, label)}
          </Text>
        )}
        <View style={tw("flex flex-row flex-wrap")}>
          {titles.map((title, index) => (
            <CheckBox
              key={index}
              testID={title.replace(" ", "_") || testID}
              accessibilityLabel={title.replace(" ", "_")}
              Component={
                isEditable ? TouchableOpacity : TouchableWithoutFeedback
              }
              checked={isGroup ? fieldValue?.includes(title) : fieldValue}
              checkedIcon={
                <Image
                  source={IMAGES["checkbox-checked"]}
                  style={{ width: 20, height: 20 }}
                />
              }
              uncheckedIcon={
                <Image
                  source={IMAGES["checkbox-unchecked"]}
                  style={{ width: 20, height: 20 }}
                />
              }
              containerStyle={[
                theme.CheckBox.inputContainerStyle,
                inputContainerStyle,
              ]}
              iconRight={iconRight}
              onPress={() => {
                if (!isEditable) return;
                onChangehandler ? onChangehandler(title) : handleOnPress(title);
              }}
              size={size}
              textStyle={{ ...theme.CheckBox.textStyle, ...textStyle }}
              title={Localise(messages, title)}
            />
          ))}
        </View>
        <Text style={{ ...theme.CheckBox.errorStyle, ...errorStyle }}>
          {errorMsg}
        </Text>
      </View>
    );
  }, [fieldValue, errorMsg, messages, title, onChangehandler, isEditable]);
};

export default React.memo(FormFieldCheckBox);
