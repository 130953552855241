import React, { useContext, useMemo } from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { COMMON, SHOP_SETTINGS, ERROR_MESSAGES } from "library/constants";
import tw from "tailwind-rn";
import { fonts, shapes, theme, colors } from "styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPromoCodeslist,
  selectShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { FormFieldSwitch } from "components/elements/forms";
import { ToasterHandler } from "components/elements";
import { LabelField, ConfirmModal } from "components/wrappers";
import { IMAGES } from "static/assets/images";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  updatePromoCode,
  fetchPromoCodes,
  deletePromoCode,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { promoParams } from "./helper";

const PromoCodesList = () => {
  const { messages, Localise } = useContext(I18NContext);
  const { isMobile, isDesktop } = useContext(DeviceContext);
  const dispatch = useDispatch();
  const memberCode = useSelector(selectShopCode);
  const promoCodes = useSelector(selectPromoCodeslist(memberCode));

  const [viewMoreCounts, setViewMoreCounts] = useStateIfMounted(10);
  const [showConfirmModal, setShowConfirmModal] = useStateIfMounted(false);
  const [promoActions, setPromoActions] = useStateIfMounted({});

  const formatDate = (date) => moment(date).format("MM/DD/YYYY");

  const showViewMore = useMemo(() => {
    return promoCodes.length > 10 && promoCodes.length > viewMoreCounts;
  }, [promoCodes, viewMoreCounts]);

  const modifyPromoCode = ({ promoCodeID, memberCode, isActive }) => {
    dispatch(
      updatePromoCode({
        params: {
          memberCode,
          promoCodeID,
          action: isActive ? "ACTIVATE" : "DEACTIVATE",
        },
        resolve: () => {
          dispatch(
            fetchPromoCodes({
              params: promoParams(),
              resolve: () => {
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_UPDATE_SUCCESS)
                );
                setPromoActions({});
              },
              reject: () => {
                //Displaying success toaster even if fetch call fails
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_UPDATE_SUCCESS)
                );
                setPromoActions({});
              },
            })
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
          );
          setPromoActions({});
        },
      })
    );
  };

  const deleteHandler = ({ promoCodeID, memberCode }) => {
    dispatch(
      deletePromoCode({
        params: { promoCodeID, memberCode },
        resolve: () => {
          dispatch(
            fetchPromoCodes({
              params: promoParams(),
              resolve: () => {
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_DELETION_SUCCESS)
                );
                setPromoActions({});
              },
              reject: () => {
                //Displaying success toaster even if fetch call fails
                ToasterHandler(
                  "success",
                  Localise(messages, SHOP_SETTINGS.PROMO_DELETION_SUCCESS)
                );
                setPromoActions({});
              },
            })
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
          );
          setPromoActions({});
        },
      })
    );
  };

  return (
    <View>
      <View style={tw(`${isDesktop ? "w-1/2" : "w-full"}`)}>
        {promoCodes.slice(0, viewMoreCounts).map((item, index) => {
          const {
            promoCode,
            memberCode,
            promoCodeID,
            discountType,
            discountValue,
            startDate,
            endDate,
            isActive,
          } = item;

          const isExpired = moment().isAfter(moment(endDate), "day");

          const amount =
            discountType === COMMON.DOLLAR
              ? `$ ${discountValue.toFixed(2)}`
              : `${discountValue.toFixed(2)} %`;

          return (
            <View
              key={`${promoCode}-${index}`}
              style={[
                tw(`flex flex-row w-full p-2 rounded-sm my-1`),
                shapes.sectionBorder,
              ]}
            >
              <View
                style={[
                  tw(`flex ${isMobile ? "flex-col" : "flex-row"}`),
                  { width: isDesktop ? "75%" : "60%" },
                ]}
              >
                <Text
                  style={[
                    tw(`${isMobile ? "w-full pb-1" : "w-2/5"}`),
                    fonts.default,
                  ]}
                  testID={`promo_code_${promoCode}`}
                  accessibilityLabel={`promo_code_${promoCode}`}
                >
                  {promoCode}
                </Text>
                <Text
                  style={[
                    tw(`${isMobile ? "w-full pb-1" : "w-1/5"}`),
                    fonts.default,
                  ]}
                  testID="promo_code_value"
                  accessibilityLabel="promo_code_value"
                >
                  {amount}
                </Text>
                <Text
                  style={[
                    tw(`${isMobile ? "w-full" : "w-2/5"}`),
                    fonts.default,
                  ]}
                  testID="promo_code_date_range"
                  accessibilityLabel="promo_code_date_range"
                >
                  {formatDate(startDate)} - {formatDate(endDate)}
                </Text>
              </View>

              <View
                style={[
                  tw(
                    `flex flex-row ${
                      isDesktop ? "justify-between" : "justify-end"
                    }`
                  ),
                  { width: isDesktop ? "25%" : "40%" },
                ]}
              >
                {promoActions?.promoCodeID !== promoCodeID ? (
                  <>
                    <View
                      style={[
                        tw(`flex flex-row justify-start items-center`),
                        { width: 100 },
                      ]}
                    >
                      {!isExpired && (
                        <View style={tw("pr-1")}>
                          <FormFieldSwitch
                            value={isActive}
                            onValueChange={(isActive) => {
                              setPromoActions({ promoCodeID, memberCode });
                              modifyPromoCode({
                                promoCodeID,
                                memberCode,
                                isActive,
                              });
                            }}
                            disabled={!isEmpty(promoActions)}
                            testID="active_inactive_promo_code"
                            accessibilityLabel="active_inactive_promo_code"
                          />
                        </View>
                      )}
                      <LabelField
                        text={Localise(
                          messages,
                          isExpired
                            ? COMMON.EXPIRED
                            : isActive
                            ? COMMON.ACTIVE
                            : COMMON.INACTIVE
                        )}
                        style={tw("pb-0")}
                      />
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        setPromoActions({ promoCodeID, memberCode });
                        setShowConfirmModal(true);
                      }}
                      testID="delete_promo_code"
                      accessibilityLabel="delete_promo_code"
                      style={[tw("flex justify-center items-start w-5")]}
                      disabled={!isEmpty(promoActions)}
                    >
                      <Image style={tw("w-5 h-5")} source={IMAGES["delete"]} />
                    </TouchableOpacity>
                  </>
                ) : (
                  <View style={tw("flex justify-center items-center w-full")}>
                    <ActivityIndicator
                      style={tw("mx-2")}
                      color={colors.activityIndicator}
                    />
                  </View>
                )}
              </View>
            </View>
          );
        })}
        {showViewMore && (
          <Button
            titleStyle={theme.Button.secondaryTitleStyle}
            buttonStyle={{
              ...theme.Button.secondaryButtonStyle,
              paddingHorizontal: 10,
              paddingVertical: 6,
              width: 95,
              height: 35,
            }}
            containerStyle={tw("items-end mx-0")}
            title={Localise(messages, "View More")}
            onPress={() => {
              setViewMoreCounts(viewMoreCounts + 10);
            }}
            testID="promo_code_view_more"
            accessibilityLabel="promo_code_view_more"
          />
        )}
      </View>

      {isEmpty(promoCodes) && (
        <Text
          style={tw("flex justify-center items-center")}
          testID="empty_promo_codes"
          accessibilityLabel="empty_promo_codes"
        >
          {Localise(messages, SHOP_SETTINGS.NO_PROMO_CODES)}
        </Text>
      )}
      <ConfirmModal
        modalVisible={showConfirmModal}
        handlePrimary={() => {
          deleteHandler(promoActions);
          setShowConfirmModal(false);
        }}
        handleSecondary={() => {
          setPromoActions({});
          setShowConfirmModal(false);
        }}
        data={{
          modal: {
            primary: COMMON.YES,
            secondary: COMMON.NO,
            content: Localise(
              messages,
              SHOP_SETTINGS.PROMO_CONFIRM_MODAL_CONTENT
            ),
          },
        }}
        contentStyle={tw("p-0 text-center")}
        isReduceBtnSpacing={true}
      />
    </View>
  );
};

export default PromoCodesList;
