const COMMON = Object.freeze({
  ACTIVE: "Active",
  AMOUNT: "Amount",
  AMOUNT_INVALID: "Invalid Amount",
  AMOUNT_REQUIRED: "Amount is required",
  DISCOUNT: "Discount",
  DISCOUNT_TYPE: "Discount Type",
  DISCOUNT_TYPE_REQUIRED: "Discount type is required",
  DOLLAR: "Dollar",
  END: "End",
  END_DATE_BEFORE_START: "End date cannot be before start date",
  END_DATE_REQUIRED: "End date is required",
  EXPIRED: "Expired",
  INACTIVE: "Inactive",
  INVALID_PERCENTAGE: "% cannot exceed 100",
  NO: "No",
  PERCENTAGE: "Percentage",
  SAVE: "Save",
  SELECT_DISCOUNT: "Select Discount",
  START: "Start",
  START_DATE_REQUIRED: "Start date is required",
  YES: "Yes",
});

export default COMMON;
