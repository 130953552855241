import React from "react";
import { Text, View, ActivityIndicator } from "react-native";
import I18NContext from "library/contexts/i18N";
import { colors, fonts } from "styles/theme";
import tw from "tailwind-rn";

const LabelField = ({
  text,
  heading,
  style,
  numberOfLines = 1,
  textColor = colors.primary,
  processing,
}) => {
  const { Localise, messages } = React.useContext(I18NContext);
  return (
    <View style={tw("flex flex-row justify-between items-center")}>
      <Text
        numberOfLines={numberOfLines}
        style={{
          ...(heading ? fonts.heading5 : fonts.default),
          paddingBottom: heading ? 8 : 4,
          color: textColor,
          ...style,
        }}
      >
        {Localise(messages, text)}
      </Text>
      {processing && <ActivityIndicator color={colors.secondary} size={14} />}
    </View>
  );
};

export default LabelField;
