import React from "react";
import { TouchableOpacity, Image } from "react-native";
import I18NContext from "library/contexts/i18N";
import IMAGES from "static/assets/images";
import { Tooltip } from "components/elements";

const OpenQuickSaleArea = ({ isQuickSaleEnabled, setFieldValue }) => {
  const formIKParentPath = `orderItems.0.deliveryInfo`;
  const formIKPath = `orderItems.0.recipientInfo`;
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <TouchableOpacity
      onPress={() => {
        setFieldValue("isQuickSaleEnabled", !isQuickSaleEnabled);

        setFieldValue(
          `${formIKPath}.deliveryMethod`,
          !isQuickSaleEnabled ? "WALK_IN" : ""
        );
        setFieldValue(`actualCustomerInfo`, "");
        setFieldValue(`customerInfo`, "");
        setFieldValue(`hasCustomerInfo`, false);

        setFieldValue(`${formIKPath}.addressLine1`, "");
        setFieldValue(`${formIKPath}.city`, "");
        setFieldValue(`${formIKPath}.firstName`, "");
        setFieldValue(`${formIKPath}.hasRecipientInfo`, false);
        setFieldValue(`${formIKPath}.lastName`, "");
        setFieldValue(`${formIKPath}.locationName`, "");
        setFieldValue(`${formIKPath}.locationType`, "");
        setFieldValue(`${formIKPath}.phone`, "");
        setFieldValue(`${formIKPath}.state`, "");
        setFieldValue(`${formIKPath}.suite`, "");
        setFieldValue(`${formIKPath}.zip`, "");

        setFieldValue(
          `${formIKParentPath}.deliveryMethod`,
          !isQuickSaleEnabled ? "WALK_IN" : ""
        );
        setFieldValue(
          `orderItems.0.deliveryMethod`,
          !isQuickSaleEnabled ? "WALK_IN" : ""
        );
        setFieldValue("orderType", !isQuickSaleEnabled ? "Local" : "");
        setFieldValue(`orderItems.0.deliveryFee`, 0);
        setFieldValue(`orderItems.0.retailDeliveryFee`, 0);
        setFieldValue(`orderItems.0.relayFee`, 0);
      }}
      key={`QuickSaleArea`}
      testID="quick_sale_area"
      accessibilityLabel="quick_sale_area"
    >
      <Tooltip text={Localise(messages, "Quick Sale")} renderForWebOnly={true}>
        <Image
          style={{ width: 29, height: 27 }}
          resizeMode="cover"
          source={
            IMAGES[
              isQuickSaleEnabled ? "quick-sale-enable" : "quick-sale-disable"
            ]
          }
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

export default React.memo(OpenQuickSaleArea);
