import React from "react";
import { View } from "react-native";
import { Text, Icon, Image } from "react-native-elements";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { useDispatch } from "react-redux";

import { Tooltip } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";

import { colors, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";
import moment from "moment";
import tw from "tailwind-rn";
import some from "lodash/some";
import get from "lodash/get";

import styles from "./styles";

let dayContentMap = [
  {
    imageName: "suspend-alert",
    valueKey: "suspendedMembersCount",
    width: 17,
    height: 16,
    toolTipText: "One or more of your shops is suspended for this date",
  },
  {
    imageName: "orders",
    valueKey: "ordersCount",
    width: 17,
    height: 17,
  },
  {
    imageName: "delivery-black",
    errorImage: "delivery-error",
    valueKey: "pendingDCONCount",
    width: 23,
    height: 15,
  },
  // {
  //   imageName: "staff-management",
  //   valueKey: "staffCount",
  //   width: 15,
  //   height: 17,
  // },
  // {
  //   imageName: "reminders",
  //   valueKey: "remindersCount",
  //   width: 16,
  //   height: 17,
  // },
  {
    imageName: "average-order-value",
    valueKey: "aovPrice",
    width: 29,
    height: 16,
  },
  {
    imageName: "dollar",
    valueKey: "salesPrice",
    width: 17,
    height: 17,
  },
];

const CalendarWidget = ({
  widgetResponse,
  calendarDates,
  setCalendarDates,
  onPress,
  shopCodes,
  shopNames,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { messages, Localise } = React.useContext(I18NContext);
  const { startDate: currentStartDate, endDate: currentEndDate } =
    calendarDates;
  const { isTablet } = React.useContext(DeviceContext);
  const { permissions } = React.useContext(AppSettingsContext);

  const handleIconClick = (direction) => {
    if (direction === "left") {
      setCalendarDates({
        startDate: moment(currentStartDate)
          .subtract(1, "weeks")
          .startOf("isoweek")
          .format("YYYY-MM-DD"),
        endDate: moment(currentEndDate)
          .subtract(1, "weeks")
          .endOf("isoweek")
          .format("YYYY-MM-DD"),
      });
    } else {
      setCalendarDates({
        startDate: moment(currentStartDate)
          .add(1, "weeks")
          .startOf("isoweek")
          .format("YYYY-MM-DD"),
        endDate: moment(currentEndDate)
          .add(1, "weeks")
          .endOf("isoweek")
          .format("YYYY-MM-DD"),
      });
    }
  };

  const handleOnPress = (index, item) => {
    if (index === 2 && item.pendingDCONCount > 0) {
      const isPastDate = moment(item.date).isBefore(moment().add(-1, "days"));
      const isPendingDcon = isPastDate && item.pendingDCONCount >= 1;

      // Clicked on undelivered orders
      if (isPendingDcon) {
        onPress(); // Clearing the filters if any applied in current-orders

        const filters = [
          {
            section: "Categories",
            title: "Needs Action",
            value: "needsAction",
          },
          {
            section: "Categories",
            title: "Undelivered Orders",
            value: "undeliveredOrders",
          },
          {
            section: "Date Range",
            title: "Custom Range",
            value: {
              startDate: moment(item.date).format("YYYY-MM-DD"),
              endDate: moment(item.date).format("YYYY-MM-DD"),
              dateType: "DELIVERY_DATE",
            },
          },
          {
            section: "Categories",
            title: "Inbound Orders",
            value: "INBOUND",
          },
        ];
        const shops = [];

        shopCodes !== "all" &&
          shops.push({
            section: "Shop Codes",
            title: `${shopCodes} ${shopNames[shopCodes]}`,
            value: shopCodes,
          });

        navigation.dispatch(
          CommonActions.navigate({
            name: "orders",
            params: {
              filters,
              shops,
            },
          })
        );
      }
    } else if (index === 0 && item.suspendedMembersCount > 0) {
      dispatch(setCurrentPage("shop-settings"));
      navigation.dispatch(
        CommonActions.navigate({
          name: "shop-settings",
          params: {
            selectedSuspendedCode: item.suspendedMemberCodes,
          },
        })
      );
    } else if (index === 1 && item.ordersCount > 0) {
      onPress(); // Clearing the filters if any applied in orders screen

      const filters = [
        {
          section: "Date Range",
          title: "Custom Range",
          value: {
            startDate: moment(item.date).format("YYYY-MM-DD"),
            endDate: moment(item.date).format("YYYY-MM-DD"),
            dateType: "DELIVERY_DATE",
          },
        },
        {
          section: "Categories",
          title: "Inbound Orders",
          value: "INBOUND",
        },
        {
          section: "Order Status",
          title: "Active Orders",
          value: "active-orders",
        },
      ];
      const shops = [];

      shopCodes !== "all" &&
        shops.push({
          section: "Shop Codes",
          title: `${shopCodes} ${shopNames[shopCodes]}`,
          value: shopCodes,
        });

      navigation.dispatch(
        CommonActions.navigate({
          name: "orders",
          params: {
            filters,
            shops,
          },
        })
      );
    } else if (index === "dateClick") {
      onPress(); // Clearing the filters if any applied in orders screen

      const filters = [
        {
          section: "Date Range",
          title: "Custom Range",
          value: {
            startDate: moment(item.date).format("YYYY-MM-DD"),
            endDate: moment(item.date).format("YYYY-MM-DD"),
            dateType: "DELIVERY_DATE",
          },
        },
        {
          section: "Categories",
          title: "Inbound Orders",
          value: "INBOUND",
        },
      ];
      const shops = [];

      shopCodes !== "all" &&
        shops.push({
          section: "Shop Codes",
          title: `${shopCodes} ${shopNames[shopCodes]}`,
          value: shopCodes,
        });

      navigation.dispatch(
        CommonActions.navigate({
          name: "orders",
          params: {
            filters,
            shops,
          },
        })
      );
    }
  };

  if (!widgetResponse) return null;

  const startDay = moment(currentStartDate, "YYYY-MM-DD").format("D");
  const endDay = moment(currentEndDate, "YYYY-MM-DD").format("D");
  const startMonth = moment(currentStartDate, "YYYY-MM-DD").format("MMMM");
  const endMonth = moment(currentEndDate, "YYYY-MM-DD").format("MMMM");
  const startYear = moment(currentStartDate, "YYYY-MM-DD").format("YYYY");
  const endYear = moment(currentEndDate, "YYYY-MM-DD").format("YYYY");

  let isAOVEnabled = false;
  let isTOVEnabled = false;

  if (shopCodes === "all") {
    const allPermissions = Object.values(permissions);

    isAOVEnabled =
      some(allPermissions, {
        DASHBOARD: [Entitlements.DASHBOARD_PAGE.AOV],
      }) || false;

    isTOVEnabled =
      some(allPermissions, {
        DASHBOARD: [Entitlements.DASHBOARD_PAGE.TOV],
      }) || false;
  } else {
    const selectedShopPermissions = get(permissions, shopCodes, {});

    isAOVEnabled = selectedShopPermissions[Entitlements.DASHBOARD]?.includes(
      Entitlements.DASHBOARD_PAGE.AOV
    );
    isTOVEnabled = selectedShopPermissions[Entitlements.DASHBOARD]?.includes(
      Entitlements.DASHBOARD_PAGE.TOV
    );
  }

  if (!isAOVEnabled)
    dayContentMap = dayContentMap.filter(
      (contentItem) => contentItem.valueKey !== "aovPrice"
    );

  if (!isTOVEnabled)
    dayContentMap = dayContentMap.filter(
      (contentItem) => contentItem.valueKey !== "salesPrice"
    );

  return (
    <View
      style={[
        styles.calendar,
        tw("border border-gray-500 justify-between mb-4"),
      ]}
    >
      <View>
        <View
          style={{
            ...tw("justify-between mb-6"),
            ...styles.headContainer,
            ...tw("p-3"),
          }}
        >
          <Text
            style={{
              fontSize: isTablet ? 20 : 24,
              color: colors.secondary,
              fontWeight: "600",
              ...tw("p-1"),
            }}
          >
            {Localise(messages, "Calendar")}
          </Text>
          <View style={tw("flex-row items-baseline p-1")}>
            <Icon
              name={"chevron-left"}
              size={14}
              type="font-awesome"
              iconStyle={{ color: colors.secondary, marginRight: 5 }}
              onPress={() => handleIconClick("left")}
              testID="calendar_left_arrow"
              accessibilityLabel="calendar_left_arrow"
            />
            <Text
              style={{
                ...styles.datesContainer,
                textAlign: "center",
              }}
            >
              {startMonth} {startDay}
              {/* {startYear !== endYear && ", " + startYear + " "} - */}
              {", " + startYear} {` - `}
              {/* {startMonth !== endMonth && endMonth} */}
              {endMonth + " "}
              {endDay}, {endYear}
            </Text>
            <Icon
              name={"chevron-right"}
              size={14}
              type="font-awesome"
              iconStyle={{ color: colors.secondary, marginLeft: 5 }}
              onPress={() => handleIconClick("right")}
              testID="calendar_right_arrow"
              accessibilityLabel="calendar_right_arrow"
            />
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={tw("w-full items-center mb-6")}>
            {widgetResponse.map((item, index) => {
              const {
                date,
                shopClosedStartDate,
                shopClosedEndDate,
                pendingDCONCount,
                suspendedMembersCount,
                ordersCount,
              } = item;

              const isPastDate = moment(date).isBefore(
                moment().add(-1, "days")
              );

              const isPendingDcon = isPastDate && pendingDCONCount >= 1;

              const isShopClosed = moment()
                .utc()
                .isBetween(
                  moment(shopClosedStartDate).utc(),
                  moment(shopClosedEndDate).utc()
                );

              const day = moment(date, "YYYY-MM-DD").format("D");
              const weekName = moment(date, "YYYY-MM-DD").format("ddd");

              return (
                <View
                  style={[
                    styles.calendarItem,
                    isShopClosed && { opacity: 0.4 },
                    tw("flex-row items-center justify-between"),
                    { margin: isTablet ? 2 : 10 },
                  ]}
                  key={index}
                >
                  <TouchableWithoutFeedback
                    style={tw("flex-row")}
                    onPress={() => {
                      if (!isShopClosed) {
                        handleOnPress("dateClick", item);
                      }
                    }}
                  >
                    <View
                      style={{
                        ...styles.sideStrip,
                        backgroundColor: backgroundColors.primary,
                      }}
                    />
                    <View style={tw("flex-col justify-center")}>
                      <Text style={{ fontSize: 22, fontWeight: "600" }}>
                        {day}
                      </Text>
                      <Text style={{ fontSize: 13, fontWeight: "500" }}>
                        {weekName}
                      </Text>
                    </View>
                  </TouchableWithoutFeedback>
                  {isShopClosed && (
                    <View>
                      <Text
                        style={{
                          width: isTablet ? 65 : 80,
                          fontSize: 10,
                          fontWeight: "600",
                        }}
                      >
                        SHOP CLOSED
                      </Text>
                    </View>
                  )}
                  <View style={tw("flex-row")}>
                    {dayContentMap.map((contentItem, index) => {
                      if (
                        contentItem.valueKey === "suspendedMembersCount" &&
                        suspendedMembersCount === 0
                      )
                        return;

                      return (
                        <TouchableWithoutFeedback
                          onPress={() => {
                            if (!isShopClosed) {
                              handleOnPress(index, item);
                            }
                          }}
                          style={{
                            cursor:
                              (contentItem.valueKey ===
                                "suspendedMembersCount" &&
                                suspendedMembersCount > 0) ||
                              (contentItem.valueKey === "pendingDCONCount" &&
                                isPendingDcon) ||
                              (contentItem.valueKey === "ordersCount" &&
                                ordersCount > 0)
                                ? "pointer"
                                : "default",
                          }}
                          key={index}
                          testID={`${contentItem.valueKey}_${date}`}
                          accessibilityLabel={`${contentItem.valueKey}_${date}`}
                        >
                          <View style={tw("flex-row")} key={index}>
                            {(contentItem.valueKey === "aovPrice" ||
                              (!isAOVEnabled &&
                                contentItem.valueKey === "salesPrice")) && (
                              <View style={styles.horizontalDivider} />
                            )}

                            <View
                              style={{
                                ...tw(
                                  "flex-col px-2 py-1 items-center justify-start"
                                ),
                                ...styles.contentItem,
                                ...styles[contentItem.valueKey],
                              }}
                            >
                              <Tooltip
                                text={Localise(
                                  messages,
                                  contentItem.toolTipText
                                )}
                              >
                                <View style={{ minHeight: 25 }}>
                                  {IMAGES[contentItem.imageName] && (
                                    <Image
                                      style={{
                                        width: contentItem.width,
                                        height: contentItem.height,
                                      }}
                                      source={
                                        (contentItem.valueKey ===
                                          "pendingDCONCount" &&
                                          isPendingDcon &&
                                          IMAGES[contentItem.errorImage]) ||
                                        IMAGES[contentItem.imageName]
                                      }
                                    />
                                  )}
                                </View>
                              </Tooltip>
                              <Text
                                style={{
                                  color:
                                    contentItem.valueKey ===
                                    "suspendedMembersCount"
                                      ? "#b32357"
                                      : "#000",
                                }}
                              >
                                {["aovPrice", "salesPrice"].includes(
                                  contentItem.valueKey
                                ) && "$"}
                                {item[contentItem.valueKey] || 0}
                              </Text>
                            </View>
                          </View>
                        </TouchableWithoutFeedback>
                      );
                    })}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default CalendarWidget;
