import React from "react";
import { View } from "react-native";
import tw from "tailwind-rn";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { Maps } from "components/elements";
import RecipientInfo from "./recipient-info";
import RequestDateChange from "./request-date-change";

const RecipientDateInfo = ({
  index,
  recordId,
  isSmallScreen,
  orderDetails,
  triggerRequest,
  triggerAction,
  supportDateChange,
}) => {
  const [mapAccordionStatus, setMapAccordionStatus] = useStateIfMounted(false);
  const {
    receivingMember: {
      latitude: fillerLatitude = "",
      longitude: fillerLongitude = "",
    } = {},
    recipientInfo: {
      latitude: recipientLatitude = "",
      longitude: recipientLongitude = "",
    } = {},
  } = orderDetails;

  const fillerLocation = {
    locations: [
      {
        latitude: parseFloat(fillerLatitude || 0),
        longitude: parseFloat(fillerLongitude || 0),
        isFillingFlorist: true,
      },
    ],
  };
  return (
    <View style={[tw("flex flex-col"), { zIndex: 6 }]}>
      <View style={tw("flex flex-row w-full")}>
        <View style={tw("w-1/2 flex items-start")}>
          <RecipientInfo
            {...{
              index,
              recordId,
              isSmallScreen,
              orderDetails,
              mapAccordionStatus,
              setMapAccordionStatus,
            }}
          />
        </View>
        <View style={tw("w-1/2 flex items-end")}>
          <RequestDateChange
            {...{
              index,
              triggerRequest,
              triggerAction,
              supportDateChange,
            }}
          />
        </View>
      </View>
      {/* Map View */}
      {mapAccordionStatus && (
        <View style={tw("py-3")}>
          <Maps
            mapMarkers={{
              ...fillerLocation,
              ...{
                recipientLatLong: {
                  recipientLatitude: parseFloat(recipientLatitude),
                  recipientLongitude: parseFloat(recipientLongitude),
                },
              },
            }}
            zoomLevel={8}
          />
        </View>
      )}
    </View>
  );
};

export default RecipientDateInfo;
